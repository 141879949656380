import React from "react";
import "./WildcardCoverWithControls.scss";
import {ReactComponent as Badge} from "../../../../svgs/badge.svg";
import logo from "../../../../../assets/images/loader_bg.png";
import {ImLock, ImUnlocked, BiPencil, FiTrash} from "react-icons/all";
import {Button} from "react-bootstrap";

export const CoverWithControls = (props) => {
    const {badge, cover, coverLink, locked, showControls, onEditCoverClick, onDeleteCoverClick} = props;

    return (
            <div className="wildcard-cover-container">
                <div className="cover">
                    <img src={cover != null ? cover : logo} alt="cover" className="cover-img"/>
                </div>
                <div className="badge-container">
                    <img className="badge-icon" src={badge} alt="Badgeicon"/>
                    <Badge style={{marginTop: "-1px"}}/>
                </div>
                <h3 className="lock" style={{color: "orange"}}>{locked ? <ImUnlocked/> : <ImLock/>}</h3>
                <div className="button-container">
                    <Button
                            variant="secondary"
                            size="lg"
                            className="rounded-circle px-2 py-1 mx-1"
                            onClick={onEditCoverClick}
                    >
                        <BiPencil/>
                    </Button>
                    {showControls &&
                            <>
                                <Button
                                        variant="secondary"
                                        size="lg"
                                        className="rounded-circle px-2 py-1 mx-1"
                                        onClick={onDeleteCoverClick}
                                        hidden={!coverLink.includes('tigerbooks.de')}
                                >
                                    <FiTrash/>
                                </Button>
                            </>
                    }
                </div>
            </div>
    );
};
import React from "react";
import "./CoverWithControls.css";
import logo from "../../../../../assets/images/loader_bg.png";
import {BiPencil} from "react-icons/all";
import {Button} from "react-bootstrap";

export const CoverWithControls = (props) => {
    const { cover, onEditCoverClick } = props;

    return (
        <div className="cover-container">
            <div className="cover">
                <img src={cover != null ? cover : logo} alt="cover" className="cover-img"/>
            </div>
            <div className="button-container">
                <Button
                    variant="secondary"
                    size="lg"
                    className="rounded-circle px-2 py-1 mx-1"
                    onClick={onEditCoverClick}
                >
                    <BiPencil />
                </Button>
            </div>
        </div>
    );
};
import './ProductCoveWithMetadata.scss';
import Strings from "../../config/Strings";
import radioplay from "../../../assets/images/search/radioplay.png";
import audiobook from "../../../assets/images/search/audiobook.png";
import music from "../../../assets/images/search/music.png";
import {getProductLength} from "../search/SearchService";
import React from "react";
import logo from "../../../assets/images/loader_bg.png";

export default function ProductCoverWithMetadata(props) {
    const {product} = props;
    const coverUrl = product.cover != null ? (
        product.cover.contentLocation != null ? (
            product.cover.contentLocation.location != null ? product.cover.contentLocation.location : logo
        ) : logo
    ) : (
        product._links != null ? (product._links.cover != null ? (product._links.cover.href != null ? product._links.cover.href : logo) :logo)
            :
            logo);

    return (
        <div className="product-metadata-cover">
            <div className="product-metadata-cover-background-container">
                {product.ageMin != null &&
                    <div className="cover-age-display"
                         dangerouslySetInnerHTML={{__html: Strings.SEARCH_ITEM_AGE.replace("{0}", product.ageMin)}}/>
                }
                {product.productClassification === 'RADIOPLAY' &&
                    <img src={radioplay} className="cover-product-classification-display" alt={"radioplay"}/>
                }
                {product.productClassification === 'AUDIOBOOK' &&
                    <img src={audiobook} className="cover-product-classification-display" alt={"audiobook"}/>
                }
                {product.productClassification === 'MUSIC' &&
                    <img src={music} className="cover-product-classification-display" alt={"music"}/>
                }
                <div className="cover-product-length-display"
                     dangerouslySetInnerHTML={{__html: getProductLength(product)}}/>
            </div>
            <img src={coverUrl} style={{borderRadius: "15px", width: "100%"}}
                 alt={"Titelbild von " + product.title}/>
        </div>
    );


}
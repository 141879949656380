import React from "react";
import "./Dashboard.scss";
import GlobalConstants from "../../config/GlobalConstants";

export const DashboardCard = (props) => {
    const {icon, text, link, onTitleClick, body, backgroundColor} = props;
    return (
        <div className="dashboard-card">

            {link && (
                <div className="dashboard-card-title" style={{color: backgroundColor}}>
                    <a href={"/" + GlobalConstants.APP_PATH + link} className="with-hover">
                        {icon} {text}
                    </a>
                </div>
            )}

            {!link && onTitleClick && (
                <div className="dashboard-card-title" onClick={onTitleClick} style={{color: backgroundColor}}>
                    <span className="with-hover">{icon} {text}</span>
                </div>
            )}

            {!link && !onTitleClick && (
                <div className="dashboard-card-title" style={{color: backgroundColor}}>
                    {icon} {text}
                </div>
            )}

            <div className={"dashboard-card-body"}
                 style={{backgroundColor: backgroundColor}}>
                {body}
            </div>
        </div>
    );
};
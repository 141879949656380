import React, {useEffect, useState} from "react";
import './DDFRedemption.css';
import Strings from "../../../config/Strings";
import {Form, Spinner} from "react-bootstrap";
import {TigerButton} from "../../../global/tiger-button/TigerButton";
import {CreateResource} from "../../../api/SpineClient";
import {loginUser} from "../../../authentication/Oauth2Service";
import {useHistory, useLocation} from "react-router";
import {authCheck} from "../../../util/authCheck";
import GlobalConstants from "../../../config/GlobalConstants";
import {InfoModal} from "../../../global/ConfirmationModal";

export default function DDFRedemptionAuthentication({ setToken, setEmail }) {

    const ERROR_CODE_DUPLICATE_EMAIL = "400-03";
    const ERROR_CODE_PASSWORT_TOO_SHORT = "400-01";

    const [form, setForm] = useState({
        email: "",
        password: "",
        passwordConfirmation: "",
        privacy: false,
        terms: false,
        newsletter: false,
        registerDeviceId: "customer-ui-service"
    });
    const [formType, setFormType] = useState("REGISTER");
    const [formError, setFormError] = useState({
        email: null,
        password: null,
        passwordConfirmation: null,
        privacy: null,
        terms: null,
        newsletter: null
    });
    const [formIsValid, setFormIsValid] = useState(form.code != null && form.password != null);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isNewAccount, setIsNewAccount] = useState(false);

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        authCheck() && history.push("/" + GlobalConstants.APP_PATH + "ddflive/preview#ddf-preview");

        if (location !== undefined && location.hash !== "") {
            const element = document.getElementById(location.hash);
            if (element) {
                element.scrollIntoView({behavior: "smooth", block: "center"});
            }
        }
    }, [history, location]);

    document.title = Strings.DDF_REDEMPTION_BROWSER_TAB;

    if (authCheck()) {
        history.push("/" + GlobalConstants.APP_PATH + "ddflive/preview#ddf-preview");
    }

    //Submitting
    const authenticate = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        setLoading(true);

        let newToken = await loginUser(form);

        if(newToken) {
            //Token is available -> Set the expiration date/time
            let expiredAt = new Date();
            expiredAt.setSeconds(expiredAt.getSeconds() + newToken.expires_in);
            newToken.expires_at = expiredAt.getTime();

            setToken(newToken);
            setEmail(form.email);
            sessionStorage.setItem("email", JSON.stringify({email: form.email}));
            history.push("/" + GlobalConstants.APP_PATH + "ddflive/preview#ddf-preview")
        }
        else {
            setFormError({
                ...formError,
                email: Strings.DDF_AUTHENTICATE_ERROR,
                password: Strings.DDF_AUTHENTICATE_ERROR
            });
        }

        setLoading(false);
    }

    const register = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        setLoading(true);

        let response = await CreateResource('api/register', form);
        if (!response.error) {
            setFormType("LOGIN");
            setIsNewAccount(true);
            const element = document.getElementById("#ddf-authentication-login");
            if (element) {
                element.scrollIntoView({behavior: "smooth", block: "center"});
            }
        } else {
            if (response.message.indexOf(ERROR_CODE_DUPLICATE_EMAIL) > -1) {
                setFormError({...formError, email: Strings.DDF_REGISTER_EMAIL_ERROR});
            } else if (response.message.indexOf(ERROR_CODE_PASSWORT_TOO_SHORT) > -1) {
                setFormError({...formError, password: Strings.DDF_REGISTER_PASSWORD_TOO_SHORT});
            } else {
                setErrorMessage(Strings.DDF_REDEMPTION_UNKNOWN_ERROR);
                setShowError(true);
            }
        }

        setLoading(false);
    }

    const updateForm = (field, value, formIsValid) => {
        setForm({
            ...form,
            [field]: value
        });
        setFormError({
            ...formError,
            [field]: null
        });
        setFormIsValid(formIsValid);
    }


    const validateForm = () => {
        //REGISTER
        if (formType === "REGISTER") {
            if (form.email.indexOf("@") < 0) {
                setFormError({...formError, email: Strings.DDF_REGISTER_INVALID_EMAIL});
                return false;
            } else if (form.password.length < 8) {
                setFormError({...formError, password: Strings.DDF_REGISTER_PASSWORD_TOO_SHORT});
                return false;
            } else if (form.password !== form.passwordConfirmation) {
                setFormError({...formError, passwordConfirmation: Strings.DDF_REGISTER_PASSWORD_DONT_MATCH});
                return false;
            }
        } else if (formType === "LOGIN") {
            if (form.email.indexOf("@") < 0) {
                setFormError({...formError, email: Strings.DDF_REGISTER_INVALID_EMAIL});
                return false;
            }
        }
        return true;
    }

    if (formType === "LOGIN") {
        return (
            <div className="ddf-container" style={{backgroundColor: "#010202"}}>
                <div className="ddf-taipan" id="#ddf-validation"/>
                <div className="ddf-title" dangerouslySetInnerHTML={{__html: Strings.DDF_AUTHENTICATE_TITLE}}/>
                <div className="ddf-page-headline"  id="#ddf-authentication-login" dangerouslySetInnerHTML={{__html: isNewAccount ? Strings.DDF_AUTHENTICATE_INFO_NEW_ACCOUNT : Strings.DDF_AUTHENTICATE_INFO}} />

                <div className="ddf-section-white">
                    <div className="ddf-section-text-content">
                        <Form className="text-left" onSubmit={(e) => authenticate(e)}>
                            {/* EMAIL */}
                            <Form.Group className="mb-3" controlId="ticketRedemptionTicketNo">
                                <Form.Label className="auth-label">{Strings.DDF_AUTHENTICATE_EMAIL}</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    isInvalid={ formError.email != null}
                                    value={form.email}
                                    onChange={e => updateForm('email', e.target.value, e.target.value.indexOf("@") > -1 && form.password.length >= 8)}
                                    plaintext={"E-Mail"}
                                    placeholder={Strings.DDF_AUTHENTICATE_EMAIL_HINT}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {formError.email}
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* PASSWORD */}
                            <Form.Group className="mb-3" controlId="ticketRedemptionTicketPin">
                                <Form.Label className="auth-label" style={{width: "100%"}}>
                                    {Strings.DDF_AUTHENTICATE_PASSWORD}
                                    <span style={{fontSize: "18px", float: "right", cursor: "pointer", color: '#02abeb', marginTop: "5px", fontWeight: "normal"}} onClick={() => setShowPassword(!showPassword)}>{showPassword ? Strings.RESET_PASSWORD_HIDE : Strings.RESET_PASSWORD_SHOW}</span>
                                </Form.Label>
                                <Form.Control
                                    type={showPassword ? "text" : "password"}
                                    required
                                    isInvalid={ formError.password != null}
                                    value={form.password}
                                    onChange={e => updateForm('password', e.target.value, e.target.value.length >= 8 && form.email.indexOf("@") > -1)}
                                    plaintext={"PIN"}
                                    placeholder={Strings.DDF_AUTHENTICATE_PASSWORD_HINT}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {formError.password}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <TigerButton className="w-100" variant="red" disabled={loading}>
                                {loading ?
                                    <Spinner animation="border" />
                                    :
                                    Strings.DDF_AUTHENTICATE_BUTTON}
                            </TigerButton>

                            {!isNewAccount &&
                            <>
                                <br />
                                <hr />

                                {Strings.DDF_SWITCH_TO_REGISTER_INFO}

                                <br />

                                <br />

                                <TigerButton className="w-100" variant="blue" type="button" onClick={() => {
                                    setFormType("REGISTER");
                                    window.location.hash = "#ddf-authentication-register";
                                    const element = document.getElementById("#ddf-authentication-register");
                                    if (element) {
                                        element.scrollIntoView({behavior: "smooth", block: "center"});
                                    }
                                }}>
                                    {Strings.DDF_SWITCH_TO_REGISTER_BUTTON}
                                </TigerButton>
                            </>
                            }
                        </Form>

                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="ddf-container" style={{backgroundColor: "#010202"}}>
            <div className="ddf-taipan" id="#ddf-validation"/>
            <div className="ddf-title" dangerouslySetInnerHTML={{__html: Strings.DDF_REGISTER_TITLE}}/>
            <div className="ddf-page-headline" id="#ddf-authentication-register" dangerouslySetInnerHTML={{__html: Strings.DDF_REGISTER_INFO}} />

            <div className="ddf-section-white ddf-registration-container">
                <div className="ddf-section-text-content">
                    <Form className="text-left" onSubmit={(e) => register(e)}>
                        {/* EMAIL */}
                        <Form.Group className="mb-3" controlId="ticketRedemptionTicketNo">
                            <Form.Label className="auth-label">{Strings.DDF_REGISTER_EMAIL}</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                isInvalid={ formError.email != null}
                                value={form.email}
                                onChange={e => updateForm('email', e.target.value,
                                    e.target.value.indexOf("@") > -1 && form.password.length >= 8 && form.password === form.passwordConfirmation)}
                                plaintext={"E-Mail"}
                                placeholder={Strings.DDF_REGISTER_EMAIL_HINT}
                            />

                            <Form.Control.Feedback type="invalid">
                                {formError.email}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* PASSWORD */}
                        <Form.Group className="mb-3" controlId="ticketRedemptionTicketPin">
                            <Form.Label className="auth-label" style={{width: "100%"}}>
                                {Strings.DDF_REGISTER_PASSWORD}
                                <span style={{fontSize: "18px", float: "right", cursor: "pointer", color: '#02abeb', marginTop: "5px", fontWeight: "normal"}} onClick={() => setShowPassword(!showPassword)}>{showPassword ? Strings.RESET_PASSWORD_HIDE : Strings.RESET_PASSWORD_SHOW}</span>
                            </Form.Label>
                            <Form.Control
                                type={showPassword ? "text" : "password"}
                                required
                                isInvalid={ formError.password != null}
                                value={form.password}
                                onChange={e => updateForm('password', e.target.value,
                                    e.target.value.length >= 8 && form.email.indexOf("@") > -1 && form.password === form.passwordConfirmation && form.terms && form.privacy)}
                                plaintext={"Password"}
                                placeholder={Strings.DDF_REGISTER_PASSWORD_HINT}
                            />

                            <Form.Control.Feedback type="invalid">
                                {formError.password}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* PASSWORD CONFIRMATION*/}
                        <Form.Group className="mb-3" controlId="ticketRedemptionTicketPin">
                            <Form.Label className="auth-label">{Strings.DDF_REGISTER_PASSWORD_CONFIRMATION}</Form.Label>
                            <Form.Control
                                type={showPassword ? "text" : "password"}
                                required
                                isInvalid={ formError.passwordConfirmation != null}
                                value={form.passwordConfirmation}
                                onChange={e => updateForm('passwordConfirmation', e.target.value,
                                    form.password === e.target.value && form.email.indexOf("@") > -1 && form.password.length >= 8 && form.terms && form.privacy)}
                                plaintext={"Password Confirmation"}
                                placeholder={Strings.DDF_REGISTER_PASSWORD_CONFIRMATION_HINT}
                            />

                            <Form.Control.Feedback type="invalid">
                                {formError.passwordConfirmation}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <div className={"ddf-check-boxes"}>
                            <div className={"round"} style={{width: "10%"}}>
                                <input name="privacy" id="privacy" type="checkbox" value={form.privacy} onChange={(e) => {
                                    updateForm('privacy', e.target.checked,
                                        e.target.checked && form.terms &&
                                        form.password === form.passwordConfirmation && form.email.indexOf("@") > -1 &&
                                        form.password.length >= 8);
                                }}/>
                                <label htmlFor="privacy"></label>
                            </div>
                            <label className="roundlabel registerCheckboxLabel" htmlFor="privacy">*Hiermit akzeptiere ich
                                die <a href="https://tiger.media/policies/privacy-policy" className="black" target="_blank"
                                       style={{color: "#02abeb"}}
                                       rel="noopener noreferrer">Datenschutzbestimmungen</a></label>
                        </div>
                        <div className={"ddf-check-boxes"}>
                            <div className={"round"}>
                                <input name="terms" id="terms" type="checkbox" value={form.terms} onChange={(e) => {
                                    updateForm('terms', e.target.checked,
                                        e.target.checked && form.privacy &&
                                        form.password === form.passwordConfirmation && form.email.indexOf("@") > -1 &&
                                        form.password.length >= 8
                                    );
                                }}/>
                                <label htmlFor="terms"></label>
                            </div>
                            <label className="roundlabel registerCheckboxLabel" htmlFor="conditions">*Hiermit akzeptiere ich
                                die <a href="https://tiger.media/policies/terms-of-service" className="black" target="_blank"
                                       style={{color: "#02abeb"}}
                                       rel="noopener noreferrer">AGB</a></label>
                        </div>
                        <div className={"ddf-check-boxes"}>
                            <div className={"round"}>
                                <input name="newsletter" id="newsletter" type="checkbox" value={form.newsletter}
                                       onChange={(e) => updateForm('newsletter', e.target.checked, form.privacy && form.terms &&
                                               form.password === form.passwordConfirmation && form.email.indexOf("@") > -1 &&
                                               form.password.length >= 8)}/>
                                <label htmlFor="newsletter"></label>
                            </div>
                            <label className="roundlabel registerCheckboxLabel"
                                   htmlFor="newsletter">{Strings.REGISTER_FORM_CHECKBOX_NEWSLETTER}</label>
                        </div>

                        <TigerButton className="w-100" variant="red" disabled={loading || !formIsValid}>
                            {loading ?
                                <Spinner animation="border" />
                                :
                                Strings.DDF_REGISTER_BUTTON}
                        </TigerButton>

                        <br />
                        <hr />

                        {Strings.DDF_SWITCH_TO_LOGIN_INFO}

                        <br />

                        <br />

                        <TigerButton className="w-100" variant="blue" type="button" onClick={() => {
                            setFormType("LOGIN");
                            window.location.hash = "#ddf-authentication-login";
                            const element = document.getElementById("#ddf-authentication-login");
                            if (element) {
                                element.scrollIntoView({behavior: "smooth", block: "center"});
                            }
                        }}>
                            {Strings.DDF_SWITCH_TO_LOGIN_BUTTON}
                        </TigerButton>

                    </Form>
                </div>
            </div>

            <InfoModal
                title={Strings.REGISTER_UNKNOWN_ERROR_TITLE}
                text={errorMessage}
                show={showError}
                onHide={() => setShowError(false)} />
        </div>
    )
}
import React, { Component } from "react";
import "./Search.scss";
import logo from "../../../assets/images/loader_bg.png";

class SeriesResultItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            series: this.props.series
        };
    }

    async componentDidMount() {
    }

    render() {
        const series = this.state.series;
        let coverUrl = logo;
        let coverClass = '';
        if (series.displayImageUrl) {
            coverUrl = series.displayImageUrl;
        } else if (series.seasons && series.seasons[0] && series.seasons[0].episodes && series.seasons[0].episodes[0]
                && series.seasons[0].episodes[0].displayImageUrl) {
            coverUrl = series.seasons[0].episodes[0].displayImageUrl;
            coverClass = 'rounded-circle';
        }
        let title = series.name;


        return (
            <a href={"/series/" + series.slug + "/" + series.id} style={{textDecoration: "none"}}>
                <div className="series-item-cover">
                    <img src={coverUrl} style={{borderRadius: "15px", width: "100%"}}
                         alt={"Titelbild von " + title} className={coverClass}/>
                </div>
                <div className="series-item-text-container">
                    <div className="series-item-name" dangerouslySetInnerHTML={{__html:title}}/>
                </div>
            </a>
        );
    }

}

export default SeriesResultItem;

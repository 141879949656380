import {encode} from "../util/Base64Service";
import {getToken} from "./TokenStorageService";
import GlobalConstants from "../config/GlobalConstants";

export async function loginUser(credentials) {

    var data = "username=" + encodeURIComponent(credentials.email) + "&password="
        + encodeURIComponent(credentials.password) + "&grant_type=password&scope=read%20write&device_id=acp";

    if (credentials.externalId !== undefined) {
        data = data + "&externalId=" + encodeURIComponent(credentials.externalId)
    }

    return fetch(GlobalConstants.SPINE_HOST + 'oauth/token',{
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
            'Authorization': 'Basic ' + encode('tigerbooksacp:e2e12b60-a316-41cd-bf26-0afc1d747a32')
        },
        body: data,
    })
        .then(function (response) {
            if(response.ok) {
                return response.json();
            }
            throw new Error(response.status + " - " + response.statusText);
        })
        .then(function(response) {
            return response;
        })
        .catch(function(error) {
            console.log("Error: " + error.message);
        });
}

export async function getAccount(token) {
    let response;
    do {
        response = await fetch(GlobalConstants.SPINE_HOST + 'api/me', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token.access_token
            }
        });
        console.log("getAccount:" + response);
    } while (response.ok && response.status === 202)

    if(response.ok) {
        return response.json();
    }
    throw new Error(response.status + " - " + response.statusText);
}

export async function getAccountRoles(token) {
    //TODO APP_HOST wieder einkommentieren
    return fetch(GlobalConstants.SPINE_HOST + 'oauth/check_token?token=' + token.access_token, {
        method: 'POST',
        headers: {
            'Authorization': 'Basic ' + encode('tigerbooksacp:e2e12b60-a316-41cd-bf26-0afc1d747a32')
        }
    })
        .then(function (response) {
            if(response.ok) {
                return response.json();
            }
            throw new Error(response.status + " - " + response.statusText);
        })
        .then(function (response) {
            if (response.user_name != null && !isNaN(response.user_name)) {
                return response.authorities;
            } else {
                return [];
            }
        })
        .catch(function (error) {
            console.log("Error: " + error.message);
        })
}

export async function getClientToken(credentials) {

    var data = "grant_type=client_credentials";

    return fetch(GlobalConstants.SPINE_HOST + 'oauth/token', {
        method: 'POST',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept": "application/json",
            "Authorization": "Basic " + encode(credentials.client_id + ':' + credentials.client_secret)
        },
        body: data,
    })
        .then(function (response) {
            return response.json();
        })
        .then(function(response) {
            var expiredAt = new Date();
            expiredAt.setSeconds(expiredAt.getSeconds() + response.expires_in);
            response.expires_at = expiredAt.getTime();
            return response;
        })
}

export async function logout({ setToken, setRoles, setAccountId, setEmail }) {
    fetch(GlobalConstants.SPINE_HOST + 'api/logout', {
        method: 'POST'
    }).then(function() {
        localStorage.clear();
        sessionStorage.clear();
        setToken({});
        setRoles([]);
        setAccountId(null);
        setEmail(null);
    })
}

export function logoutLocally({ setToken, setRoles, setAccountId }) {
    localStorage.clear();
    sessionStorage.clear();
    setToken({});
    setRoles([]);
    setAccountId(null);
}

export async function sendPasswordReset(data) {
    return fetch(GlobalConstants.SPINE_HOST + 'api/password_recovery', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
        .then(function (response) {
            if(response.ok) {
                return response;
            }
            throw new Error(response.status + " - " + response.statusText);
        })
        .catch(function (error) {
            console.log("Error: " + error.message);
        });
}

export async function changePassword(accountId, data) {

    const token = getToken();

    return fetch(GlobalConstants.SPINE_HOST + 'api/accounts/' + accountId + '/change_password', {
        method: 'PATCH',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token.access_token
        },
        body: JSON.stringify(data)
    })
        .then(function (response) {
            if(response.ok) {
                return {error: false};
            } else {
                return {error: true, message: response.statusText}
            }
        })
        .catch(function (error) {
            return {error: true, message: error}
        });
}

export async function changeEmail(accountId, data) {

    const token = getToken();

    return fetch(GlobalConstants.SPINE_HOST + 'api/accounts/' + accountId + '/change_mail', {
        method: 'PATCH',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token.access_token
        },
        body: JSON.stringify(data)
    })
        .then(function (response) {
            if(response.ok) {
                return {error: false};
            } else {
                return {error: true, message: response.statusText}
            }
        })
        .catch(function (error) {
            return {error: true, message: error}
        });
}

export async function resetPassword(token, data) {
    return fetch(GlobalConstants.SPINE_HOST + 'api/password_reset?token=' + token, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
        .then(function (response) {
            if(response.ok) {
                return response.ok;
            }
            throw new Error(response.status + " - " + response.statusText);
        })
        .catch(function (error) {
            console.log("Error: " + error.message);
        });
}

export async function fetchShopToken() {

    var data = "grant_type=client_credentials";

    return fetch(GlobalConstants.SPINE_HOST + 'oauth/token',{
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/vnd.tigerbooks.v1+json',
            'Authorization': 'Basic ' + encode(GlobalConstants.SHOP_CLIENT + ':' + GlobalConstants.SHOP_CLIENT_SECRET),
        },
        body: data,
    })
        .then(function (response) {
            if(response.ok) {
                return response.json();
            }
            throw new Error(response.status + " - " + response.statusText);
        })
        .then(function(response) {
            return response;
        })
        .catch(function(error) {
            console.log("Error: " + error.message);
        });
}

export function hasValidToken() {
    var token = getToken();
    return token && token.expires_at && token.expires_at > new Date().getTime();
}
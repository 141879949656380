import React from "react";
import Strings from "../../../config/Strings";
import './Purchase.scss';


export default function PurchaseError() {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code")

    document.title = Strings.TICKET_REDEMPTION_REDEMPTION_BROWSER_TAB;
    window.scrollTo(0, 0);


    //Rendering
    return (
        <div className="purchase-error-container">
            <h1>{Strings.PURCHASE_ERROR_TITLE}</h1>
            <br />
            <div dangerouslySetInnerHTML={{__html: code != null ? Strings.PURCHASE_ERROR_INFO_WITH_CODE.replace("{0}", "Fehlercode " + code) : Strings.PURCHASE_ERROR_INFO}} />
        </div>
    );
}
import {useState} from "react";

export default function useToken() {

    const getToken = () => {
        let token = JSON.parse(localStorage.getItem('ls.token'));
        if (token == null) {
            token = {};
        }
        if (!tokenIsValid(token)) {
            token = {};
        }
        return token;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem('ls.token', JSON.stringify(userToken));
        setToken(userToken);
    };

    return {
        setToken: saveToken,
        token
    }

}

export function getToken() {
    let token = JSON.parse(localStorage.getItem('ls.token'));
    if (token == null) {
        token = {};
    }
    if (!tokenIsValid(token)) {
        token = {};
    }
    return token;
}

export function tokenIsValid(token) {
    let now = new Date();
    let nowSeconds = now.getSeconds();
    return token && token.expires_at && token.expires_at > nowSeconds;
}

export function setToken(token) {
    localStorage.setItem('ls.token', JSON.stringify(token));
    return token;
}

export function isInRole(role) {
    const token = getToken();
    if(token != null && token.sec != null) {
        const roles = atob(token.sec);
        if (roles !== undefined && roles !== "") {
            return roles.includes(role);
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export function isInAnyRole(){
    const token = getToken();
    if(token != null) {
        return token !== "" && token.sec != null;
    } else {
        return false;
    }
}
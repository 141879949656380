import React from "react";
import "./CoverWithBadge.scss";
import {ReactComponent as Badge} from "../../../../svgs/badge.svg";
import logo from "../../../../../assets/images/loader_bg.png";

export const CoverWithBadge = (props) => {
    const { badge, cover } = props;

    return (
        <div className="wildcards-item-cover-container">
            <div className="cover">
                <img src={cover != null ? cover : logo} alt="cover" className="cover-img"/>
            </div>
            <div className="badge-container">
                <img className="badge-icon" src={badge} alt="Badgeicon"/>
                <Badge style={{marginTop: "-1px"}}/>
            </div>
        </div>
    );
};
import React, {useEffect, useState} from "react";
import Strings from "../../../config/Strings";
import {TigerButton} from "../../../global/tiger-button/TigerButton";
import {Spinner} from "react-bootstrap";
import GlobalConstants from "../../../config/GlobalConstants";
import {useHistory, useLocation} from "react-router";
import {authCheck} from "../../../util/authCheck";
import {InfoModal} from "../../../global/ConfirmationModal";
import logo from "../../../../assets/images/ddf-film/ddf-film-logo.jpeg";
import {CreateResource} from "../../../api/SpineClient";

export default function DdfFilmRedemptionPreview({ email, setToken }) {

    const [loading, setLoading] = useState(false);
    const coupon = JSON.parse(sessionStorage.getItem('ls.ddf-film'))
    const history = useHistory();
    const [showError, setShowError] = useState(false);
    const [errorMessage] = useState(null);
    const location = useLocation();

    //ROUTING
    useEffect(() => {
        if (!authCheck()) {
            history.push("/" + GlobalConstants.APP_PATH + "dreifragezeichenfilm/authenticate#ddf-film-authentication-register");
        }

        if (coupon == null || coupon.code == null || coupon.pin == null) {
            history.push("/" + GlobalConstants.APP_PATH + "dreifragezeichenfilm");
        }

        if (location !== undefined && location.hash !== "") {
            const element = document.getElementById(location.hash);
            if (element) {
                element.scrollIntoView({behavior: "smooth", block: "center"});
            }
        }
    }, [history, coupon, location]);

    if (coupon == null || coupon.code == null || coupon.pin == null) {
        history.push("/" + GlobalConstants.APP_PATH + "dreifragezeichenfilm");
    }

    if (!authCheck()) {
        history.push("/" + GlobalConstants.APP_PATH + "dreifragezeichenfilm/authenticate#ddf-film-authentication-register");
    }

    const redeemDdfFilm = async () => {
        setLoading(true);

        //Submit the response
        CreateResource('api/purchases/coupons/submit-order',{code: coupon.code, pin: coupon.pin}, true, GlobalConstants.BILL_HOST)
            .then(r => r);

        window.scrollTo(0, 0);
        history.push("/" + GlobalConstants.APP_PATH + "dreifragezeichenfilm/success");

        setLoading(false);
    }

    //Rendering
    return (
        <>
            <div className="ddf-film-container" style={{backgroundColor: "#010302"}}>
                <div className="ddf-film-title" id="#ddf-film-preview"><img alt="logo" src={logo} style={{width: "100%", maxWidth:"600px"}}/></div>
                <div className="ddf-film-info" id="#ddf-film-preview" style={{textAlign: "center"}} dangerouslySetInnerHTML={{__html: Strings.DDFFILM_PREVIEW_INFO.replace("{0}", email)}} />

                <div className="ddf-film-form-container form-container-small">
                    <div className="ddf-film-form">
                        <TigerButton className="w-100" variant="red" type="button" onClick={() => redeemDdfFilm()} disabled={loading}>
                            {loading ?
                                <Spinner animation="border" />
                                :
                                Strings.DDFFILM_REDEEM_BUTTON}
                        </TigerButton>
                        <br />
                        <hr />

                        <div dangerouslySetInnerHTML={{__html: Strings.DDFFILM_SWITCH_ACCOUNT_INFO.replace("{0}", email)}} />

                        <br />

                        <TigerButton className="w-100" variant="blue" type="button" onClick={() => setToken({})}>
                            {Strings.DDFFILM_CHANGE_ACCOUNT_BUTTON}
                        </TigerButton>
                    </div>
                </div>
            </div>
            <InfoModal
                title={Strings.REGISTER_UNKNOWN_ERROR_TITLE}
                text={errorMessage}
                show={showError}
                onHide={() => setShowError(false)} />
        </>
    )
}
import React, {Component} from "react";
import Strings from "../../config/Strings";
import {BackButton} from "../../global/tiger-button/BackButton";
import {withToast} from "../../util/ToastService";
import GlobalConstants from "../../config/GlobalConstants";
import './LandingPage.css';
import {Form, Spinner} from "react-bootstrap";
import {TigerButton} from "../../global/tiger-button/TigerButton";
import {SubscribeToNewsletter, UnSubscribeFromNewsletter} from "../dashboard/DashboardService";
import {GetUnauthorizedResources} from "../../api/SpineClient";
import {TigerSpinner} from "../../global/tiger-spinner/TigerSpinner";
import {TigerMessage} from "../../global/tiger-message/TigerMessage";
import {InfoModal} from "../../global/ConfirmationModal";

class NewsletterLandingPage extends Component {

    constructor(props) {
        super(props);

        const queryParams = new URLSearchParams(window.location.search);

        this.state = {
            newsletter: [],
            loading: true,
            email: null,
            isLoggedIn: false,
            subscribe: !(queryParams != null && queryParams.get("unsubscribe") != null),
            form: {},
            showSubscriptionModal: false,
            showUnsubscriptionModal: false
        };

        document.title = "Newsletter :: tiger.media";
    }

    async componentDidMount() {
        let newsletterRes = await GetUnauthorizedResources('api/newsletter', {active_only: true});
        if (!newsletterRes.error) {
            let newsletterList = newsletterRes.result;
            if (newsletterList != null && Array.isArray(newsletterList)) {
                newsletterList = newsletterList.filter(n => n.active);
            }
            let form = {};
            for (let i = 0; i < newsletterList.length; i++) {
                form = {...form,
                    [newsletterList[i].name]: this.state.subscribe
                }
            }
            let email = this.props.email;
            if (email == null) {
                const emailFromStorage = JSON.parse(sessionStorage?.getItem('email'));
                if (emailFromStorage?.email) {
                    email = emailFromStorage.email;
                }
            }
            form.email = email;

            this.setState(prevState => ({
                ...prevState,
                newsletter: newsletterList,
                email: email,
                isLoggedIn: email != null,
                form: form,
                loading: false
            }), () => this.initializeForm(true))
        } else {
            this.setState(prevState => ({...prevState, loading: false}));
        }
        window.scrollTo(0, 0);
    }

    initializeForm(selected) {
        let form = {};
        for (let i = 0; i < this.state.newsletter.length; i++) {
            form = {...form,
                [this.state.newsletter[i].name]: selected
            }
        }
        let email = this.props.email;
        if (email == null) {
            const emailFromStorage = JSON.parse(sessionStorage?.getItem('email'));
            if (emailFromStorage?.email) {
                email = emailFromStorage.email;
            }
        }
        form.email = email;
        this.setState(prevState => ({...prevState, form: form}));
    }

    updateForm(field, value) {
        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                [field]: value
            }
        }));
    }

    async updateNewsletter() {
        this.setState(prevState => ({...prevState, loading: true}));
        //Update is only required if the settings have been changed
        let form = this.state.form;
        if (form.email == null) {
            this.props.addToast(Strings.NEWSETTER_EMAIL_REQUIRED, {
                appearance: "error",
                autoDismiss: true
            });
            return;
        }

        if (this.state.subscribe) {
            //Subscribe to newsletter
            let response = await SubscribeToNewsletter(form);
            if(!response.error) {
                this.setState(prevState => ({
                    ...prevState,
                    showSubscriptionModal: true
                }));
            } else {
                this.props.addToast(Strings.DASHBOARD_NEWSLETTER_UPDATE_ERROR, {
                    autoDismiss: true,
                    appearance: 'error',
                    placement: 'bottom-center'
                });
            }
        } else {
            //Unsubscribe from newsletter
            await UnSubscribeFromNewsletter(form);
            this.setState(prevState => ({
                ...prevState,
                showUnsubscriptionModal: true
            }));
            if (form.tigerpost) {
                window.location.href = GlobalConstants.LANDING_PAGE_TIGERPOST_UNSUBSCRIPTION_SURVEY;
            }
        }

        this.setState(prevState => ({...prevState, loading: false}));
    }

    closeView = () => {
        this.props.history.push({
            pathname: "/" + GlobalConstants.APP_PATH,
            hash: this.state.source,
            state: this.state.profileLink
        });
    }

    render() {
        return (
            <div className="content-box">
                {this.state.isLoggedIn &&
                    <BackButton onClick={() => this.closeView()}/>
                }
                <div className="content-header" style={{color: '#e62319'}}>{Strings.NAV_NEWSLETTER}</div>
                <div className="content-box-centered p-3 mb-4">

                    {this.state.loading && <TigerSpinner/>}
                    {!this.state.loading && this.state.newsletter.length === 0 && (
                        <TigerMessage text={Strings.NEWSLETTER_ERROR}/>
                    )}
                    {!this.state.loading && this.state.newsletter.length > 0 && (
                        <>
                            <h3 className="landingpage-header">
                                {this.state.subscribe ? Strings.NEWSLETTER_SUBSCRIBE_TITLE : Strings.NEWSLETTER_UNSUBSCRIBE_TITLE}
                            </h3>
                            <div className="landing-page-body">
                                <ul style={{listStyleType: "none"}}>
                                    {this.state.newsletter.map(n => (
                                        <li className="landingpage-checkbox">
                                            <div className="round">
                                                <input name={n.name} id={n.name} type="checkbox" value={this.state.form[n.name]} checked={this.state.form[n.name]} onChange={(e) => this.updateForm(n.name, !this.state.form[n.name])}/>
                                                <label htmlFor={n.name}><span className="landingpage-checkbox-label">{n.displayName}</span></label>
                                            </div>
                                            <br />
                                            <span dangerouslySetInnerHTML={{__html: this.state.subscribe ?
                                                    (n.subscriptionMessage != null ? n.subscriptionMessage : n.description) :
                                                    (n.unsubscriptionMessage != null ? n.unsubscriptionMessage : n.description)}} />
                                        </li>
                                    ))}
                                </ul>

                                {!this.state.isLoggedIn &&
                                    <div className="landing-page-input">
                                        <hr />
                                        <br />
                                        <Form.Control
                                            type={"text"}
                                            placeholder="E-Mail-Adresse"
                                            onChange={e => this.updateForm('email', e.target.value)}
                                        />
                                    </div>
                                }

                                <TigerButton className="w-50" style={{marginTop: "40px", marginLeft: "auto", marginRight: "auto"}}
                                             disabled={this.state.form.email == null} variant="red" type="button"
                                             onClick={() => this.updateNewsletter()}
                                >
                                    {this.state.loading ?
                                        <Spinner animation="border"/>
                                        :
                                        this.state.subscribe ? Strings.NEWSLETTER_SUBSCRIBE_BUTTON : Strings.NEWSLETTER_UNSUBSCRIBE_BUTTON
                                    }
                                </TigerButton>

                                <p className="newsletter-dsgvo">
                                    Informationen dazu, wie tigermedia mit deinen Daten umgeht, findest du in unserer <a href="https://tiger.media/policies/privacy-policy">Datenschutzerklärung</a>.&#xA0;
                                    {this.state.subscribe ?
                                        <>
                                            Du kannst dich jederzeit kostenfrei <span onClick={() => this.setState(prevState => ({...prevState, subscribe: false}), () => this.initializeForm(false))} style={{cursor: "pointer", color: "#ce023e"}}>abmelden</span>.
                                        </>
                                        :
                                        <>
                                            Zurück zur <span onClick={() => this.setState(prevState => ({...prevState, subscribe: true}), () => this.initializeForm(true))} style={{cursor: "pointer", color: "#ce023e"}}>Anmeldung</span>.
                                        </>
                                    }
                                </p>
                            </div>
                        </>
                    )}

                </div>

                <InfoModal
                    title={"Anmeldung erfolgreich"}
                    text={Strings.DASHBOARD_NEWSLETTER_UPDATE_SUCCESS}
                    show={this.state.showSubscriptionModal}
                    onHide={() => this.setState(prevState => ({...prevState, showSubscriptionModal: false}))} />

                <InfoModal
                    title={"Abmeldung erfolgreich"}
                    text={Strings.NEWSLETTER_UNSUBSCRIBE_SUCCESS}
                    show={this.state.showUnsubscriptionModal}
                    onHide={() => this.setState(prevState => ({...prevState, showUnsubscriptionModal: false}))} />
            </div>
        )
    }
}

export default withToast(NewsletterLandingPage);
import "./Authentication.css";
import {useState} from "react";
import {resetPassword} from "./Oauth2Service";
import Strings from "../config/Strings";
import {Form, Spinner} from "react-bootstrap";
import {useHistory, useLocation} from "react-router";
import GlobalConstants from "../config/GlobalConstants";
import {Link} from "react-router-dom";
import {withToast} from "../util/ToastService";
import {TigerButton} from "../global/tiger-button/TigerButton";
import {authCheck} from "../util/authCheck";

function ResetPassword(props) {
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [resetError, setResetError] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(true);
    const [tokenError, setTokenError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const {addToast} = props;

    const history = useHistory();
    const resetToken = new URLSearchParams(useLocation().search).get('token');

    const setField = (field, value, isValid) => {
        setForm({
          ...form,
          [field]: value
        });
        
        if (!isValid) {
            setErrors({
                ...errors,
                [field]: true
            });
            setSubmitDisable(true);
        }
        else {
            setErrors({
                ...errors,
                [field]: false
            });
            if(form.confirmation != null) {
                setSubmitDisable(false);
            }
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setSubmitDisable(true);
        setResetError(false);

        if(resetToken == null) {
            setTokenError(true)
            setSubmitDisable(false);
            return;
        }

        let hasErrors = !Object.values(errors).every(x => x === false);

        if (!hasErrors && form.password === form.confirmation) {
            setSubmitted(true);

            let reset = await resetPassword(resetToken, {password: form.password});

            if (reset) {
                setResetError(false);
                localStorage.clear();
                sessionStorage.clear();
                history.push("/" + GlobalConstants.APP_PATH + "login");
                addToast(Strings.TOAST_PASSWORD_RESET_LOG_IN, {
                    autoDismiss: true,
                    appearance: 'success',
                    placement: 'bottom-center'
                });
            }
            else {
                setResetError(true);
                setSubmitDisable(false);
            }
        } else {
            setSubmitDisable(false);
        }
    }

    return(
        <>
            <div className="register-form">
                <p style={{fontSize: "50px", fontWeight: "bold"}}>{Strings.RESET_PASSWORD_TITLE}</p>
                <p style={{fontSize: "20px"}}>{Strings.RESET_PASSWORD_INFO}</p>
                <Form className="text-left" onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="loginFormEmail">
                        <Form.Label style={{width: "100%"}}>
                            <span style={{fontWeight: "bold", fontSize: "18px"}}>{Strings.RESET_PASSWORD_FIRST}</span>
                            <span style={{fontSize: "18px", float: "right", cursor: "pointer", color: '#ce023e'}} onClick={() => setShowPassword(!showPassword)}>{showPassword ? Strings.RESET_PASSWORD_HIDE : Strings.RESET_PASSWORD_SHOW}</span>
                        </Form.Label>
                        <Form.Control 
                            type={showPassword ? "text" : "password"}
                            required 
                            isInvalid={ errors.password }
                            onChange={ e => setField('password', e.target.value, (e.target.value.length >= 8 && e.target.value.length <= 50)) }
                        />
                        <Form.Control.Feedback type="invalid">
                            <span style={{fontSize: "16px"}}>{Strings.RESET_PASSWORD_ERROR_LENGTH}</span>
                        </Form.Control.Feedback>

                        <br />

                        <Form.Label><span style={{fontWeight: "bold", fontSize: "18px"}}>{Strings.RESET_PASSWORD_CONFIRM}</span></Form.Label>
                        <Form.Control
                            type="password"
                            required
                            isInvalid={ errors.confirmation }
                            onChange={ e => setField('confirmation', e.target.value, (form.confirmation == null || e.target.value === form.password))}
                        />

                        {(form.confirmation != null && form.confirmation !== "" && form.confirmation !== form.password) &&
                            <Form.Control.Feedback type="invalid">
                                <span style={{fontSize: "16px"}}>{Strings.RESET_PASSWORD_ERROR_MATCH}</span>
                            </Form.Control.Feedback>
                        }


                    </Form.Group>

                    <p className="text-danger" style={{fontSize: "20px"}} hidden={!(resetError && submitted)}>
                        {Strings.RESET_PASSWORD_ERROR}
                    </p>
                    <p className="text-danger" style={{fontSize: "20px"}} hidden={!tokenError}>
                        {Strings.RESET_PASSWORD_TOKEN_ERROR}
                    </p>

                    <TigerButton className="w-100" disabled={submitDisable} variant="red">
                        {(submitDisable && submitted) ?
                            <Spinner animation="border" />
                            : Strings.RESET_PASSWORD_SUBMIT}
                    </TigerButton>
                </Form>
                {!authCheck() &&
                    <p style={{marginTop: "30px", fontSize: "20px"}}>
                        <Link to={"/" + GlobalConstants.APP_PATH + "login"}
                              style={{fontWeight: "bold", color: "#333", textDecoration: "underline"}}>
                            {Strings.RESET_PASSWORD_LOGIN_LINK}
                        </Link>
                    </p>
                }
            </div>
        </>
    )
}

export default withToast(ResetPassword);
import React, {Component} from "react";
import { Card, Button, InputGroup, DropdownButton, Dropdown } from "react-bootstrap";
import Strings from "../../config/Strings";
import {FiArrowUp, FiArrowDown, FcPaid, FcCalendar} from "react-icons/all";
import { PaginationHelp } from "../../global/pagination-help/PaginationHelp";
import {BackButton} from "../../global/tiger-button/BackButton";
import { TigerSpinner } from "../../global/tiger-spinner/TigerSpinner";
import { TigerMessage } from "../../global/tiger-message/TigerMessage";
import {withToast} from "../../util/ToastService";
import {GetFilteredSubscriptions, GetProductsByIaps} from "./TigerticketService";
import { StatusIndicator } from "../../global/status-indicator/StatusIndicator";
import GlobalConstants from "../../config/GlobalConstants";
import ErrorCodes from "../../config/ErrorCodes";
import {ConvertDateToReadableString} from "../../util/ConverterUtil";

class Tigertickets extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            subscriptions: [],
            productIaps: [],
            products: [],
            filter: {
                page: 1,
                per_page: 10,
                articleLike: 'tigerticket',
                active_only: true,
                sortBy: "validUntil",
                sortDirection: "ASC"
            },
            lastPage: 1
        };
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.accountId !== this.props.accountId) {
            await this.loadTigertickets();
        }
    }

    async componentDidMount() {
        await this.loadTigertickets();
    }

    async loadTigertickets() {
        this.setState({loading: true});
        if (this.props.accountId != null) {
            let subscriptions = await GetFilteredSubscriptions(this.props.accountId, this.state.filter);

            if (!subscriptions.error) {
                const subscriptionsRes = subscriptions.result;

                this.setState({
                    subscriptions: subscriptionsRes,
                    productIaps: subscriptionsRes.map(s => s.articleId),
                    lastPage: Math.ceil(subscriptions.length / this.state.filter.per_page)
                }, () => this.loadProducts());
            } else {
                this.props.addToast(Strings.TOAST_ERROR_FETCHING_DATA + ErrorCodes.CODE_SUBSCRIPTION + " - " + subscriptions.message, {
                    autoDismiss: true,
                    appearance: 'error',
                    placement: 'bottom-center'
                });
                this.setState({
                    loading: false,
                    subscriptions: []
                });
            }
        }
    }

    async loadProducts() {
        if (this.state.productIaps.length !== 0) {
            let products = await GetProductsByIaps(this.state.productIaps);
            if (!products.error) {
                const productRes = products.result;
                this.setState({
                    products: productRes
                });
            }
            else {
                this.props.addToast(Strings.TOAST_ERROR_FETCHING_DATA + ErrorCodes.CODE_PRODUCT + " - " + products.message, {
                    autoDismiss: true,
                    appearance: 'error',
                    placement: 'bottom-center'
                });
                this.setState({
                    loading: false,
                    products: []
                });
            }
        }

        this.setState({loading: false});
    }

    findProductForSubscription = (subscription) => {
        return this.state.products.find(p => p.iapProductIdentifier === subscription.articleId);
    };

    handleChangeOrder() {
        let newOrder = 'ASC';
        this.state.filter.sortDirection === 'ASC' ? newOrder = 'DESC' : newOrder = 'ASC';
        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                sortDirection: newOrder
            }
        }), () => this.loadTigertickets());
    }

    handleChangeSort(sort) {
        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                sortBy: sort,
                page: 1
            }
        }), () => this.loadTigertickets());
    }

    changePage = (page) => {
        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                page: page
            }
        }), () => this.loadTigertickets());
    }

    toggleActiveOnly() {
        let newFilter = !this.state.filter.active_only;
        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                page: 1,
                active_only: newFilter
            }
        }), () => this.loadTigertickets());
    }

    calculateDaysUntill = (exp) => {
        var todayDate = new Date().getTime();
        var endDate = Date.parse(exp);
        var diff = endDate - todayDate;
        return Math.ceil(diff / (1000 * 3600 * 24));
    }

    closeView = () => {
        this.props.history.push({
            pathname: "/" + GlobalConstants.APP_PATH,
            state: this.state.profileLink
        })
    }

    render() {
        return (
            <div className="content-box">
                <BackButton onClick={() => this.closeView()}/>

                <div className="content-header" style={{color: '#00b4eb'}}>{Strings.NAV_TIGERTICKETS}</div>
                <div className="p-3 mb-4">

                    <InputGroup className="mb-3 justify-content-end">
                        <Button variant="outline-secondary" 
                            onClick={() => this.toggleActiveOnly()}
                            disabled={this.state.loading}
                        >
                            {this.state.filter.active_only === true ? Strings.TIGERTICKETS_SHOW_ALL : Strings.TIGERTICKETS_SHOW_ACTIVE}
                        </Button>

                        <Button variant="outline-secondary" 
                            onClick={() => this.handleChangeOrder()}
                            disabled={this.state.loading}
                        >
                            {this.state.filter.sortDirection === 'ASC' ? <FiArrowUp/> : <FiArrowDown/>}
                        </Button>

                        <DropdownButton variant="outline-secondary" id="input-group-dropdown-sort" 
                            title={Strings.TIGERTICKETS_SORY_BY}
                            disabled={this.state.loading}
                        >
                            <Dropdown.Item onClick={() => this.handleChangeSort('validUntil')}>{Strings.TIGERTICKETS_SORT_EXPIRY_DATE}</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.handleChangeSort('purchaseTime')}>{Strings.TIGERTICKETS_SORT_PURCHASE_DATE}</Dropdown.Item>
                        </DropdownButton>
                    </InputGroup>

                    {!this.state.loading && this.state.subscriptions.length !== 0 &&
                        <>
                            {this.state.subscriptions.map(s => {
                                const subscriptionProduct = s.articleId != null ? this.findProductForSubscription(s) : null;
                                const daysTillExpiration = this.calculateDaysUntill(s.validUntil);
                                return (
                                    <Card key={s.id} style={{marginBottom: "20px"}}>
                                        <Card.Body>
                                            <StatusIndicator status={s.active === true} />
                                            <Card.Title>
                                                {subscriptionProduct ? subscriptionProduct.title : Strings.TIGERTICKETS_ERROR_UNKNOWN_TICKET}
                                            </Card.Title>
                                            <Card.Subtitle className="text-muted">{subscriptionProduct ? subscriptionProduct.description : ""}</Card.Subtitle>
                                            <Card.Text className="mt-3">
                                                <span><FcPaid size={24}/> {Strings.TIGERTICKETS_LABEL_PURCHASED} {ConvertDateToReadableString(s.purchaseTime)}</span>
                                                <br/>
                                                <span><FcCalendar size={24}/> {Strings.TIGERTICKETS_LABEL_EXPIRES} {ConvertDateToReadableString(s.validUntil)} </span>
                                                {s.active === true ? 
                                                    <span>({daysTillExpiration < 2 ? Strings.TIGERTICKETS_LABEL_1_DAY_LEFT : daysTillExpiration+" "+Strings.TIGERTICKETS_LABEL_DAYS_LEFT})</span> 
                                                : ""}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                )
                            })}

                            <PaginationHelp
                                page={this.state.filter.page} 
                                totalPages={this.state.lastPage}
                                handlePageClick={this.changePage}
                            />
                        </>
                    }

                    {!this.state.loading && this.state.subscriptions.length === 0 && (
                        <TigerMessage text={Strings.TIGERTICKETS_NOTHING_FOUND}/>
                    )}

                    {this.state.loading && (
                        <TigerSpinner/>
                    )}

                </div>
            </div>
        )
    }
}

export default withToast(Tigertickets);
import {GetFilteredRestResource, GetRestResource} from "../../api/RestResourceClient";
import Strings from "../../config/Strings";

//API METHODS
export async function GetProduct(productId) {
    return await GetFilteredRestResource('api/searches/products-by-clients?productId=' + productId, null, null, false);
}

export async function GetPrevNextProductsInSeries(productId) {
    return await GetRestResource('api/searches/prev-next-products-of-series?productId=' + productId);
}

export function GetProductTitle(product) {
    if (product) {
        if (product._embedded && product._embedded.series && product._embedded.series[0])
        {
            const series = product._embedded.series[0];
            const episodes = product._embedded.episodes;
            let episodeNumberText = GetEpisodeNumberText(episodes[0]);
            let episodeTitles;
            if (episodes.length < 2) {
                episodeTitles = <span className="product-title-product-title">
                                    {episodes[0].episodeTitle}
                                </span>
            } else if (episodes.length === 2) {
                episodeTitles = <span className="product-title-product-title">
                                    {episodes[0].episodeTitle}
                                    <br/>
                                    <span> & 1 {Strings.SEARCH_TITLE_INCLUDES_ONE_MORE_EPISODES_TEXT}</span>
                                </span>;
            } else if (episodes.length > 2) {
                episodeTitles = <span className="product-title-product-title">
                                    {episodes[0].episodeTitle}
                                    <br/>
                                    <span> & {(episodes.length - 1) + ' ' + Strings.SEARCH_TITLE_INCLUDES_MULTIPLE_EPISODES_TEXT}</span>
                                </span>;
            }

            let productTitelSeriesTitle = series.name + (series.divisionType === 'SEASONS' ? " – " + product._embedded.season[0].name : "")
            + (series.seriesType ==='ARTIST'? '' : ': ' + episodeNumberText);
            return <>
                        {episodeTitles}
                        <br/>
                        <span className="product-title-series-title">
                            {productTitelSeriesTitle}
                        </span>
                    </>;

        } else {
            return <span className="product-title-product-title">{product.title}</span>;
        }
    }
}

export function GetPrevNextProductTitle(product) {
    if (product) {
        if (product._embedded && product._embedded.series && product._embedded.series[0])
        {
            const series = product._embedded.series[0];
            const episodes = product._embedded.episodes;
            let episodeNumberText = GetEpisodeNumberText(episodes[0]);
            let episodeTitles;
            if (episodes.length < 2) {
                episodeTitles = <span className="product-title-product-title">
                                    {episodes[0].episodeTitle}
                                </span>
            } else if (episodes.length === 2) {
                episodeTitles = <span className="product-title-product-title">
                                    {episodes[0].episodeTitle}
                                    <br/>
                                    <span> & 1 {Strings.SEARCH_TITLE_INCLUDES_ONE_MORE_EPISODES_TEXT}</span>
                                </span>;
            } else if (episodes.length > 2) {
                episodeTitles = <span className="product-title-product-title">
                                    {episodes[0].episodeTitle}
                                    <br/>
                                    <span> & {(episodes.length - 1) + ' ' + Strings.SEARCH_TITLE_INCLUDES_MULTIPLE_EPISODES_TEXT}</span>
                                </span>;
                        }

            return <>
                {series.seriesType === 'ARTIST' ? '' : episodeNumberText}
                {series.seriesType !== 'ARTIST' &&
                <br/>
                }
                {episodeTitles}
            </>;

        } else {
            return <span className="product-title-product-title">{product.title}</span>;
        }
    }
}

export function GetProductTitleWithoutSeriesInformation(product, arrowButton) {
    if (product) {
        if (product._embedded && product._embedded.series && product._embedded.series[0])
        {
            const episodes = product._embedded.episodes;
            if (episodes.length < 2) {
                return <div className="product-title-product-title">
                        {episodes[0].episodeTitle}
                        </div>
            } else if (episodes.length === 2) {
                return <>
                        <div className="product-title-product-title">
                        {episodes[0].episodeTitle}
                        </div>
                        <div className={'product-title-number-episodes'}> & 1 {Strings.SEARCH_TITLE_INCLUDES_ONE_MORE_EPISODES_TEXT}</div>
                        <div className={'product-title-arrow-button'}>{arrowButton}</div>
                        </>
            } else if (episodes.length > 2) {
                return <>
                        <div className="product-title-product-title">
                        {episodes[0].episodeTitle}
                        </div>
                        <div className={'product-title-number-episodes'}> & {(episodes.length - 1) + ' ' + Strings.SEARCH_TITLE_INCLUDES_MULTIPLE_EPISODES_TEXT}</div>
                        <div className={'product-title-arrow-button'}>{arrowButton}</div>
                        </>
            }
        } else {
            return <span className="product-title-product-title">{product.title}</span>;
        }
    }
}

export function GetEpisodeNumberText(episode) {
    let episodeNumber = episode.episodeNumber;
    let regEx = /[0-9]+-[0-9]+/
    let extendedRegEx = /(Folge|Band|Volume) (\d+)(.*)([-–])(.*)/;
    if (regEx.test(episodeNumber) || extendedRegEx.test(episodeNumber)) {
        let index = episodeNumber.toString().search(/[-–]/);
        episodeNumber = episodeNumber.substring(0, index);
    }
    return isNaN(episodeNumber) ? episodeNumber : Strings.SEARCH_EPISODE_STANDARD_NAME + " " + episodeNumber;
}

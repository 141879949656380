import React, {useEffect, useState} from "react";
import Strings from "../../../config/Strings";
import {TigerButton} from "../../../global/tiger-button/TigerButton";
import {Spinner} from "react-bootstrap";
import GlobalConstants from "../../../config/GlobalConstants";
import useToken from "../../../authentication/TokenStorageService";
import {useHistory} from "react-router";
import {authCheck} from "../../../util/authCheck";
import {InfoModal} from "../../../global/ConfirmationModal";
import {ConvertDateToReadableString, ConvertPeriodToDurationString} from "../../../util/ConverterUtil";
import ticketPic from "../../../../assets/images/tigerticket-Logo-ticket.png";

export default function TicketRedemptionPreview({ email, setToken }) {

    const [loading, setLoading] = useState(false);
    const {token} = useToken();
    const history = useHistory();
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const ticket = JSON.parse(sessionStorage.getItem('ls.ticket'));

    //ROUTING
    useEffect(() => {
        if (!authCheck()) {
            history.push("/" + GlobalConstants.APP_PATH + "ticket/authenticate");
        }

        if (ticket == null || ticket.code == null || ticket.pin == null) {
            history.push("/" + GlobalConstants.APP_PATH + "ticket");
        }
    }, [history, ticket]);

    if (ticket == null || ticket.code == null || ticket.pin == null) {
        history.push("/" + GlobalConstants.APP_PATH + "ticket");
    }

    if (!authCheck()) {
        history.push("/" + GlobalConstants.APP_PATH + "ticket/authenticate");
    }

    const redeemTicket = async () => {

        setLoading(true);

        //Submit the response
        let error = false;
        let response = await fetch(GlobalConstants.BILL_HOST + "api/purchases/tickets/submit-order", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.access_token,
            },
            body: JSON.stringify({code: ticket.code, pin: ticket.pin}),
        })
            .then(function (response){
                if(!response.ok) {
                    error = true;
                }
                return response.json();
            })
            .then(function (response){
                if(error) {
                    return {error: true, result: response}
                } else {
                    return {error: false, result: response}
                }
            })
            .catch(function (error){
                console.log("ERROR: " + error);
                return {error: true}
            });

        if (!response.error) {
            //Redirect
            history.push("/" + GlobalConstants.APP_PATH + "ticket/success");
        } else {
            validateError(response.result);
        }

        setLoading(false);
    }

    const validateError = (result) => {
        if (result == null) {
            setErrorMessage(Strings.TICKET_REDEMPTION_REDEMPTION_UNKNOWN_ERROR);
            setShowError(true);
        } else {
            if (result.errorCode == null) {
                setErrorMessage(Strings.TICKET_REDEMPTION_REDEMPTION_UNKNOWN_ERROR);
                setShowError(true);
            } else {
                switch (result.errorCode) {
                    case 10:
                        setErrorMessage(Strings.TICKET_REDEMPTION_ERROR_1);
                        setShowError(true);
                        break;
                    case 24:
                        setErrorMessage(Strings.TICKET_REDEMPTION_ERROR_2);
                        setShowError(true);
                        break;
                    case 25:
                        setErrorMessage(Strings.TICKET_REDEMPTION_ERROR_2);
                        setShowError(true);
                        break;
                    default:
                        setErrorMessage(Strings.TICKET_REDEMPTION_REDEMPTION_UNKNOWN_ERROR);
                        setShowError(true);
                }
            }
        }
    }

    //Rendering
    return (
        <>
            <div className="ticket-redemption-container">
                <div className="ticket-redemption-title"><h1 dangerouslySetInnerHTML={{__html: Strings.TICKET_REDEMPTION_PREVIEW_TITLE}} /></div>
                <div className="ticket-redemption-info"  style={{textAlign: "center"}} dangerouslySetInnerHTML={{__html: Strings.TICKET_REDEMPTION_PREVIEW_INFO_1
                        .replace("{0}", ticket != null ? ticket.product.description : " ")
                        .replace("{1}", ConvertDateToReadableString(ticket != null ? ticket.endDate : null))}} />
                <div className="ticket-pic">
                    <div className="ticket-period">
                        {ConvertPeriodToDurationString(ticket != null ? ticket.product.period : null)}
                    </div>
                    <img src={ticketPic} alt={"ticket"} />
                </div>
                {/**
                    <div className="ticket-redemption-product-details">
                        <ul style={{listStyleType: "none"}}>
                            <li><FcClock size={26}/> <strong>{Strings.TICKET_REDEMPTION_PREVIEW_DURATION}</strong>{ConvertPeriodToDurationString(ticket.product.period)}</li>
                            <li><FcPlanner size={26}/> <strong>{Strings.TICKET_REDEMPTION_PREVIEW_ENDDATE}</strong>{ConvertDateToReadableString(ticket.endDate)}</li>
                        </ul>
                    </div>
                **/}
                <div className="ticket-redemption-info" style={{textAlign: "center"}} dangerouslySetInnerHTML={{__html: Strings.TICKET_REDEMPTION_PREVIEW_INFO_2}} />

                <div className="ticket-redemption-form-container form-container-small">
                    <div className="ticket-redemption-form">
                        <TigerButton className="w-100" variant="red" type="button" onClick={() => redeemTicket()} disabled={loading}>
                            {loading ?
                                <Spinner animation="border" />
                                :
                                Strings.TICKET_REDEMPTION_REDEEM_BUTTON}
                        </TigerButton>
                        <br />
                        <hr />

                        <div dangerouslySetInnerHTML={{__html: Strings.TICKET_REDEMPTION_SWITCH_ACCOUNT_INFO.replace("{0}", email)}} />

                        <br />

                        <TigerButton className="w-100" variant="blue" type="button" onClick={() => setToken({})}>
                            {Strings.TICKET_REDEMPTION_CHANGE_ACCOUNT_BUTTON}
                        </TigerButton>
                    </div>
                </div>
            </div>
            <InfoModal
                title={Strings.REGISTER_UNKNOWN_ERROR_TITLE}
                text={errorMessage}
                show={showError}
                onHide={() => setShowError(false)} />
        </>
    )
}
import React from "react";
import "./PaginationHelp.css";
import ReactPaginate from "react-paginate";

export const PaginationHelp = (props) => {
    const { page, totalPages, handlePageClick, hrefBuilder} = props;

    return (
        <div className="pagination-container">
            <ReactPaginate
                breakLabel="…"
                nextLabel="Weiter >"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={Number(totalPages)}
                previousLabel="< Zurück"
                renderOnZeroPageCount={null}
                forcePage={Number(page)}
                containerClassName="pagination justify-content-center"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="pagination-prev page-item"
                previousLinkClassName="page-link"
                nextClassName="pagination-next  page-item"
                nextLinkClassName="page-link"
                hrefAllControls={true}
                hrefBuilder={hrefBuilder}
                activeClassName="pagination-active"                            />
        </div>
    );
};
import GlobalConstants from "../config/GlobalConstants";

class BillwerkJSWrapper {
    /* global SubscriptionJS */

    _signupQueue = [];
    _finalizeQueue = [];
    _criticalErrorQueue = [];

    _signup = null;
    _payment = null;

    _criticalError = null;

    retries = 50;

    constructor() {
        this.loadBillwerkJS();
    }

    loadBillwerkJS() {
        if (GlobalConstants.BILLWERK_JS_URL) {
            const script = document.createElement('script');

            script.src = GlobalConstants.BILLWERK_JS_URL;
            script.async = true;

            script.onload = this.billwerkJSLoaded.bind(this);
            script.onerror = () => this.criticalError('BillwerkJS could not be loaded!');
            document.body.appendChild(script);
        } else {
            this.criticalError('BillwerkJS Url not defined!');
        }
    }

    billwerkJSLoaded() {
        let paymentData = {
            publicApiKey: GlobalConstants.BILLWERK_PUBLIC_API_KEY,
            providerReturnUrl: GlobalConstants.BILLWERK_PROVIDER_RETURN_URL
        };
        let paymentSuccess = () => {
            this._payment = payment;
            this._signup = signup;
            this.signupReady();
        };

        let paymentError = (error) => {
            this.criticalError(error.errorMessage);
        };

        let signup = new SubscriptionJS.Signup();
        let payment = new SubscriptionJS.Payment(paymentData, paymentSuccess, paymentError);

        this.finalizeReady();
    }

    signupReady() {
        if (this._signupQueue) {
            for (let fn of this._signupQueue) {
                this.doSignup(fn);
            }
        }
        delete this._signupQueue;
    }
    doSignup(fn) {
        fn(this._signup, this._payment);
    }
    signup(fn) {
        if (this._signupQueue) {
            this._signupQueue.push(fn);
        } else {
            this.doSignup(fn);
        }
    }

    finalizeReady() {
        if (this._finalizeQueue) {
            for (let { success, error } of this._finalizeQueue) {
                this.doFinalize(success, error);
            }
        }
        delete this._finalizeQueue;
    }
    doFinalize(success, error) {
        SubscriptionJS.finalize(success, error);
    }
    finalize(success, error) {
        if (this._finalizeQueue) {
            this._finalizeQueue.push({ success: success, error: error });
        } else {
            this.doFinalize(success, error);
        }
    }

    criticalError(error) {
        this._criticalError = error;
        if (this._criticalErrorQueue) {
            for (let fn of this._criticalErrorQueue) {
                this.doErrorCallback(fn);
            }
        }
        delete this._criticalErrorQueue;
    }
    doErrorCallback(fn) {
        fn(this._criticalError);
    }
    errorCallback(fn) {
        if (this._criticalErrorQueue) {
            this._criticalErrorQueue.push(fn);
        } else {
            this.doErrorCallback(fn);
        }
    }

    paySignupInteractive(secretPaymentData, order, success, error) {
        let paySignupInteractive = (signup, payment) => {
            signup.paySignupInteractive(payment, secretPaymentData, order, success, error);
        };
        this.signup(paySignupInteractive);
    }
}


let billwerkJSWrapper = new BillwerkJSWrapper();
export const finalize = billwerkJSWrapper.finalize.bind(billwerkJSWrapper);
export const errorCallback = billwerkJSWrapper.errorCallback.bind(billwerkJSWrapper);
export const paySignupInteractive = billwerkJSWrapper.paySignupInteractive.bind(billwerkJSWrapper);
import React from "react";
import "./Switch.css";
import Strings from "../../config/Strings";

export const Switch = (props) => {
    const { checked, onChange } = props;

    return (
        <label className="switch" htmlFor="inputId">
            <input className="switch-input" type="checkbox" checked={checked} onChange={onChange} id="inputId" />
            <span className="slider" />
            <div className={checked ? 'switch-text-checked' : 'switch-text-unchecked'}>
                {checked ? Strings.WILDCARDS_TOGGLE_PLAYABLE_ON : Strings.WILDCARDS_TOGGLE_PLAYABLE_OFF}
            </div>
        </label>
    );
}
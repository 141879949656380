import React, { Component } from "react";
import "./AudioItem.css";
import {Button, FormControl} from "react-bootstrap";
import {calculateDuration} from "../../../WildcardHelper";
import {BiPencil, FiTrash, GiHamburgerMenu, FaCheck, FaPlay, FaPause} from "react-icons/all";
import {Draggable} from "react-beautiful-dnd";

export class AudioItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isRenaming: false,
            isPlaying: false,
            itemName: null
        };
    }

    componentDidMount() {
        this.setState({
            itemName: this.props.item.name
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.item !== this.props.item) {
            this.setState({
                itemName: this.props.item.name
            })
        }
    }

    onRenameItem = () => {
        this.props.onItemRename(this.props.item, this.state.itemName)
        this.setState({
            isRenaming: false
        })
    }

    togglePlayAudio = () => {
        const audio = this.props.item.audio;
        if (this.state.isPlaying) {
            audio.pause();
        }
        else {
            audio.play();
        }

        this.setState(prevState => ({
            isPlaying: !prevState.isPlaying
        }));
    }

    render() {
        return (
            <>
                <Draggable
                    draggableId={"item-"+this.props.index}
                    index={this.props.index}
                    isDragDisabled={!this.props.isEditing}
                >
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="content-item"
                        >
                            <div className="d-flex align-items-center item-name ml-2">
                                {this.props.item.isEditable && this.props.isEditing && (
                                    <Button
                                        variant="secondary"
                                        size="lg"
                                        className="d-flex align-items-center rounded-circle p-2 mr-2"
                                        onClick={() => this.togglePlayAudio()}
                                    >
                                        {this.state.isPlaying ? <FaPause /> : <FaPlay />}
                                    </Button>
                                )}

                                {this.state.isRenaming && this.props.isEditing ? (
                                        <FormControl
                                            size="lg"
                                            defaultValue={this.state.itemName}
                                            onChange={e => this.setState({ itemName: e.target.value })}
                                        />
                                    )
                                    : this.state.itemName
                                }
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="mr-4">
                                    {calculateDuration(this.props.item.duration)}
                                </div>
                                {this.props.isEditing && (
                                    <>
                                        {this.props.item.isEditable && !this.state.isRenaming && (
                                            <Button
                                                variant="secondary"
                                                size="lg"
                                                className="d-flex align-items-center rounded-circle p-2 mr-2"
                                                onClick={() => this.setState({isRenaming: true})}
                                            >
                                                <BiPencil />
                                            </Button>
                                        )}
                                        {this.props.item.isEditable && this.state.isRenaming && (
                                            <Button
                                                variant="success"
                                                size="lg"
                                                className="d-flex align-items-center rounded-circle p-2 mr-2"
                                                onClick={() => this.onRenameItem()}
                                            >
                                                <FaCheck />
                                            </Button>
                                        )}
                                        <Button
                                            variant="secondary"
                                            size="lg"
                                            className="d-flex align-items-center rounded-circle p-2"
                                            onClick={this.props.onItemDelete}
                                        >
                                            <FiTrash />
                                        </Button>
                                        <h2><GiHamburgerMenu className="ml-3" /></h2>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </Draggable>
            </>
        )
    }
}

import React, {useEffect} from "react";
import Strings from "../../../config/Strings";
import {TigerButton} from "../../../global/tiger-button/TigerButton";
import googlePlay from "../../../../assets/images/Badge-GooglePlay@2x.png";
import appStore from "../../../../assets/images/Badge-AppStore@2x.png";
import GlobalConstants from "../../../config/GlobalConstants";
import {useHistory, useLocation} from "react-router";
import {authCheck} from "../../../util/authCheck";
import logo from "../../../../assets/images/ddf-film/ddf-film-logo.jpeg";

export default function DdfFilmRedemptionSuccess() {

    const targetUrl = "tigertones://product/play/1075278193";

    const history = useHistory();
    const coupon = JSON.parse(sessionStorage.getItem('ls.ddf-film'));
    const location = useLocation();

    //ROUTING
    useEffect(() => {
        if (!authCheck()) {
            history.push("/" + GlobalConstants.APP_PATH + "dreifragezeichenfilm/authenticate");
        }

        if (coupon == null) {
            history.push("/" + GlobalConstants.APP_PATH + "dreifragezeichenfilm");
        }

        if (location !== undefined && location.hash !== "") {
            const element = document.getElementById(location.hash);
            if (element) {
                element.scrollIntoView({behavior: "smooth", block: "center"});
            }
        }
    }, [history, coupon, location]);

    if (coupon == null) {
        history.push("/" + GlobalConstants.APP_PATH + "dreifragezeichenfilm");
    }

    if (!authCheck()) {
        history.push("/" + GlobalConstants.APP_PATH + "dreifragezeichenfilm/authenticate");
    }

    //Rendering
    return (
        <>
            <div className="ddf-film-container" style={{backgroundColor: "#010302"}}>
                <div className="ddf-film-title"><img alt="logo" src={logo} style={{width: "100%", maxWidth:"600px"}}/></div>
                <div className="ddf-film-info" style={{textAlign: "center"}} dangerouslySetInnerHTML={{__html: Strings.DDFFILM_SUCCESS_INFO}} />

                <div className="huibu-section-white">
                    <div className="ddf-film-section-text-content">

                        <br /><br />
                        <div dangerouslySetInnerHTML={{__html: Strings.DDFFILM_SUCCESS_INSTALL_APP_HINT}} />

                        <br />

                        <div>
                            <a className="app-icon icon-right" href="https://apps.apple.com/de/app/tigertones/id1269083572">
                                <img src={appStore} alt={"tigertones im Apple App Store"}/>
                            </a>
                            <a className="app-icon icon-left" href={"market://launch?id=com.tigermedia.TigerAudio&url=" + targetUrl}>
                                <img src={googlePlay} alt={"tigertones im Google Play Store"} />
                            </a>
                        </div>

                        <br />
                        <br />
                        <br />
                        <br />
                        <div dangerouslySetInnerHTML={{__html: Strings.DDFFILM_SUCCESS_INSTALL_APP_HINT_2}} style={{clear: "both", marginTop : "1em"}}/>
                        <br />
                        <a href={targetUrl} className="openlink">
                            <TigerButton className="w-100" variant="red" type="button">
                                {Strings.DDFFILM_SUCCESS_OPEN_PLAYLIST}
                            </TigerButton>
                        </a>
                        <br />
                        <div dangerouslySetInnerHTML={{__html: Strings.DDFFILM_SUCCESS_INSTALL_APP_HINT_3}} />
                    </div>
                </div>
            </div>
        </>
    )

}
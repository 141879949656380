import React from "react";
import Strings from "../../../config/Strings";
import {ConvertDateToReadableString} from "../../../util/ConverterUtil";
import GlobalConstants from "../../../config/GlobalConstants";
import './../Dashboard.scss';
import './DashboardCard.scss';
import {Link} from "react-router-dom";

export const PremiumAccessCard = (props) => {

    const {subscription, contract, loading} = props;

    const isActive = subscription != null;
    const isTones = isActive && subscription.articleId != null && subscription.articleId.indexOf('tigertones') > -1;
    const isAppleIap = isActive && subscription.source != null && subscription.source.indexOf('_APPLE') > -1;
    const isGoogleIap = isActive && subscription.source != null && subscription.source.indexOf('_GOOGLE') > -1;
    const isIap = isAppleIap || isGoogleIap;
    const isTicket = isActive && subscription.articleId != null && subscription.articleId.indexOf('tigerticket') > -1 && !isIap;
    const isSwiss = isTicket && subscription.articleId != null && subscription.articleId.indexOf('swiss') > -1 && !isIap;
    const hideContractLinks = true;

    const getStatusText = () => {
        return isActive ? Strings.DASHBOARD_PREMIUM_STATUS_VALUE_ACTIVE :
            Strings.DASHBOARD_PREMIUM_STATUS_VALUE_INACTIVE;
    }

    const getDurationText = () => {
        if (isTicket) {
            //Active ticket
            return Strings.DASHBOARD_PREMIUM_DURATION_VALUE_TICKET.replace("{0}", ConvertDateToReadableString(subscription.validUntil, false, false));
        } else if (isActive) {
            //Active subscription
            if (isGoogleIap || isAppleIap) {
                return Strings.DASHBOARD_PREMIUM_DURATION_VALUE_SUB_IAP.replace("{0}", ConvertDateToReadableString(subscription.validUntil, false, false));
            } else {
                if (contract != null && contract.EndDate != null) {
                    return Strings.DASHBOARD_PREMIUM_DURATION_VALUE_SUB_CANCELLED.replace("{0}", ConvertDateToReadableString(contract.EndDate, false, false));
                } else {
                    return Strings.DASHBOARD_PREMIUM_DURATION_VALUE_SUB_UNCANCELLED.replace("{0}", ConvertDateToReadableString(subscription.validUntil, false, false));
                }
            }
        } else {
            return null;
        }
    }

    const getAccessTypeText = () => {
        if (!isActive) {
            return null;
        }
        if (isTicket) {
            if (isSwiss) {
                return Strings.DASHBOARD_PREMIUM_TYPE_VALUE_SWISS_TICKET;
            } else {
                return Strings.DASHBOARD_PREMIUM_TYPE_VALUE_TICKET;
            }
        } else {
            if (isAppleIap) {
                if (isTones) {
                    return Strings.DASHBOARD_PREMIUM_TYPE_VALUE_SUB_IAP_APPLE_TONES;
                } else {
                    return Strings.DASHBOARD_PREMIUM_TYPE_VALUE_SUB_IAP_APPLE_BOOKS;
                }
            } else if (isGoogleIap) {
                if (isTones) {
                    return Strings.DASHBOARD_PREMIUM_TYPE_VALUE_SUB_IAP_GOOGLE_TONES;
                } else {
                    return Strings.DASHBOARD_PREMIUM_TYPE_VALUE_SUB_IAP_GOOGLE_BOOKS;
                }
            } else {
                if (isTones) {
                    return Strings.DASHBOARD_PREMIUM_TYPE_VALUE_SUB_BILLWERK_TONES;
                } else {
                    return Strings.DASHBOARD_PREMIUM_TYPE_VALUE_SUB_BILLWERK_BOOKS;
                }
            }
        }
    }

    const getInfoText = () => {
        if (!isActive) {
            return Strings.DASHBOARD_PREMIUM_INACTIVE_INFO;
        } else if (isTicket && subscription.source != null && subscription.source === 'SUPPORT') {
            return Strings.DASHBOARD_PREMIUM_SUPPORT;
        } else {
            if (subscription.source != null && subscription.source === 'SUPPORT') {
                return Strings.DASHBOARD_PREMIUM_SUPPORT;
            } else {
                if (contract == null) {
                    return Strings.DASHBOARD_PREMIUM_IAP_INFO;
                } else {
                    return " ";
                }
            }
        }
    }

    if (loading) {
        return <></>;
    }

    return (
        <>
            <h4 className="dashboard-card-header">{Strings.DASHBOARD_PREMIUM_CARD_LABEL}</h4>
            <div className="static-content-container">
                <div className="dashboard-card-grid">
                    <div className="py-3 w-25">
                        {isActive ? <>&#128994;</> : <>&#128308;</>}
                    </div>
                    <div className="py-1 px-2 w-50">
                        {Strings.DASHBOARD_PREMIUM_STATUS_LABEL}
                       <br />
                        <span style={{fontWeight: "bold"}}>{getStatusText()}</span>
                    </div>
                    <div className="py-3 w-25">
                        {isActive ? <>&#128994;</> : <>&#128308;</>}
                    </div>
                </div>
                <hr style={{width: "270px"}}/>
                {isActive ?
                    <>
                        <div>
                            {Strings.DASHBOARD_PREMIUM_TYPE_LABEL}
                            <br />
                            <span style={{fontWeight: "bold"}}>{getAccessTypeText()}</span>
                        </div>
                        <hr style={{width: "270px"}}/>
                        <div>
                            {Strings.DASHBOARD_PREMIUM_DURATION_LABEL}
                            <br />
                            <div style={{fontWeight: "bold"}} dangerouslySetInnerHTML={{__html: getDurationText()}} />
                        </div>
                    </>
                :
                    <div style={{width: "250px", margin: "auto"}}>{getInfoText()}</div>
                }
                {!hideContractLinks &&
                    <div>
                        <div className="dashboard-card-grid">
                            <div className="py-3 px-2 w-100">
                                <a href={GlobalConstants.APP_PATH + "/invoices"}><span className="dashboard-card-link">{Strings.DASHBOARD_PREMIUM_SUBSCRIPTION_OVERVIEW} &#187;</span></a>
                            </div>
                        </div>
                        <div className="dashboard-card-grid">
                            <div className="px-2 w-100">
                                <a href={GlobalConstants.APP_PATH + "/invoices"}><span className="dashboard-card-link">{Strings.DASHBOARD_PREMIUM_INVOICES_OVERVIEW} &#187;</span></a>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="dashboard-card-footer centered">
                <Link to={"/" + GlobalConstants.APP_PATH + "premium"}>
                    <button
                        className={"dashboard-card-button"}
                        type="button"
                    >{Strings.DASHBOARD_PREMIUM_MAINTAIN_BUTTON}</button>
                </Link>
            </div>
        </>
    );
};
import React, {Component} from "react";
import "../Wildcards.scss";
import "./WildcardDetails.scss";
import {withToast} from "../../../util/ToastService";
import {TigerMessage} from "../../../global/tiger-message/TigerMessage";
import {GetCard, GetCover, ResetCover, UnassignWildcard, UpdateMetadata, UploadContent} from "../WildcardService";
import {TigerSpinner} from "../../../global/tiger-spinner/TigerSpinner";
import {calculateDuration, checkFileMimeType, getTranscodingStatus} from "../WildcardHelper";
import {Button, FormControl, InputGroup, Table} from "react-bootstrap";
import {BiPencil, FaCheck, ImCross, IoArrowBackCircleOutline} from "react-icons/all";
import Strings from "../../../config/Strings";
import {TigerButton} from "../../../global/tiger-button/TigerButton";
import GlobalConstants from "../../../config/GlobalConstants";
import {ConfirmationModal} from "../../../global/ConfirmationModal";
import {CoverWithControls} from "./cover-controls/CoverWithControls";
import {Switch} from "../../../global/switch/Switch";
import WildcardContent from "./wildcard-content/WildcardContent";

class WildcardDetails extends Component {

    constructor(props) {
        super(props);

        this.fileInput = React.createRef();

        this.state = {
            loading: true,
            coverLoading: true,
            showRemoveModal: false,
            showCoverRemoveModal: false,
            isEditing: false,
            newName: null,
            newCover: null,
            wildcardLink: null,
            wildcard: null,
            wildcardProps: null,
            totalDuration: null,
            coverToUpload: null,
        };

        //Window title
        document.title = "wildcard :: tiger.media";
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.location.state) {
            this.setState({
                wildcardLink: this.props.location.state
            }, () => this.loadWildcard(true));
        }
    }

    componentDidUpdate(prevProps) {
        window.scrollTo(0, 0);
        if (prevProps.location.state !== this.props.location.state) {
            this.setState({
                wildcardLink: this.props.location.state
            }, () => this.loadWildcard(true));
        }
    }

    async loadWildcard(loadCover) {
        await GetCard(this.state.wildcardLink).then(res => {
            if (!res.error) {
                const wildcard = res.result;

                //Window title
                document.title = "wildcard " + wildcard.wildcardName + " :: tiger.media";

                const accountGeneratedContents = wildcard._embedded && wildcard._embedded.accountgeneratedcontents;
                const product = wildcard._embedded ? (wildcard._embedded.accountgeneratedcontents || wildcard._embedded.products) : null;
                const cover = product?._links?.cover?.href;
                const badge = wildcard?._links?.badge?.href;
                const description = product && product.description
                        ? product.description.replace("\u21b5", "\n").split("\n")
                        : [];
                const playableOnAllBoxes = wildcard?._embedded?.accountgeneratedcontents
                        ? wildcard._embedded.accountgeneratedcontents.publicContent
                        : wildcard._embedded.product
                        ? true
                        : undefined;
                const unusedDuration = wildcard?._embedded?.accountgeneratedcontents
                        ? wildcard._embedded.accountgeneratedcontents.unusedDuration
                        : undefined;

                const coverLink = product?._links?.cover?.href;

                const totalDuration = product.totalDuration || null;

                const wildcardProps = {
                    accountGeneratedContents: accountGeneratedContents,
                    product: product,
                    cover: cover,
                    coverLink: coverLink,
                    badge: badge,
                    description: description,
                    playableOnAllBoxes: playableOnAllBoxes,
                    unusedDuration: unusedDuration
                }

                this.setState({
                    loading: false,
                    coverLoading: coverLink?.includes('tigerbooks.de') || !loadCover,
                    wildcard: wildcard,
                    wildcardProps: wildcardProps,
                    totalDuration: totalDuration
                }, async () => {
                    if (loadCover) {
                        await this.loadCover(coverLink);
                    }
                });
            } else {
                this.props.addToast(Strings.WILDCARDS_DETAILS_ERROR + res.message, {
                    autoDismiss: true,
                    appearance: 'error',
                    placement: 'bottom-center'
                });
                this.setState({
                    loading: false,
                    wildcard: null
                });
            }
        });
    }

    async loadCover(cover) {
        if (cover?.includes('tigerbooks.de')) {
            await GetCover(cover).then(res => {
                this.setState(prevState => ({
                    coverLoading: false,
                    wildcardProps: {
                        ...prevState.wildcardProps,
                        cover: res
                    }
                }));
            });
        }
    }

    updateWildcardName() {
        if (this.state.newName) {
            UpdateMetadata(this.state.wildcard, {wildcardName: this.state.newName}).then(res => {
                if (!res.error) {
                    this.setState(prevState => ({
                        wildcard: {
                            ...prevState.wildcard,
                            wildcardName: this.state.newName || prevState.wildcardName
                        },
                        isEditing: false
                    }));
                }
            });
        }
    }

    removeWildcard = () => {
        UnassignWildcard(this.state.wildcard).then(res => {
            if (!res.error) {
                this.props.history.push("/" + GlobalConstants.APP_PATH + "wildcards");
                this.props.addToast(Strings.WILDCARDS_REMOVE_SUCCESS, {
                    autoDismiss: true,
                    appearance: 'success',
                    placement: 'bottom-center'
                });
            }
            else {
                this.props.addToast(Strings.WILDCARDS_REMOVE_ERROR + res.message, {
                    autoDismiss: true,
                    appearance: 'error',
                    placement: 'bottom-center'
                });
            }
        });
    }

    openCoverEditDialog = () => {
        this.fileInput.current.click();
    }

    onImageAdded = (event) => {
        if (event.target.files && event.target.files?.length > 0) {
            const fileToUpload = event.target.files[0];
            checkFileMimeType(
                fileToUpload,
                ['image/jpeg'],
                this.imageChangeErrorCallback,
                this.imageChangeSuccessCallback
            );
        }
        if (this.fileInput.current) {
            this.fileInput.current.value = '';
        }
    }

    imageChangeErrorCallback = () => {
        this.props.addToast(Strings.WILDCARDS_COVER_UPLOAD_FILE_ERROR, {
            autoDismiss: true,
            appearance: 'error',
            placement: 'bottom-center'
        });
    }

    imageChangeSuccessCallback = (file) => {
        let wildcard = this.state.wildcard;
        if (wildcard?._embedded?.accountgeneratedcontents) {
            UploadContent(wildcard, {cover: file}).then(res => {
                if (!res.error)
                {
                    const url = URL.createObjectURL(file);
                    this.setState({
                        coverLoading: true,
                        newCover: url
                    }, () => this.checkTranscodingStatus());
                }
            });
        } else {
            const url = URL.createObjectURL(file);
            this.setState(prevState => ({
                wildcardProps: {
                    ...prevState.wildcardProps,
                    cover: url
                },
                coverToUpload: file
            }));
        }
    }

    checkTranscodingStatus = () => {
        this.loadWildcard(false);
        const interval = setInterval(() => {
            if (this.state.wildcardProps.product.transcodingStatus !== "FINISHED") {
                this.loadWildcard(false);
            }
            else {
                this.setState(prevState => ({
                    coverLoading: false,
                    wildcardProps: {
                        ...prevState.wildcardProps,
                        cover: prevState.newCover
                    }
                }));
                clearInterval(interval);
            }
        }, 5000);
    }

    removeWildcardCover = () => {
        if (this.state.wildcardProps.coverLink?.includes('tigerbooks.de')) {
            ResetCover(this.state.wildcard).then(res => {
                if (!res.error) {
                    const defaultCover = res.result?._links?.cover?.href;
                    this.setState(prevState => ({
                        wildcardProps: {
                            ...prevState.wildcardProps,
                            cover: defaultCover,
                            coverLink: defaultCover
                        }
                    }));
                } else {
                    this.props.addToast(Strings.WILDCARDS_REMOVE_COVER_ERROR + res.message, {
                        autoDismiss: true,
                        appearance: 'error',
                        placement: 'bottom-center'
                    });
                }
            });
            this.setState({
                showCoverRemoveModal: false
            });
        }
    }

    togglePlayableOnAllBoxes = () => {
        const newValue = !this.state.wildcardProps.playableOnAllBoxes;
        UpdateMetadata(this.state.wildcardProps.product, {publicContent: newValue}).then(res => {
            if (!res.error) {
                this.setState(prevState => ({
                    wildcardProps: {
                        ...prevState.wildcardProps,
                        playableOnAllBoxes: newValue
                    }
                }));
            }
        });
    }

    updatePlayingTime = (totalDuration) => {
        this.setState({
            totalDuration: totalDuration
        });
    }

    render() {
        return (
            <div className="content-box">
                <div className="content-details-header" style={{backgroundColor: "#838383"}} id="#title">
                    <a href={"/" + GlobalConstants.APP_PATH + "wildcards"}>
                        <IoArrowBackCircleOutline />
                    </a>&#xA0;&#xA0;{Strings.WILDCARDS_DETAILS_TITLE}
                </div>
                <div className="mb-4">
                    {!this.state.loading && this.state.wildcard && (
                        <>
                            <div className="wildcard-details-wrap">
                                <div className="cover-wrap">

                                    {this.state.coverLoading ?
                                        <TigerSpinner/>
                                    : (
                                        <>
                                            <input
                                                type="file"
                                                className="d-none"
                                                onChange={this.onImageAdded}
                                                ref={this.fileInput}
                                                accept={"image/*"}
                                            />
                                            <CoverWithControls
                                                cover={this.state.wildcardProps.cover}
                                                coverLink={this.state.wildcardProps.coverLink}
                                                badge={this.state.wildcardProps.badge}
                                                locked={this.state.wildcardProps.playableOnAllBoxes}
                                                showControls={this.state.wildcard._embedded.accountgeneratedcontents}
                                                onEditCoverClick={this.openCoverEditDialog}
                                                onDeleteCoverClick={() => this.setState({showCoverRemoveModal: true})}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className="py-3 px-1 w-100 wildccard-metadata-container">
                                    
                                    {this.state.isEditing ? (
                                        <InputGroup className="w-100 mb-2">
                                            <FormControl
                                                size="lg"
                                                defaultValue={this.state.wildcard.wildcardName}
                                                onChange={e => this.setState({ newName: e.target.value })}
                                            />

                                            <Button
                                                variant="danger"
                                                onClick={() =>
                                                    this.setState({
                                                        isEditing: false,
                                                        newName: this.state.wildcard.wildcardName
                                                    })
                                                }
                                            >
                                                <ImCross />
                                            </Button>
                                            <Button
                                                variant="success"
                                                onClick={() => this.updateWildcardName()}
                                            >
                                                <FaCheck />
                                            </Button>

                                        </InputGroup>
                                    ) : (
                                        <h4>
                                            {this.state.wildcard.wildcardName}
                                            <Button
                                                variant="outline-secondary"
                                                className="px-1 py-0 ml-10 edit-wildcard-name-button"
                                                onClick={() => this.setState({isEditing: true})}
                                            >
                                                <BiPencil />
                                            </Button>
                                        </h4>
                                    )}

                                    <Table className="mt-4" responsive>
                                        <tbody>
                                        <tr>
                                            <td>{Strings.WILDCARDS_LABEL_CODE}</td>
                                            <td>{this.state.wildcard.code}</td>
                                        </tr>
                                        {this.state.wildcardProps.unusedDuration && (
                                            <>
                                                <tr>
                                                    <td>{Strings.WILDCARDS_LABEL_PLAYING_TIME}</td>
                                                    <td>{this.state.wildcardProps.product.totalDuration && calculateDuration(this.state.totalDuration)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{Strings.WILDCARDS_LABEL_STATUS}</td>
                                                    <td>{getTranscodingStatus(this.state.wildcardProps.product.transcodingStatus)}</td>
                                                </tr>
                                            </>
                                        )}
                                        {this.state.wildcardProps.playableOnAllBoxes !== undefined && (
                                            <tr>
                                                <td>{Strings.WILDCARDS_LABEL_PLAYABLE_ON_ALL_BOXES}</td>
                                                <td>
                                                    <Switch
                                                        checked={!!this.state.wildcardProps.playableOnAllBoxes}
                                                        onChange={this.togglePlayableOnAllBoxes}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>

                            <div className="my-5">
                                <WildcardContent
                                    wildcard={this.state.wildcard}
                                    wildcardProps={this.state.wildcardProps}
                                    totalDuration={this.state.totalDuration}
                                    setPlayingTime={this.updatePlayingTime}
                                    coverToUpload={this.state.coverToUpload}
                                    loadWildcard={() => this.loadWildcard(true)}
                                />
                            </div>

                            <TigerButton
                                className="btn-ci btn-ci-hover mx-auto"
                                variant="red"
                                onClick={() => this.setState({showRemoveModal: true})}
                            >
                                {Strings.WILDCARDS_REMOVE_BUTTON_LABEL}
                            </TigerButton>

                            <ConfirmationModal 
                                title={Strings.WILDCARDS_MODAL_REMOVE_TITLE}
                                text={Strings.WILDCARDS_MODAL_REMOVE_TEXT}
                                actionButtonLabel={Strings.WILDCARDS_MODAL_REMOVE_BUTTON_LABEL}
                                actionButtonVariant="danger"
                                onActionPress={() => this.removeWildcard()} 
                                show={this.state.showRemoveModal}
                                onHide={() => this.setState({showRemoveModal: false})}
                            />

                            <ConfirmationModal
                                title={Strings.WILDCARDS_MODAL_REMOVE_COVER_TITLE}
                                text={Strings.WILDCARDS_MODAL_REMOVE_COVER_TEXT}
                                actionButtonLabel={Strings.WILDCARDS_MODAL_REMOVE_COVER_BUTTON_LABEL}
                                actionButtonVariant="danger"
                                onActionPress={() => this.removeWildcardCover()}
                                show={this.state.showCoverRemoveModal}
                                onHide={() => this.setState({showCoverRemoveModal: false})}
                            />
                        </>
                    )}

                    {!this.state.loading && !this.state.wildcard && (
                        <TigerMessage text={Strings.WILDCARDS_NOTHING_FOUND} />
                    )}

                    {this.state.loading && <TigerSpinner/>}
                </div>
            </div>
        )
    }
}

export default withToast(WildcardDetails);
import "./AppNavbar.scss";
import React from "react";
import {Nav, Navbar} from "react-bootstrap";
import logo from '../../../assets/images/Logo-Login@2x.png'

export default function AppNavbar({setToken, roles, setRoles, setAccountId}) {

    return (
        <div  className="navbar-container">
            <Navbar fixed="top" bg="white" variant="light" expand="xl" className="navbar">
                <Navbar.Brand href={"https://tiger.media"}>
                    <img
                        src={logo}
                        width="120"
                        height="24"
                        alt="Tigermedia Logo"
                    />{' '}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-sm-end">
                    <Nav style={{textAlign: "left!important"}}>
                        <Nav.Link href={"https://tiger.media/"}><span className="navbar-link" style={{textAlign: "center", width: "calc(96px - 1rem)"}}>Home</span></Nav.Link>
                        <Nav.Link href={"/"}><span className="navbar-link" style={{textAlign: "center", width: "calc(117px - 1rem)"}}>Mein&nbsp;Konto</span></Nav.Link>
                        <Nav.Link href={"https://helpcenter.tiger.media/hc/de"}><span className="navbar-link" style={{textAlign: "center", width: "calc(70px - 1rem)"}}>Hilfe</span></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}
const ErrorCodes = {
    CODE_ACCOUNT: 406,
    CODE_USER: 407,
    CODE_AUDIOBOOK: 408,
    CODE_DEVICE: 409,
    CODE_TIGERTICKET: 410,
    CODE_WILDCARD: 411,
    CODE_SUBSCRIPTION: 412,
    CODE_PRODUCT: 413,
    CODE_WATCHLIST: 414,
    CODE_PROFILES: 415
};

export default ErrorCodes;
import React from "react";
import Strings from "../../../config/Strings";
import {TigerButton} from "../../../global/tiger-button/TigerButton";
import googlePlay from "../../../../assets/images/Badge-GooglePlay@2x.png";
import appStore from "../../../../assets/images/Badge-AppStore@2x.png";
import GlobalConstants from "../../../config/GlobalConstants";
import {useHistory} from "react-router";

export default function TicketRedemptionSuccess() {

    window.scrollTo(0, 0);

    const history = useHistory();

    const redirectToDashboard = () => {
        history.push("/" + GlobalConstants.APP_PATH + "dashboard");
    }

    //Rendering
    return (
        <>
            <div className="ticket-redemption-container">
                <div className="ticket-redemption-title"><h1 dangerouslySetInnerHTML={{__html: Strings.TICKET_REDEMPTION_SUCCESS_TITLE}} /></div>
                <div className="ticket-redemption-info" style={{textAlign: "center"}} dangerouslySetInnerHTML={{__html: Strings.TICKET_REDEMPTION_SUCCESS_INFO}} />

                <div className="ticket-redemption-form-container form-container-small">
                    <div className="ticket-redemption-form">
                        <TigerButton className="w-100" variant="red" type="button" onClick={() => redirectToDashboard()}>
                            {Strings.TICKET_REDEMPTION_SUCCESS_BUTTON}
                        </TigerButton>
                        <br />
                        <hr />
                        <div dangerouslySetInnerHTML={{__html: Strings.TICKET_REDEMPTION_SUCCESS_INSTALL_APP_HINT}} />

                        <br />

                        <div>
                            <a className="app-icon icon-left" href="market://launch?id=com.tigermedia.TigerAudio&url=tigertones://productlist/97932">
                                <img src={googlePlay} alt={"tigertones im Google Play Store"} />
                            </a>
                            <a className="app-icon icon-right" href="https://itunes.apple.com/de/app/tigertones/id1269083572">
                                <img src={appStore} alt={"tigertones im Apple App Store"}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
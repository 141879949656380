import React, { Component } from "react";
import logo from "../../../assets/images/loader_bg.png";
import {withToast} from "../../util/ToastService";
import "./Series.scss";
import Strings from "../../config/Strings";
import {TigerSpinner} from "../../global/tiger-spinner/TigerSpinner";
import {GetAllSeries} from "./SeriesService";
import { Helmet } from "react-helmet";
import {Form} from "react-bootstrap";
import {FaSearch} from "react-icons/all";
import AllSeriesItem from "./AllSeriesItem";
import {PaginationHelp} from "../../global/pagination-help/PaginationHelp";


class SeriesDetails extends Component {
    constructor(props) {
        super(props);
        const queryParams = new URLSearchParams(window.location.search);

        this.state = {
            loading: false,
            series: [],
            lastPage: 0,
            filter: {
                query: '',
                page: queryParams.get("page") ? queryParams.get("page") : "",
                per_page: 20,
                sortBy: 'name'
            }
        };

    }

    async componentDidUpdate(prevProps) {
        if (prevProps.series !== this.props.series) {
            await this.loadAllSeries(0);
        }
    }

    async componentDidMount() {
        if (this.state.filter.page && this.state.filter.page > 0) {
            this.loadAllSeries(this.state.filter.page)
        } else {
            await this.loadAllSeries(0);
        }
    }

    async loadAllSeries(page) {
        this.setState({
            loading: true,
        });
        let filter = this.state.filter;
        filter.page = page;

        const response = await GetAllSeries(filter);
        if (response.error === true) {
            this.setState(prevState => ({...prevState, error: response}));
        } else {
            this.setState(prevState => ({
                ...prevState,
                loading: false,
                series: response.result,
                lastPage: Math.ceil(response.length/this.state.filter.per_page),
                filter: {...prevState.filter, page: page}}));
        }

        this.setState({
            loading: false,
        }, () => this.scrollTo());
    }

    scrollTo() {
        if (!this.state.filter.page || this.state.filter.page === 0) {
            window.scrollTo(0, 0)
        } else {
            window.scrollTo(0, 0)
        }
        let pageParam = this.state.filter.page  && this.state.filter.page > 0 ? "?page=" + this.state.filter.page : '';
        window.history.pushState({}, null, "/series/all" + pageParam);
    }

    handlePageClick = (event) => {
        this.setState(prevState => ({...prevState, page: event.selected}), () => this.loadAllSeries(event.selected));
    };

    hrefBuilder = (pageIndex, pageCount, selectedPage) =>  {
        if (pageIndex > pageCount) {
            pageIndex = Number(selectedPage) + 1;
        }
        return '/series/all' + (Number(pageIndex) - 1 === 0 ? '' : '?page=' + (Number(pageIndex) - 1));
    };

    render() {
        const series = this.state.series;

        if(this.state.loading) {
            return (
                <div style={{width: "100%", height: "500px", textAlign: "center", fontSize: "20px"}}>
                    <br />
                    <br />
                    <br />
                    <TigerSpinner />
                    <br />
                </div>
            )
        }

        return (
                series && series.length ?
                <div>
                    <div className="search-query-input-container">
                        <Form onSubmit={() => {
                            this.props.history.push('/search/?query=' + this.state.filter.query);
                        }}>

                            <Form.Control type="search"
                                          value={this.state.filter.query}
                                          className="search-query-input"
                                          placeholder={Strings.PROFILE_PREFERENCES_SEARCH}
                                          onChange={(e) => this.setState(prevState => ({...prevState, filter: {
                                                  ...prevState.filter,
                                                  query: e.target.value
                                              }}))} />
                            <button id={"search-button"} type="submit"><FaSearch /></button>
                        </Form>
                    </div>

                <div className="all-series-container">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{"Serien bei tigertones und auf der tigerbox :: tiger.media"}</title>
                        <meta name="description" content={'Alle Hörspielserien, Hörbuchserien, Künstler und Musiker, die im Streaming bei tigertones und auf der tigerbox verfügbar sind'} />
                        <meta name="keywords" content="Serie,Hörspiel,Kinder,tigerbox,Musik,Hörbuch,Hörspielreihen,Hörbuchreihen,streamen,streaming" />
                    </Helmet>

                    {series.length > 0 &&
                            <>
                                <div>
                                    <div className="search-title">
                                        Alle Serien, Heldinnen und Helden
                                    </div>
                                    <hr className={"search-hr"}/>
                                </div>
                                <div className="all-series-items-container">
                                    {series.map(s => (
                                            <div className="all-series-item" key={s.id}>
                                                <AllSeriesItem series={s}/>
                                            </div>
                                    ))}
                                </div>
                                <PaginationHelp
                                        page={this.state.filter.page}
                                        totalPages={this.state.lastPage}
                                        hrefBuilder={this.hrefBuilder}
                                        handlePageClick={this.handlePageClick}
                                />
                            </>
                    }
                    </div>
                </div>
                :
                <>
                    <div className="search-query-input-container">

                        <Form.Control type="search" value={this.state.filter.query}
                                      className="search-query-input"
                                      placeholder={Strings.PROFILE_PREFERENCES_SEARCH}
                                      onChange={(e) => this.setState(prevState => ({...prevState, filter: {
                                              ...prevState.filter,
                                              query: e.target.value
                                          }}))} />
                        <button id={"search-button"} onClick={() => {
                            this.history.push("/search/?query=" + this.state.filter.query);
                        }}><FaSearch /></button>
                    </div>

                    <div style={{width: "100%", height: "500px", textAlign: "center", fontSize: "25px"}}>
                        <img src={logo} alt="tigermedia logo" width={100} />
                        <br />
                        <br />
                        <span style={{fontSize: "40px"}}>Keine Serien gefunden</span>
                        <br />
                        <br />
                        {Strings.SEARCH_ERROR_MESSAGE}
                    </div>
                </>
        );
    }
}

export default withToast(SeriesDetails);

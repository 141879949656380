import React from "react";
import "./TigerButton.css";
import {BiArrowBack} from "react-icons/all";
import Strings from "../../config/Strings";
import {TigerButton} from "./TigerButton";

export const BackButton = (props) => {
    const { onClick, disabled = false, } = props;
    return (
            <div className="back-button">
                <TigerButton
                        className={"btn-ci-outlined back-button btn-ci-hover"}
                        variant="purple"
                        disabled={disabled}
                        onClick={onClick}
                        outline={true}
                ><BiArrowBack/> {Strings.BACK_BUTTON}</TigerButton>
            </div>
    );
}

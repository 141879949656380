import './AvatarSelection.scss';
import React from "react";

export default function AvatarSelection(props) {
    return(
        <>
            {props.avatarList.map(category => (
                <div className="avatar-category" key={category.name}>
                    <div className="avatar-name" style={{color: '#ec8320'}}>{category.name}</div>
                    <div className={"avatar-item-container"}>
                        {category.avatarList.map(avatar => (
                            <div className={avatar.url === props.currentAvatar ? "avatar-item selected" : "avatar-item"}
                                 onClick={() => props.onSelectAvatar(avatar)}
                                 key={avatar.url}>
                                <img src={avatar.url} alt="" className={"avatar-image"}/>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </>
    )
}
import React, { Component } from "react";
import "./Series.scss";
import Strings from "../../config/Strings";
import logo from "../../../assets/images/loader_bg.png";
import radioplay from "../../../assets/images/search/radioplay.png";
import audiobook from "../../../assets/images/search/audiobook.png";
import music from "../../../assets/images/search/music.png";
import {getEpisodeLength} from "./SeriesService";
import LazyLoad from 'react-lazy-load';

class EpisodeItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            episode: this.props.episode,
            series: this.props.series,
        };
    }

    async componentDidMount() {
    }

    render() {
        const episode = this.state.episode;
        const coverUrl = episode.displayImageUrl ? episode.displayImageUrl : logo;
        let title = '<span class="product-title-product-title">' + episode.episodeTitle + '</span>' +
                (this.state.series.seriesType !== 'ARTIST' ? '<br/><span class="product-title-series-title">' + this.getEpisodeNumberText(episode)  + '</span>' : '');


        return (
            <a href={"/product/" + episode.productId} style={{textDecoration: "none"}}>
                <div className="episode-item-cover">
                    <div className="episode-item-cover-background-container">
                        {episode.ageRecommended  != null && episode.ageRecommended > -1 &&
                                <div className="cover-age-display" dangerouslySetInnerHTML={{__html:Strings.SEARCH_ITEM_AGE.replace("{0}", episode.ageRecommended)}}/>
                        }
                        {episode.episodeContentType === 'RADIOPLAY' &&
                                <img src={radioplay} className="cover-product-classification-display" alt={"radioplay"} />
                        }
                        {episode.episodeContentType === 'AUDIOBOOK' &&
                                <img src={audiobook} className="cover-product-classification-display" alt={"audiobook"} />
                        }
                        {episode.episodeContentType === 'MUSIC' &&
                                <img src={music} className="cover-product-classification-display" alt={"music"} />
                        }
                        <div className="cover-product-length-display" dangerouslySetInnerHTML={{__html: getEpisodeLength(episode)}}/>
                    </div>
                    <LazyLoad height={200} offset={300}>
                        <img src={coverUrl} style={{borderRadius: "15px", width: "100%"}}
                             alt={"Titelbild von " + episode.episodeTitle}/>
                    </LazyLoad>
                </div>
                <div className="episode-item-text-container">
                    <div className="episode-item-name" dangerouslySetInnerHTML={{__html:title}}></div>
                    <div className="episode-item-description" dangerouslySetInnerHTML={{__html:episode.description}}></div>
                </div>
            </a>
        );
    }

    getEpisodeNumberText(episode) {
        let episodeNumber = episode.episodeNumber;
        let regEx = /[0-9]+-[0-9]+/
        let isSubEpisodeNumber = false;
        if (regEx.test(episodeNumber) === true) {
            isSubEpisodeNumber = true;
        }
        return (!isNaN(episodeNumber) || isSubEpisodeNumber) ? Strings.SEARCH_EPISODE_STANDARD_NAME + " " + episodeNumber : episodeNumber;
    }

}

export default EpisodeItem;

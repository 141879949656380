import React from "react";
import "./TigerButton.css";

export const TigerButton = (props) => {
    let { variant = "orange", color, onClick, disabled = false, outline = false, type="submit"} = props;

    // variants and colors
    let bgColor = color;
    let borderColor = color;
    if (!bgColor && !outline) {
        switch (variant) {
            case "orange":
                bgColor = "#f07319";
                color = "white";
                break;
            case "red":
            case "danger":
                bgColor = "#e50045";
                color = "white";
                break;
            case "gray":
                bgColor = "#6c757d";
                color = "black";
                break;
            case "light":
                bgColor = "#9ea8b3";
                break;
            case "green":
                bgColor = "#1eaf8c";
                color = "white";
                break;
            case "blue":
                bgColor = "#02abeb";
                color = "white";
                break;
            case "purple":
                bgColor = "#a51482"
                color = "white";
                break;
            default:
                bgColor = "#f07319";
        }
        borderColor = bgColor;
    } else if (!borderColor && outline) {
        switch (variant) {
            case "orange":
                borderColor = "#f07319";
                break;
            case "red":
            case "danger":
                borderColor = "#e50045";
                break;
            case "gray":
                borderColor = "#6c757d";
                break;
            case "light":
                borderColor = "#9ea8b3";
                break;
            case "green":
                borderColor = "#1eaf8c";
                break;
            case "blue":
                borderColor = "#02abeb";
                break;
            case "purple":
                borderColor = "#a51482"
                break;
            default:
                borderColor = "#f07319";
        }
        color = borderColor;
    }

    return (
        <button 
            className={`btn-ci ${props.className} ${!outline && 'p-0'} ${!disabled && 'btn-ci-hover'}`}
            style={{...props.style, backgroundColor: bgColor, borderColor: borderColor, color: color}}
            type={type}
            onClick={onClick} 
            disabled={disabled}
        >
            <span>
                {props.children}
            </span>
        </button>
    );
}

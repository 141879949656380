import React, {useEffect} from "react";
import './../Purchase.scss';
import PurchaseStatus from "../PurchaseStatus";
import Strings from "../../../../config/Strings";
import {useHistory} from "react-router";
import tigertonesLogo from "./../../../../../assets/images/tones.png";
import appstoreLogo from "./../../../../../assets/images/Badge-AppStore@2x.png";
import playstoreLogo from "./../../../../../assets/images/Badge-GooglePlay@2x.png";

export default function TarifSuccess() {

    const history = useHistory();

    document.title = Strings.PURCHASE_SUBSCRIPTION_BROWSER_TAB;

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        sessionStorage.removeItem("ls.purchase");
    }, [history]);

    sessionStorage.removeItem("ls.purchase");

    return (
        <div className="purchase-container">
            <PurchaseStatus step={4} maxStep={4}
                            onChangeStep={() => {}}
            />
            <div className="purchase-title">{Strings.PURCHASE_SUCCESS_TITLE}</div>
            <div className="purchase-success-tones-icon">
                <img src={tigertonesLogo} alt={"tigertones logo"} />
            </div>
            <div className="purchase-info" dangerouslySetInnerHTML={{__html: Strings.PURCHASE_SUBSCRIPTION_SUCCESS_INFO}}/>
            <div className="purchase-success-icon-box">
                <a href="market://launch?id=com.tigermedia.TigerAudio&url=tigertones://productlist/97932" target="_blank" rel="noreferrer">
                    <img className="purchase-success-icon-box-column" src={playstoreLogo} alt={"tigertones im Google Play Store"} />
                </a>
                <a href="https://itunes.apple.com/de/app/tigertones/id1269083572" target="_blank" rel="noreferrer">
                    <img className="purchase-success-icon-box-column" src={appstoreLogo} alt={"tigertones im App Store"} />
                </a>
            </div>

            <div className="purchase-info" dangerouslySetInnerHTML={{__html: Strings.PURCHASE_SUCCESS_INFO_2}}/>
        </div>
    )
}
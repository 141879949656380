import GlobalConstants from "../../config/GlobalConstants";
import {GetResource, UpdateResource} from "../../api/SpineClient";

//API METHODS

export async function GetCustomerContracts(subscriptionsOnly = true){
    return await GetResource('api/purchases/customer/contracts?subscriptionsOnly=' + subscriptionsOnly,
        'application/json', {}, GlobalConstants.BILL_HOST);
}

export async function GetCustomerInvoices(contractId) {
    if (contractId != null) {
        return await GetResource('api/purchases/customer/invoices?invoicesOnly=true&contractId=' + contractId,
            'application/json', {}, GlobalConstants.BILL_HOST);
    } else {
        return await GetResource('api/purchases/customer/invoices?invoicesOnly=true',
            'application/json', {}, GlobalConstants.BILL_HOST);
    }

}

export async function GetProductDetails() {
    return await GetResource('api/purchases/products/search/billwerk',
        'application/json', {}, GlobalConstants.BILL_HOST);
}

export async function GetCustomerSelfService() {
    return await GetResource('api/purchases/customer/self-service',
        'application/json', {}, GlobalConstants.BILL_HOST);
}

export async function GetAccountTickets(accountId) {
    return await GetResource('api/purchases/customer/' + accountId + '/tickets', 'application/json', {}, GlobalConstants.BILL_HOST);
}

export async function GetBillwerkAccountByOwner(accountId) {
    return await GetResource('api/purchases/customer/search/by-account-id?accountId=' + accountId, 'application/json', {}, GlobalConstants.BILL_HOST);
}

export async function UpdateBillwerkCustomerAddress(accountId, customer) {
    const request = {
        FirstName: customer.FirstName,
        LastName: customer.LastName,
        EmailAddress: customer.EmailAddress,
        Address: customer.Address
    }
    return await UpdateResource('api/purchases/customer/' + accountId + '/update-address', request, true, GlobalConstants.BILL_HOST);
}
import React, {useState} from "react";
import Strings from "../../../config/Strings";
import {
    AiFillCloseCircle,
    BsInfoCircle
} from "react-icons/all";
import GlobalConstants from "../../../config/GlobalConstants";
import {Link} from "react-router-dom";
import './DashboardCard.scss';

export const NewsletterPreferencesCard = (props) => {

    let [showInfo, setShowInfo] = useState(false);
    const [newsletterList] = useState(props.newsletterList.length > 3 ? props.newsletterList.splice(0, 3) : props.newsletterList);
    if (showInfo) {
        return (
            <div className="newsletter-tooltip" onClick={() => setShowInfo(false)}>
                <div style={{float: "right"}}><AiFillCloseCircle size={24}/></div>
                <br/>
                <div>
                    Mit der <b>tigerpost</b> verpasst du keine spannenden
                    Neuigkeiten, Gewinnspiele uvw. rund ums tigerversum.
                    <br/><br/>
                    Mit den <b>tigertipps</b> erfährst du in 11 E-Mails alles
                    über Features und Funktionen deiner Tiger-Produkte.
                    <br/><br/>
                    Außerdem informieren wir dich 1x die Woche über <b>neue Folgen</b> deiner ausgewählten Helden und Welten.
                </div>
            </div>
        );
    }
    return (
        <>
            <h4 className="dashboard-card-header">
                {Strings.DASHBOARD_NEWSLETTER_CARD_LABEL}
                <span style={{float: "right", cursor: "pointer"}} onClick={() => setShowInfo(true)}>
                    <BsInfoCircle />
                </span>
            </h4>
            <div className="static-content-container">
                {newsletterList.map(n => (
                    <>
                    <div>
                        <span style={{fontWeight: "bold"}}>{n.displayName}</span>
                        <br />
                        {n.description}
                    </div>
                    <hr style={{width: "270px"}}/>
                    </>
                ))}

            </div>

            <div className="dashboard-card-footer centered">
                <Link to={"/" + GlobalConstants.APP_PATH + "newsletter"}>
                    <button
                        className={"dashboard-card-button"}
                        type="button"
                    >{Strings.NEWSLETTER_CARD_SELECT}</button>
                </Link>
            </div>
        </>
    );
};


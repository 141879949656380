import './Header.css';

export default function Header(props) {

    return (
        <div style={{backgroundColor: props.backgroundColor != null ? props.backgroundColor : "#ffffff"}}>
            <div className="siteHeader">
            </div>
        </div>
    );
}
import {
    GetResource,
    GetAllResources,
    DeleteResource,
    UpdateResource, CreateResource
} from "../../api/SpineClient";

//API METHODS

export async function GetAccountUsers(accountId){
    return await GetAllResources('api/accounts/' + accountId + '/users');
}

export async function GetAccountUserWatchlist(accountId, userId) {
    return await GetAllResources('api/accounts/' + accountId + '/users/' + userId + '/watchlist');
}

export async function GetWatchlistEntry(accountId, userId, productId) {
    return await GetResource('api/accounts/' + accountId + '/users/' + userId + '/watchlist/' + productId);
}


export async function AddWatchlistEntry(accountId, userId, productId) {
    return await CreateResource('api/accounts/' + accountId + '/users/' + userId + '/watchlist/', {accountId: accountId, userId: userId, productId: productId});
}

export async function DeleteWatchlistEntry(accountId, userId, productId) {
    return await DeleteResource('api/accounts/' + accountId + '/users/' + userId + '/watchlist/' + productId, false);
}

export async function UpdateWatchlist(accountId, userId, watchlist) {
    return await UpdateResource('api/accounts/' + accountId + '/users/' + userId + '/watchlist/order', watchlist);
}
import {useState} from "react";

export default function useShopToken() {

    const getShopToken = () => {
        return JSON.parse(localStorage.getItem('ls.shoptoken'));
    };

    const [shopToken, setShopToken] = useState(getShopToken());

    const saveShopToken = shopToken => {
        localStorage.setItem('ls.shoptoken', JSON.stringify(shopToken));
        setShopToken(shopToken);
    };

    return {
        setShopToken: saveShopToken,
        shopToken
    }

}

export function getShopToken() {
    return JSON.parse(localStorage.getItem('ls.shoptoken'));
}

export function setShopToken(shopToken) {
    localStorage.setItem('ls.shoptoken', JSON.stringify(shopToken));
    return shopToken;
}
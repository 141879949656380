import "./Authentication.css";
import React, {useState} from "react";
import {changePassword} from "./Oauth2Service";
import Strings from "../config/Strings";
import {Form, Spinner} from "react-bootstrap";
import { useHistory } from "react-router";
import GlobalConstants from "../config/GlobalConstants";
import {Link} from "react-router-dom";
import { withToast } from "../util/ToastService";
import { TigerButton } from "../global/tiger-button/TigerButton";

function ChangePassword(props) {
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [resetError, setResetError] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const {addToast} = props;

    const history = useHistory();

    window.scrollTo(0, 0);

    const setField = (field, value, isValid) => {
        setForm({
          ...form,
          [field]: value
        });
        
        if (!isValid) {
            setErrors({
                ...errors,
                [field]: true
            });
        }
        else {
            setErrors({
                ...errors,
                [field]: false
            });
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setSubmitDisable(true);
        setResetError(false);

        let hasErrors = !Object.values(errors).every(x => x === false);

        if (!hasErrors) {
            setSubmitted(true);
            let response = await changePassword(props.accountId, {newPassword: form.newPassword, oldPassword: form.oldPassword});

            if (!response.error) {
                setResetError(false);
                history.push("/" + GlobalConstants.APP_PATH + "dashboard");
                addToast(Strings.TOAST_PASSWORD_CHANGE, {
                    autoDismiss: true,
                    appearance: 'success',
                    placement: 'bottom-center'
                });
            } else {
                addToast("Bei der Anfrage ist ein Fehler aufgetreten. Bitte versuche es später erneut oder kontaktiere den Support.", {
                    autoDismiss: true,
                    appearance: "error"
                });
                setResetError(false);
                setSubmitDisable(false);
            }
        } else {
            addToast("Bitte überprüfe deine Angaben.", {
                autoDismiss: true,
                appearance: "error"
            });
            setResetError(true);
            setSubmitDisable(false);
        }
    }

    return(
        <>
            <div className="register-form">
                <p style={{fontSize: "50px", fontWeight: "bold"}}>{Strings.CHANGE_PASSWORD_TITLE}</p>
                <p style={{fontSize: "20px"}}>{Strings.CHANGE_PASSWORD_INFO}</p>
                <br />
                <Form className="text-left" onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="changePasswordOld">
                        <Form.Label className="auth-label" style={{width: "100%"}}>
                            {Strings.CHANGE_PASSWORD_OLD}
                            <span style={{fontSize: "18px", float: "right", cursor: "pointer", color: '#ce023e', marginTop: "5px", fontWeight: "normal"}} onClick={() => setShowOldPassword(!showOldPassword)}>{showOldPassword ? Strings.RESET_PASSWORD_HIDE : Strings.RESET_PASSWORD_SHOW}</span>
                        </Form.Label>
                        <Form.Control 
                            type={showOldPassword ? "text" : "password"}
                            required 
                            isInvalid={ errors.email }
                            onChange={ e => setField('oldPassword', e.target.value, true) }
                        />
                        
                        <Form.Control.Feedback type="invalid">
                            {Strings.FORGOT_PASSWORD_ERROR_INVALID_EMAIL}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="changePasswordNew">
                        <Form.Label className="auth-label" style={{width: "100%"}}>
                            {Strings.CHANGE_PASSWORD_NEW}
                            <span style={{fontSize: "18px", fontWeight: "normal", color: '#ababab', marginLeft: "5px"}}>{Strings.REGISTER_FORM_PASSWORD_REQUIREMENTS}</span>
                            <span style={{fontSize: "18px", float: "right", cursor: "pointer", color: '#ce023e', marginTop: "5px", fontWeight: "normal"}} onClick={() => setShowNewPassword(!showNewPassword)}>{showNewPassword ? Strings.RESET_PASSWORD_HIDE : Strings.RESET_PASSWORD_SHOW}</span>
                        </Form.Label>
                        <Form.Control
                            type={showNewPassword ? "text" : "password"}
                            required
                            isInvalid={ errors.newPassword }
                            onChange={ e => setField('newPassword', e.target.value, (e.target.value.length >= 8 && e.target.value.length <= 50)) }
                        />

                        <Form.Control.Feedback type="invalid">
                            {Strings.CHANGE_PASSWORD_ERROR_INVALID_PASSWORD}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="changePasswordConfirmation">
                        <Form.Label className="auth-label">{Strings.CHANGE_PASSWORD_CONFIRMATION}</Form.Label>
                        <Form.Control
                            type="password"
                            required
                            isInvalid={ errors.confirmationPassword }
                            onChange={ e => setField('confirmationPassword', e.target.value, (e.target.value === form.newPassword)) }
                        />

                        {(form.confirmationPassword != null && form.confirmationPassword !== "" && form.confirmationPassword !== form.newPassword) &&
                        <Form.Control.Feedback type="invalid">
                            <span style={{fontSize: "16px"}}>{Strings.CHANGE_PASSWORD_ERROR_NOT_MATCH}</span>
                        </Form.Control.Feedback>
                        }
                    </Form.Group>

                    <p className="text-danger" style={{fontSize: "20px"}} hidden={!(resetError && submitted)}>
                        {Strings.FORGOT_PASSWORD_ERROR}
                    </p>

                    <TigerButton className="w-100" disabled={submitDisable} variant="red">
                        {(submitDisable && submitted) ?
                            <Spinner animation="border" />
                            : Strings.CHANGE_PASSWORD_SUBMIT}
                    </TigerButton>
                </Form>
                <p style={{marginTop: "30px", fontSize: "20px"}}>
                    <Link to={"/" + GlobalConstants.APP_PATH + "dashboard"} style={{fontWeight: "bold", color: "#333", textDecoration: "underline"}}>
                        {Strings.CHANGE_PASSWORD_DASHBOARD_LINK}
                    </Link>
                </p>
            </div>
        </>
    )
}

export default withToast(ChangePassword);

export const authCheck = () => {
  let isAuthenticated = false;
  const token = JSON.parse(localStorage.getItem('ls.token'));
  if (token && token.expires_at && token.expires_at > new Date().getTime()) {
    isAuthenticated = true;
  }

  return isAuthenticated;
};

export const shopTokenCheck = () => {
  let isAuthenticated = false;
  const token = JSON.parse(localStorage.getItem('ls.shoptoken'));

  if (token && token.expires_at && token.expires_at > new Date().getTime()) {
    isAuthenticated = true;
  }

  return isAuthenticated;
};

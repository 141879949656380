import React, {Component} from "react";
import Strings from "../../../config/Strings";
import {BackButton} from "../../../global/tiger-button/BackButton";
import {TigerSpinner} from "../../../global/tiger-spinner/TigerSpinner";
import {withToast} from "../../../util/ToastService";
import GlobalConstants from "../../../config/GlobalConstants";
import {GetCustomerContracts, GetCustomerInvoices, GetCustomerSelfService, GetProductDetails} from "../PremiumService";
import './Invoices.css';
import {ConvertDateToReadableString} from "../../../util/ConverterUtil";
import {TigerButton} from "../../../global/tiger-button/TigerButton";
import logo from "../../../../assets/images/loader_bg.png";


class Invoices extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            contracts: [],
            selectedContract: {invoices: []},
            selfService: {},
            filter: {
                page: 1,
                per_page: 10,
            },
            lastPage: 1
        };
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.accountId !== this.props.accountId) {
            await this.loadPremiumData();
        }
    }

    async componentDidMount() {
        await this.loadPremiumData();
    }

    async loadPremiumData() {
        this.setState({loading: true});
        if (this.props.accountId != null) {
            let contractResponse = await GetCustomerContracts();
            let productResponse = await GetProductDetails();
            let selfServiceResponse = await GetCustomerSelfService();

            if(!contractResponse.error && !productResponse.error && !selfServiceResponse.error) {
                let contracts = contractResponse.result;
                let products = productResponse.result;
                let selfService = selfServiceResponse.result;

                if (Array.isArray(selfService) && selfService.length > 0) {
                    selfService = selfService[0];
                }

                let activeContract = {invoices: []};

                for (let contract of contracts) {

                    let invoices = await GetCustomerInvoices(contract.Id);

                    if (!invoices.error) {
                        contract.invoices = invoices.result;
                    } else {
                        contract.invoices = [];
                        console.log("Error during loading invoices: " + invoices.message);
                    }

                    //Set the status (either active or inactive)
                    if (contract.CurrentPhase !== null && contract.CurrentPhase.Type !== null && contract.CurrentPhase.Type !== 'Inactive') {
                        contract.active = true;
                        activeContract = contract;
                    } else {
                        contract.active = false;
                    }

                    //Set the premium type (of the active contract)
                    let shortName = ""
                    if (contract.PlanVariantId !== null) {
                        const matchingProducts = products.filter(p => p.planVariantId === contract.PlanVariantId);
                        if (matchingProducts.length > 0) {
                            shortName = matchingProducts[0].shortName;
                        }
                    }
                    contract.shortName = shortName;
                }
                /*
                          this.setState(prevState => ({...prevState, contracts: [],
                              selectedContract: {invoices: []},
                              selfService: selfService}));
                    */
                  this.setState(prevState => ({...prevState, contracts: contracts,
                      selectedContract: activeContract,
                      selfService: selfService}));
            } else {
                this.props.addToast(Strings.PREMIUM_LOADING_ERROR, {
                    autoDismiss: true,
                    appearance: "error"
                });
            }
        }
        this.setState({loading: false});
    }

    handleContractSelection(contract) {
        this.setState(prevState => ({...prevState, selectedContract: contract}));
        //Scrolling
        document.getElementById("invoices").scrollIntoView({behavior: "smooth"});
    }

    closeView = () => {
        this.props.history.push({
            pathname: "/" + GlobalConstants.APP_PATH,
            state: this.state.profileLink
        })
    }

    render() {
        return (
            <div className="content-box">
                <BackButton onClick={() => this.closeView()}/>
                <div className="content-header" style={{color: '#00b4eb'}}>{Strings.NAV_PREMIUM}</div>


                <div>

                    {!this.state.loading && (

                        <div className="premium-box">
                            <div className="box-info">
                                <div dangerouslySetInnerHTML={{__html: Strings.PREMIUM_INFO}} />
                            </div>
                            <br />
                            <div className="box-left">
                                <div className="box-title">Meine tigertones-Website-Abos (über die tigermedia-Website)</div>
                                <div className="box-info">Hier kannst du dein Abo auswählen:</div>
                                <div className="contracts-box">
                                    {this.state.contracts.length === 0 &&
                                        <div className="contracts-info">
                                            <img src={logo} alt={"tigermedia Logo"} /><br />
                                            <div dangerouslySetInnerHTML={{__html: Strings.PREMIUM_NO_CONTENT}} />
                                        </div>
                                    }
                                    {this.state.contracts.length > 0 && this.state.contracts.map(c => (
                                        <div className={c.active ? (this.state.selectedContract.Id === c.Id ?
                                            "contract-item selected-item active-contract" :
                                            "contract-item active-contract") :
                                            (this.state.selectedContract.Id === c.Id ?
                                                "contract-item selected-item inactive-contract" :
                                                "contract-item inactive-contract")} onClick={() => this.handleContractSelection(c)}>
                                            {Strings.PREMIUM_CONTRACT_NO.replace("{0}", c.ReferenceCode)}

                                            <div className="contract-dates" >
                                                {Strings.PREMIUM_CONTRACT_START_DATE.replace("{0}", ConvertDateToReadableString(c.StartDate))}
                                                <br />
                                                {c.EndDate != null ?
                                                    new Date() < new Date(c.EndDate) ? <span style={{color: "#CE023E", fontWeight: "bold"}}>{Strings.PREMIUM_CONTRACT_END_DATE_FUTURE.replace("{0}", ConvertDateToReadableString(c.EndDate))}</span> : Strings.PREMIUM_CONTRACT_END_DATE_PAST.replace("{0}", c.EndDate)
                                                :
                                                    <strong>{Strings.PREMIUM_CONTRACT_NEXT_BILLING_DATE.replace("{0}", ConvertDateToReadableString(c.NextBillingDate))}</strong>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="box-right" id="invoices">
                                <div className="box-title">Meine Rechnungen</div>
                                <div className="box-info">Hier siehst du die Rechnungen des ausgewählten Vertrages:</div>
                                <div className="invoices-box">
                                    {/* NO CONTRACT SELECTED */}
                                    {this.state.selectedContract.Id == null &&
                                        <div className="invoices-info">
                                            <img src={logo} alt={"tigermedia Logo"} /><br />
                                            {Strings.PREMIUM_NO_CONTRACT_SELECTED}
                                        </div>
                                    }
                                    {/* CONTRACT WITHOUT ANY INVOICES */}
                                    {(this.state.selectedContract.Id != null && this.state.selectedContract.invoices.length === 0) ?
                                        <div className="invoices-info">
                                            <img src={logo} alt={"tigermedia Logo"} /><br />
                                            {Strings.PREMIUM_CONTRACT_HAS_NO_INVOICES}
                                        </div>
                                    :
                                        this.state.selectedContract.invoices.map(i => (
                                        <div className="invoice-item">
                                            <div style={{float: "left"}}>
                                                {Strings.PREMIUM_INVOICE_DATE.replace("{0}", ConvertDateToReadableString(i.Created))}
                                                <br />
                                                <div className="invoice-item-no">({Strings.PREMIUM_INVOICE_NO.replace("{0}",i.InvoiceNumber)})</div>
                                            </div>
                                            <div className="invoice-download-button">
                                                <a href={i.details.downloadUrl} style={{textDecoration: "none"}}>
                                                    <TigerButton className="invoice-download-button-link btn-ci-hover"
                                                                 style={{alignSelf: "end"}}
                                                                 variant="red"
                                                                 noOutline="true"
                                                                 type="button"
                                                    >{Strings.PREMIUM_DOWNLOAD_BUTTON}</TigerButton>
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="premium-placeholder" />

                    {(!this.state.loading && this.state.selfService.Url != null) &&
                        <div className="premium-box">
                            <div className="box-title">Meine Zahlungsdaten</div>
                            <div className="box-info">Hier kannst du deine Zahlungsdaten bearbeiten:</div>
                            <div className="box-full">
                                {!this.state.loading &&
                                    <iframe key={this.state.selfService.Url} src={this.state.selfService.Url}
                                            width="100%"
                                            height="1200px"
                                            frameBorder={0}
                                            title="Billwerk self service"/>
                                }
                            </div>
                        </div>
                    }

                    {this.state.loading && (
                        <TigerSpinner/>
                    )}

                </div>
            </div>
        )
    }
}

export default withToast(Invoices);
import React from "react";
import "./TigerSpinner.css";

export const TigerSpinner = () => {
    return (
        <div className="loader">
            <span className="loader-bg"/>
        </div>
    );
}

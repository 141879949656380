import React, { Component } from "react";
import "../Wildcards.scss";
import { Card } from "react-bootstrap";
import {ImLock, ImUnlocked} from "react-icons/all";
import GlobalConstants from "../../../config/GlobalConstants";
import { calculateDuration, TOTAL_DURATION_IN_SECONDS } from "../WildcardHelper";
import {CoverWithBadge} from "./cover-badge/CoverWithBadge";
import {GetCover} from "../WildcardService";
import {TigerSpinner} from "../../../global/tiger-spinner/TigerSpinner";

class SingleWildcard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEditing: false,
            expanded: false,
            newName: null,
            wildcardName: null,
            wildcard: null,
            coverLoading: true
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadWildcard();
    }

    loadWildcard() {
        const wildcard = this.props.wildcard;
        const wildcardName = this.props.wildcard.wildcardName;
        const product = wildcard._embedded ? (wildcard._embedded.accountgeneratedcontents || wildcard._embedded.products) : null;
        const cover = product?._links?.cover?.href;
        const badge = wildcard?._links?.badge?.href;

        const playableOnAllBoxes = wildcard?._embedded?.accountgeneratedcontents
            ? wildcard._embedded.accountgeneratedcontents.publicContent
            : wildcard._embedded?.product
                ? true
                : undefined;
        const unusedDuration = wildcard._embedded?.accountgeneratedcontents
            ? wildcard._embedded.accountgeneratedcontents.unusedDuration
            : undefined;

        this.setState({
            wildcardName: wildcardName,
            coverLoading: cover?.includes('tigerbooks.de'),
            wildcard: {
                wildcard: wildcard,
                product: product,
                badge: badge,
                cover: cover,
                playableOnAllBoxes: playableOnAllBoxes,
                unusedDuration: unusedDuration
            }
        }, () => this.loadCover(cover));
    }

    async loadCover(cover) {
        if (cover?.includes('tigerbooks.de')) {
            await GetCover(cover).then(res => {
                this.setState(prevState => ({
                    coverLoading: false,
                    wildcard: {
                        ...prevState.wildcard,
                        cover: res
                    }
                }))
            });
        }
    }

    openOverview() {
        this.props.history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "wildcard-details",
            state: this.props.wildcard._links?.self?.href
        });
    }

    render() {
        return (
            this.state.wildcard && (
                <Card className="wildcard mx-2" onClick={() => this.openOverview()}>
                    {this.state.coverLoading ?
                        <div className="h-100">
                            <TigerSpinner/>
                        </div>
                    : (
                        <CoverWithBadge cover={this.state.wildcard.cover} badge={this.state.wildcard.badge} />
                    )}
                    <Card.Body>
                        <Card.Title>{this.state.wildcard.wildcard.wildcardName}</Card.Title>
                        {this.state.wildcard.unusedDuration && (
                            <p className="mb-0">{`Spieldauer: ${calculateDuration(TOTAL_DURATION_IN_SECONDS - this.state.wildcard.unusedDuration)}`}</p>
                        )}
                        {this.state.wildcard.wildcard.lastValidated ? `Zuletzt gehört am: ${new Date(this.state.wildcard.wildcard.lastValidated).toLocaleDateString()}` : 'Noch nicht gehört'}

                        <div className="lock-icon">
                            <h3>{this.state.wildcard.playableOnAllBoxes ? <ImUnlocked /> : <ImLock />}</h3>
                            <div className="tooltiptext">{this.state.wildcard.playableOnAllBoxes ? 'Öffentlich' : 'Privat'}</div>
                        </div>
                    </Card.Body>
                </Card>
            )
        );
    }
}

export default SingleWildcard;

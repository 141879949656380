import {
    CreateResource,
    DeleteResource,
    GetAllResources,
    GetFilteredResources,
    GetResource,
    UpdateResource
} from "../../api/SpineClient";
import avatarList from '../../../assets/files/list-avatars.json';
import {getToken} from "../../authentication/TokenStorageService";
import GlobalConstants from "../../config/GlobalConstants";
import {GetRestResource} from "../../api/RestResourceClient";

//API METHODS

export async function CreateProfile(profile) {
    return await CreateResource('api/profiles', profile);
}

export async function GetProfilesOfAccount(accountId){
    return await GetAllResources('api/accounts/' + accountId + '/profiles');
}

export async function UpdateProfileName(profile, newName) {
    return await UpdateResource('api/profiles/' + profile.id, {...profile, name: newName});
}

export async function UpdateProfileGender(profile, newGender) {
    return await UpdateResource('api/profiles/' + profile.id, {...profile, gender: newGender});
}

export async function UpdateProfileBirthday(profile, newBirthday) {
    return await UpdateResource('api/profiles/' + profile.id, {...profile, birthday: newBirthday});
}

export async function UpdateProfile(profile) {
    return await UpdateResource('api/profiles/' + profile.id, profile);
}

export async function GetProfile(url) {
    return await GetResource(url);
}

export async function DeleteProfile(id) {
    return await DeleteResource('api/profiles/' + id, false);
}

export async function GetAvatarList() {
    //TODO Implement this again as soon as cross origin for cdn works
    /*
    return await fetch("https://cdn.tigerbooks.de/profiles/list-avatars.json", {
        method: 'GET',
        headers: {
            'Accept': "application/json"
        }
    })
        .then(function(response) {
            return response.json();
        })
        .then(function (response) {
            console.log(response);
            return {error: false, message: "", result: response}
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error, result: {}}
        });
     */
    return {error: false, message: "", result: avatarList};
}

export async function UpdateAvatar(profileId, newAvatar) {
    let errorOccurred = false;
    let token = getToken();
    return await fetch(GlobalConstants.SPINE_HOST + 'api/profiles/' + profileId + '/update-avatar', {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token.access_token,
            'Content-Type': 'text/uri-list'
        },
        body: newAvatar
    }).then(function(response) {
        if(!response.ok) {
            errorOccurred = true;
        }
        return response;
    })
        .then(function (response) {
            if(errorOccurred) {
                return {error: true, message: response.message};
            } else {
                return {error: false};
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error}
        });
}

export async function GetRecentlyUsedProductsOfProfile(accountId, profileId) {
    return GetFilteredResources('api/accounts/' + accountId + '/users/' + profileId + '/recentlyUsedProducts');
}

export async function LoadCluster(clusterId) {
    return GetRestResource('api/clusters/' + clusterId);
}


export async function LoadClusters(page = 1) {
    return GetFilteredResources('api/clusters', {per_page: 250, page: page});
}

export async function GetProfilePreferences(profileId) {
    return await GetFilteredResources('api/profiles/' + profileId + '/preferences', {});
}

export async function UpdatePreferences(profileId, preferences) {
    //Get the urls
    let urls = preferences.map(p => GlobalConstants.SPINE_CLIENT + 'api/clusters/' + p.id).join('\n');
    let errorOccurred = false;
    let token = getToken();
    return await fetch(GlobalConstants.SPINE_HOST + 'api/profiles/' + profileId + '/preferences', {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token.access_token,
            'Content-Type': 'text/uri-list'
        },
        body: urls
    }).then(function(response) {
        if(!response.ok) {
            errorOccurred = true;
        }
        return response;
    })
        .then(function (response) {
            if(errorOccurred) {
                return {error: true, message: response.message};
            } else {
                return {error: false};
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error}
        });
}
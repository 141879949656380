import React from "react";
import { Route, Redirect } from "react-router-dom";
import { authCheck } from "./authCheck";
import Header from "../global/header/Header";
import AppNavbar from "../global/navbar/AppNavbar";
import Footer from "../global/footer/Footer";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  let authentified = authCheck();
  return (
    <div>
      <Route
        {...rest}
        render={(props) => {
          if (authentified) {
            return (
                <>
                    <Header backgroundColor={rest.headerBackgroundColor} />
                    <AppNavbar setToken={rest.setToken} roles={rest.roles} setRoles={rest.setRoles} setAccountId={rest.setAccountId} />
                    <Component {...rest} {...props}/>
                    <Footer backgroundColor={rest.footerBackgroundColor} />
                </>
            );
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          }
        }}
      />
    </div>
  );
};

export default ProtectedRoute;
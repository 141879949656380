import {CreateResource, GetFilteredResources, GetResource} from "../../api/SpineClient";
import GlobalConstants from "../../config/GlobalConstants";

//API METHODS
export async function GetCurrentAccount() {
    return await GetResource('api/me');
}

export async function GetProfiles(accountId) {
    return await GetResource('api/accounts/' + accountId + '/profiles');
}

export async function SubscribeToNewsletter(request) {
    return await CreateResource('api/newsletter/subscribe', request, false);
}

export async function UnSubscribeFromNewsletter(request) {
    return await CreateResource('api/newsletter/unsubscribe', request, false);
}

export async function GetSubscribersFromCrmService(email) {
    return await GetFilteredResources('api/newsletter/subscribers/byEmail', {email: email});
}

export async function GetRecommendationSubscriber(email) {
    return await GetFilteredResources('api/crm/recommendation/subscribers/byEmail', {email: email});
}

export async function CreateSubscriber(sub) {
    return await CreateResource('api/newsletter/subscribers/add', sub);
}

export async function GetActiveBillwerkContractsOfCustomer() {
    return await GetResource('api/purchases/customer/contracts?activeOnly=true', 'application/json', {},
        GlobalConstants.BILL_HOST);
}

export async function GetProductByPlanVariantId(planVariantId) {
    return await GetResource('api/purchases/products/search/by-planvariant-id?planVariantId=' + planVariantId,
        'application/json', {}, GlobalConstants.BILL_HOST);
}

export async function GetPlanVariantById(planVariantId) {
    return await GetResource('api/purchases/billwerk/product/search/by-planvariant-id?planVariantId=' + planVariantId,
        'application/json', {}, GlobalConstants.BILL_HOST);
}
//Mailjet
export async function GetAccountNewsletterLists(email) {
    return await GetResource('api/newsletter/lists?email=' + email);
}
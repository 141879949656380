import React, {Component} from "react";
import {Form, Spinner} from "react-bootstrap";
import Strings from "../../../config/Strings";
import {TigerButton} from "../../../global/tiger-button/TigerButton";
import {withToast} from "../../../util/ToastService";
import './TigerpostB2B.css';
import GlobalConstants from "../../../config/GlobalConstants";

class UnsubscribeTigerpostB2B extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            email: null,
            error: false
        };
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
    }

    //Submitting
    async submitForm(e) {
        e.preventDefault();

        if (this.state.email == null || this.state.email === "") {
            this.setState(prevState => ({...prevState, error: true}));
            return;
        }

        this.setState(prevState => ({...prevState, loading: true}));

        let result = await fetch(GlobalConstants.SPINE_HOST +  'api/newsletter/merchant/unsubscribe?email=' + this.state.email, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            }
        })
            .then(function(response) {
                return response;
            })
            .catch(function (error) {
                console.log("Error: " + error);
                return error;
            });

        if (result.ok) {
            this.props.addToast(Strings.UNSUBSCRIBE_TIGERPOST_B2B_SUCCESS, {autoDismiss: true, appearance: "success"});
        } else {
            this.props.addToast(Strings.UNSUBSCRIBE_TIGERPOST_B2B_ERROR, {autoDismiss: true, appearance: "error"});
        }

        this.setState(prevState => ({...prevState, loading: false}));
    }

    //Rendering
    render() {
        return (
            <div className="tigerpost-container">
                <div className="tigerpost-title">{Strings.UNSUBSCRIBE_TIGERPOST_B2B_TITLE}</div>
                <div className="tigerpost-info" dangerouslySetInnerHTML={{__html: Strings.UNSUBSCRIBE_TIGERPOST_B2B_INFO}} />
                <Form className="tigerpost-form" onSubmit={(e) => this.submitForm(e)}>
                    {/* E-Mail */}
                    <Form.Group className="mb-3" controlId="tigerpostB2BEmail">
                        <Form.Label className="auth-label">E-Mail-Adresse</Form.Label>
                        <Form.Control
                            type="email"
                            value={this.state.email}
                            isInvalid={this.state.error}
                            onChange={e => this.setState(prevState => ({
                                ...prevState,
                                email: e.target.value
                            }))} />
                        <Form.Control.Feedback type="invalid">
                            {Strings.CONTRACT_CANCELLATION_ERROR_EMAIL}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <TigerButton className="tigerpost-button" variant="red"
                                 disabled={this.state.loading}>
                        {this.state.loading ?
                            <Spinner animation="border"/>
                            :
                            Strings.UNSUBSCRIBE_TIGERPOST_B2B_BUTTON}
                    </TigerButton>
                </Form>
            </div>
        )
    }
}

export default withToast(UnsubscribeTigerpostB2B);
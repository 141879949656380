import React, {useEffect, useState} from "react";
import './../Purchase.scss';
import {useHistory} from "react-router";
import PurchaseStatus from "../PurchaseStatus";
import Strings from "../../../../config/Strings";
import GlobalConstants from "../../../../config/GlobalConstants";
import {authCheck} from "../../../../util/authCheck";
import {TigerButton} from "../../../../global/tiger-button/TigerButton";
import {Spinner} from "react-bootstrap";
import {ConfirmationModal, InfoModal} from "../../../../global/ConfirmationModal";
import googleTagManagerWrapper from "../../../../api/GoogleTagManagerWrapper";
import {CreateResource, GetResource} from "../../../../api/SpineClient";

export default function TicketPurchaseConfirmation() {



    const [purchaseData] = useState(JSON.parse(sessionStorage.getItem('ls.purchase')) != null ?
        JSON.parse(sessionStorage.getItem('ls.purchase')) : {payment: { channelSecrets: {}}, contact: {}}
    );
    const email = JSON.parse(sessionStorage.getItem('email'));
    const [priceData, setPriceData] = useState({netto: 0.00, tax: 0.00, brutto: 0.00, unit: "€"});
    const [product, setProduct] = useState({});
    const [agb, setAgb] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showLoginHint, setShowLoginHint] = useState(false);

    const history = useHistory();

    document.title = Strings.PURCHASE_TICKET_BROWSER_TAB;

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);

        if (!authCheck()) {
            purchaseData.maxStep = 1;
            sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));
            history.push("/" + GlobalConstants.APP_PATH + "purchase/ticket/authenticate");
            return null;
        } else if (queryParams.get("error") != null) {
            setErrorMessage(Strings.PURCHASE_CONFIRMATION_UNKNOWN_ERROR);
        } else if (queryParams.get("paymentFinished") != null && queryParams.get("paymentFinished") === "1") {
            history.push("/" + GlobalConstants.APP_PATH + "purchase/ticket/success");
            return null;
        }

        if (purchaseData == null) {
            history.push("/" + GlobalConstants.APP_PATH + "purchase");
            return null;
        }

        const loadProduct = async (planVariantId) => {
            setLoading(true);
            let p = await GetResource('api/purchases/products/search/by-planvariant-id?planVariantId=' + planVariantId,
                'application/json', {}, GlobalConstants.BILL_HOST);
            setLoading(false);
            if (p.error || p.result == null) {
                googleTagManagerWrapper.event('cancelPurchase', {reason: 'criticalError'});
                googleTagManagerWrapper.noPurchase();
                history.push("/" + GlobalConstants.APP_PATH + "purchase/error?code=4");
                return;
            }

            return p.result;
        }

        if (product.id == null) {
            loadProduct(purchaseData.planVariantId).then(r => {
                setProduct(r);
                setPriceData(calculatePrices(r, purchaseData));
                if (queryParams.get("error") != null) {
                    setErrorMessage(Strings.PURCHASE_CONFIRMATION_UNKNOWN_ERROR);
                }
            });
        } else if (queryParams.get("error") != null) {
            setErrorMessage(Strings.PURCHASE_CONFIRMATION_UNKNOWN_ERROR);
        }
    }, [history, purchaseData, product]);

    if (purchaseData == null && new URLSearchParams(window.location.search).get("paymentFinished") !== "1") {
        history.push("/" + GlobalConstants.APP_PATH + "purchase");
        return null;
    }

    const submitOrder = async (e) => {
        e.preventDefault();
        setLoading(true);

        //Create the request
        let request = {
            productId: purchaseData.productId,
            payment: purchaseData.payment,
            contact: purchaseData.contact,
            //Required for the target redirect url of the payone response
            clientId: "customer-ui"
        }

        if (orderIsValid(request)) {
            let response = await CreateResource('api/purchases/payone/order/submit', request, true, GlobalConstants.BILL_HOST)
            if (response.error || response.result == null || response.result.status == null) {
                //An unknown error occurred
                console.log("Error: " + response.message);
                setErrorMessage(Strings.PURCHASE_CONFIRMATION_UNKNOWN_ERROR);
            } else if (response.result.status !== 'REDIRECT') {
                //We expect a redirect from payone
                console.log("Warning: Status is " + response.result.status);
            } else {
                //Redirect to the success page
                setLoading(false);
                window.location.href = response.result.redirectUrl;
                //googleTagManagerWrapper.purchaseDTT(purchaseData.planVariantId, product.price, {id: product.id, name: product.billwerkName}); //disable until overall GA concept
            }
        }

        setLoading(false);
    }

    const orderIsValid = (order) => {
        if (order.productId == null) {
            //Product is not set
            setErrorMessage(Strings.PURCHASE_CONFIRMATION_PRODUCT_ERROR);
            return false;
        } else if (order.payment == null || order.payment.channel == null || (order.payment.channel === 'creditcard' && (
            order.payment.channelSecrets === null || order.payment.channelSecrets.pseudocardpan == null ))
        ) {
            //Payment channel is not set OR pseudocardpan in case of "creditcard" payment is not set)
            setErrorMessage(Strings.PURCHASE_CONFIRMATION_PAYMENT_ERROR);
            return false;
        } else if (order.contact == null) {
            setErrorMessage(Strings.PURCHASE_CONFIRMATION_CONTACT_ERORR);
            return false;
        }
        return true;
    }

    const calculatePrices = (product, purchaseData) => {
        //Default
        let tax = 0.19;
        let unit = "€";
        if (purchaseData != null && purchaseData.contact != null && purchaseData.contact.country != null) {
            if (purchaseData.contact.country === "DE") {
                tax = Number(GlobalConstants.TAX_DE);
            } else if (purchaseData.contact.country === "AT") {
                tax = Number(GlobalConstants.TAX_AT);
            } else if (purchaseData.contact.country === "CH") {
                tax = Number(GlobalConstants.TAX_CH);
                unit = "CHF";
            }
        }

        //Calculation and formatting
        let brutto = (product.price).toFixed(2);
        let netto = (product.price / (1 + tax)).toFixed(2)
        let taxAmount = (brutto - netto).toFixed(2);

        return {brutto: brutto, tax: taxAmount, netto: netto, unit: unit}
    }

    const getPaymentMethodName = (method) => {
        switch (method) {
            case GlobalConstants.PAYMENT_METHOD_CREDIT_CARD:
                return Strings.PURCHASE_PAYMENT_METHOD_CC;
            default:
                return Strings.PURCHASE_TICKET_PAYMENT_METHOD_PAYPAL;
        }
    }

    const onChangeStep = (step) => {
        switch (step) {
            case 1:
                setShowLoginHint(true);
                return;
            case 2:
                history.push("/" + GlobalConstants.APP_PATH + "purchase/ticket/payment");
                return;
            case 3:
                //Active step -> Do nothing
                return;
            case 4:
                if (purchaseData.maxStep > 3) {
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/ticket/confirmation");
                }
                return;
            default:
                return;
        }
    }

    return (
        <div className="purchase-container">
            <PurchaseStatus step={3} maxStep={purchaseData != null ? purchaseData.maxStep : 1}
                            onChangeStep={(step) => onChangeStep(step)}
            />
            <div className="purchase-info" dangerouslySetInnerHTML={{__html: Strings.PURCHASE_CONFIRMATION_INFO}}/>
            <div className="purchase-form-container">
                <div className="purchase-form">
                    <div className="purchase-confirmation-section">
                        {Strings.PURCHASE_CONFIRMATION_PRODUCT_DATA}
                    </div>
                    <div>
                        <ul className="purchase-confirmation-list">
                            <li style={{paddingLeft: "10px", paddingTop: "10px"}}><span dangerouslySetInnerHTML={{__html: Strings.PURCHASE_TICKET_CONFIRMATION_PRODUCT_NAME.replace("{0}", product.premiumAccessDuration)}} /></li>
                            <li style={{paddingLeft: "10px", paddingTop: "10px"}}>{Strings.PURCHASE_TICKET_CONFIRMATION_PRODUCT_ACCESS}</li>
                            <li style={{paddingLeft: "10px", paddingTop: "10px"}}>{Strings.PURCHASE_TICKET_CONFIRMATION_PRODUCT_CANCELLATION}</li>
                        </ul>
                        <ul style={{listStyleType: "none"}}>
                            <hr />
                            <li>
                                {Strings.PURCHASE_CONFIRMATION_NETTO}
                                <span style={{float: "right"}}>{priceData.netto + " " + priceData.unit}</span>
                            </li>
                            <li>
                                {Strings.PURCHASE_CONFIRMATION_MWST}
                                <span style={{float: "right"}}>{priceData.tax + " " + priceData.unit}</span>
                            </li>
                            <hr />
                            <li style={{fontWeight: "bold"}}>
                                {Strings.PURCHASE_CONFIRMATION_BRUTTO}
                                <span style={{float: "right"}}>{priceData.brutto + " " + priceData.unit}</span>
                            </li>
                            <hr />
                        </ul>
                    </div>

                    <div className="purchase-confirmation-section">
                        {Strings.PURCHASE_CONFIRMATION_ACCOUNT}
                    </div>
                    <div>
                        <ul style={{listStyleType: "none"}}>
                            <li>{email.email}</li>
                        </ul>
                    </div>
                    <div className="purchase-confirmation-section">
                        {Strings.PURCHASE_CONFIRMATION_PAYMENT_METHOD}
                        <div className="purchase-confirmation-edit" onClick={() => history.push("/" + GlobalConstants.APP_PATH + "purchase/ticket/payment")}>
                            {Strings.PURCHASE_CONFIRMATION_EDIT}
                        </div>
                    </div>
                    <div>
                        <ul style={{listStyleType: "none"}}>
                            <li>{getPaymentMethodName(purchaseData.paymentMethod)}</li>
                        </ul>
                    </div>
                    <div className="purchase-confirmation-section">
                        {Strings.PURCHASE_CONFIRMATION_INVOICE}
                        <div className="purchase-confirmation-edit" onClick={() => history.push("/" + GlobalConstants.APP_PATH + "purchase/ticket/address")}>
                            {Strings.PURCHASE_CONFIRMATION_EDIT}
                        </div>
                    </div>
                    <div>
                        <ul style={{listStyleType: "none"}}>
                            <li>{purchaseData.contact.firstName + " " + purchaseData.contact.lastName}</li>
                            <li>{purchaseData.contact.street + " " + purchaseData.contact.houseNumber}</li>
                            <li>{purchaseData.contact.zip + " " + purchaseData.contact.city}</li>
                            <li>{purchaseData.contact.country === "DE" ? Strings.PURCHASE_COUNTRY_DE :
                                (purchaseData.contact.country === "AT" ? Strings.PURCHASE_COUNTRY_AT : Strings.PURCHASE_COUNTRY_CH)}</li>
                        </ul>
                    </div>
                </div>
                <div className="purchase-info purchase-info-checkbox">
                    <div className={"round"}>
                        <input name="agb" id="agb" type="checkbox" value={agb} checked={agb}
                               onChange={(e) => setAgb(!agb)}/>
                        <label htmlFor="agb"></label>
                    </div>
                    <label className="roundlabel" htmlFor="agb" dangerouslySetInnerHTML={{__html: Strings.PURCHASE_CONFIRMATION_AGB}} />
                </div>
                <div className="purchase-form">
                    <TigerButton className="w-100" type="button" onClick={(e) => submitOrder(e)} variant="red" disabled={!agb}>
                        {loading ?
                            <Spinner animation="border" />
                            :
                            Strings.PURCHASE_CONFIRMATION_SUBMIT_BUTTON}
                    </TigerButton>
                </div>
            </div>

            <InfoModal
                title={Strings.REGISTER_UNKNOWN_ERROR_TITLE}
                text={errorMessage}
                show={errorMessage != null}
                onHide={() => setErrorMessage(null)} />

            <ConfirmationModal
                title={Strings.PURCHASE_REAUTHENTICATE_TITLE}
                text={Strings.PURCHASE_REAUTHENTICATE_HINT}
                actionButtonLabel={Strings.PURCHASE_REAUTHENTICATE_BUTTON}
                actionButtonVariant="danger"
                onActionPress={() => {
                    localStorage.removeItem("ls.token");
                    sessionStorage.removeItem("ls.purchase");
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/" + purchaseData.planVariantId)
                }}
                show={showLoginHint}
                onHide={() => setShowLoginHint(false)}
            />
        </div>
    )
}
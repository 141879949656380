import React from "react";
import GlobalConstants from "../../../config/GlobalConstants";
import Strings from "../../../config/Strings";
import {TigerSpinner} from "../../../global/tiger-spinner/TigerSpinner";
import logo from "../../../../assets/images/loader_bg.png";
import add from "../../../../assets/images/add.png";
import {SingleCardItem} from "./single-item/SingleCardItem";
import {CreateProfile} from "../../profiles/ProfileService";
import './DashboardCard.scss';

export const ProfilesCard = (props) => {
    const {profiles, maxProfiles, loading, history} = props;
    if (loading) {
        return (
            <TigerSpinner/>
        )
    }

    const getCover = (profile) => {
        if (profile != null && profile._links != null && profile._links.avatar != null && profile._links.avatar.href != null) {
            return profile._links.avatar.href;
        } else {
            return logo;
        }
    }

    const openProfile = (profile) => {
        history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "profile-details",
            state: profile._links.self.href.replace("{?projection}", "")
        });
    }

    const createNewProfile = async () => {
        const newProfile = {name: "Neues Profil", defaultProfile: false};
        let res = await CreateProfile(newProfile);
        if (!res.error) {
            history.push({
                pathname: "/" + GlobalConstants.APP_PATH + "profile-details",
                state: res.result._links.self.href
            });
        }
    }

    return (
        <>
            <h4 className="dashboard-card-header">{Strings.DASHBOARD_PROFILES_CARD_LABEL}</h4>
            <div className="dashboard-card-content" style={{color: "#fefefe", paddingLeft: '15px', paddingRight: '15px'}}>
                {profiles.map(profile => (
                    <SingleCardItem icon={getCover(profile)}
                                    label={profile.name}
                                    key={profile.id}
                                    sublabel={null}
                                    onClick={() => openProfile(profile)}
                                    rounded
                                    centered
                    />
                ))}

                {profiles.length < maxProfiles &&
                    <SingleCardItem icon={add}
                                    label={<>{Strings.PROFILES_NEW}</>}
                                    sublabel={null}
                                    onClick={() => createNewProfile()}
                                    rounded
                                    centered
                    />
                }
                <br/>
            </div>
        </>
    );
};
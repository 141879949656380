import React, {Component} from "react";
import "./Dashboard.scss";
import Strings from "../../config/Strings";
import {withToast} from "../../util/ToastService";
import {DashboardCard} from "./DashboardCard";
import {GetActiveBillwerkContractsOfCustomer, GetCurrentAccount} from "./DashboardService";
import {TigerSpinner} from "../../global/tiger-spinner/TigerSpinner";
import {GetDevicesForAccount} from "../tigerboxes/TigerboxSevice";
import {GetAccountWildcards, GetCover} from "../wildcards/WildcardService";
import {GetFilteredSubscriptions} from "../subscriptions/SubscriptionService";
import {TigerboxesCard} from "./cards/TigerboxesCard";
import {WildcardsCard} from "./cards/WildcardsCard";
import ErrorCodes from "../../config/ErrorCodes";
import {NewsletterPreferencesCard} from "./cards/NewsletterPreferencesCard";
import GlobalConstants from "../../config/GlobalConstants";
import {InfoModal} from "../../global/ConfirmationModal";
import {PremiumAccessCard} from "./cards/PremiumAccessCard";
import {ProfilesCard} from "./cards/ProfilesCard";
import {AccountCard} from "./cards/AccountCard";
import {GetUnauthorizedResources} from "../../api/SpineClient";

class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            account: {},
            maxProfiles: 5,
            profiles: [],
            loading: true,
            loadProducts: true,
            wildcards: [],
            activeSubscription: null,
            billwerkContract: null,
            tigerboxes: [],
            selectedProfile: null,
            newsletterList: [],
            infoCode: new URLSearchParams(window.location.search).get("info"),
            info: null
        };

        //Window title
        document.title = "Mein Account :: tiger.media";
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.accountId !== this.props.accountId) {
            window.scrollTo(0, 0);
            this.setState(prevState => ({...prevState, loadProducts: true, loading: true}));
            await this.loadAccount();
            await this.loadDevices();
            await this.loadWildcards();
            await this.loadSubscriptions();
            await this.loadNewsletterPreferences();
            await this.loadBillwerkContract();
            this.setState(prevState => ({...prevState, loadProducts: false, loading: false}));
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.setState(prevState => ({...prevState, loadProducts: true, loading: true}));
        await this.loadAccount();
        await this.loadDevices();
        await this.loadWildcards();
        await this.loadSubscriptions();
        await this.loadNewsletterPreferences();
        await this.loadBillwerkContract();
        this.setState(prevState => ({...prevState, loadProducts: false, loading: false}));

        this.showInfo();
    }

    showInfo() {
        let message = null;
        if (this.state.infoCode === "premium-active") {
            message = Strings.PURCHASE_SUBSCRIPTION_ERROR_SUBSCRIPTION_ACTIVE;
        } else if (this.state.infoCode === "no-tarif") {
            message = Strings.PURCHASE_TARIF_ERROR_NO_AVAILABLE_TARIF_FOUND;
        } else if (this.state.infoCode === "active-iap") {
            message = Strings.PURCHASE_TARIF_ERROR_ACTIVE_IAP;
        } else if (this.state.infoCode === 'tarif-error') {
            message = Strings.PURCHASE_TARIF_UNKNOWN_ERROR;
        }
        if (message != null) {
            this.setState(prevState => ({...prevState, info: message}));
        }
    }

    async loadAccount() {
        if (this.props.accountId != null) {
            const accountRes = await GetCurrentAccount();
            if (!accountRes.error) {
                const account = accountRes.result;
                const profiles = (account != null && account._embedded != null && account._embedded.profiles != null) ? account._embedded.profiles : [];
                this.setState(prevState => ({...prevState, account: account, profiles: profiles, maxProfiles: account.maxProfiles}));
            }
        }
    }
    async loadNewsletterPreferences() {
        let newsletterRes = await GetUnauthorizedResources('api/newsletter', {active_only: true});
        if (!newsletterRes.error) {
            let newsletterList = newsletterRes.result;
            if (newsletterList != null && Array.isArray(newsletterList)) {
                newsletterList = newsletterList.filter(n => n.active);
            }
            this.setState(prevState => ({...prevState, newsletterList: newsletterList}));
        }
    }

    async loadBillwerkContract() {
        if (this.props.accountId != null) {
            let contract = null;
            let contracts = await GetActiveBillwerkContractsOfCustomer();

            if (!contracts.error) {
                let contractsList = contracts.result;
                if (contractsList != null && Array.isArray(contractsList) && contractsList.length > 0) {
                    contract = contractsList[0];
                }
            }
            this.setState(prevState => ({...prevState, billwerkContract: contract, loading: false}));
        } else {
            this.setState(prevState => ({...prevState, loading: false}));
        }
    }

    async loadDevices() {
        if (this.props.accountId != null) {
            let response = await GetDevicesForAccount(this.props.accountId);

            if (response.error === false) {
                const devicesRes = response.result._embedded?.devices ? response.result._embedded?.devices : [];

                this.setState(prevState => ({
                    ...prevState,
                    tigerboxes: devicesRes.length > 6 ? devicesRes.splice(0, 6) : devicesRes,
                }));
            } else {
                this.props.addToast(Strings.TOAST_ERROR_FETCHING_DATA + ErrorCodes.CODE_DEVICE + " - " + response.message, {
                    autoDismiss: true,
                    appearance: 'error',
                    placement: 'bottom-center'
                });
            }
        }
    }

    async loadWildcards() {
        if (this.props.accountId != null) {
            //Load the wildcards
            let wildcards = await GetAccountWildcards();

            if (wildcards.error === false) {
                const wildcardsRes = wildcards.result;

                //Show max 6 wildcards
                const wildcardsToShow = wildcardsRes.page.totalElements > 6 ? wildcardsRes._embedded.cards.splice(0, 6) : (wildcardsRes.page.totalElements > 0 ? wildcardsRes._embedded.cards : []);

                for (const wildcardToShow of wildcardsToShow) {
                    let product = wildcardToShow._embedded ? (wildcardToShow._embedded.accountgeneratedcontents || wildcardToShow._embedded.products) : null;
                    let cover = product && product._links && product._links.cover && product._links.cover.href;
                    if (cover && cover?.includes('tigerbooks.de')) {
                        wildcardToShow["blob"] = await GetCover(cover);
                    }
                }

                this.setState(prevState => ({
                    ...prevState,
                    wildcards: wildcardsToShow
                }));

            } else {
                this.props.addToast(Strings.TOAST_ERROR_FETCHING_DATA + ErrorCodes.CODE_WILDCARD + " - " + wildcards.message, {
                    autoDismiss: true,
                    appearance: 'error',
                    placement: 'bottom-center'
                });
            }
        }
    }

    async loadSubscriptions() {
        if (this.props.accountId != null) {
            let filter = {
                page: 1,
                active_only: true
            };
            let subscriptions = await GetFilteredSubscriptions(this.props.accountId, filter);

            if (subscriptions.error === false) {
                const subscriptionsRes = subscriptions.result;

                this.setState(prevState => ({
                    ...prevState,
                    activeSubscription: subscriptionsRes.length > 0 ? subscriptionsRes[0] : null
                }));
            } else {
                this.props.addToast(Strings.TOAST_ERROR_FETCHING_DATA + ErrorCodes.CODE_SUBSCRIPTION + " - " + subscriptions.message, {
                    autoDismiss: true,
                    appearance: 'error',
                    placement: 'bottom-center'
                });
            }
        }
    }

    render() {
        return (
            <div style={{width: "100%", display: "inline-block"}}>
                <div className="content-header" style={{color: '#ec8320', paddingLeft: "2%"}}>{Strings.TITLE_DASHBOARD}</div>
                {!this.state.loading && (
                    <div className="dashboard">
                        <DashboardCard
                            text={Strings.DASHBOARD_ACCOUNT_SETTINGS}
                            backgroundColor={'#a51482'}
                            body={<AccountCard account={this.state.account}
                                               loading={this.state.loading}
                                               history={this.props.history}
                                               setToken={this.props.setToken}
                                               setRoles={this.props.setRoles}
                                               setAccountId={this.props.setAccountId}
                                               setEmail={this.props.setEmail}
                            />}
                        />
                        <DashboardCard
                            text={Strings.DASHBOARD_PREMIUM_ACCESS}
                            link="premium"
                            backgroundColor={'#f07319'}
                            body={<PremiumAccessCard subscription={this.state.activeSubscription}
                                                     contract={this.state.billwerkContract}
                                                     loading={this.state.loading}/>}
                        />
                        <DashboardCard
                            text={Strings.DASHBOARD_NUMBER_PROFILES}
                            link="profiles"
                            backgroundColor={'#00b4eb'}
                            body={<ProfilesCard profiles={this.state.profiles}
                                                loading={this.state.loading}
                                                maxProfiles={this.state.maxProfiles}
                                                history={this.props.history}/>}
                        />
                        <DashboardCard
                            text={Strings.DASHBOARD_NUMBER_TIGERBOXES}
                            link="tigerboxes"
                            backgroundColor={'#fab900'}
                            body={<TigerboxesCard devices={this.state.tigerboxes}
                                                  loading={this.state.loading}
                                                  history={this.props.history}
                            />}
                        />
                        <DashboardCard
                            text={Strings.DASHBOARD_NUMBER_WILDCARDS}
                            link="wildcards"
                            backgroundColor={'#00afb4'}
                            body={
                                <WildcardsCard wildcards={this.state.wildcards}
                                               loading={this.state.loading}
                                               history={this.props.history}/>
                            }
                        />
                        <DashboardCard
                            text={Strings.DASHBOARD_NEWSLETTER}
                            link="newsletter"
                            backgroundColor={'#e60046'}
                            body={<NewsletterPreferencesCard newsletterList={this.state.newsletterList} />}
                        />
                    </div>
                )}

                {this.state.loading && (
                    <TigerSpinner/>
                )}

                <InfoModal
                    title={"Info"}
                    text={this.state.info}
                    show={this.state.info != null}
                    onHide={() => {
                        this.setState(prevState => ({...prevState, info: null, infoCode: null}));
                        this.props.history.push("/" + GlobalConstants.APP_PATH + "dashboard");
                    }}
                />
            </div>
        )
    }
}

export default withToast(Dashboard);
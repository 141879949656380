import React, {Component} from "react";
import Strings from "../../config/Strings";
import {withToast} from "../../util/ToastService";
import './LandingPage.css';
import {Spinner} from "react-bootstrap";
import {TigerButton} from "../../global/tiger-button/TigerButton";
import GlobalConstants from "../../config/GlobalConstants";
import {TigerSpinner} from "../../global/tiger-spinner/TigerSpinner";

class AccountActivationLandingPage extends Component {

    constructor(props) {
        super(props);
        const queryParams = new URLSearchParams(window.location.search);
        this.state = {
            key: queryParams.get("key"),
            loading: true,
            error: false
        };
    }

    async componentDidMount() {
        if (this.state.key != null) {
            const response = await fetch(GlobalConstants.SPINE_HOST + 'api/activate?key=' + this.state.key, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json'
                }
            }).then(function (response) {
                return response;
            }).then(function (response) {
                return response;
            }).catch(function (error) {
                return error;
            });
            this.setState(prevState => ({
                ...prevState,
                loading: false,
                error: !response.ok
            }));
        } else {
            this.setState(prevState => ({...prevState, loading: false}));
        }
    }


    render() {
        if (this.state.key == null) {
            return (
                <div className="p-3 mb-4">
                    <h1 className="landingpage-header">
                        {Strings.ACCOUNT_ACTIVATION_TITLE_ERROR}
                    </h1>
                    <div className="landing-page-body" style={{textAlign: "center", lineHeight: "42px"}}>
                        {this.state.loading ?
                            <Spinner animation="border"/>
                            :
                            <div dangerouslySetInnerHTML={{__html: Strings.ACCOUNT_ACTIVATION_BODY_MISSING_KEY}} />
                        }
                    </div>
                </div>
            )
        }

        if (this.state.error) {
            return (
                <div className="p-3 mb-4">
                    <h1 className="landingpage-header">
                        {Strings.ACCOUNT_ACTIVATION_TITLE_ERROR}
                    </h1>
                    <div className="landing-page-body" style={{textAlign: "center", lineHeight: "42px"}}>
                        {this.state.loading ?
                            <Spinner animation="border"/>
                            :
                            <div dangerouslySetInnerHTML={{__html: Strings.ACCOUNT_ACTIVATION_BODY_ERROR}} />
                        }
                    </div>
                </div>
            )
        }

        if (this.state.loading) {
            return (
                <div className="p-3 mb-4">
                    <TigerSpinner />
                </div>
            );
        }

        return (
            <div className="p-3 mb-4">
                <h1 className="landingpage-header">
                    {Strings.ACCOUNT_ACTIVATION_TITLE}
                </h1>
                <div className="landing-page-body" style={{textAlign: "center", lineHeight: "42px"}}>
                    <div dangerouslySetInnerHTML={{__html: Strings.ACCOUNT_ACTIVATION_BODY}} />
                    <br />
                    <TigerButton style={{margin: "auto", minWidth: "300px"}} variant="red" onClick={() => this.props.history.push("/")}>Zum Login</TigerButton>
                </div>
            </div>
        )
    }
}

export default withToast(AccountActivationLandingPage);
import './../Tarif.css';
import {Card} from "react-bootstrap";
import logo from './../../../../../../assets/images/loader_bg.png';
import {TigerButton} from "../../../../../global/tiger-button/TigerButton";

export const SingleTarif = (props) => {
    const {tarif} = props;
    return (
        tarif && (
            <Card className="tarif-item">
                <Card.Header> </Card.Header>
                <div style={{width: "200px", height: "200px", margin: "30px auto"}}>
                    <Card.Img style={{width: "200px", borderRadius: "20px"}} variant="top" src={tarif.coverUrl != null ? tarif.coverUrl : logo}/>
                </div>
                <Card.Body>
                    <Card.Title className="text-center" style={{
                        fontWeight: "bold",
                        fontSize: "22px",
                        color: "#e50045",
                    }}>{tarif.name}</Card.Title>
                    <hr />
                    <Card.Text style={{fontSize: "20px"}} dangerouslySetInnerHTML={{__html: tarif.description}} />
                    <br />
                    <div>
                        <TigerButton style={{margin: "auto"}} type="button" onClick={props.onSelectTarif}>Jetzt auswählen</TigerButton>
                    </div>
                </Card.Body>
            </Card>
        )
    );
}
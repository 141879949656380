import React from "react";
import {Route} from "react-router-dom";
import AppNavbar from "../global/navbar/AppNavbar";
import Header from "../global/header/Header";
import Footer from "../global/footer/Footer";

const RouteWithNavbar = ({ component: Component, ...rest }) => {
  return (
    <div>
      <Route
        {...rest}
        render={(props) => {
            return (
                <>
                    {!rest.hideHeader &&
                        <Header backgroundColor={rest.headerBackgroundColor}/>
                    }
                    <AppNavbar setToken={rest.setToken} roles={rest.roles} setRoles={rest.setRoles}
                               setAccountId={rest.setAccountId}/>
                    <Component {...rest} {...props}/>
                    <Footer backgroundColor={rest.footerBackgroundColor} footerColor={rest.footerColor}/>
                </>
            );
        }
        }
      />
    </div>
  );
};

export default RouteWithNavbar;
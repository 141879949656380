import './ClusterProduct.scss';
import React from "react";
import GlobalConstants from "../../config/GlobalConstants";
import {GetProductTitle} from "../products/ProductService";
import {Card, Col} from "react-bootstrap";
import ProductCoverWithMetadata from "../products/ProductCoverWithMetadata";

export default function ClusterProduct(props) {
    const {product} = props;

    const openProduct = () => {
        props.history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "product/" + product.id,
            state: {
                clusterId: props.clusterId,
                profileLink: props.profileLink,
                profileId: props.profileId,
                isSelected: props.isSelected
            }
        })
    }

    let title = GetProductTitle(product);

    return (
        <div className="cluster-product-container">

            <Card className="rounded-card cluster-product-item justify-content-between">
                <Col className="cluster-product-item-cover-container">
                    <ProductCoverWithMetadata product={product}/>
                </Col>
                <Col className="pl-0">
                    <Card.Body className="pl-0 h-100">
                        <Card.Title className={"cluster-product-item-title"}><a
                            href={"/product/" + product.id} onClick={() => openProduct()}>{title}</a></Card.Title>

                        <Card.Text dangerouslySetInnerHTML={{__html: product.description}}></Card.Text>
                    </Card.Body>
                </Col>
            </Card>
        </div>
    )
}
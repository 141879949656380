import React, {useEffect, useState} from "react";
import './../Purchase.scss';
import {useHistory} from "react-router";
import PurchaseStatus from "../PurchaseStatus";
import Strings from "../../../../config/Strings";
import GlobalConstants from "../../../../config/GlobalConstants";
import {authCheck} from "../../../../util/authCheck";
import creditCard from "../../../../../assets/images/creditcard.png";
import {TigerButton} from "../../../../global/tiger-button/TigerButton";
import {Form} from "react-bootstrap";
import {CreditCardCheckForm} from "../CreditCardCheckForm";
import {ConfirmationModal} from "../../../../global/ConfirmationModal";

export default function TicketPurchasePayment() {

    const [purchaseData, setPurchaseData] = useState(JSON.parse(sessionStorage.getItem('ls.purchase')));
    const [paymentMethod, setPaymentMethod] = useState(purchaseData != null ? purchaseData.paymentMethod : null);
    const [showLoginHint, setShowLoginHint] = useState(false);

    const history = useHistory();

    document.title = Strings.PURCHASE_TICKET_BROWSER_TAB;

    useEffect(() => {
        if (purchaseData == null) {
            history.push("/" + GlobalConstants.APP_PATH + "purchase");
        }
        if (!authCheck()) {
            //Set the max step to 2 and proceed
            purchaseData.maxStep = 1;
            sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));
            history.push("/" + GlobalConstants.APP_PATH + "purchase/ticket/authenticate");
        }
    }, [history, purchaseData]);

    const submitPayPalChannel = (e) => {
        e.preventDefault();

        let secrets = {
            pseudocardpan: null,
            iban: null,
            bic: null,
            owner: null
        };

        purchaseData.payment = {
            channel: paymentMethod,
            channelSecrets: secrets
        };
        purchaseData.paymentMethod = paymentMethod;

        setPurchaseData(purchaseData);
        sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));

        history.push("/" + GlobalConstants.APP_PATH + "purchase/ticket/address");
    }

    const submitCreditCardChannel = (response) => {
        if (response.status === 'VALID') {
            let secrets = {
                pseudocardpan: response.pseudocardpan,
                iban: null,
                bic: null,
                owner: null
            };

            purchaseData.payment = {
                channel: paymentMethod,
                channelSecrets: secrets
            };
            purchaseData.paymentMethod = paymentMethod;

            setPurchaseData(purchaseData);
            sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));

            history.push("/" + GlobalConstants.APP_PATH + "purchase/ticket/address");
        }
    }

    const onChangeStep = (step) => {
        switch (step) {
            case 1:
                setShowLoginHint(true);
                return;
            case 2:
                //Active step -> Do nothing
                return;
            case 3:
                if (purchaseData.maxStep > 2) {
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/ticket/confirmation");
                }
                return;
            case 4:
                if (purchaseData.maxStep > 3) {
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/ticket/confirmation");
                }
                return;
            default:
                return;
        }
    }

    return (
        <div className="purchase-container">
            <PurchaseStatus step={2} maxStep={purchaseData != null ? purchaseData.maxStep : 1}
                            onChangeStep={(step) => onChangeStep(step)}
            />
            <div className="purchase-info" dangerouslySetInnerHTML={{__html: Strings.PURCHASE_PAYMENT_METHOD_INFO}}/>
            <div className="purchase-form-container">
                <div className="purchase-payment-buttons">
                    <div className="purchase-payment-provider">
                        {Strings.PURCHASE_TICKET_PAYMENT_METHOD_PAYPAL}
                        <div className={paymentMethod === GlobalConstants.PAYMENT_METHOD_PAYPAL ? "purchase-payment-provider-img purchase-payment-provider-img-active" : "purchase-payment-provider-img"}
                             onClick={() => setPaymentMethod(GlobalConstants.PAYMENT_METHOD_PAYPAL)}>
                            <img src="https://www.paypalobjects.com/webstatic/de_DE/i/de-pp-logo-150px.png" alt={"payment paypal"} />
                        </div>
                        <div className={"round"} style={{margin: "0 auto", width: "20px"}}>
                            <input name="paypal" id="paypal" type="checkbox" value={paymentMethod === GlobalConstants.PAYMENT_METHOD_PAYPAL}
                                   checked={paymentMethod === GlobalConstants.PAYMENT_METHOD_PAYPAL}
                                   onChange={() => setPaymentMethod(GlobalConstants.PAYMENT_METHOD_PAYPAL)}
                                   />
                            <label htmlFor="paypal" />
                        </div>
                    </div>
                    {
                    <div className="purchase-payment-provider">
                        {Strings.PURCHASE_PAYMENT_METHOD_CC}
                        <div className={paymentMethod === GlobalConstants.PAYMENT_METHOD_CREDIT_CARD ? "purchase-payment-provider-img purchase-payment-provider-img-active" : "purchase-payment-provider-img"}
                             onClick={() => setPaymentMethod(GlobalConstants.PAYMENT_METHOD_CREDIT_CARD)}>
                            <img src={creditCard} alt={"payment creditcard"} />
                        </div>
                        <div className={"round"} style={{margin: "0 auto", width: "20px"}}>
                            <input name="creditcard" id="creditcard" type="checkbox" value={paymentMethod === GlobalConstants.PAYMENT_METHOD_CREDIT_CARD}
                                   checked={paymentMethod === GlobalConstants.PAYMENT_METHOD_CREDIT_CARD}
                                   onChange={() => setPaymentMethod(GlobalConstants.PAYMENT_METHOD_CREDIT_CARD)}
                            />
                            <label htmlFor="creditcard" />
                        </div>
                    </div>
                     }
                </div>
                <div className="purchase-form">
                    <Form className="text-left" onSubmit={(e) => submitPayPalChannel(e)}>
                        {/* Following form is only required for creditcard*/}
                        {paymentMethod === GlobalConstants.PAYMENT_METHOD_CREDIT_CARD ?
                            <CreditCardCheckForm handleFormClick={(r) => submitCreditCardChannel(r)}/>
                        :
                            <TigerButton className="w-100" variant="red" disabled={paymentMethod == null}>
                                {Strings.PURCHASE_PROCEED_BUTTON}
                            </TigerButton>
                        }

                    </Form>
                </div>
                <ConfirmationModal
                    title={Strings.PURCHASE_REAUTHENTICATE_TITLE}
                    text={Strings.PURCHASE_REAUTHENTICATE_HINT}
                    actionButtonLabel={Strings.PURCHASE_REAUTHENTICATE_BUTTON}
                    actionButtonVariant="danger"
                    onActionPress={() => {
                        localStorage.removeItem("ls.token");
                        sessionStorage.removeItem("ls.purchase");
                        history.push("/" + GlobalConstants.APP_PATH + "purchase/" + purchaseData.planVariantId)
                    }}
                    show={showLoginHint}
                    onHide={() => setShowLoginHint(false)}
                />
            </div>
        </div>
    )
}
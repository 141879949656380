import React, {Component} from "react";
import Strings from "../../config/Strings";
import {BackButton} from "../../global/tiger-button/BackButton";
import {TigerSpinner} from "../../global/tiger-spinner/TigerSpinner";
import {withToast} from "../../util/ToastService";
import {GetDevicesForAccount, ReleaseFromDevice, GetDeviceIcon} from "./TigerboxSevice";
import GlobalConstants from "../../config/GlobalConstants";
import ErrorCodes from "../../config/ErrorCodes";
import {Card, Row} from "react-bootstrap";
import {TigerMessage} from "../../global/tiger-message/TigerMessage";

class Tigerboxes extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            devices: [],
            showRemoveModal: false,
            deviceForRemoval: null
        };
    }

    async componentDidUpdate(prevProps) {
        window.scrollTo(0, 0);
        if (prevProps.accountId !== this.props.accountId) {
            await this.loadDevices();
        }
    }

    async componentDidMount() {
        await this.loadDevices();
    }

    async loadDevices() {
        this.setState({loading: true});
        if (this.props.accountId != null) {
            let devices = await GetDevicesForAccount(this.props.accountId);

            if (!devices.error) {
                const devicesRes = devices.result._embedded.devices;

                this.setState({
                    devices: devicesRes
                });
            } else {
                this.props.addToast(Strings.TOAST_ERROR_FETCHING_DATA + ErrorCodes.CODE_DEVICE + " - " + devices.message, {
                    autoDismiss: true,
                    appearance: 'error',
                    placement: 'bottom-center'
                });
                this.setState({
                    loading: false,
                    devices: []
                });
            }
        }
        this.setState({loading: false});

        //Window title
        document.title = "Meine tigerboxen :: tiger.media";
    }

    showModal = (deviceId, index) => {
        this.setState({
            showRemoveModal: true,
            deviceForRemoval: {
                id: deviceId,
                index: index
            }
        });
    }

    openOverview(device) {
        this.props.history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "tigerbox-details",
            state: {device: device}
        });
    }

    removeDevice = () => {
        ReleaseFromDevice(this.state.deviceForRemoval.id).then(res => {
            if (res.error === false) {
                // remove from state
                let deviceArray = this.state.devices;
                let ind = deviceArray.findIndex(d => d.id === this.state.deviceForRemoval.id);
                deviceArray.splice(ind, 1);

                this.setState({
                    devices: deviceArray
                }, () => {
                    this.props.addToast(Strings.TIGERBOXES_UNASSIGN_SUCCESS, {
                        autoDismiss: true,
                        appearance: 'success',
                        placement: 'bottom-center'
                    });
                });
            }
            else {
                this.props.addToast(Strings.TIGERBOXES_UNASSIGN_ERROR + res.message, {
                    autoDismiss: true,
                    appearance: 'error',
                    placement: 'bottom-center'
                });
            }
            this.setState({
                showRemoveModal: false,
                deviceForRemoval: null
            });
        });
    }

    closeView = () => {
        this.props.history.push({
            pathname: "/" + GlobalConstants.APP_PATH,
            state: this.state.profileLink
        })
    }

    render() {
        return (
            <div className="content-box devices-container">
                <BackButton onClick={() => this.closeView()}/>
                <div className="content-header" style={{color: '#fab900'}}>{Strings.TITLE_TIGERBOXES}</div>
                <div className="mb-4">
                    {!this.state.loading && this.state.devices.length !== 0 &&
                    <Row className="mx-0 devices-items-container">
                        {this.state.devices.map(device => {
                            return this.singleDevice(device)
                        })}
                    </Row>
                    }

                    {!this.state.loading && this.state.devices.length === 0 && (
                        <TigerMessage text={Strings.DEVICES_NOTHING_FOUND}/>
                    )}

                    {this.state.loading && <TigerSpinner/>}
                </div>
            </div>
        )
    }

    singleDevice(device) {
        return (
            <Card className="device mx-2" onClick={() => this.openOverview(device)}>
                <div style={{height: "23.6rem"}}>
                    <div style={{height: "100%"}}>
                        <img className="cover-image" src={GetDeviceIcon(device)} alt="cover" />
                    </div>
                </div>
                <Card.Body>
                    <Card.Title>{device.displayName}</Card.Title>
                </Card.Body>
            </Card>
        );
    }
}

export default withToast(Tigerboxes);
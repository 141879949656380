import React from "react";
import {TigerSpinner} from "../../../../global/tiger-spinner/TigerSpinner";
import logo from "../../../../../assets/images/loader_bg_white.png";
import {SingleCardItem} from "../../../dashboard/cards/single-item/SingleCardItem";
import './../../../dashboard/cards/DashboardCard.scss';
import GlobalConstants from "../../../../config/GlobalConstants";

export const ProfileCard = (props) => {
    const {products, loading, buttonText, onButtonClick, onShowAll, onOpenResource, noContentTitle, noContentInfo, centered = false, rounded = false} = props;

    const getCover = (product) => {
        let url = logo;
        if (product.cover != null && product.cover.contentLocation != null && product.cover.contentLocation.location) {
            url = product.cover.contentLocation.location;
        }
        if (product._links != null && product._links.cover != null && product._links.cover.href != null) {
            url = product._links.cover.href;
        }
        if (product._links != null && product._links.icon != null && product._links.icon.href != null) {
            url = product._links.icon.href;
        }
        if (GlobalConstants.ENVIRONMENT !== 'production') {
            url = url.replace('production', 'staging');
        }
        return url;
    }

    if (loading) {
        return (
            <TigerSpinner/>
        )
    }

    if (products.length === 0) {
        return (
            <>
                <div className="dashboard-card-header"/>
                <div className="static-content-container">
                    <img src={logo} width={70} alt={"wildcards Logo"}/>
                    <hr style={{width: "270px"}}/>
                    <span style={{fontWeight: "bold"}}>{noContentTitle}</span>
                    <div className="small-text"
                         dangerouslySetInnerHTML={{__html: noContentInfo}}/>
                </div>
                {buttonText != null && onButtonClick != null &&
                <div className="dashboard-card-footer centered">
                    <button
                        className={"dashboard-card-button"}
                        type="button"
                        onClick={onButtonClick}
                    >{buttonText}
                    </button>
                </div>
                }
            </>
        );
    }
    return (
        <>
            <div className="dashboard-card-header"/>
            <div style={{height: "250px", color: "#fefefe", paddingLeft: "15px", paddingRight: "15px"}}>
                {products.map(product => (
                    <SingleCardItem icon={getCover(product)}
                                    key={product.id}
                                    //label={product[label]}
                                    //sublabel={sublabel != null ? product[sublabel] : sublabel}
                                    centered={centered}
                                    rounded={rounded}
                                    onClick={() => onOpenResource(product)}

                    />
                ))}
            </div>
            {onShowAll &&
                <div className="dashboard-card-footer centered">
                    <button
                        className={"dashboard-card-button"}
                        type="button"
                        onClick={onShowAll}
                    >Alle anzeigen
                    </button>
                </div>
            }
        </>
    );
};
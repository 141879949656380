import React, {Component} from "react";
import {TigerSpinner} from "../../../global/tiger-spinner/TigerSpinner";
import GlobalConstants from "../../../config/GlobalConstants";
import {TigerButton} from "../../../global/tiger-button/TigerButton";
import {Spinner} from "react-bootstrap";
import Strings from "../../../config/Strings";
import './Purchase.scss';
import {GetResource} from "../../../api/SpineClient";

export class CreditCardCheckForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            hasLoadingError: false,
        };
    }

    componentDidMount() {
        window.payCallback = this.payCallback;

        const script = document.createElement('script');

        script.src ='https://secure.pay1.de/client-api/js/v1/payone_hosted_min.js';
        script.async = true;

        script.onload = this.payoneJSLoaded.bind(this);

        document.body.appendChild(script);
    }

    payoneJSLoaded() {
        // eslint-disable-next-line no-undef
        Payone.ClientApi.Language.de.placeholders.cvc = 'CCV';
        // eslint-disable-next-line no-undef
        Payone.ClientApi.Language.de.placeholders.expireMonth = 'MM';     // not for input field type “select”
        // eslint-disable-next-line no-undef
        Payone.ClientApi.Language.de.placeholders.expireYear = 'JJJJ';   // not for input field type “select”

        let request = {};

        const successFunc = (response) => {
            request = {
                api_version: '3.11',                         // api version
                request: 'creditcardcheck',                  // fixed value
                responsetype: 'JSON',                        // fixed value
                mode: GlobalConstants.PAYONE_MODE,                     // desired mode
                mid: GlobalConstants.PAYONE_MID,                       // your MID
                aid: GlobalConstants.PAYONE_AID,                       // your AID
                portalid: GlobalConstants.PAYONE_PORTAL_ID,             // your PortalId
                encoding: 'UTF-8',                           // desired encoding
                storecarddata: 'yes',                        // fixed value
                // hash calculated over your request-parameter-values (alphabetical request-order) plus PMI portal key
                hash: `${response.value}`
            };
            this.setState({loading: false, hasLoadingError: false});
            // eslint-disable-next-line no-undef
            this.iframes = new Payone.ClientApi.HostedIFrames(payoneConfig, request);
        };

        const payoneConfig = {
            fields: {
                cardtype: {
                    selector: 'cardtype',   // put name of your div-container here
                    cardtypes: ['V'], //['V', 'M'],  // define possible cardtypes in PAYONE iFrame
                },
                cardpan: {
                    selector: 'cardpan',
                    type: 'text',
                },
                cardcvc2: {
                    selector: 'cardcvc2',
                    type: 'text',  // Could be "text" as well.
                    size: '4',
                    maxlength: '4',
                    length: { 'V': 3, 'M': 3 } // enforce 3 digit CVC für VISA and Mastercard
                },
                cardexpiremonth: {
                    selector: 'cardexpiremonth',
                    type: 'text', // select (default), text, tel
                    size: '2',
                    maxlength: '2',
                    iframe: {
                        width: '180px'
                    },
                },
                cardexpireyear: {
                    selector: 'cardexpireyear',
                    type: 'text', // select (default), text, tel
                    maxlength: 4,
                    iframe: {
                        width: '180px'
                    },
                }
            },
            defaultStyle: {
                input: 'width: 100%; padding: 7px 22px 9px; border-radius: 35px; border: 1px solid #dedede; outline: 0; font-size: 24px; color: inherit; background-color: white;',
                select: 'outline: none; font-size: 1em; border: 1px solid rgb(222, 222, 222);;width: 100%; border-radius: 35px;font-size: 24px;padding: 7px 22px 9px;',
                iframe: {
                    height: '47px',
                    width: '100%'
                }
            },
            autoCardtypeDetection: {
                // Is not working as expected -> Therefor disabled
                supportedCardtypes: ['V'], //['V', 'M'],
                callback: function(detectedCardtype) {
                    // For the output container below.
                    document.getElementById('autodetectionResponsePre').innerHTML = detectedCardtype;

                    if (detectedCardtype === 'V') {
                        document.getElementById('visa').style.borderColor = '#00F';
                        document.getElementById('mastercard').style.borderColor = '#FFF';
                    } else if (detectedCardtype === 'M') {
                        document.getElementById('visa').style.borderColor = '#FFF';
                        document.getElementById('mastercard').style.borderColor = '#00F';
                    } else {
                        document.getElementById('visa').style.borderColor = '#FFF';
                        document.getElementById('mastercard').style.borderColor = '#FFF';
                    }
                } ,
                deactivate: true // To turn off automatic card type detection.
            },

            // eslint-disable-next-line no-undef
            language: Payone.ClientApi.Language.de, //, // Language to display error-messages (default: Payone.ClientApi.Language.en)
            error: 'error' // area to display error-messages (optional)
        };

        const errorFunc = () => {
            this.setState({loading: false, hasLoadingError: true});
        };


        GetResource('api/v1/hash', 'application/json', {}, GlobalConstants.BILL_HOST).then(r => {
            if (!r.error) {
                successFunc(r.result);
            } else {
                errorFunc();
            }
        })
    }

    render() {

        if(this.state.loading) {
            return <TigerSpinner isLoading={this.state.loading} />;
        }

        return (
            <div className="purchase-cc-check-form">
                <input type="hidden" name="pseudocardpan" id="pseudocardpan" />
                <input type="hidden" name="truncatedcardpan" id="truncatedcardpan" />
                <input type="hidden" name="cardtypeResponse" id="cardtypeResponse" />
                <input type="hidden" name="cardexpiredateResponse" id="cardexpiredateResponse" />


                <div className="purchase-cc-check-label">
                    <label htmlFor="cardtypeInput">Kreditkartentyp</label>
                    <span id="cardtype" className="inputIframe"></span>
                </div>

                <div className="purchase-cc-check-label">
                    <label htmlFor="cardpanInput">Kreditkartennummer</label>
                    <span className="inputIframe" id="cardpan"></span>
                </div>

                <div className="flexContainer">
                    <div className="purchase-cc-check-date month">
                        <label htmlFor="cardexpiremonth">Monat</label>
                        <br />
                        <span id="cardexpiremonth"></span>
                    </div>
                    <div className="purchase-cc-check-date year">
                        <label htmlFor="cardexpireyear">Jahr</label>
                        <br />
                        <span id="cardexpireyear"></span>
                    </div>
                </div>

                <div className="purchase-cc-check-label">
                    <label htmlFor="cvcInput">Kartenprüfnummer</label>
                    <span className="inputIframe" id="cardcvc2"></span>
                </div>

                <br />
                <div id="error" style={{color: "#e50045"}}/>
                <br />

                <TigerButton className="w-100" variant="red" disabled={this.state.loading}
                             id="submit" type="button" onClick={this.onClickSubmit}>
                    {this.state.loading ?
                        <Spinner animation="border" />
                        :
                        Strings.PURCHASE_PROCEED_BUTTON}
                </TigerButton>
            </div>
        );
    }

    // Function called by submitting PAY-button
    payCallback = (response) => {
        this.props.handleFormClick(response);
    };

    pay = () =>  {
        if (this.iframes.isComplete()) {
            // Perform "CreditCardCheck" to create and get a PseudoCardPan; then call your function "payCallback"
            this.iframes.creditCardCheck('payCallback');
        } else {
            alert('Die Überprüfung konnte nicht vollständig abgeschlossen werden. Bitte lade die Seite neu und versuche es erneut.');
        }
    };

    onClickSubmit = () => {
        this.pay();
    };
}
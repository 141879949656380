import React, {useEffect, useState} from "react";
import Strings from "../../../config/Strings";
import {TigerButton} from "../../../global/tiger-button/TigerButton";
import {Spinner} from "react-bootstrap";
import GlobalConstants from "../../../config/GlobalConstants";
import {useHistory, useLocation} from "react-router";
import {authCheck} from "../../../util/authCheck";
import {InfoModal} from "../../../global/ConfirmationModal";
import {CreateResource, GetUnauthorizedResources} from "../../../api/SpineClient";

export default function DDFRedemptionPreview({ email, setToken }) {

    const [loading, setLoading] = useState(false);
    const coupon = JSON.parse(sessionStorage.getItem('ls.ddf'))
    const history = useHistory();
    const [showError, setShowError] = useState(false);
    const [errorMessage] = useState(null);
    const location = useLocation();

    //ROUTING
    useEffect(() => {
        if (!authCheck()) {
            history.push("/" + GlobalConstants.APP_PATH + "ddflive/authenticate#ddf-authentication-register");
        }

        if (coupon == null || coupon.code == null || coupon.pin == null) {
            history.push("/" + GlobalConstants.APP_PATH + "ddflive");
        }

        if (location !== undefined && location.hash !== "") {
            const element = document.getElementById(location.hash);
            if (element) {
                element.scrollIntoView({behavior: "smooth", block: "center"});
            }
        }
    }, [history, coupon, location]);

    if (coupon == null || coupon.code == null || coupon.pin == null) {
        history.push("/" + GlobalConstants.APP_PATH + "ddflive");
    }

    if (!authCheck()) {
        history.push("/" + GlobalConstants.APP_PATH + "ddflive/authenticate#ddf-authentication-register");
    }

    const redeemDdf = async () => {
        setLoading(true);

        //Submit the response
        CreateResource('api/purchases/coupons/submit-order',{code: coupon.code, pin: coupon.pin},
            true, GlobalConstants.BILL_HOST)
            .then(r => r);

        //Get the target url
        let targetUrl = GlobalConstants.DEFAULT_DEEPLINK_DDF;
        let redirect = await GetUnauthorizedResources("api/purchases/coupons/deeplinks/ddf?redirect=false", {}, 'application/json', GlobalConstants.BILL_HOST);

        if (!redirect.error && redirect.result != null && redirect.result.targetUrl != null) {
            targetUrl = redirect.result.targetUrl;
        }

        //Redirect
        window.scrollTo(0, 0);
        history.push("/" + GlobalConstants.APP_PATH + "ddflive/success?target=" + targetUrl);
        setLoading(false);
    }

    //Rendering
    return (
        <>
            <div className="ddf-container">
                <div className="ddf-taipan" />
                <div className="ddf-title" id="#ddf-preview" style={{textAlign: "center"}} dangerouslySetInnerHTML={{__html: Strings.DDF_PREVIEW_HEADLINE}} />

                <div className="ddf-section-white">
                    <div className="ddf-section-text-content" >
                        <div dangerouslySetInnerHTML={{__html: Strings.DDF_PREVIEW_INFO}} />
                        <br />
                        <TigerButton className="w-100" variant="red" type="button" onClick={() => redeemDdf()} disabled={loading}>
                            {loading ?
                                <Spinner animation="border" />
                                :
                                Strings.DDF_REDEEM_BUTTON}
                        </TigerButton>
                        <br />
                        <hr />

                        <div dangerouslySetInnerHTML={{__html: Strings.DDF_SWITCH_ACCOUNT_INFO.replace("{0}", email)}} />

                        <br />

                        <TigerButton className="w-100" variant="blue" type="button" onClick={() => setToken({})}>
                            {Strings.DDF_CHANGE_ACCOUNT_BUTTON}
                        </TigerButton>
                    </div>
                </div>
            </div>
            <InfoModal
                title={Strings.REGISTER_UNKNOWN_ERROR_TITLE}
                text={errorMessage}
                show={showError}
                onHide={() => setShowError(false)} />
        </>
    )
}
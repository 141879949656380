import {GetResource, PostWithoutResponse, UpdateResource} from "../../api/SpineClient";
import {GetRestResource} from "../../api/RestResourceClient";
import tigerbox_grey from "../../../assets/images/tigerboxes/tigerbox_touch_grey.png";
import tigerbox_black from "../../../assets/images/tigerboxes/tigerbox_touch_black.png";
import tigerbox_purple from "../../../assets/images/tigerboxes/tigerbox_touch_purple.png";
import tigerbox_green from "../../../assets/images/tigerboxes/tigerbox_touch_green.png";
import tigerbox_red from "../../../assets/images/tigerboxes/tigerbox_touch_red.png";
import tigerbox_blue from "../../../assets/images/tigerboxes/tigerbox_touch_blue.png";
import tigerbox_yellow from "../../../assets/images/tigerboxes/tigerbox_touch_yellow.png";

//API METHODS

export async function GetDevicesForAccount(accountId) {
    return await GetRestResource('api/devices/search/by-account-id?accountId=' + accountId, false, false);
}

export async function GetAccountUser(accountId, userId) {
    return await GetResource('api/accounts/'+accountId+'/users/'+userId);
}

export async function ReleaseFromDevice(deviceId) {
    return await PostWithoutResponse('api/devices/' + deviceId + '/release');
}

export async function UpdateDeviceName(device, newName) {
    return await UpdateResource('api/devices/' + device.id, {...device, displayName: newName});
}

export function GetDeviceIcon(device) {
    if (device == null || device.color == null) {
        return tigerbox_grey;
    } else if (device.color === "BLACK") {
        return tigerbox_black;
    } else if (device.color === "PURPLE") {
        return tigerbox_purple;
    } else if (device.color === "GREEN") {
        return tigerbox_green;
    } else if (device.color === "RED") {
        return tigerbox_red;
    } else if (device.color === "BLUE") {
        return tigerbox_blue;
    } else if (device.color === "YELLOW") {
        return tigerbox_yellow;
    } else {
        return tigerbox_grey;
    }
}

import React, {useEffect, useState} from "react";
import './../Purchase.scss';
import Strings from "../../../../config/Strings";
import {Form, Spinner} from "react-bootstrap";
import {TigerButton} from "../../../../global/tiger-button/TigerButton";
import {CreateResource} from "../../../../api/SpineClient";
import {loginUser} from "../../../../authentication/Oauth2Service";
import {useHistory} from "react-router";
import {authCheck} from "../../../../util/authCheck";
import GlobalConstants from "../../../../config/GlobalConstants";
import {InfoModal} from "../../../../global/ConfirmationModal";
import PurchaseStatus from "../PurchaseStatus";
import googleTagManagerWrapper from "../../../../api/GoogleTagManagerWrapper";

export default function TicketPurchaseAuthentication({ setToken, setEmail }) {

    const ERROR_CODE_DUPLICATE_EMAIL = "400-03";
    const ERROR_CODE_PASSWORT_TOO_SHORT = "400-01";

    const [form, setForm] = useState({
        email: "",
        password: "",
        passwordConfirmation: "",
        privacy: false,
        terms: false,
        newsletter: false,
        registerDeviceId: "customer-ui-service"
    });
    const [formType, setFormType] = useState("LOGIN");
    const [formError, setFormError] = useState({
        email: null,
        password: null,
        passwordConfirmation: null,
        privacy: null,
        terms: null,
        newsletter: null
    });
    const [formIsValid, setFormIsValid] = useState(form.code != null && form.password != null);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const purchaseData = JSON.parse(sessionStorage.getItem('ls.purchase'));

    const history = useHistory();

    document.title = Strings.PURCHASE_TICKET_BROWSER_TAB;

    useEffect(() => {
        if (purchaseData == null) {
            history.push("/" + GlobalConstants.APP_PATH + "purchase");
        }
        if (authCheck() && purchaseData != null) {
            //Set the max step to 2 and proceed
            purchaseData.maxStep = 2;
            sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));
            history.push("/" + GlobalConstants.APP_PATH + "purchase/ticket/payment");
        } else if (purchaseData != null) {
            //Set the mex step
            purchaseData.maxStep = 1;
            sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));
        }
    }, [history, purchaseData]);

    if (purchaseData == null) {
        history.push("/" + GlobalConstants.APP_PATH + "purchase");
    }

    if (authCheck() && purchaseData != null) {
        purchaseData.maxStep = 2;
        sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));
        history.push("/" + GlobalConstants.APP_PATH + "purchase/ticket/payment");
    } else if (purchaseData != null) {
        //Set the mex step
        purchaseData.maxStep = 1;
        sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));
    }



    //Submitting
    const authenticate = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        setLoading(true);

        let newToken = await loginUser(form);

        if(newToken) {
            //Token is available -> Set the expiration date/time
            let expiredAt = new Date();
            expiredAt.setSeconds(expiredAt.getSeconds() + newToken.expires_in);
            newToken.expires_at = expiredAt.getTime();

            setToken(newToken);
            setEmail(form.email);
            sessionStorage.setItem("email", JSON.stringify({email: form.email}));
            purchaseData.maxStep = 2;
            sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));
            history.push("/" + GlobalConstants.APP_PATH + "purchase/ticket/payment");

            googleTagManagerWrapper.login();
        }
        else {
            setFormError({
                ...formError,
                email: Strings.TICKET_REDEMPTION_AUTHENTICATE_ERROR,
                password: Strings.TICKET_REDEMPTION_AUTHENTICATE_ERROR
            });
        }

        setLoading(false);
    }

    const register = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        setLoading(true);

        let response = await CreateResource('api/register', form);
        if (!response.error) {

            googleTagManagerWrapper.registration();

            let newToken = await loginUser(form);
            let expiredAt = new Date();
            expiredAt.setSeconds(expiredAt.getSeconds() + newToken.expires_in);
            newToken.expires_at = expiredAt.getTime();
            setToken(newToken);
            setEmail(form.email);
            sessionStorage.setItem("email", JSON.stringify({email: form.email}));
            purchaseData.maxStep = 2;
            sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));
            history.push("/" + GlobalConstants.APP_PATH + "purchase/ticket/payment");
        } else {
            if (response.message.indexOf(ERROR_CODE_DUPLICATE_EMAIL) > -1) {
                setFormError({...formError, email: Strings.TICKET_REDEMPTION_REGISTER_EMAIL_ERROR});
            } else if (response.message.indexOf(ERROR_CODE_PASSWORT_TOO_SHORT) > -1) {
                setFormError({...formError, password: Strings.TICKET_REDEMPTION_REGISTER_PASSWORD_TOO_SHORT});
            } else {
                setErrorMessage(Strings.TICKET_REDEMPTION_REDEMPTION_UNKNOWN_ERROR);
                setShowError(true);
            }
        }

        setLoading(false);
    }

    const updateForm = (field, value, formIsValid) => {
        setForm({
            ...form,
            [field]: value
        });
        setFormError({
            ...formError,
            [field]: null
        });
        setFormIsValid(formIsValid);
    }


    const validateForm = () => {
        //REGISTER
        if (formType === "REGISTER") {
            if (form.email.indexOf("@") < 0) {
                setFormError({...formError, email: Strings.TICKET_REDEMPTION_REGISTER_INVALID_EMAIL});
                return false;
            } else if (form.password.length < 8) {
                setFormError({...formError, password: Strings.TICKET_REDEMPTION_REGISTER_PASSWORD_TOO_SHORT});
                return false;
            } else if (form.password !== form.passwordConfirmation) {
                setFormError({...formError, passwordConfirmation: Strings.TICKET_REDEMPTION_REGISTER_PASSWORD_DONT_MATCH});
                return false;
            }
        } else if (formType === "LOGIN") {
            if (form.email.indexOf("@") < 0) {
                setFormError({...formError, email: Strings.TICKET_REDEMPTION_REGISTER_INVALID_EMAIL});
                return false;
            }
        }
        return true;
    }

    const onChangeStep = (step) => {
        switch (step) {
            case 1:
                //Active step -> Do nothing
                return;
            case 2:
                if (purchaseData.maxStep > 2) {
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/ticket/payment");
                }
                return;
            case 3:
                if (purchaseData.maxStep > 2) {
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/ticket/confirmation");
                }
                return;
            case 4:
                if (purchaseData.maxStep > 3) {
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/ticket/confirmation");
                }
                return;
            default:
                return;
        }
    }

    if (formType === "LOGIN") {
        return (
            <div className="purchase-container">
                <PurchaseStatus step={1} maxStep={purchaseData != null ? purchaseData.maxStep : 1}
                                onChangeStep={(step) => onChangeStep(step)}
                />
                <div className="purchase-info" dangerouslySetInnerHTML={{__html: Strings.PURCHASE_TICKET_AUTHENTICATE_INFO.replace("{0}", (purchaseData != null && purchaseData.duration != null)  ? "(" + purchaseData.duration + ")": " ")}} />
                <div className="purchase-form-container">
                    <div className="purchase-form">
                        <Form className="text-left" onSubmit={(e) => authenticate(e)}>
                            {/* EMAIL */}
                            <Form.Group className="mb-3" controlId="ticketRedemptionTicketNo">
                                <Form.Label className="auth-label">{Strings.TICKET_REDEMPTION_AUTHENTICATE_EMAIL}</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    isInvalid={ formError.email != null}
                                    value={form.email}
                                    onChange={e => updateForm('email', e.target.value, e.target.value.indexOf("@") > -1 && form.password.length >= 8)}
                                    plaintext={"E-Mail"}
                                    placeholder={Strings.TICKET_REDEMPTION_AUTHENTICATE_EMAIL_HINT}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {formError.email}
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* PASSWORD */}
                            <Form.Group className="mb-3" controlId="ticketRedemptionTicketPin">
                                <Form.Label className="auth-label" style={{width: "100%"}}>
                                    {Strings.TICKET_REDEMPTION_AUTHENTICATE_PASSWORD}
                                    <span style={{fontSize: "18px", float: "right", cursor: "pointer", marginTop: "5px", fontWeight: "normal"}} onClick={() => setShowPassword(!showPassword)}>{showPassword ? Strings.RESET_PASSWORD_HIDE : Strings.RESET_PASSWORD_SHOW}</span>
                                </Form.Label>
                                <Form.Control
                                    type={showPassword ? "text" : "password"}
                                    required
                                    isInvalid={ formError.password != null}
                                    value={form.password}
                                    onChange={e => updateForm('password', e.target.value, e.target.value.length >= 8 && form.email.indexOf("@") > -1)}
                                    plaintext={"PIN"}
                                    placeholder={Strings.TICKET_REDEMPTION_AUTHENTICATE_PASSWORD_HINT}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {formError.password}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <TigerButton className="w-100" variant="red" disabled={loading}>
                                {loading ?
                                    <Spinner animation="border" />
                                    :
                                    Strings.TICKET_REDEMPTION_AUTHENTICATE_BUTTON}
                            </TigerButton>

                            <br />
                            <hr />

                            {Strings.TICKET_REDEMPTION_SWITCH_TO_REGISTER_INFO}

                            <br />

                            <br />

                            <TigerButton className="w-100" variant="blue" type="button" onClick={() => setFormType("REGISTER")}>
                                {Strings.TICKET_REDEMPTION_SWITCH_TO_REGISTER_BUTTON}
                            </TigerButton>
                        </Form>

                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="purchase-container">
            <PurchaseStatus step={1} maxStep={purchaseData != null ? purchaseData.maxStep : 1}
                            onChangeStep={(step) => onChangeStep(step)}
            />
            <div className="purchase-info" dangerouslySetInnerHTML={{__html: Strings.PURCHASE_TICKET_REGISTER_INFO.replace("{0}", (purchaseData != null && purchaseData.duration != null) ? "(" + purchaseData.duration + ")": " ")}} />

            <div className="purchase-form-container purchase-registration-container">
                <div className="purchase-form">
                    <Form className="text-left" onSubmit={(e) => register(e)}>
                        {/* EMAIL */}
                        <Form.Group className="mb-3" controlId="ticketRedemptionTicketNo">
                            <Form.Label className="auth-label">{Strings.TICKET_REDEMPTION_REGISTER_EMAIL}</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                isInvalid={ formError.email != null}
                                value={form.email}
                                onChange={e => updateForm('email', e.target.value,
                                    e.target.value.indexOf("@") > -1 && form.password.length >= 8 && form.password === form.passwordConfirmation)}
                                plaintext={"E-Mail"}
                                placeholder={Strings.TICKET_REDEMPTION_REGISTER_EMAIL_HINT}
                            />

                            <Form.Control.Feedback type="invalid">
                                {formError.email}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* PASSWORD */}
                        <Form.Group className="mb-3" controlId="ticketRedemptionTicketPin">
                            <Form.Label className="auth-label" style={{width: "100%"}}>
                                {Strings.TICKET_REDEMPTION_REGISTER_PASSWORD}
                                <span style={{fontSize: "18px", float: "right", cursor: "pointer", marginTop: "5px", fontWeight: "normal"}} onClick={() => setShowPassword(!showPassword)}>{showPassword ? Strings.RESET_PASSWORD_HIDE : Strings.RESET_PASSWORD_SHOW}</span>
                            </Form.Label>
                            <Form.Control
                                type={showPassword ? "text" : "password"}
                                required
                                isInvalid={ formError.password != null}
                                value={form.password}
                                onChange={e => updateForm('password', e.target.value,
                                    e.target.value.length >= 8 && form.email.indexOf("@") > -1 && form.password === form.passwordConfirmation && form.terms && form.privacy)}
                                plaintext={"Password"}
                                placeholder={Strings.TICKET_REDEMPTION_REGISTER_PASSWORD_HINT}
                            />

                            <Form.Control.Feedback type="invalid">
                                {formError.password}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* PASSWORD CONFIRMATION*/}
                        <Form.Group className="mb-3" controlId="ticketRedemptionTicketPin">
                            <Form.Label className="auth-label">{Strings.TICKET_REDEMPTION_REGISTER_PASSWORD_CONFIRMATION}</Form.Label>
                            <Form.Control
                                type={showPassword ? "text" : "password"}
                                required
                                isInvalid={ formError.passwordConfirmation != null}
                                value={form.passwordConfirmation}
                                onChange={e => updateForm('passwordConfirmation', e.target.value,
                                    form.password === e.target.value && form.email.indexOf("@") > -1 && form.password.length >= 8 && form.terms && form.privacy)}
                                plaintext={"Password Confirmation"}
                                placeholder={Strings.TICKET_REDEMPTION_REGISTER_PASSWORD_CONFIRMATION_HINT}
                            />

                            <Form.Control.Feedback type="invalid">
                                {formError.passwordConfirmation}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <div>
                            <div className={"round"}>
                                <input name="privacy" id="privacy" type="checkbox" value={form.privacy} onChange={(e) => {
                                    updateForm('privacy', e.target.checked,
                                        e.target.checked && form.terms &&
                                        form.password === form.passwordConfirmation && form.email.indexOf("@") > -1 &&
                                        form.password.length >= 8);
                                }}/>
                                <label htmlFor="privacy"></label>
                            </div>
                            <label className="roundlabel registerCheckboxLabel" htmlFor="privacy">*Hiermit akzeptiere ich
                                die <a href="https://tiger.media/policies/privacy-policy" className="black" target="_blank"
                                       rel="noopener noreferrer">Datenschutzbestimmungen</a></label>
                        </div>
                        <div>
                            <div className={"round"}>
                                <input name="terms" id="terms" type="checkbox" value={form.terms} onChange={(e) => {
                                    updateForm('terms', e.target.checked,
                                        e.target.checked && form.privacy &&
                                        form.password === form.passwordConfirmation && form.email.indexOf("@") > -1 &&
                                        form.password.length >= 8
                                    );
                                }}/>
                                <label htmlFor="terms"></label>
                            </div>
                            <label className="roundlabel registerCheckboxLabel" htmlFor="conditions">*Hiermit akzeptiere ich
                                die <a href="https://tiger.media/policies/terms-of-service" className="black" target="_blank"
                                       rel="noopener noreferrer">AGB</a>.</label>
                        </div>
                        <div>
                            <div className={"round"}>
                                <input name="newsletter" id="newsletter" type="checkbox" value={form.newsletter}
                                       onChange={(e) => updateForm('newsletter', e.target.checked)}/>
                                <label htmlFor="newsletter"></label>
                            </div>
                            <label className="roundlabel registerCheckboxLabel"
                                   htmlFor="newsletter">{Strings.REGISTER_FORM_CHECKBOX_NEWSLETTER}</label>
                        </div>

                        <TigerButton className="w-100" variant="red" disabled={loading || !formIsValid}>
                            {loading ?
                                <Spinner animation="border" />
                                :
                                Strings.TICKET_REDEMPTION_REGISTER_BUTTON}
                        </TigerButton>

                        <br />
                        <hr />

                        {Strings.TICKET_REDEMPTION_SWITCH_TO_LOGIN_INFO}

                        <br />

                        <br />

                        <TigerButton className="w-100" variant="blue" type="button" onClick={() => setFormType("LOGIN")}>
                            {Strings.TICKET_REDEMPTION_SWITCH_TO_LOGIN_BUTTON}
                        </TigerButton>

                    </Form>
                </div>
            </div>

            <InfoModal
                title={Strings.REGISTER_UNKNOWN_ERROR_TITLE}
                text={errorMessage}
                show={showError}
                onHide={() => setShowError(false)} />
        </div>
    )
}
const GlobalConstants = {
    //General details
    ENVIRONMENT: process.env["REACT_APP_CUSTOM_NODE_ENV"],
    URL_TICKETS: process.env["REACT_APP_URL_TICKETS"],
    URL_CANCEL_CONTRACTS: process.env["REACT_APP_URL_CANCEL_CONTRACT"],
    CUSTOMER_UI_CLIENT_ID: process.env["REACT_APP_CUSTOMER_UI_CLIENT_ID"],
    CUSTOMER_UI_CLIENT_SECRET: process.env["REACT_APP_CUSTOMER_UI_CLIENT_SECRET"],

    UI_HOST: process.env["REACT_APP_UI_HOST"],
    TEAMS_CLIENT_HOST: process.env["REACT_APP_TEAMS_CLIENT_HOST"],
    TEAMS_MESSAGES_ACTIVE: process.env["REACT_APP_TEAMS_MESSAGES_ACTIVE"],

    //Server and client settings
    SPINE_HOST: process.env["REACT_APP_CLIENT_HOST"],
    REST_RESOURCES_HOST: process.env["REACT_APP_REST_RESOURCES_HOST"],
    APP_PATH: process.env["REACT_APP_CLIENT_PATH"],
    BILL_HOST: process.env["REACT_APP_BILL_SERVICE_HOST"],
    SPINE_CLIENT: process.env["REACT_APP_SPINE_CLIENT"],
    SPINE_CLIENT_SECRET: process.env["REACT_APP_SPINE_CLIENT_SECRET"],
    SHOP_CLIENT: process.env["REACT_APP_SHOP_CLIENT"],
    SHOP_CLIENT_SECRET: process.env["REACT_APP_SHOP_CLIENT_SECRET"],
    TARGET_URL_CRM_REDIRECT: process.env["REACT_APP_TARGET_URL_CRM_REDIRECT"],
    TARGET_URL_DDF: process.env["REACT_APP_TARGET_URL_DDF"],

    APP_ID_TIGERTONES: process.env["REACT_APP_APP_ID_TIGERTONES"],

    //Mailjet
    MAILJET_HOST: "https://api.mailjet.com/",
    MAILJET_AUTH: process.env["REACT_APP_MJ_AUTH"],
    MAILJET_PUBLIC_KEY: process.env["REACT_APP_MJ_APIKEY_PUBLIC"],
    MAILJET_PRIVATE_KEY: process.env["REACT_APP_MJ_APIKEY_PRIVATE"],
    MAILJET_TIGERTIPPS_LIST_ID: 2404033,
    MAILJET_TIGERPOST_LIST_ID: 2400362,

    //Landing Pages
    LANDING_PAGE_SUBSCRIPTION_1MONTH: process.env["REACT_APP_LANDING_PAGE_SUBSCRIPTION_1MONTH"],
    LANDING_PAGE_TIGERTICKET: process.env["REACT_APP_LANDING_PAGE_TIGERTICKET"],
    LANDING_PAGE_TIGERPOST_UNSUBSCRIPTION_SURVEY: process.env["REACT_APP_TIGERPOST_UNSUBSCRIPTION_SURVEY"],

    //Data
    AMAZONBUCKET_URL: "https://s3.eu-central-1.amazonaws.com/tigerbooks.",
    GOOGLESTORAGE_URL: "streaming-content.tiger.media/",

    PRODUCT_QUERY_DDF: process.env["REACT_APP_PRODUCT_QUERY_DDF"],
    DDF_PRODUCT_IDS: process.env["REACT_APP_DDF_PRODUCT_IDS"],
    REDIRECT_WEBSITE: process.env["REACT_APP_REDIRECT_WEBSITE"],
    REDIRECT_TO_PRODUCT: process.env["REACT_APP_REDIRECT_TO_PRODUCT"],
    TONES_DEEPLINK_TO_PRODUCT: process.env["REACT_APP_TONES_DEEPLINK_TO_PRODUCT"],
    TONES_DEEPLINK_PROTOCOL: process.env["REACT_APP_TONES_DEEPLINK_PROTOCOL"],
    SHOP_URL_TIGERTONES: process.env["REACT_APP_SHOP_URL_TIGERTONES"],
    DEFAULT_DEEPLINK_DDF: process.env["REACT_APP_DDF_DEEPLINK_DEFAULT"],
    DEFAULT_DEEPLINK_HUIBUH: process.env["REACT_APP_HUIBUH_DEEPLINK_DEFAULT"],
    REWE_BILLWERK_PLAN_VARIANT_ID: process.env["REACT_APP_REWE_BILLWERK_PLAN_VARIANT_ID"],

    //Payone
    PAYONE_MID: process.env["REACT_APP_PAYONE_MID"],
    PAYONE_AID: process.env["REACT_APP_PAYONE_AID"],
    PAYONE_PORTAL_ID: process.env["REACT_APP_PAYONE_PORTAL_ID"],
    PAYONE_MODE: process.env["REACT_APP_PAYONE_MODE"],

    PAYMENT_METHOD_PAYPAL: "paypal",
    PAYMENT_METHOD_DEBIT: "debit",
    PAYMENT_METHOD_CREDIT_CARD: "creditCard",

    //Billwerk
    BILLWERK_JS_URL: process.env["REACT_APP_BILLWERK_JS_URL"],
    BILLWERK_PUBLIC_API_KEY: process.env["REACT_APP_BILLWERK_PUBLIC_API_KEY"],
    BILLWERK_PROVIDER_RETURN_URL: process.env["REACT_APP_BILLWERK_PROVIDER_RETURN_URL"],

    //Taxes
    TAX_DE: process.env["REACT_APP_TAXES_DE"],
    TAX_AT: process.env["REACT_APP_TAXES_AT"],
    TAX_CH: process.env["REACT_APP_TAXES_CH"],
};

export default GlobalConstants;
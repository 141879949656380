import React, {useEffect, useState} from "react";
import './../Purchase.scss';
import {useHistory} from "react-router";
import PurchaseStatus from "../PurchaseStatus";
import Strings from "../../../../config/Strings";
import GlobalConstants from "../../../../config/GlobalConstants";
import {authCheck} from "../../../../util/authCheck";
import {TigerButton} from "../../../../global/tiger-button/TigerButton";
import {Spinner} from "react-bootstrap";
import {ConfirmationModal, InfoModal} from "../../../../global/ConfirmationModal";
import * as billwerkJSWrapper from './../../../../api/BillwerkJSWrapper.js';
import googleTagManagerWrapper from "../../../../api/GoogleTagManagerWrapper";
import {CreateResource, GetResource, SendTeamsNotification} from "../../../../api/SpineClient";
import {GetPlanVariantById} from "../../../dashboard/DashboardService";
import {GetCustomerContracts} from "../../../premium/PremiumService";
import {ConvertDateToReadableString} from "../../../../util/ConverterUtil";

export default function TarifConfirmation() {

    const [purchaseData] = useState(JSON.parse(sessionStorage.getItem('ls.purchase')) != null ?
        JSON.parse(sessionStorage.getItem('ls.purchase')) : {payment: { channelSecrets: {}}, contact: {}}
    );
    const email = JSON.parse(sessionStorage.getItem('email'));
    const [priceData, setPriceData] = useState({netto: 0.00, tax: 0.00, brutto: 0.00, unit: "€"});
    const [tarif, setTarif] = useState({phases: []});
    const [agb, setAgb] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showLoginHint, setShowLoginHint] = useState(false);
    const [paymentError, setPaymentError] = useState(false);
    const [nextProduct, setNextProduct] = useState(null);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [changeDate, setChangeDate] = useState(null);
    const [isTicket, setIsTicket] = useState(false);

    const history = useHistory();

    document.title = Strings.PURCHASE_SUBSCRIPTION_BROWSER_TAB;

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        //Routing
        if (purchaseData == null) {
            //No purchase data available -> Return to step 1
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif");
        } else if (!authCheck() || email == null) {
            //Not authenticated -> Return to step 1 (but with existing purchase data)
            purchaseData.maxStep = 1;
            sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            if (purchaseData.tarif != null) {
                history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif?tarif=" + purchaseData.tarif);
            } else {
                history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif");
            }
        } else if (purchaseData.tarif == null) {
            //No tarif selected -> Return to step 2
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/select");
        } else if (purchaseData.paymentRequired && purchaseData.payment == null) {
            //No payment data provided
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/payment");
        } else if (queryParams.get("error") != null) {
            setErrorMessage(Strings.PURCHASE_CONFIRMATION_UNKNOWN_ERROR);
        } else if (queryParams.get("paymentFinished") != null && queryParams.get("paymentFinished") === "1") {
            //Finalize the payment
            const onSuccess = () => {
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/success");
            };
            const onError = (...args) => {
                SendTeamsNotification("finalize", purchaseData.orderId, purchaseData.planVariantId, args).then(r => r);
                setPaymentError(true);
            }

            if (purchaseData.paymentRequired) {
                billwerkJSWrapper.finalize(onSuccess, onError);
            } else {
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/success");
            }
        }

        const loadTarif = async (tarifId) => {
            setLoading(true);
            let p = await GetResource('api/purchases/premium-access-types/' + tarifId,
                'application/json', {}, GlobalConstants.BILL_HOST)
            setLoading(false);
            if (p.error || p.result == null) {
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                googleTagManagerWrapper.event('cancelPurchase', {reason: 'criticalError'});
                googleTagManagerWrapper.noPurchase();
                history.push("/" + GlobalConstants.APP_PATH + "purchase/error?code=6");
                return null;
            }

            return p.result;
        }

        const loadNextProduct = async (tarif) => {
            if (tarif != null && tarif.phases != null && tarif.phases.length > 0) {
                const lastProduct = tarif.phases[tarif.phases.length - 1];
                const planVariantId = lastProduct.mappedBillwerkProduct && lastProduct.mappedBillwerkProduct.planVariantId;
                if (planVariantId != null) {
                    const loadedProduct = await GetPlanVariantById(planVariantId);
                    if (!loadedProduct.error && loadedProduct.result != null) {
                        setNextProduct(loadedProduct.result);
                    }
                }
            }
        }

        const loadCurrentProduct = async () => {
            let contracts = await GetCustomerContracts(false);
            if (!contracts.error && contracts.result != null) {
                contracts = contracts.result.filter(c => c.CurrentPhase != null && c.CurrentPhase.Type != null && c.CurrentPhase.Type !== 'Inactive' && c.CurrentPhase.PlanVariantId != null);
                if (contracts.length > 0) {
                    const loadedProduct = await GetPlanVariantById(contracts[0].CurrentPhase.PlanVariantId);
                    console.log(loadedProduct);
                    if (!loadedProduct.error && loadedProduct.result != null) {
                        setCurrentProduct(loadedProduct.result);
                        setChangeDate(contracts[0].NextBillingDate);
                        setIsTicket(contracts[0].EndDate != null);
                    }
                }
            }
        }

        if (tarif.id == null && purchaseData != null && purchaseData.tarif != null) {
            loadTarif(purchaseData.tarif).then(r => {
                if (r != null) {
                    setTarif(r);
                    setPriceData(calculatePrices(r, purchaseData));
                    loadNextProduct(r).then(s => s);
                    loadCurrentProduct().then(s => s);
                }
            });
        } else if (queryParams.get("error") != null) {
            setErrorMessage(Strings.PURCHASE_CONFIRMATION_UNKNOWN_ERROR);
        }
    }, [history, purchaseData, tarif, email]);

    if (new URLSearchParams(window.location.search).get("paymentFinished") === "1") {
        //Finalize the payment
        const onSuccess = () => {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/success");
        };
        const onError = (...args) => {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            SendTeamsNotification("finalize", purchaseData.orderId, purchaseData.planVariantId, args).then(r => r);
            history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/confirmation");
            setPaymentError(true);
        }
        if (purchaseData != null && purchaseData.paymentRequired) {
            billwerkJSWrapper.finalize(onSuccess, onError);
        } else {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/success");
        }
    }

    const submitOrder = async (e) => {
        e.preventDefault();
        setLoading(true);

        //Create the request for the purchase service
        if (!orderDataIsValid()) {
            return;
        }
        let request = {
            EmailAddress: email.email,
            FirstName: purchaseData.contact.firstName,
            LastName: purchaseData.contact.lastName,
            Address: {
                Street: purchaseData.contact.street,
                HouseNumber: purchaseData.contact.houseNumber,
                PostalCode: purchaseData.contact.zip,
                City: purchaseData.contact.city,
                Country: purchaseData.contact.country
            }
        }

        let response = await CreateResource('api/purchases/premium-access-types/' + tarif.id + '/order', request, true, GlobalConstants.BILL_HOST);
        if (response.error || response.result == null) {
            console.log("Error during purchase attempt: " + response.message);
            setErrorMessage(Strings.PURCHASE_CONFIRMATION_UNKNOWN_ERROR);
            return;
        }

        if (Array.isArray(response.result)) {
            for (let i = 0; i < response.result.length; i++) {
                purchaseData.orderId = response.result[i].orderId;
            }
        } else {
            purchaseData.orderId = response.result.orderId;
        }
        sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));


        //Billwerk pay request
        if (!purchaseDataIsValid()) {
            return;
        }

        //if there is not payment required, continue
        if (!purchaseData.paymentRequired) {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/success");
        } else {
            let paymentData = {};
            if (purchaseData.paymentMethod === GlobalConstants.PAYMENT_METHOD_DEBIT) {
                paymentData = {
                    bearer: 'Debit:PayOne',
                    accountHolder: purchaseData.paymentData.accountHolder.trim(),
                    iban: purchaseData.paymentData.iban.trim(),
                    bic: purchaseData.paymentData.bic.trim()
                }
            } else if (purchaseData.paymentMethod === GlobalConstants.PAYMENT_METHOD_CREDIT_CARD) {
                let expiryYear = purchaseData.paymentData.expiryYear;
                if (expiryYear < 100) {
                    //Convert format from i.e. '23' to '2023'
                    expiryYear = Number(expiryYear) + 2000;
                }
                paymentData = {
                    bearer: 'CreditCard:PayOne',
                    cardNumber: purchaseData.paymentData.cardNumber.trim(),
                    expiryMonth: purchaseData.paymentData.expiryMonth.trim(),
                    expiryYear: expiryYear,
                    cardHolder: purchaseData.paymentData.cardHolder.trim(),
                    cvc: purchaseData.paymentData.verificationNumber.trim()
                }
            }

            let onError = (data) => {
                SendTeamsNotification("paySignupInteractive", purchaseData.orderId, purchaseData.planVariantId, data).then(r => r);
                setLoading(false);
                setPaymentError(true);
            }

            let onSuccess = (data) => {
                const url2fa = data.Url;
                if (url2fa) {
                    window.location.href = url2fa;
                } else {
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/success");
                }
            }

            const ordersWithPayment = (Array.isArray(response.result) && response.result.length > 0) ?
                response.result.filter(r => r.paymentRequired) : [];

            if (ordersWithPayment.length === 1) {
                //Only this is supported yet
                let order = {OrderId:ordersWithPayment[0].orderId, Currency: "EUR"};
                billwerkJSWrapper.paySignupInteractive(paymentData, order, onSuccess, onError);
            }
        }
        setLoading(false);
    }

    const purchaseDataIsValid = () => {
        if (!purchaseData.paymentRequired) {
            //No payment data required
            return true;
        }
        if (purchaseData.paymentMethod === GlobalConstants.PAYMENT_METHOD_CREDIT_CARD) {
            if (purchaseData.paymentData == null) {
                setErrorMessage(Strings.PURCHASE_CONFIRMATION_VALIDATION_ERROR.replace("{0}", "10"));
                return false;
            } else if (purchaseData.paymentData.cardNumber == null || purchaseData.paymentData.cardHolder == null ||
                purchaseData.paymentData.expiryMonth == null || purchaseData.paymentData.expiryYear == null ||
                purchaseData.paymentData.verificationNumber == null) {
                setErrorMessage(Strings.PURCHASE_CONFIRMATION_VALIDATION_ERROR.replace("{0}", "11"));
                return false;
            }
        } else if (purchaseData.paymentMethod === GlobalConstants.PAYMENT_METHOD_DEBIT) {
            if (purchaseData.paymentData == null) {
                setErrorMessage(Strings.PURCHASE_CONFIRMATION_VALIDATION_ERROR.replace("{0}", "10"));
                return false;
            } else if (purchaseData.paymentData.accountHolder ==  null || purchaseData.paymentData.iban == null || purchaseData.paymentData.bic == null) {
                setErrorMessage(Strings.PURCHASE_CONFIRMATION_VALIDATION_ERROR.replace("{0}", "11"));
                return false;
            }
        }
        return true;
    }

    const orderDataIsValid = () => {
        if (purchaseData == null) {
            //Purchase data is not set
            setErrorMessage(Strings.PURCHASE_CONFIRMATION_VALIDATION_ERROR.replace("{0}", "7"));
            return false;
        } else if (purchaseData.tarif == null) {
            setErrorMessage(Strings.PURCHASE_CONFIRMATION_VALIDATION_ERROR.replace("{0}", "8"));
            return false;
        } else if (purchaseData.contact == null) {
            setErrorMessage(Strings.PURCHASE_CONFIRMATION_VALIDATION_ERROR.replace("{0}", "9"));
            return false;
        } else if (purchaseData.paymentRequired && purchaseData.paymentData == null) {
            setErrorMessage(Strings.PURCHASE_CONFIRMATION_VALIDATION_ERROR.replace("{0}", "10"));
            return false;
        }
        return true;
    }

    const calculatePrices = (tarif, purchaseData) => {
        //Default
        let tax = 0.19;
        let unit = "€";
        if (purchaseData != null && purchaseData.contact != null && purchaseData.contact.country != null) {
            if (purchaseData.contact.country === "DE") {
                tax = Number(GlobalConstants.TAX_DE);
            } else if (purchaseData.contact.country === "AT") {
                tax = Number(GlobalConstants.TAX_AT);
            } else if (purchaseData.contact.country === "CH") {
                tax = Number(GlobalConstants.TAX_CH);
                unit = "CHF";
            }
        }

        //Calculation and formatting
        let brutto = (tarif.displayPrice).toFixed(2);
        let netto = (tarif.displayPrice / (1 + tax)).toFixed(2)
        let taxAmount = (brutto - netto).toFixed(2);

        return {brutto: brutto, tax: taxAmount, netto: netto, unit: unit}
    }

    const getPaymentMethodName = (method) => {
        if (method == null) {
            return null;
        }
        switch (method) {
            case GlobalConstants.PAYMENT_METHOD_CREDIT_CARD:
                return Strings.PURCHASE_PAYMENT_METHOD_CC;
            default:
                return Strings.PURCHASE_SUBSCRIPTION_PAYMENT_METHOD_DEBIT;
        }
    }

    const onChangeStep = (step) => {
        switch (step) {
            case 1:
                setShowLoginHint(true);
                return;
            case 2:
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/select");
                return;
            case 3:
                //Active step -> Do nothing
                return;
            case 4:
                if (purchaseData.maxStep > 3) {
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/confirmation");
                }
                return;
            default:
                return;
        }
    }

    return (
        <div className="purchase-container">
            <PurchaseStatus step={3} maxStep={purchaseData != null ? purchaseData.maxStep : 1}
                            onChangeStep={(step) => onChangeStep(step)}
            />
            <div className="purchase-info" dangerouslySetInnerHTML={{__html: Strings.PURCHASE_CONFIRMATION_INFO}}/>
            {currentProduct != null &&
                <div className="purchase-info">
                    {Strings.PURCHASE_TARIF_CURRENT_TARIF}
                    <br />
                    {isTicket ? Strings.PURCHASE_TARIF_TM_TICKET : Strings.PURCHASE_TARIF_TM_SUBSCRIPTION} ({currentProduct.planVariantDescription})
                </div>
            }
            {nextProduct != null && changeDate != null &&
                <div className="purchase-info">
                    <div dangerouslySetInnerHTML={{__html: Strings.PURCHASE_TARIF_NEXT_TARIF.replace("{0}", ConvertDateToReadableString(changeDate))}} />
                    {Strings.PURCHASE_TARIF_TM_SUBSCRIPTION} ({nextProduct.planVariantDescription})
                </div>
            }

            <div className="purchase-form-container">
                <div className="purchase-form">
                    <div className="purchase-confirmation-section">
                        {Strings.PURCHASE_CONFIRMATION_PRODUCT_DATA}
                        <div className="purchase-confirmation-edit" onClick={() => {
                            history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/select");
                        }}>
                            {Strings.PURCHASE_CONFIRMATION_CHANGE}
                        </div>
                    </div>
                    <div>
                        <div className="purchase-confirmation-list" dangerouslySetInnerHTML={{__html: tarif.cartDescription}} />
                        <ul style={{listStyleType: "none"}}>
                            <hr />
                            <li>
                                {Strings.PURCHASE_CONFIRMATION_NETTO}
                                <span style={{float: "right"}}>{priceData.netto + " " + priceData.unit}</span>
                            </li>
                            <li>
                                {Strings.PURCHASE_CONFIRMATION_MWST}
                                <span style={{float: "right"}}>{priceData.tax + " " + priceData.unit}</span>
                            </li>
                            <hr />
                            <li style={{fontWeight: "bold"}}>
                                {Strings.PURCHASE_CONFIRMATION_BRUTTO}
                                <span style={{float: "right"}}>{priceData.brutto + " " + priceData.unit}</span>
                            </li>
                            <hr />
                        </ul>
                    </div>

                    <div className="purchase-confirmation-section">
                        {Strings.PURCHASE_CONFIRMATION_ACCOUNT}
                        <div className="purchase-confirmation-edit" onClick={() => setShowLoginHint(true)}>
                            {Strings.PURCHASE_CONFIRMATION_EDIT}
                        </div>
                    </div>
                    <div>
                        <ul style={{listStyleType: "none"}}>
                            <li>{email.email}</li>
                        </ul>
                    </div>
                    {purchaseData.paymentRequired &&
                        <div className="purchase-confirmation-section">
                            {Strings.PURCHASE_CONFIRMATION_PAYMENT_METHOD}
                            <div className="purchase-confirmation-edit" onClick={() => {
                                history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/payment");
                            }}>
                                {Strings.PURCHASE_CONFIRMATION_EDIT}
                            </div>
                        </div>
                    }
                    {purchaseData.paymentRequired &&
                        <div>
                            <ul style={{listStyleType: "none"}}>
                                <li>{getPaymentMethodName(purchaseData.paymentMethod)}</li>
                            </ul>
                        </div>
                    }

                    <div className="purchase-confirmation-section">
                        {Strings.PURCHASE_CONFIRMATION_INVOICE}
                        <div className="purchase-confirmation-edit" onClick={() => {
                            history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/address");
                        }}>
                            {Strings.PURCHASE_CONFIRMATION_EDIT}
                        </div>
                    </div>
                    <div>
                        <ul style={{listStyleType: "none"}}>
                            <li>{purchaseData.contact.firstName + " " + purchaseData.contact.lastName}</li>
                            <li>{purchaseData.contact.street + " " + purchaseData.contact.houseNumber}</li>
                            <li>{purchaseData.contact.zip + " " + purchaseData.contact.city}</li>
                            <li>{purchaseData.contact.country === "DE" ? Strings.PURCHASE_COUNTRY_DE :
                                (purchaseData.contact.country === "AT" ? Strings.PURCHASE_COUNTRY_AT : Strings.PURCHASE_COUNTRY_CH)}</li>
                        </ul>
                    </div>
                </div>
                <div className="purchase-info purchase-info-checkbox">
                    <div className={"round"}>
                        <input name="agb" id="agb" type="checkbox" value={agb} checked={agb}
                               onChange={(e) => setAgb(!agb)}/>
                        <label htmlFor="agb"></label>
                    </div>
                    <label className="roundlabel" htmlFor="agb" dangerouslySetInnerHTML={{__html: Strings.PURCHASE_CONFIRMATION_AGB}} />
                </div>
                <div className="purchase-form">
                    <TigerButton className="w-100" type="button" onClick={(e) => submitOrder(e)} variant="red" disabled={!agb}>
                        {loading ?
                            <Spinner animation="border" />
                            :
                            Strings.PURCHASE_CONFIRMATION_SUBMIT_BUTTON}
                    </TigerButton>
                </div>
            </div>

            <InfoModal
                title={Strings.REGISTER_UNKNOWN_ERROR_TITLE}
                text={errorMessage}
                show={errorMessage != null}
                onHide={() => setErrorMessage(null)} />


            <InfoModal
                title={Strings.PURCHASE_PAYMENT_ERROR_TITLE}
                text={Strings.PURCHASE_PAYMENT_ERROR_BODY}
                show={paymentError}
                onHide={() => setPaymentError(false)}/>

            <ConfirmationModal
                title={Strings.PURCHASE_REAUTHENTICATE_TITLE}
                text={Strings.PURCHASE_REAUTHENTICATE_HINT}
                actionButtonLabel={Strings.PURCHASE_REAUTHENTICATE_BUTTON}
                actionButtonVariant="danger"
                onActionPress={() => {
                    localStorage.removeItem("ls.token");
                    sessionStorage.removeItem("ls.purchase");
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif");
                }}
                show={showLoginHint}
                onHide={() => setShowLoginHint(false)}
            />
        </div>
    )
}
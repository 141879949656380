import {
    GetAllResources,
    GetFilteredResources
} from "../../api/SpineClient";

//API METHODS

export async function GetFilteredSubscriptions(accountId, filter){
    return await GetFilteredResources('api/accounts/' + accountId + '/subscriptions', filter);
}

export async function GetProductsByIaps(iaps) {
    return await GetAllResources('api/products/byIaps?iaps=' + iaps);
}
import React from "react";
import './SingleCardItem.scss';

export const SingleCardItem = (props) => {
    const {icon, label, sublabel, onClick, centered, rounded} = props;

    const getStyle = (base, onClick, centered) => {
        if (onClick) {
            base = base + " clickable";
        }
        if (centered) {
            base = base + " centered";
        }
        return base;
    }

    const getRoundedStyle = (base, onClick, centered, rounded) => {
        if (rounded) {
            base = base + "-round";
        }
        return getStyle(base, onClick, centered);
    }

    const trimLabel = (label) => {
        if (label == null || label === "") {
            return null;
        }
        if (label.length > 12) {
            return label.substr(0,9) + "...";
        } else {
            return label;
        }
    }
    return (
        <div className={getStyle('card-item', onClick, centered, rounded)} onClick={onClick}>
            <img src={icon} className={getRoundedStyle('card-item-icon', onClick, centered, rounded)} alt={"Cover von " + label}/>
            <div className={getStyle('card-item-label', onClick, centered)}>{trimLabel(label)}</div>
            <div className={getStyle('card-item-sublabel', onClick, centered)}>{trimLabel(sublabel)}</div>
        </div>
    );
}
import React from "react";
import GlobalConstants from "../../../config/GlobalConstants";
import Strings from "../../../config/Strings";
import {TigerSpinner} from "../../../global/tiger-spinner/TigerSpinner";
import logo from "../../../../assets/images/wildcard_logo.png";
import './DashboardCard.scss';
import {SingleCardItem} from "./single-item/SingleCardItem";
import {Link} from "react-router-dom";
import add from "../../../../assets/images/add.png";

export const WildcardsCard = (props) => {
    const {wildcards, loading, history} = props;

    if (loading) {
        return (
            <TigerSpinner/>
        )
    }

    if (wildcards.length === 0) {
        return (
            <>
                <h4 className="dashboard-card-header">{Strings.DASHBOARD_WILDCARDS_CARD_LABEL}</h4>
                <div className="static-content-container">
                    <img src={logo} width={180} alt={"wildcards Logo"}/>
                    <hr style={{width: "270px"}}/>
                    <span style={{fontWeight: "bold"}}>{Strings.DASHBOARD_NO_WILDCARDS}</span>
                    <div className="small-text"
                        dangerouslySetInnerHTML={{__html: Strings.DASHBOARD_WILDCARDS_TOOLTIP}} />
                </div>
                <div className="dashboard-card-footer centered">
                    <Link to={"/" + GlobalConstants.APP_PATH + 'wildcards'}>
                        <button
                            className={"dashboard-card-button"}
                            type="button"
                        >wildcard hinzufügen
                        </button>
                    </Link>
                </div>
            </>
        );
    }

    const getCover = (wildcard) => {
        let product = wildcard._embedded ? (wildcard._embedded.accountgeneratedcontents || wildcard._embedded.products) : null;
        let cover = product && product._links && product._links.cover && product._links.cover.href;
        if (cover?.includes('tigerbooks.de')) {
            if (wildcard.blob) {
                return wildcard.blob;
            } else {
                return logo;
            }
        } else {
            if (cover) {
                return cover;
            } else {
                return logo;
            }
        }
    }

    const addWildcardList = () => {
        history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "wildcards",
        });
    }

    const openWildcard = (wildcard) => {
        history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "wildcard-details",
            state: wildcard._links.self.href
        });
    }

    return (
        <>
            <h4 className="dashboard-card-header">{Strings.DASHBOARD_WILDCARDS_CARD_LABEL}</h4>
            <div className="dashboard-card-content">
                {wildcards.map(wildcard => (
                    <SingleCardItem icon={getCover(wildcard)}
                                    key={wildcard.id}
                                    label={wildcard.wildcardName}
                                    sublabel={null}
                                    onClick={() => openWildcard(wildcard)}
                                    rounded
                                    centered
                    />
                ))}

                {wildcards.length < 6 &&
                <SingleCardItem icon={add}
                                label={<>{Strings.WILDCARDS_ADD}</>}
                                sublabel={null}
                                onClick={() => addWildcardList()}
                                rounded
                                centered
                />
                }
            </div>
            <div className="dashboard-card-footer centered">
                <Link to={"/" + GlobalConstants.APP_PATH + 'wildcards'}>
                    <button
                        className={"dashboard-card-button"}
                        type="button">
                    Alle anzeigen
                    </button>
                </Link>
            </div>
        </>
    );
};
import React, {Component} from "react";
import Strings from "../../../config/Strings";
import GlobalConstants from "../../../config/GlobalConstants";
import './DdfDeeplinkRedirect.css';
import logo from './../../../../assets/images/tiger_logo_yellow.png';
import {Button} from "react-bootstrap";
import Footer from "../../../global/footer/Footer";

class DdfDeeplinkRedirect extends Component {
    constructor(props) {
        super(props);

        const queryParams = new URLSearchParams(window.location.search);
        this.state = {
            loading: false,
            link: queryParams.get("link"),
            platform: queryParams.get("platform"),
            showCanvas: true
        };
    }

    async componentDidMount() {
        document.title = Strings.DEEPLINK_REDIRECT_BROWSER_TAB;
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div style={{marginTop: "-20px", backgroundColor: "#010202", color: "#fefefe", paddingBottom: "50px"}} id={"ddf-redirect-page"}>
                <div className="ddf-taipan" />
                <div className="ddf-container" style={{marginBottom: "-70px", padding: "0"}}>
                    <div className="ddf-section-black">
                        <div className="ddf-section-text-content">
                            <div dangerouslySetInnerHTML={{__html: Strings.DDF_REDIRECT_INFO}} />
                        </div>
                    </div>
                    <div className="redirect-button">
                        <a href={this.state.link != null ? GlobalConstants.TONES_DEEPLINK_PROTOCOL + this.state.link : GlobalConstants.REDIRECT_WEBSITE}>
                            <Button style={{backgroundColor: "#fab900", borderRadius: "40px", borderWidth: "0px", width: "260px"}} type="button"><img style={{width: "20%"}} src={logo} alt={"tigermedia logo"}/>{Strings.DEEPLINK_REDIRECT_OPEN_TONES}</Button>
                        </a>
                    </div>
                    <div className="ddf-section-black">
                        <div className="ddf-section-text-content">
                            <div dangerouslySetInnerHTML={{__html: Strings.DDF_SUCCESS_INSTALL_APP_HINT}} />
                        </div>
                    </div>
                    <div className="redirect-button">
                        <a href={this.state.link != null ? (this.state.platform === 'APPLE' ? "https://apps.apple.com/de/app/tigertones/id1269083572" : "market://launch?id=com.tigermedia.TigerAudio&url=" + GlobalConstants.TONES_DEEPLINK_PROTOCOL + this.state.link) : GlobalConstants.REDIRECT_WEBSITE}>
                            <Button style={{backgroundColor: "#fab900", borderRadius: "40px", borderWidth: "0px", width: "260px"}} type="button"><img style={{width: "20%"}} src={logo} alt={"tigermedia logo"}/>{Strings.DEEPLINK_REDIRECT_INSTALL_TONES}</Button>
                        </a>
                    </div>
                </div>
                <Footer backgroundColor={"#010202"} footerColor={"#010202"}/>
            </div>
        );
    }
}

export default DdfDeeplinkRedirect;
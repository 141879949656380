import React from "react";
import { Modal } from "react-bootstrap";
import Strings from "../config/Strings";
import { TigerButton } from "./tiger-button/TigerButton";

export const ConfirmationModal = (props) => {
    const {title, text, actionButtonLabel, actionButtonVariant, closeButtonLabel, closeButtonVariant, onActionPress, show, onHide} = props;
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            style={{borderRadius: "6px"}}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5 dangerouslySetInnerHTML={{__html: text || props.children}}/>
            </Modal.Body>
            <Modal.Footer className="h-100 d-flex justify-content-between">
                <TigerButton
                    className="btn-ci-sm btn-ci-hover" 
                    variant={actionButtonVariant || "orange"}
                    noOutline="true"
                    onClick={onActionPress}
                >
                    {actionButtonLabel}
                </TigerButton>

                <TigerButton
                    className="btn-ci-sm btn-ci-hover" 
                    variant={closeButtonVariant || "gray"}
                    noOutline="true"
                    onClick={onHide}
                >
                    {closeButtonLabel || Strings.MODAL_CLOSE_BUTTON}
                </TigerButton>
            </Modal.Footer>
        </Modal>
    );
}

export const FunctionalModal = (props) => {
    const {title, actionButtonLabel, actionButtonVariant, closeButtonLabel, closeButtonVariant, onActionPress, show, onHide, actionDisabled = false} = props;
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            style={{borderRadius: "6px"}}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            <Modal.Footer className="h-100 d-flex justify-content-between">
                <TigerButton
                    className="btn-ci-sm btn-ci-hover"
                    variant={actionButtonVariant || "orange"}
                    noOutline="true"
                    disabled={actionDisabled}
                    onClick={onActionPress}
                >
                    {actionButtonLabel}
                </TigerButton>

                <TigerButton
                    className="btn-ci-sm btn-ci-hover"
                    variant={closeButtonVariant || "gray"}
                    noOutline="true"
                    onClick={onHide}
                >
                    {closeButtonLabel || Strings.MODAL_CLOSE_BUTTON}
                </TigerButton>
            </Modal.Footer>
        </Modal>
    );
}

export const InfoModal = (props) => {
    const {title, text, closeButtonLabel, closeButtonVariant, show, onHide} = props;
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            style={{borderRadius: "6px"}}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{__html: text || props.children}}/>
            </Modal.Body>
            <Modal.Footer className="h-100 d-flex justify-content-between">
                <TigerButton
                    className="btn-ci-sm btn-ci-hover"
                    variant={closeButtonVariant || "gray"}
                    noOutline="true"
                    onClick={onHide}
                >
                    {closeButtonLabel || Strings.MODAL_CLOSE_BUTTON}
                </TigerButton>
            </Modal.Footer>
        </Modal>
    );
}
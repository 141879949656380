import React, {Component, forwardRef} from "react";
import "../Profiles.scss";
import "./ProfileDetails.scss";
import {BackButton} from "../../../global/tiger-button/BackButton";
import {withToast} from "../../../util/ToastService";
import {getAccount} from "../../../authentication/Oauth2Service";
import {
    DeleteProfile,
    GetAvatarList,
    GetProfile,
    GetRecentlyUsedProductsOfProfile,
    LoadClusters,
    UpdateAvatar,
    UpdatePreferences,
    UpdateProfile,
    UpdateProfileBirthday,
    UpdateProfileGender,
    UpdateProfileName
} from "../ProfileService";
import {TigerSpinner} from "../../../global/tiger-spinner/TigerSpinner";
import {Button, Dropdown, Form, FormControl, InputGroup, Spinner} from "react-bootstrap";
import {
    BiCheck,
    BiPencil,
    FaCheck,
    ImCross,
    IoArrowBackCircleOutline,
    RiArrowGoBackFill
} from "react-icons/all";
import Strings from "../../../config/Strings";
import {TigerButton} from "../../../global/tiger-button/TigerButton";
import GlobalConstants from "../../../config/GlobalConstants";
import {CoverWithControls} from "./cover-controls/CoverWithControls";
import {
    ConvertDateToReadableString,
    ProfileBirthdayDatepickerHeader,
    transformToDatepickerFormat,
    transformToLocalDate
} from "../../../util/ConverterUtil";
import DatePicker, {registerLocale} from "react-datepicker";
import {ConfirmationModal, FunctionalModal} from "../../../global/ConfirmationModal";
import AvatarSelection from "./avatar-selection/AvatarSelection";
import {DashboardCard} from "../../dashboard/DashboardCard";
import {ProfileCard} from "./profile-cards/ProfileCard";
import {GetAccountUserWatchlist} from "../../watchlist/WatchlistService";
import { GetProduct} from "../../products/ProductService";
import ProfilePreferences from "./preferences/ProfilePreferences";
import de from "date-fns/locale/de";

class ProfileDetails extends Component {

    constructor(props) {
        super(props);

        registerLocale('de', de);

        this.fileInput = React.createRef();
        this.birthdayRef = React.createRef();

        this.MAX_AGE = 14;

        this.state = {
            loading: true,
            profileLink: null,
            profile: null,
            isEditingName: false,
            isEditingBirthday: false,
            isEditingGender: false,
            isEditingAgeRange: false,
            isUpdating: false,
            showChangeAvatarDialog: false,
            newBirthday: null,
            ageRangeError: null,
            ageRangeBackup: {min: null, max: null},
            showAvatarSelection: false,
            avatarList: [],
            showDeletionModal: false,
            loadingProfileData: false,

            watchlistProducts: [],
            recentlyUsedProducts: [],
            preferences: [],
            clusters: [],
            showPreferences: false,
        };

        //Window title
        document.title = "Mein Profil :: tiger.media";

    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const token = JSON.parse(localStorage.getItem('ls.token'));
        if (this.props.location.state) {
            this.setState({
                profileLink: this.props.location.state,
            }, () => this.loadProfile(true));
        } else if (token) {
            getAccount(token).then(res => {
                if (!res.error) {
                    this.setState({
                        profileLink: res._embedded.profiles[0]._links.self.href.replace("{?projection}", ""),
                    }, () => this.loadProfile(true));
                }
            })
        } else {
            this.props.history.push("/" + GlobalConstants.APP_PATH + "profiles",)
        }
        window.scrollTo(0, 0);
    }

    async loadProfile() {
        let profile = null;
        let profileRes = await GetProfile(this.state.profileLink);
        if (!profileRes.error) {
            profile = profileRes.result;
            const cover = profile && profile._links && profile._links.avatar && profile._links.avatar.href;
            this.setState({
                loadingProfileData: true,
                profile: profile,
                ageRangeBackup: profile && profile.ageRange,
                cover: cover
            });
            //Window title
            document.title = "Profil " + profile.name + " :: tiger.media";
        } else {
            this.props.addToast(Strings.WILDCARDS_DETAILS_ERROR + profileRes.message, {
                autoDismiss: true,
                appearance: 'error',
                placement: 'bottom-center'
            });
            this.setState({
                loading: false,
                profile: null
            });
        }

        if (profile && profile._embedded && profile._embedded.account) {
            const accountId = profile._embedded.account.id;
            let productsRes = await GetRecentlyUsedProductsOfProfile(accountId, profile.id);
            if (!productsRes.error) {
                let products = (productsRes.result != null && productsRes.result._embedded != null && productsRes.result._embedded.products != null) ? productsRes.result._embedded.products.filter(p => (p.state !== 'INACTIVE' && p.state !== 'TAKEDOWN')) : [];
                if (products.length > 6) {
                    products = products.slice(0, 6);
                }
                this.setState(prevState => ({...prevState, recentlyUsedProducts: products, account: profile._embedded.account}));
            }
            let watchlistRes = await GetAccountUserWatchlist(accountId, profile.id);
            if (!watchlistRes.error) {
                const watchlists = watchlistRes.result._embedded ? watchlistRes.result._embedded.watchListEntryList : [];

                if (watchlists.length > 0) {
                    //Get the product ids
                    let productIds = watchlists.length > 5 ? watchlists.map(item => item.productId).slice(0, 5) : watchlists.map(item => item.productId);

                    //Fetch the products
                    let products = [];
                    for (let i = 0; i < productIds.length; i++) {
                        let productRes = await GetProduct(productIds[i]);
                        if (!productRes.error) {
                            //We only want to show products with state = 'ACTIVE'
                            let product = productRes.result?._embedded?.simpleHalRepresentationModels[0]
                            if (product && product.state === 'ACTIVE') {
                                products.push(product);
                            }
                        }
                    }
                    this.setState(prevState => ({...prevState, watchlistProducts: products}));
                }
            }
        }
        if (profile && profile._embedded && profile._embedded.preferences) {
            let preferences = profile._embedded.preferences;
            this.setState(prevState => ({...prevState, preferences: preferences}));
        }

        this.setState(prevState => ({...prevState, loadingProfileData: false}));

        if (profile && this.props.location.hash != null && this.props.location.hash === '#preferences') {
            this.openPreferences();
        }

        this.setState(prevState => ({...prevState, loading: false}));
    }

    updateProfileName() {
        if (this.state.newName) {
            UpdateProfileName(this.state.profile, this.state.newName).then(res => {
                if (!res.error) {
                    this.setState(prevState => ({
                        ...prevState,
                        profile: {
                            ...prevState.profile,
                            name: this.state.newName || prevState.name
                        },
                        isEditingName: false
                    }));
                }
            });
        }
    }

    async updateProfileGender(newGender) {
        if (this.state.profile && this.state.profile.gender !== newGender) {
            this.setState(prevState => ({...prevState, updating: true}));
            let res = await UpdateProfileGender(this.state.profile, newGender);
            if (!res.error) {
                this.setState(prevState => ({
                    ...prevState,
                    profile: {
                        ...prevState.profile,
                        gender: newGender
                    },
                    updating: false,
                    isEditingGender: false
                }));
            }
        }
    }

    async updateProfileBirthday(newBirthday) {
        let convertedBirthday = transformToLocalDate(newBirthday);
        if (this.state.profile && this.state.profile.birthday !== convertedBirthday) {
            this.setState(prevState => ({...prevState, updating: true}));
            let res = await UpdateProfileBirthday(this.state.profile, convertedBirthday);
            if (!res.error) {
                this.setState(prevState => ({
                    ...prevState,
                    profile: res.result,
                    updating: false,
                    isEditingBirthday: false
                }));
            }
        }
    }

    async removeBirthday() {
        if (this.state.profile) {
            this.setState(prevState => ({...prevState, updating: true}));
            let res = await UpdateProfileBirthday(this.state.profile, null);
            if (!res.error) {
                this.setState(prevState => ({
                    ...prevState,
                    profile: res.result,
                    updating: false,
                    isEditingBirthday: false
                }));
                this.birthdayRef.current.setOpen(false);
            }
        }
    }

    async updateProfileAgeRange() {
        if (this.state.profile && this.state.profile.ageRange) {
            this.setState(prevState => ({...prevState, updating: true}));
            let res = await UpdateProfile(this.state.profile);
            if (!res.error) {
                this.setState(prevState => ({...prevState, updating: false, isEditingAgeRange: false, ageRangeBackup: this.state.profile.ageRange}));
            }
        }
    }

    async updateProfileAvatar(avatar) {
        if (this.state.profile && this.state.profile.id && avatar && avatar.url) {
            let res = await UpdateAvatar(this.state.profile.id, avatar.url) ;
            if (!res.error) {
                this.setState(prevState => ({...prevState, cover: avatar.url, showAvatarSelection: false}), () => {
                    const element = document.getElementById("#title");
                    if (element) {
                        element.scrollIntoView({behavior: "smooth", block: "start"});
                    }
                });
            }
        }
    }

    async deleteProfile() {
        if (this.state.profile && this.state.profile.id) {
            let res = await DeleteProfile(this.state.profile.id);
            if (!res.error) {
                window.location.href = GlobalConstants.UI_HOST + "profiles";
            }
        }
    }

    async togglePreference(p) {
        let preferences = this.state.preferences;
        const index = preferences.map(i => i.id).indexOf(p.id);
        if (index > -1) {
            preferences.splice(index, 1);
        } else {
            preferences.push(p);
        }
        UpdatePreferences(this.state.profile.id, preferences).then(r => {
            this.setState(prevState => ({...prevState, preferences: preferences}));
        });
    }

    getBirthdayText() {
        const birthday = this.state.profile && this.state.profile.birthday;
        if (birthday != null) {
            return ConvertDateToReadableString(birthday);
        } else {
            return Strings.PROFILES_DETAILS_UNDEFINED;
        }
    }

    getGenderText() {
        const gender = this.state.profile && this.state.profile.gender;
        if (gender === 'MALE') {
            return Strings.PROFILES_DETAILS_GENDER_MALE;
        } else if (gender === 'FEMALE') {
            return Strings.PROFILES_DETAILS_GENDER_FEMALE;
        } else {
            return Strings.PROFILES_DETAILS_UNDEFINED;
        }
    }

    getAgeRangeText() {
        const min = this.state.profile && this.state.profile.ageRange && this.state.profile.ageRange.min;
        const max = this.state.profile && this.state.profile.ageRange && this.state.profile.ageRange.max;
        if (min > 0 || max > 0) {
            return Strings.PROFILES_DETAILS_AGE_RANGE.replace("{0}", min).replace("{1}", max);
        } else {
            return Strings.PROFILES_DETAILS_UNDEFINED;
        }
    }

    openWatchlist() {
        this.props.history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "watchlist",
            state: {profileId: this.state.profile.id, profileLink: this.state.profileLink, accountId: this.state.account.id}
        });
    }

    openRecentlyUsedProducts() {
        this.props.history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "audiobooks",
            state: {profileId: this.state.profile.id, profileLink: this.state.profileLink, account: this.state.account}
        });
    }

    openSearch() {
        this.props.history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "search",
            state: {profileId: this.state.profile.id, profileLink: this.state.profileLink, account: this.state.account}
        });
    }

    async openPreferences() {
        window.scrollTo(0, 0);
        this.setState(prevState => ({...prevState, loading: true}));
        let page = 1;
        let response = {};
        let clusters = [];
        let loadNextPage = true;
        do {
            response = await LoadClusters(page);
            if (response.error) {
                break;
            }
            if (response.result != null && response.result._embedded && response.result._embedded.clusters &&
                Array.isArray(response.result._embedded.clusters) && response.result._embedded.clusters.length > 0) {
                clusters = clusters.concat(response.result._embedded.clusters);
                page++;
            } else {
                loadNextPage = false;
            }
            if (page >= 10) {
                //Fallback
                loadNextPage = false;
            }
        } while (loadNextPage);

        if (clusters != null && Array.isArray(clusters) && clusters.length > 0) {
            clusters = clusters.filter(c => c.visibility === true);
            this.setState(prevState => ({...prevState, clusters: clusters, loading: false, showPreferences: true}));
        }
        window.scrollTo(0, 0);
    }

    openCluster(id) {
        this.props.history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "cluster/" + id,
            state: {profileLink: this.state.profileLink, profileId: this.state.profile.id, isSelected: true, source: 'details'}
        });
    }

    openProduct(product) {
        window.location.href = GlobalConstants.REDIRECT_TO_PRODUCT + product.id;
    }

    render() {
        if (this.state.showAvatarSelection){
            return (
                <div className="content-box mb-4">
                    <Button variant={"outline-danger"}
                        onClick={() => {
                            this.setState(prevState => ({...prevState, showAvatarSelection: false}));
                            window.scrollTo(0, 0);
                        }}
                        type="button"
                    ><RiArrowGoBackFill /> {Strings.CANCEL_BUTTON}</Button>
                    <AvatarSelection avatarList={this.state.avatarList} currentAvatar={this.state.cover} onSelectAvatar={(avatar) => this.updateProfileAvatar(avatar)}/>
                </div>
            );
        }

        if (this.state.showPreferences) {
            //Window title
            document.title = "Helden und Welten :: tiger.media";
            return (
                <div className="content-box">
                    <BackButton onClick={() => {
                                this.props.history.push({
                                    hash: "",
                                    state: this.state.profileLink
                                });
                                //Window title
                                document.title = this.state.profile != null ? "Profil " + this.state.profile.name + " :: tiger.media" : "Profil :: tiger.media";
                                this.setState(prevState => ({...prevState, showPreferences: false}));
                                window.scrollTo(0, 0);
                            }}/>

                    <br />
                    {this.state.loading &&
                        <TigerSpinner />
                    }
                    {!this.state.loading &&
                        <>
                            <ProfilePreferences clusters={this.state.clusters} preferences={this.state.preferences}
                                                onToggleCluster={(c) => this.togglePreference(c)}
                                                history={this.props.history}
                                                profileLink={this.state.profileLink}
                                                profile={this.state.profile} loading={this.state.loading}/>
                        </>
                    }
                </div>
            );
        }

        return (
            <div className="content-box">
                <div className="content-details-header" style={{backgroundColor: "#838383"}} id="#title">
                    <a href={"/" + GlobalConstants.APP_PATH + "profiles"}>
                        <IoArrowBackCircleOutline />
                    </a>&#xA0;&#xA0;{Strings.PROFILES_DETAILS_TITLE}
                </div>
                <div className="mb-4">
                    {!this.state.loading && this.state.profile && (
                        <>
                            <div className="profile-details-wrap">
                                <div className={"cover-drop-container"}>
                                    <input
                                        type="file"
                                        className="d-none"
                                        onChange={this.onImageAdded}
                                        ref={this.fileInput}
                                        accept="image/jpeg"
                                    />
                                    <CoverWithControls
                                        cover={this.state.cover}
                                        onEditCoverClick={() => GetAvatarList().then(r => {
                                            window.scrollTo(0, 0);
                                            if (!r.error) {
                                                this.setState(prevState => ({...prevState, showAvatarSelection: true, avatarList: this.transformAvatarListLayout(r.result)}));
                                            }
                                        })}
                                    />
                                </div>
                                <div className="py-4 px-2 w-100">
                                    <div>
                                        {this.state.isEditingName ? (
                                            <InputGroup className="w-100 mb-2">
                                                <FormControl
                                                    size="lg"
                                                    defaultValue={this.state.profile.name}
                                                    isInvalid={this.state.newName == null || this.state.newName === ""}
                                                    onChange={e => this.setState({ newName: e.target.value })}
                                                />

                                                <Button
                                                    variant="danger"
                                                    onClick={() =>
                                                        this.setState({
                                                            isEditingName: false,
                                                            newName: this.state.profile.name
                                                        })
                                                    }
                                                >
                                                    <ImCross />
                                                </Button>
                                                <Button
                                                    disabled={this.state.newName == null || this.state.newName === ""}
                                                    variant={(this.state.newName == null || this.state.newName === "") ? "secondary" : "success"}
                                                    onClick={() => this.updateProfileName()}
                                                >
                                                    <FaCheck />
                                                </Button>
                                                <Form.Control.Feedback type="invalid">
                                                    {Strings.PROFILES_NAME_ERROR}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        ) : (
                                            <h2 className={"profile-name"}>
                                                {this.state.profile.name}
                                                {"  "}
                                                <Button
                                                    variant="outline-secondary"
                                                    className="px-1 py-0 ml-2"
                                                    onClick={() => this.setState({isEditingName: true, newName: this.state.profile.name})}
                                                >
                                                    <BiPencil />
                                                </Button>
                                            </h2>
                                        )}
                                    </div>
                                    <hr />
                                    <div className="profile-infos-wrap">
                                        <div className="px-1 w-100">
                                            {Strings.PROFILES_DETAILS_BIRTHDAY_LABEL}
                                        </div>
                                        <div className="px-1 w-100">
                                            <div className="profile-infos-wrap">
                                                <div className="profile-info">
                                                    {this.getBirthdayText()}
                                                </div>
                                                <div className="profile-info-edit profile-infos-wrap" style={{justifyContent: "normal"}}>
                                                    <div className="datepicker-edit">
                                                        <DatePicker
                                                            wrapperClassName="datepicker"
                                                            ref={this.birthdayRef}
                                                            maxDate={new Date()}
                                                            todayButton={Strings.CONTRACT_CANCELLATION_DATE_PICKER_TODAY}
                                                            dateFormat={"dd.MM.yyyy"}
                                                            calendarStartDay={1}
                                                            locale="de"
                                                            placeholderText={Strings.CONTRACT_CANCELLATION_FORM_SELECT_PLACEHOLDER}
                                                            withPortal
                                                            customInput={<ProfileBirthdayInput />}
                                                            renderCustomHeader={({
                                                                                     date,
                                                                                     changeYear,
                                                                                     changeMonth,
                                                                                     decreaseMonth,
                                                                                     increaseMonth,
                                                                                     prevMonthButtonDisabled,
                                                                                     nextMonthButtonDisabled
                                                                                 }) => ProfileBirthdayDatepickerHeader(new Date(date), changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled, () => this.removeBirthday(), this.state.profile.birthday == null)}
                                                            selected={transformToDatepickerFormat(this.state.profile.birthday)}
                                                            onChange={(date) => this.updateProfileBirthday(date.toString())}/>
                                                    </div>
                                                    {this.state.profile != null && this.state.profile.birthday != null &&
                                                        <div className="datepicker-edit">
                                                            <Button
                                                                variant="outline-danger"
                                                                className="px-1 py-0 ml-2"
                                                                onClick={() => this.removeBirthday()}
                                                            >
                                                                <ImCross/>
                                                            </Button>
                                                        </div>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="profile-infos-wrap">
                                        <div className="px-1 w-100">
                                            {Strings.PROFILES_DETAILS_GENDER_LABEL}
                                        </div>
                                        <div className="px-1 w-100">
                                            {this.state.isEditingGender ?
                                                (<>
                                                    {this.state.updating ?
                                                        <Spinner animation="border" />
                                                        :
                                                        <Dropdown id="gender-dropdown" onToggle={() => {
                                                            if (this.state.isEditingGender) {
                                                                this.setState({isEditingGender: false});
                                                            }
                                                        }}>
                                                            <Dropdown.Menu variant="dark" show={true}>
                                                                <Dropdown.Header>{Strings.PROFILES_DETAILS_SELECT_GENDER}</Dropdown.Header>
                                                                <Dropdown.Item active={this.state.profile && this.state.profile.gender === 'MALE'} onClick={() => this.updateProfileGender('MALE')}>
                                                                    {Strings.PROFILES_DETAILS_GENDER_MALE}
                                                                </Dropdown.Item>
                                                                <Dropdown.Item active={this.state.profile && this.state.profile.gender === 'FEMALE'} onClick={() => this.updateProfileGender('FEMALE')}>
                                                                    {Strings.PROFILES_DETAILS_GENDER_FEMALE}
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    }
                                                </>)
                                            :
                                                (<div className="profile-infos-wrap">
                                                    <div className="profile-info">
                                                        {this.getGenderText()}
                                                    </div>
                                                    <div className="profile-info-edit profile-infos-wrap" style={{justifyContent: "normal"}}>
                                                        <Button
                                                            variant="outline-secondary"
                                                            className="px-1 py-0 ml-2"
                                                            onClick={() => this.setState({isEditingGender: true})}
                                                        >
                                                            <BiPencil />
                                                        </Button>
                                                        {this.state.profile != null && this.state.profile.gender != null && this.state.profile.gender !== "UNDEFINED" &&
                                                            <div className="datepicker-edit">
                                                                <Button
                                                                    variant="outline-danger"
                                                                    className="px-1 py-0 ml-2"
                                                                    onClick={() => this.updateProfileGender('UNDEFINED')}
                                                                >
                                                                    <ImCross/>
                                                                </Button>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>)
                                            }

                                        </div>
                                    </div>
                                    <hr />
                                    <div className="profile-infos-wrap">
                                        <div className="px-1 w-100">
                                            {Strings.PROFILES_DETAILS_AGE_RANGE_LABEL}
                                        </div>
                                        <div className="px-1 w-100">
                                            <div className="profile-infos-wrap">
                                                <div className="profile-info">
                                                    {this.getAgeRangeText()}
                                                </div>
                                                <div className="profile-info-edit">
                                                    <Button
                                                        variant="outline-secondary"
                                                        className="px-1 py-0 ml-2"
                                                        onClick={() => this.setState({isEditingAgeRange: true})}
                                                    >
                                                        <BiPencil />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <hr />
                            <div style={{width: "100%", display: "inline-block"}}>
                                <div className="dashboard">
                                    <DashboardCard
                                        text={Strings.DASHBOARD_NUMBER_WATCHLIST}
                                        onTitleClick={() => this.openWatchlist()}
                                        backgroundColor={'#f0731a'}
                                        body={
                                            <ProfileCard noContentTitle={Strings.WATCHLIST_EMPTY}
                                                         noContentInfo={Strings.DASHBOARD_WATCHLIST_TOOLTIP}
                                                         products={this.state.watchlistProducts.length > 6 ? this.state.watchlistProducts.slice(0, 6) : this.state.watchlistProducts}
                                                         buttonText={Strings.PROFILES_DETAILS_GOTO_SEARCH}
                                                         onButtonClick={() => this.openSearch()}
                                                         onShowAll={() => this.openWatchlist()}
                                                         onOpenResource={(p) => this.openProduct(p)}
                                                         loading={this.state.loadingProfileData}
                                            />
                                        }
                                    />
                                    <DashboardCard
                                        text={Strings.DASHBOARD_NUMBER_AUDIOBOOKS}
                                        onTitleClick={() => this.openRecentlyUsedProducts()}
                                        backgroundColor={'#a51382'}
                                        body={
                                            <ProfileCard noContentTitle={Strings.DASHBOARD_NO_RECENTLY_USED_PRODUCTS}
                                                         noContentInfo={Strings.DASHBOARD_RECENTLY_USED_PRODUCTS_TOOLTIP}
                                                         products={this.state.recentlyUsedProducts.length > 6 ? this.state.recentlyUsedProducts.slice(0, 6) : this.state.recentlyUsedProducts}
                                                         onShowAll={() => this.openRecentlyUsedProducts()}
                                                         onOpenResource={(p) => this.openProduct(p)}
                                                         loading={this.state.loadingProfileData}/>
                                        }
                                    />
                                    <DashboardCard
                                        text={Strings.DASHBOARD_NUMBER_CLUSTERS}
                                        onTitleClick={() => this.openPreferences()}
                                        backgroundColor={'#1bafb4'}
                                        body={
                                            <ProfileCard noContentTitle={Strings.DASHBOARD_CLUSTERS_EMPTY}
                                                         noContentInfo={Strings.DASHBOARD_CLUSTERS_TOOLTIP}
                                                         onShowAll={() => this.openPreferences()}
                                                         onOpenResource={(c) => this.openCluster(c.id)}
                                                         label={"name"}
                                                         buttonText={Strings.PROFILES_DETAILS_ADD_CLUSTER}
                                                         onButtonClick={() => this.openPreferences()}
                                                         centered
                                                         products={this.state.preferences.length > 6 ? this.state.preferences.slice(0, 6) : this.state.preferences}
                                                         loading={this.state.loadingProfileData} />
                                        }
                                    />
                                </div>
                            </div>

                            <hr />
                            {this.state.profile && !this.state.profile.defaultProfile &&
                                <TigerButton
                                    className="btn-ci btn-ci-hover mx-auto"
                                    variant="red"
                                    onClick={() => this.setState({showDeletionModal: true})}
                                >
                                    {Strings.PROFILES_DETAILS_DELETE_BUTTON}
                                </TigerButton>
                            }
                        </>
                    )}

                    {this.state.loading && <TigerSpinner/>}
                </div>

                <FunctionalModal
                    title={Strings.PROFILES_DETAILS_EDIT_AGE_RANGE}
                    actionButtonLabel={<><BiCheck />{Strings.PROFILES_DETAILS_SAVE_BUTTON}</>}
                    actionButtonVariant="green"
                    onActionPress={() => this.updateProfileAgeRange()}
                    actionDisabled={!this.ageRangeIsValid('min') || !this.ageRangeIsValid('max')}
                    show={this.state.isEditingAgeRange}
                    onHide={() => this.setState(prevState => ({...prevState, isEditingAgeRange: false, ageRangeError: null, profile: {...prevState.profile, ageRange: prevState.ageRangeBackup}}))}
                >{this.ageRangeEdit()}</FunctionalModal>

                <ConfirmationModal
                    title={Strings.PROFILES_DELETION_WARNING_TITLE}
                    text={Strings.PROFILES_DELETION_WARNING_BODY.replace("{0}", this.state.profile && this.state.profile.name)}
                    actionButtonLabel={Strings.PROFILES_DELETION_BUTTON}
                    actionButtonVariant="danger"
                    onActionPress={() => this.deleteProfile()}
                    show={this.state.showDeletionModal}
                    onHide={() => this.setState({showDeletionModal: false})}
                />
            </div>
        )
    }

    ageRangeEdit() {
        return(
            <>
                {Strings.PROFILES_DETAILS_EDIT_AGE_RANGE_INFO}<br />
                <div style={{textAlign: "center"}}>
                    <input type="number" min="0" max="8" className={this.ageRangeIsValid("min") ? "profile-details-input" : "profile-details-input invalid"}
                           value={(this.state.profile != null && this.state.profile.ageRange != null && this.state.profile.ageRange.min != null) ? this.state.profile.ageRange.min : 0}
                           onChange={(e) => {
                               this.setState(prevState => ({
                               ...prevState,
                               profile: {
                                   ...prevState.profile,
                                   ageRange: {
                                       ...prevState.profile.ageRange,
                                       min: Number(e.target.value)
                                   }
                               },
                               ageRangeError: this.ageRangeError("min", e.target.value)
                           }))}}
                    />
                    {Strings.PROFILES_DETAILS_EDIT_AGE_RANGE_TO}
                    <input type="number" min="2" max="14" className={this.ageRangeIsValid("max") ? "profile-details-input" : "profile-details-input invalid"} value={(this.state.profile != null && this.state.profile.ageRange != null && this.state.profile.ageRange.max != null) ? this.state.profile.ageRange.max : this.MAX_AGE}
                           onChange={(e) => {
                               this.setState(prevState => ({
                                   ...prevState,
                                   profile: {
                                       ...prevState.profile,
                                       ageRange: {
                                           ...prevState.profile.ageRange,
                                           max: Number(e.target.value)
                                       }
                                   },
                                   ageRangeError: this.ageRangeError("max", e.target.value)
                               }))
                           }}
                    />
                    {Strings.PROFILES_DETAILS_EDIT_AGE_RANGE_YEARS}<br />
                    {this.state.ageRangeError != null &&
                        <span style={{color: 'red'}}>{this.state.ageRangeError}</span>
                    }
                </div>
            </>
        );
    }

    ageRangeIsValid(range) {
        if (this.state.profile == null || this.state.profile.ageRange == null || this.state.profile.ageRange.max == null || this.state.profile.ageRange.min == null) {
            return true;
        } else {
            let positive = this.state.profile.ageRange[range] >= 0;
            let positive_range = this.state.profile.ageRange.max >= this.state.profile.ageRange.min + 2;
            let allowedValue = range === 'max' ? this.state.profile.ageRange[range] >=2 && this.state.profile.ageRange[range] <= this.MAX_AGE : this.state.profile.ageRange[range] <= 10;
            return positive && positive_range && allowedValue;
        }
    }

    ageRangeError(range, value) {
        if (this.state.profile == null || this.state.profile.ageRange == null || this.state.profile.ageRange.max == null || this.state.profile.ageRange.min == null) {
            return null;
        } else if (value < 0) {
            return Strings.PROFILES_AGE_RANGE_POSITIVE_ERROR;
        } else if ((range === 'max' && value < this.state.profile.ageRange.min + 2) || (range === 'min' && value > this.state.profile.ageRange.max - 2)) {
            return Strings.PROFILES_AGE_RANGE_POSITIVE_AGERANGE_ERROR;
        } else if (range === 'max' && (value < 2 || value > this.MAX_AGE)) {
            return Strings.PROFILES_AGE_RANGE_MAX_ERROR;
        } else if (range === 'min' && (value > 8 || value < 0)) {
            return Strings.PROFILES_AGE_RANGE_MIN_ERROR;
        } else {
            return null;
        }
    }

    transformAvatarListLayout(avatarList) {
        let transformedList = [];
        if (avatarList == null || avatarList.length === 0) {
            return [];
        }

        if (avatarList.categories != null) {
            avatarList.categories.forEach(c => transformedList.push({name: c.name, avatarList: c.avatars}));
        }
        return transformedList;
    }
}

export default withToast(ProfileDetails);


const ProfileBirthdayInput = forwardRef(({ value, onClick }, ref) => (
        <Button
            ref={ref}
            variant="outline-secondary"
            className="px-1 py-0 ml-2"
            onClick={onClick}
        >
            <BiPencil />
        </Button>
    ));
import React, {useEffect} from "react";
import Strings from "../../../config/Strings";
import {TigerButton} from "../../../global/tiger-button/TigerButton";
import googlePlay from "../../../../assets/images/Badge-GooglePlay@2x.png";
import appStore from "../../../../assets/images/Badge-AppStore@2x.png";
import GlobalConstants from "../../../config/GlobalConstants";
import {useHistory, useLocation} from "react-router";
import {authCheck} from "../../../util/authCheck";
import tigertones_app_icon from "../../../../assets/images/ddf-tigertones-app-icon.png";

export default function DDFRedemptionSuccess() {

    const queryParams = new URLSearchParams(window.location.search);
    const targetUrl = queryParams.get("target") != null ? queryParams.get("target") : GlobalConstants.DEFAULT_DEEPLINK_DDF;

    const history = useHistory();
    const coupon = JSON.parse(sessionStorage.getItem('ls.ddf'));
    const location = useLocation();

    //ROUTING
    useEffect(() => {
        if (!authCheck()) {
            history.push("/" + GlobalConstants.APP_PATH + "ddflive/authenticate#ddf-authentication-register");
        }

        if (coupon == null) {
            history.push("/" + GlobalConstants.APP_PATH + "ddflive");
        }

        if (location !== undefined && location.hash !== "") {
            const element = document.getElementById(location.hash);
            if (element) {
                element.scrollIntoView({behavior: "smooth", block: "center"});
            }
        }
    }, [history, coupon, location]);

    if (coupon == null) {
        history.push("/" + GlobalConstants.APP_PATH + "ddflive");
    }

    if (!authCheck()) {
        history.push("/" + GlobalConstants.APP_PATH + "ddflive/authenticate#ddf-authentication-register");
    }

    //Rendering
    return (
        <>
            <div className="ddf-container">
                <div className="ddf-taipan"/>
                <div className="ddf-title" style={{textAlign: "center"}} id="#ddf-success" dangerouslySetInnerHTML={{__html: Strings.DDF_SUCCESS_HEADLINE}} />


                <div className="ddf-section-white">
                    <div className="ddf-section-text-content">

                        <img src={tigertones_app_icon} width={"25%"} alt={"tigertones icon"} />
                        <br /><br />
                        <div dangerouslySetInnerHTML={{__html: Strings.DDF_SUCCESS_INSTALL_APP_HINT}} />

                        <br />

                        <div>
                            <a className="app-icon icon-right" href="https://apps.apple.com/de/app/tigertones/id1269083572">
                                <img src={appStore} alt={"tigertones im Apple App Store"}/>
                            </a>
                            <a className="app-icon icon-left" href="market://launch?id=com.tigermedia.TigerAudio&url=tigertones://productlist/97932">
                                <img src={googlePlay} alt={"tigertones im Google Play Store"} />
                            </a>
                        </div>

                        <br />
                        <br />
                        <br />
                        <br />
                        <div dangerouslySetInnerHTML={{__html: Strings.DDF_SUCCESS_INSTALL_APP_HINT_2}} />
                        <br />
                        <a href={targetUrl} className="openlink">
                            <TigerButton className="w-100" variant="red" type="button">
                                {Strings.DDF_SUCCESS_OPEN_PLAYLIST}
                            </TigerButton>
                        </a>
                        <br />
                        <div dangerouslySetInnerHTML={{__html: Strings.DDF_SUCCESS_INSTALL_APP_HINT_3}} />
                    </div>
                </div>
            </div>
        </>
    )

}
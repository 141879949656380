export function createFilterQuery(filter) {

    //Return an empty string if the filter is empty
    if(filter == null) {
        return "";
    }

    //Create a query otherwise
    let query = "?";

    Object.keys(filter).forEach((key, i) => {
        if(filter[key] != null && filter[key] !== "") {

            //Check for the data type of the filter param
            if(Array.isArray(filter[key])) {
                //TYPE = Array -> Transform Array ["A","B","C",...] to single String "A,B,C,"
                let entries = "";
                filter[key].forEach(entry => (entries = entries + "," + entry));
                if (entries.slice(-1) === ",") {
                    entries = entries.slice(0, -1);
                }
                if (filter[key].length > 0) {
                    query = query + key + "=" + entries + "&";
                }
            } else {
                //TYPE = Default
                query = query + key + "=" + filter[key] + "&";
            }
        }
    });

    //Remove the last character if its a '&' or '?'
    const lastChar = query.slice(-1);
    if(lastChar === '&' || lastChar === '?' ){
        query = query.slice(0,-1);
    }

    //URI Encoding
    query = encodeURI(query);

    return query;
}
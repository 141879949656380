import React from "react";
import "./TigerMessage.css";

export const TigerMessage = (props) => {
    const { text } = props;

    return (
        <>
            <div className="tiger"></div>
            <h3 className="tiger-text">{text}</h3>
        </>
    );
}

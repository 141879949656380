import React, {Component} from "react";
import Strings from "../../config/Strings";
import {BackButton} from "../../global/tiger-button/BackButton";
import {withToast} from "../../util/ToastService";
import GlobalConstants from "../../config/GlobalConstants";
import './LandingPage.css';
import {Spinner} from "react-bootstrap";
import {TigerButton} from "../../global/tiger-button/TigerButton";

class AccountDeletionPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    async componentDidMount() {
    }

    async deleteAccount() {
        this.setState(prevState => ({...prevState, loading: true}));

        this.setState(prevState => ({...prevState, loading: false}));

    }

    closeView = () => {
        this.props.history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "dashboard",
            hash: this.state.source,
            state: this.state.profileLink
        });
    }

    render() {
        return (
            <div className="content-box">
                <BackButton onClick={() => this.closeView()}/>

                <div className="p-3 mb-4">
                    <h3 className="landingpage-header">
                        {Strings.ACCOUNT_DELETION_INFO}
                    </h3>
                    <div className="landing-page-body">

                        <TigerButton className="w-50" style={{marginTop: "40px", marginLeft: "auto", marginRight: "auto"}}
                                     variant="red" type="button"
                                     onClick={() => this.deleteAccount()}
                        >
                            {this.state.loading ?
                                <Spinner animation="border"/>
                                :
                                Strings.ACCOUNT_DELETION_BUTTON
                            }
                        </TigerButton>

                    </div>
                </div>
            </div>
        )
    }
}

export default withToast(AccountDeletionPage);
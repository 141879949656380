import React from "react";
import GlobalConstants from "../../../config/GlobalConstants";
import Strings from "../../../config/Strings";
import {TigerSpinner} from "../../../global/tiger-spinner/TigerSpinner";
import './DashboardCard.scss';
import {logout} from "../../../authentication/Oauth2Service";

export const AccountCard = (props) => {
    const {account, loading, history, setToken, setRoles, setAccountId, setEmail} = props;

    if (loading) {
        return (
            <TigerSpinner/>
        )
    }

    const openPasswordChange = () => {
        history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "change-password"
        });
    }

    const openEmailChange = () => {
        history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "change-email"
        });
    }

    /*
    const openDeleteAccount = () => {
        history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "delete-account"
        });
    }*/

    const onLogout = async() => {
        await logout({setToken, setRoles, setAccountId, setEmail});
    }

    return (
        <>
            <h4 className="dashboard-card-header">{Strings.DASHBOARD_ACCOUNT_CARD_LABEL}</h4>
            <div className="static-content-container">
                <div>{Strings.DASHBOARD_ACCOUNT_EMAIL_LABEL}</div>
                <div style={{fontWeight: "bold", overflow: "hidden"}}>{account.email}</div>
                <hr style={{width: "270px"}}/>
                <div className="dashboard-card-grid">
                    <div className="px-2 w-100" onClick={() => openEmailChange()}>
                        <span className="dashboard-card-link">{Strings.DASHBOARD_ACCOUNT_CHANGE_EMAIL}&#187;</span>
                    </div>
                </div>
                <hr style={{width: "270px"}}/>
                <div className="dashboard-card-grid">
                    <div className="px-2 w-100" onClick={() => openPasswordChange()}>
                        <span className="dashboard-card-link">{Strings.DASHBOARD_ACCOUNT_CHANGE_PASSWORD}&#187;</span>
                    </div>
                </div>
            </div>
            <div className="dashboard-card-footer centered">
                <button
                    className={"dashboard-card-button"}
                    type="button"
                    onClick={() => onLogout()}
                >{Strings.DASHBOARD_ACCOUNT_LOGOUT}</button>
            </div>
        </>
    );
};
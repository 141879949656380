import {
    CreateResource,
    DeleteResource,
    GetBlob,
    GetResource,
    PostFormData,
    UpdateResource
} from "../../api/SpineClient";

//API METHODS

export async function GetAccountWildcards() {
    return await GetResource('api/cards/search/all-wildcards-for-account');
}

export async function GetCard(wildcardLink) {
    return await GetResource(wildcardLink, "application/json, text/plain, */*");
}

export async function GetCover(wildcardCoverLink) {
    return await GetBlob(wildcardCoverLink);
}

export async function UpdateMetadata(wildcard, metadata) {
    return await UpdateResource(wildcard._links['update-metadata'].href, metadata, false);
}

export async function UploadContent(wildcard, resource) {
    return await PostFormData(wildcard._links['upload'].href, resource, false);
}

export async function ResetCover(wildcard) {
    const resetCover = wildcard._embedded.accountgeneratedcontents._links['reset-cover'].href;
    return await DeleteResource(resetCover);
}

export async function UnassignWildcard(wildcard) {
    return await UpdateResource(wildcard._links['unassign-account'].href, {}, false);
}

export async function AssignWildcard(wildcardCode) {
    return await CreateResource('api/cards/assign-account-with-code?code=' + wildcardCode, {});
}

export async function GetWildcardContent(wildcard) {
    const accountGeneratedContentLink = wildcard._embedded.accountgeneratedcontents._links.accountGeneratedContent.href;
    return await GetResource(accountGeneratedContentLink);
}

export async function DeleteAllWildcardContent(wildcard) {
    const accountGeneratedContentLink = wildcard._embedded.accountgeneratedcontents._links.accountGeneratedContent.href;
    return await DeleteResource(accountGeneratedContentLink, false);
}

export async function DeleteWildcardContent(url, itemPositionsToDelete) {
    return await DeleteResource(url, false, itemPositionsToDelete);
}

export async function UpdateContentOrder(url, sortOrder) {
    return await UpdateResource(url, sortOrder);
}

export async function AddWildcardContent(url, resource) {
    return await PostFormData(url, resource, false);
}
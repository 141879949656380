import React, { Component } from "react";
import "../Profiles.scss";
import { Card } from "react-bootstrap";
import GlobalConstants from "../../../config/GlobalConstants";
import {TigerSpinner} from "../../../global/tiger-spinner/TigerSpinner";
import logo from "../../../../assets/images/loader_bg.png";

class SingleProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEditing: false,
            expanded: false,
            newName: null,
            profileName: null,
            profile: null,
            coverLoading: true
        };
    }

    componentDidMount() {
        this.loadProfile();
    }

    loadProfile() {
        const profile = this.props.profile;
        const profileName = this.props.profile.name;
        const cover = (profile != null && profile._links != null && profile._links.avatar && profile._links.avatar.href != null) ? profile._links.avatar.href : "https://cdn.tigerbooks.de/profiles/dev/katze.png";
        this.setState({
            profile: profile,
            profileName: profileName,
            coverLoading: false,
            cover: cover
        });
    }

    openOverview() {
        this.props.history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "profile-details",
            state: this.props.profile._links.self.href.replace("{?projection}", "")
        });
    }

    render() {
        return (
            this.state.profile && (
                <Card className="profile mx-2" onClick={() => this.openOverview()}>
                    {this.state.coverLoading ?
                        <div className="h-100">
                            <TigerSpinner/>
                        </div>
                    : (
                        <div style={{height: "23.6rem"}}>
                            <div style={{height: "100%"}}>

                                <img className="cover-image" src={this.state.cover || logo} alt="cover" />
                            </div>
                        </div>
                    )}
                    <Card.Body>
                        <Card.Title>{this.state.profileName}</Card.Title>
                    </Card.Body>
                </Card>
            )
        );
    }
}

export default SingleProfile;

import {getToken} from "../authentication/TokenStorageService";
import GlobalConstants from "../config/GlobalConstants";
import {createFilterQuery} from "./APIUtil";
import {setShopToken} from "../authentication/ShopTokenStorageService";
import {fetchShopToken} from "../authentication/Oauth2Service";

export async function GetResource(url, accept = 'application/json', additionalHeaders = {}, host = GlobalConstants.SPINE_HOST) {

    const token = getToken();
    let errorOccurred = false;
    let resourceNotFound = false;

    return fetch(fixUrl(url, host), {
        method: 'GET',
        headers: {
            ...additionalHeaders,
            'Accept': accept,
            'Authorization': 'Bearer ' + token.access_token,
        }
    })
        .then(function(response) {
            if(!response.ok) {
                if(response.status === 404) {
                    resourceNotFound = true;
                    return response;
                } else {
                    errorOccurred = true;
                }
            }
            return response.json();
        })
        .then(function (response) {
            if(errorOccurred) {
                return {error: true, message: "An error occurred. CODE: " + response.errorCode + " - CAUSE: " + response.info, result: {}}
            } else if(resourceNotFound) {
                return {error: false, message: "Resource not found", result: null}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error, result: {}}
        });
}

export async function GetBlob(url, host = GlobalConstants.SPINE_HOST) {
    var token = getToken();

    return fetch(fixUrl(url, host), {
        method: 'GET',
        headers: {
            Accept: "*/*",
            Authorization: 'Bearer ' + token.access_token
        }
    })
        .then(response => {
            return response.blob().then(myBlob => {
                return URL.createObjectURL(myBlob);
            });
        });
}

export async function GetAllResources(url, host = GlobalConstants.SPINE_HOST){
    //Security check
    var token = getToken();
    let errorOccurred = false;
    let length = 0;

    return fetch(fixUrl(url, host), {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        }
    })
        .then(function(response) {
            if(!response.ok) {
                errorOccurred = true;
            } else {
                length = response.headers.get('Content-Length');
            }
            return response.json();
        })
        .then(function (response) {
            if(errorOccurred) {
                return {error: true, message: "An error occurred. CODE: " + response.errorCode + " - CAUSE: " + response.info, result: {}, length: 0}
            } else {
                return {error: false, message: "", result: response, length: length}
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error, result: [], length: 0}
        });
}

export async function GetResourcesWithPost(url, host = GlobalConstants.SPINE_HOST){
    //Security check
    var token = getToken();
    let errorOccurred = false;
    let length = 0;

    return fetch(fixUrl(url, host), {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        }
    })
        .then(function(response) {
            if(!response.ok) {
                errorOccurred = true;
            } else {
                length = response.headers.get('Content-Length');
            }
            return response.json();
        })
        .then(function (response) {
            if(errorOccurred) {
                return {error: true, message: "An error occurred. CODE: " + response.errorCode + " - CAUSE: " + response.info, result: {}, length: 0}
            } else {
                return {error: false, message: "", result: response, length: length}
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error, result: [], length: 0}
        });
}

export async function PostWithoutResponse(url, host = GlobalConstants.SPINE_HOST){
    //Security check
    var token = getToken();
    let errorOccurred = false;
    let length = 0;

    return fetch(fixUrl(url, host), {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        }
    })
        .then(function(response) {
            if(!response.ok) {
                errorOccurred = true;
            } else {
                length = response.headers.get('Content-Length');
            }
            return {};
        })
        .then(function (response) {
            if(errorOccurred) {
                return {error: true, message: "An error occurred. CODE: " + response.errorCode + " - CAUSE: " + response.info, result: {}, length: 0}
            } else {
                return {error: false, message: "", result: response, length: length}
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error, result: [], length: 0}
        });
}

export async function GetFilteredResources(url, filter, accept = 'application/json', host = GlobalConstants.SPINE_HOST){
    //Security check
    var token = getToken();
    let errorOccurred = false;
    let length = 0;

    let headers = token.access_token != null ?
        {
            'Accept': accept,
            'Authorization': 'Bearer ' + token.access_token,
        }
        :
        {
            'Accept': accept,
        }

    return fetch(fixUrl(url, host) + createFilterQuery(filter), {
        method: 'GET',
        headers: headers
    })
        .then(function(response) {
            if(!response.ok) {
                errorOccurred = true;
                return response.json();
            } else {
                length = response.headers.get('X-Total-Count');
                return response.json();
            }
        })
        .then(function (response) {
            if(errorOccurred) {
                return {error: true, message: response.status + " - CODE: " + response.errorCode + " - CAUSE: " + response.info, result: {}, length: 0}
            } else {
                return {error: false, message: "", result: response, length: length}
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error, result: [], length: 0}
        });
}

export async function GetUnauthorizedResources(url, filter, accept = 'application/json', host = GlobalConstants.SPINE_HOST){
    //Security check
    let errorOccurred = false;
    let length = 0;

    let headers =
        {
            'Accept': accept,
        }

    return fetch(fixUrl(url, host) + createFilterQuery(filter), {
        method: 'GET',
        headers: headers
    })
        .then(function(response) {
            if(!response.ok) {
                errorOccurred = true;
                return response;
            } else {
                length = response.headers.get('X-Total-Count');
            }
            return response.json();
        })
        .then(function (response) {
            if(errorOccurred) {
                return {error: true, message: "An error occurred. STATUS: " + response.status + " - URL: " + response.url + " - INFO: " + response.statusText, result: response.status, length: 0}
            } else {
                return {error: false, message: "", result: response, length: length}
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error, result: [], length: 0}
        });
}

export async function CreateResource(url, resource, dtoIsReturned = true, host = GlobalConstants.SPINE_HOST, token = null){
    //Security check
    if (!token) {
        token = getToken();
    }
    let errorOccurred = false;

    const headers = (token != null && token.access_token != null) ? {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token.access_token,
    } : {
        'Content-Type': 'application/json',
    }

    return fetch(fixUrl(url, host), {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(resource),
    })
        .then(function (response){
            if(!response.ok) {
                errorOccurred = true;
                return response.json();
            } else {
                //Result ok
                if(dtoIsReturned) {
                    //In case of an ResponseEntity with a dto
                    return response.json();
                } else {
                    //Void
                    return response;
                }
            }
        })
        .then(function (response){
            if(errorOccurred) {
                return {error: true, message: "An error occurred. CODE: " + response.errorCode + " - CAUSE: " + response.info, result: response}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function (error){
            return {error: true, message: "An unknown error occurred: " + error, result: {}}
        });
}


export async function PostFormData(url, resource, dtoIsReturned = true, host = GlobalConstants.SPINE_HOST) {
    //Security check
    var token = getToken();
    let errorOccurred = false;

    //Create the form data
    const formData = new FormData();
    // if array
    if (resource instanceof Array) {
        for (let i = 0; i < resource.length; i++) {
            const item = resource[i];
            let keys = Object.keys(item);
            for (let i = 0; i < keys.length; i++) {
                formData.append(keys[i], item[keys[i]]);
            }
        }
    }
    else {
        let keys = Object.keys(resource);
        for (let i = 0; i < keys.length; i++) {
            formData.append(keys[i], resource[keys[i]]);
        }
    }

    return fetch(fixUrl(url, host), {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token.access_token,
        },
        body: formData,
    })
        .then(function (response){
            if(!response.ok) {
                errorOccurred = true;
                return response.json();
            } else {
                //Result ok
                if(dtoIsReturned) {
                    //In case of an ResponseEntity with a dto
                    return response.json();
                } else {
                    //Void
                    return response;
                }
            }
        })
        .then(function (response){
            if(errorOccurred) {
                return {error: true, message: "An error occurred. CODE: " + response.errorCode + " - CAUSE: " + response.info, result: {}};
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function (error){
            return {error: true, message: "An unknown error occurred: " + error, result: {}}
        });
}

export async function UpdateResource(url, resource, dtoIsReturned = true, host = GlobalConstants.SPINE_HOST) {
    //Security check
    var token = getToken();
    let errorOccurred = false;

    return fetch(fixUrl(url, host), {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        },
        body: JSON.stringify(resource),
    })
        .then(function(response) {
            if(!response.ok) {
                errorOccurred = true;
                return response.json();
            } else {
                //Result ok
                if(dtoIsReturned) {
                    //In case of an ResponseEntity with a dto
                    return response.json();
                } else {
                    //Void
                    return response;
                }
            }
        })
        .then(function(response) {
            if(errorOccurred) {
                return {error: true, message: "An error occurred. CODE: " + response.errorCode + " - CAUSE: " + response.info, result: {}}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(async function(error) {
            return {error: true, message: "An unknown error occurred: " + error, result: {}}
        });
}

export async function DeleteResource(url, dtoIsReturned = true, body = null, host = GlobalConstants.SPINE_HOST) {
    //Security check
    var token = getToken();
    let errorOccurred = false;

    return fetch(fixUrl(url, host), {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        },
        body: body ? JSON.stringify(body) : ""
    })
        .then(function(response) {
            if(!response.ok) {
                errorOccurred = true;
                return response.json();
            } else {
                //Result ok
                if(dtoIsReturned) {
                    //In case of an ResponseEntity with a dto
                    return response.json();
                } else {
                    //Void
                    return response;
                }
            }
        })
        .then(function (response) {
            if(errorOccurred) {
                return {error: true, message: "An error occurred. CODE: " + response.errorCode + " - CAUSE: " + response.info, result: {}}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function(error) {
            return {error: true, message: "An unknown error occurred: " + error, result: {}}
        });
}

export async function UploadFile(url, resource, host = GlobalConstants.SPINE_HOST) {
    //Security check
    var token = getToken();
    let errorOccurred = false;

    return fetch(fixUrl(url, host), {
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token.access_token,
        },
        body: resource,
    })
        .then(function(response) {
            if(!response.ok) {
                errorOccurred = true;
            }
            return response.json();
    })
        .then(function(response) {
            if(errorOccurred) {
                return {error: true, message: "An error occurred during upload. CODE: " + response.errorCode + " - CAUSE: "+ response.info, result: {}}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function(error) {
            return {error: true, message: "An unkown error occurred: " + error, result: {}}
        });
}

export async function GetShopResource(url, customerAccept = true, host = GlobalConstants.SPINE_HOST) {
    let token = {}
    let shopToken = await fetchShopToken();

    if(shopToken !== undefined) {
        let expiredAt = new Date();
        expiredAt.setSeconds(expiredAt.getSeconds() + shopToken.expires_in);
        shopToken.expires_at = expiredAt.getTime();

        //Now save the shopToken
        token = setShopToken(shopToken);
    }

    let headers = customerAccept ?
        {
            'Accept': 'application/vnd.tigerbooks.v1+json',
            'Authorization': 'Bearer ' + token.access_token
        } :
        {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.access_token
        }


    return fetch(fixUrl(url, host),{
        method: 'GET',
        headers: headers,
    })
        .then(function (response) {
            if(response.ok) {
                return response.json();
            }
            throw new Error(response.status + " - " + response.statusText);
        })
        .then(function(response) {
            return response;
        })
        .catch(function(error) {
            console.log("Error: " + error.message);
        });
}

export async function SendTeamsNotification(event, orderId, planVariantId, response){
    //Security check
    const token = getToken();

    if (Array.isArray(response)) {
        response = response[0];
    }
    if (response["0"] != null) {
        delete response["0"];
    }

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token.access_token,
    };

    return fetch(GlobalConstants.BILL_HOST + "api/purchases/transactions/send-notification?event=" + event + "&orderId=" + orderId + "&planvariantId=" + planVariantId, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(response)
    })
        .then(function (response){
            return response;
        })
        .then(function (response){
            return response;
        })
        .catch(function (error){
            return error;
        });
}

//HELPERS

// adds GlobalConstants.APP_HOST in front of endpoint if url is not a link
const fixUrl = (url, host) => {
    return url.indexOf("http://") === 0 || url.indexOf("https://") === 0 ? url : host + url;
}

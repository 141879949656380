import React, {Component} from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import './ContractCancellation.scss'
import {Form, Spinner, Table} from "react-bootstrap";
import Strings from "../../../config/Strings";
import {TigerButton} from "../../../global/tiger-button/TigerButton";
import {withToast} from "../../../util/ToastService";
import {
    ConvertDateToReadableString,
    customDatePickerHeader,
    transformStringToReadableDate,
    transformToDatepickerFormat,
    transformToLocalDateTime
} from "../../../util/ConverterUtil";
import de from 'date-fns/locale/de';
import {InfoModal} from "../../../global/ConfirmationModal";
import {CancellationConfirmationDocument} from "./CancellationConfirmationPDF";
import {PDFDownloadLink} from '@react-pdf/renderer';
import GlobalConstants from "../../../config/GlobalConstants";
import {Widget} from '@typeform/embed-react'

class ContractCancellation extends Component {

    constructor(props) {
        super(props);

        registerLocale('de', de);

        this.cancellationTypeOnTime = "ON_TIME";
        this.extraordinaryReason = "EXTRAORDINARY"
        this.productTypeTigerbooks = "TIGERBOOKS";
        this.productTypeSubscription = "TIGERTONES";
        this.productTypeBundle = "BUNDLE";
        this.productTypeTigerticket = "TIGERTICKET";
        this.cancellationDateNext = "NEXT";
        this.cancellationDateSelected = "SELECT";
        this.reasonOptionOther = "OTHER";
        this.platformWeb = "WEB";
        this.platformApple = "APPLE";
        this.platformApp = "GOOGLE";

        this.reasonOptionTooExpensive = "TOO_EXPENSIVE";
        this.reasonOptionNotEnoughtContent = "NOT_ENOUGH_CONTENT_FOR_OLDER_KIDS";
        this.reasonOptionContent = "UNSATISFIED_CONTENT";
        this.reasonOptionTickets = "PREFER_TICKETS";
        this.reasonOptionTechnicalProblems = "TECHNICAL_PROBLEMS";
        this.reasonOptionTigertones = "TIGERTONES";
        this.reasonOptionAvailability = "AVAILABILITY";

        this.state = {
            loading: false,
            submitted: false,
            cancellationDateOption: null,
            reasonOption: null,
            platformOption: this.platformWeb,
            form: {
                submittedAt: null,
                cancellationType: null,
                extraordinaryReason: null,
                productType: null,
                cancellationDate: "",
                email: null,
                reason: null
            },
            confirmation: {},
            errors: {
                email: false,
                productType: false,
                extraordinaryReason: false
            },
            disabled: false,
            processingError: null,
        };
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.accountId !== this.props.accountId) {

        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
    }

    //Submitting
    async submitForm(e) {

        this.setState(prevState => ({...prevState, loading: true}));
        e.preventDefault();

        if (!this.validateForm()) {
            this.setState(prevState => ({...prevState, loading: false}));
            return;
        }

        this.setDefaultsIfEmpty();

        //Make the request
        let errorOccurred = false;
        let form = this.state.form;
        if (form.productType === this.productTypeBundle) {
            form.productType = this.productTypeSubscription;
        }
        let result = await fetch(GlobalConstants.SPINE_HOST + 'api/crm/contracts/cancel', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(form),
        })
            .then(function (response){
                if(!response.ok) {
                    errorOccurred = true;
                }
                return response.json();
            })
            .then(function (response){
                if(errorOccurred) {
                    return {error: true, message: "An error occurred. CODE: " + response.errorCode + " - CAUSE: " + response.info, result: response}
                } else {
                    return {error: false, message: "", result: response}
                }
            })
            .catch(function (error){
                return {error: true, message: "An unknown error occurred: " + error, result: {}}
            });

        /**
         let result = {
            error: false,
            result: {
                id: 1,
                submittedAt: "2022-06-22T08:45:00",
                cancellationType: "EXTRAORDINARY",
                extraordinaryReason: "TEST",
                productType: "TIGERTONES",
                cancellationDate: "2022-06-22T08:45:00",
                contractValidUntil: "2022-06-22T08:00:00",
                origContractValidity: "2022-06-22T08:00:00",
                email: "sebastian.mahncke@tiger.media",
                reason: "Test",
                withdrawn: false,
                billwerkCustomerId: "62a866b1115139d65bbc5479",
                billwerkContractId: "62ab3ebe3b24dd634cff9db2",
                accountId: 1031082564,
                status: "SUBMITTED",
                mailSendStatus: null,
                createdDate: "2022-06-22T09:04:47.956002",
                createdBy: "system",
                lastModifiedDate: "2022-06-22T09:04:48.305774",
                lastModifiedBy: "system"
            }
        }

         let result = {
            error: true,
            result: {
                status: 404,
                code: 1,
                message: ""
            }
        }**/

        if (!result.error) {
            this.setState(prevState => ({...prevState, submitted: true, confirmation: result.result}));
            window.scrollTo(0, 0);
        } else {
            this.handleError(result.result);
        }

        this.setState(prevState => ({...prevState, loading: false}));
    }

    handleError(error) {
        let message = null;
        switch (error.code) {
            case 1:
                message = Strings.CONTRACT_CANCELLATION_CUSTOMER_NOT_FOUND.replace("{email}", this.state.form.email);
                break;
            case 2:
                message = Strings.CONTRACT_CANCELLATION_NO_CONTRACTS_FOUND.replace("{email}", this.state.form.email);
                break;
            case 3:
                message = Strings.CONTRACT_CANCELLATION_NO_ACTIVE_CONTRACT_FOUND.replace("{email}", this.state.form.email);
                break;
            case 6:
                message = Strings.CONTRACT_CANCELLATION_NO_ACTIVE_CONTRACT_FOUND.replace("{email}", this.state.form.email);
                break;
            default:
                message = Strings.CONTRACT_CANCELLATION_UNKNOWN_ERROR;
                break;
        }

        this.setState(prevState => ({...prevState, processingError: message}));
    }

    //Form stuff
    updateForm(field, value, resetError = false) {
        if (resetError) {
            this.setState(prevState => ({
                ...prevState,
                errors: {
                    ...prevState.errors,
                    [field]: false
                },
                form: {
                    ...prevState.form,
                    [field]: value
                },
            }))
        } else {
            this.setState(prevState => ({
                ...prevState,
                form: {
                    ...prevState.form,
                    [field]: value
                }
            }))
        }
    }

    setDefaultsIfEmpty() {
        let form = this.state.form;

        const date = new Date().toString();

        //Created date -> Set to today automatically
        form.submittedAt = transformToLocalDateTime(date);

        //Cancellation Type
        if (form.cancellationType == null) {
            form.cancellationType = this.cancellationTypeOnTime;
            form.extraordinaryReason = null;
        }

        //Cancellation Date
        if (form.cancellationDate == null || form.cancellationDate === "") {
            form.cancellationDate = transformToLocalDateTime(date);
        }

        //Reason
        if (this.state.reasonOption !== this.reasonOptionOther) {
            form.reason = this.state.reasonOption;
        }

        this.setState(prevState => ({...prevState, form: form}));
    }

    validateForm() {
        let errors = {email: false, productType: false, extraordinaryReason: false};

        //Cancellation Type -> Must not be null or blank
        if (this.state.form.cancellationType === this.extraordinaryReason) {
            if (this.state.form.extraordinaryReason === null || this.state.form.extraordinaryReason === "") {
                errors.extraordinaryReason = true;
            }
        }

        //Product Type -> Must not be null
        if (this.state.form.productType === null) {
            errors.productType = true;
        }

        //Email -> Must not be null, blank and a valid email address
        if (this.state.form.email === null || this.state.form.email === "") {
            errors.email = true;
        } else if (this.state.form.email.indexOf("@") < 0) {
            errors.email = true;
        }

        this.setState(prevState => ({...prevState, errors: errors}));

        return Object.keys(errors).map(i => errors[i]).indexOf(true) < 0;
    }

    //Rendering
    render() {
        return (
            <div className="cancellation-body">
                {!this.state.submitted ?
                    <>
                        <div className="cancellation-headline">{Strings.CONTRACT_CANCELLATION_HEADLINE}</div>
                        <div className="cancellation-info"
                             dangerouslySetInnerHTML={{__html: Strings.CONTRACT_CANCELLATION_INFO_1}}/>
                        <Form className="text-left cancellation-form" onSubmit={(e) => this.submitForm(e)}>
                            {/* Product Type */}
                            {this.state.platformOption !== this.platformApp &&
                                    <>


                                        {/* Product Type */}
                                        <Form.Group className="mb-3" controlId="contractCancellationProductType"
                                                    style={{marginTop: "40px"}}>
                                            <Form.Label
                                                    className="auth-label">{Strings.CONTRACT_CANCELLATION_FORM_PRODUCT_TYPE + "*"}</Form.Label>
                                            <Form.Control
                                                    as="select"
                                                    value={this.state.form.productType}
                                                    placeholder={Strings.CONTRACT_CANCELLATION_FORM_SELECT_PLACEHOLDER}
                                                    isInvalid={this.state.errors.productType}
                                                    onChange={e => {
                                                        this.updateForm('productType', e.target.value, true);
                                                        if (e.target.value === this.productTypeTigerticket) {
                                                            this.setState(prevState => ({...prevState, disabled: true}));
                                                        } else {
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                disabled: this.state.platformOption !== this.platformWeb
                                                            }));
                                                        }
                                                    }}>
                                                <option value="" disabled
                                                        selected>{Strings.CONTRACT_CANCELLATION_FORM_SELECT_PLACEHOLDER}</option>
                                                <option
                                                        value={this.productTypeSubscription}>{Strings.CONTRACT_CANCELLATION_FORM_PRODUCT_TYPE_OPTION_SUBSCRIPTION}</option>
                                                <option
                                                        value={this.productTypeBundle}>{Strings.CONTRACT_CANCELLATION_FORM_PRODUCT_TYPE_OPTION_BUNDLE}</option>
                                                <option
                                                        value={this.productTypeTigerticket}>{Strings.CONTRACT_CANCELLATION_FORM_PRODUCT_TYPE_OPTION_TICKET}</option>
                                            </Form.Control>
                                            {this.state.form.productType === this.productTypeTigerticket &&
                                                    <Form.Text style={{
                                                        fontWeight: "bold",
                                                        color: "#ce023e"
                                                    }}>{Strings.CONTRACT_CANCELLATION_PRODUCT_TICKET_HINT}</Form.Text>
                                            }
                                            <Form.Control.Feedback type="invalid">
                                                {Strings.CONTRACT_CANCELLATION_ERROR_PRODUCT_TYPE}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </>
                            }

                            {!this.state.disabled &&
                            <>
                                {/* Type */}
                                <Form.Group className="mb-3" controlId="contractCancellationType">
                                    <Form.Label
                                            className="auth-label">{Strings.CONTRACT_CANCELLATION_FORM_TYPE}</Form.Label>
                                    <div className="form-group-info"
                                         dangerouslySetInnerHTML={{__html: Strings.CONTRACT_CANCELLATION_INFO_TYPE}}/>
                                    <Form.Control
                                            as="select"
                                            value={this.state.form.cancellationType}
                                            disabled={this.state.disabled}
                                            onChange={e => this.updateForm('cancellationType', e.target.value)}>
                                        <option value="" disabled
                                                selected>{Strings.CONTRACT_CANCELLATION_FORM_SELECT_PLACEHOLDER}</option>
                                        <option
                                                value={this.cancellationTypeOnTime}>{Strings.CONTRACT_CANCELLATION_FORM_TYPE_OPTION_ON_TIME}</option>
                                        <option
                                                value={this.extraordinaryReason}>{Strings.CONTRACT_CANCELLATION_FORM_TYPE_OPTION_REASON}</option>
                                    </Form.Control>
                                    {this.state.form.cancellationType === this.extraordinaryReason &&
                                            <>
                                                <br/>
                                                <Form.Control
                                                        as="textarea"
                                                        rows={5}
                                                        isInvalid={this.state.errors.extraordinaryReason}
                                                        value={this.state.form.extraordinaryReason}
                                                        placeholder={Strings.CONTRACT_CANCELLATION_ERROR_REASON}
                                                        disabled={this.state.disabled}
                                                        onChange={e => this.updateForm('extraordinaryReason', e.target.value, true)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {Strings.CONTRACT_CANCELLATION_ERROR_REASON}
                                                </Form.Control.Feedback>
                                            </>
                                    }
                                </Form.Group>

                                <br/>

                            </>
                            }

                            {/* Platform */}
                            {this.state.form.productType !== this.productTypeTigerticket &&
                            <Form.Group className="mb-3" controlId="contractCancellationPlatform">
                                <Form.Label
                                    className="auth-label">{Strings.CONTRACT_CANCELLATION_FORM_PLATFORM}</Form.Label>
                                <div style={{width: "100%", marginBottom: "30px"}}>
                                    <div style={{float: "left", width: "49%"}}>
                                        <div className={"round"}>
                                            <input name="optionWeb" id="optionWeb" type="checkbox"
                                                   value={this.state.platformOption === this.platformWeb}
                                                   checked={this.state.platformOption === this.platformWeb}
                                                   onChange={() => this.setState(prevState => ({
                                                       ...prevState,
                                                       platformOption: this.platformWeb,
                                                       disabled: this.state.form.productType === this.productTypeTigerticket
                                                   }))}/>
                                            <label htmlFor="optionWeb"></label>
                                        </div>
                                        <label className="roundlabel registerCheckboxLabel"
                                               htmlFor="optionWeb">{Strings.CONTRACT_CANCELLATION_PLATFORM_WEB}</label>
                                    </div>
                                    <div style={{float: "left", width: "49%"}}>
                                        <div className={"round"}>
                                            <input name="optionApp" id="optionApp" type="checkbox"
                                                   value={this.state.platformOption === this.platformApp}
                                                   checked={this.state.platformOption === this.platformApp}
                                                   onChange={() => this.setState(prevState => ({
                                                       ...prevState,
                                                       platformOption: this.platformApp,
                                                       disabled: true
                                                   }))}
                                            />
                                            <label htmlFor="optionApp"></label>
                                        </div>
                                        <label className="roundlabel registerCheckboxLabel"
                                               htmlFor="optionApp">{Strings.CONTRACT_CANCELLATION_PLATFORM_APP}</label>
                                    </div>
                                </div>
                                {this.state.platformOption !== this.platformWeb &&
                                <Form.Text style={{color: "#ce023e"}}>
                                    <div
                                        dangerouslySetInnerHTML={{__html: Strings.CONTRACT_CANCELLATION_PLATFORM_HINT}}/>
                                </Form.Text>
                                }
                            </Form.Group>
                            }
                            <br/>

                            {!this.state.disabled &&
                            <>
                                <br/>

                                {/* Cancellation Date */}
                                <Form.Group className="mb-3" controlId="contractCancellationCancellationDate">
                                    <Form.Label
                                        className="auth-label">{Strings.CONTRACT_CANCELLATION_FORM_CANCELLATION_DATE}</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={this.state.cancellationDateOption}
                                        placeholder={Strings.CONTRACT_CANCELLATION_FORM_SELECT_PLACEHOLDER}
                                        disabled={this.state.disabled}
                                        onChange={e => {
                                            this.setState(prevState => ({
                                                ...prevState,
                                                cancellationDateOption: e.target.value
                                            }));
                                            this.updateForm('cancellationDate', e.target.value === this.cancellationDateSelected ? null : transformToLocalDateTime(new Date().toString()));
                                        }}>
                                        <option value="" disabled
                                                selected>{Strings.CONTRACT_CANCELLATION_FORM_SELECT_PLACEHOLDER}</option>
                                        <option
                                            value={this.cancellationDateNext}>{Strings.CONTRACT_CANCELLATION_FORM_DATE_OPTION_NEXT}</option>
                                        <option
                                            value={this.cancellationDateSelected}>{Strings.CONTRACT_CANCELLATION_FORM_DATE_OPTION_SELECTED}</option>
                                    </Form.Control>
                                    {this.state.cancellationDateOption === this.cancellationDateSelected &&
                                    <>
                                        <br/>
                                        <DatePicker
                                            wrapperClassName="datepicker"
                                            locale="de"
                                            minDate={new Date()}
                                            todayButton={Strings.CONTRACT_CANCELLATION_DATE_PICKER_TODAY}
                                            dateFormat={"dd.MM.yyyy"}
                                            placeholderText={Strings.CONTRACT_CANCELLATION_FORM_SELECT_PLACEHOLDER}
                                            withPortal
                                            renderCustomHeader={({
                                                                     date,
                                                                     changeYear,
                                                                     changeMonth,
                                                                     decreaseMonth,
                                                                     increaseMonth,
                                                                     prevMonthButtonDisabled,
                                                                     nextMonthButtonDisabled
                                                                 }) => customDatePickerHeader(new Date(date), changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled)}
                                            selected={transformToDatepickerFormat(this.state.form.cancellationDate)}
                                            onChange={(date) => this.updateForm('cancellationDate', transformToLocalDateTime(date.toString()))}/>
                                    </>
                                    }
                                </Form.Group>

                                <br/>

                                {/* Email */}
                                <Form.Group className="mb-3" controlId="contractCancellationEmail">
                                    <Form.Label
                                        className="auth-label">{Strings.CONTRACT_CANCELLATION_FORM_EMAIL + "*"}</Form.Label>
                                    <Form.Control
                                        value={this.state.form.email}
                                        isInvalid={this.state.errors.email}
                                        disabled={this.state.disabled}
                                        onChange={e => this.updateForm('email', e.target.value, true)}
                                    />
                                    <Form.Text>{Strings.CONTRACT_CANCELLATION_EMAIL_HINT}</Form.Text>
                                    <Form.Control.Feedback type="invalid">
                                        {Strings.CONTRACT_CANCELLATION_ERROR_EMAIL}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <br/>

                                {/* Created date */}
                                <Form.Group className="mb-3" controlId="contractCancellationCreatedDate">
                                    <Form.Label
                                        className="auth-label">{Strings.CONTRACT_CANCELLATION_FORM_CREATED_DATE}</Form.Label>
                                    <Form.Control
                                        type="datetime"
                                        required
                                        disabled={true}
                                        value={transformStringToReadableDate(this.state.form.submittedAt, true)}
                                        placeholder={Strings.CONTRACT_CANCELLATION_FORM_CREATED_DATE_PLACEHOLDER}
                                    />
                                </Form.Group>

                                <br/>

                                <TigerButton className="cancellation-button" variant="red"
                                             disabled={this.state.loading || this.state.disabled}>
                                    {this.state.loading ?
                                        <Spinner animation="border"/>
                                        :
                                        Strings.CONTRACT_CANCELLATION_SUBMIT}
                                </TigerButton>
                            </>
                            }
                        </Form>
                        <div className="cancellation-info" style={{marginTop: "100px", fontWeight: "bold"}}
                             dangerouslySetInnerHTML={{__html: Strings.CONTRACT_CANCELLATION_INFO_3}}/>

                        <div className="cancellation-info">
                            <div style={{margin: "10px auto"}}>
                                <a href={GlobalConstants.URL_TICKETS} style={{textDecoration: "none"}}>
                                    <TigerButton className="cancellation-button" variant="red" type="button">
                                        {Strings.CONTRACT_CANCELLATION_TICKET_LINK}
                                    </TigerButton>
                                </a>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        {this.state.confirmation.id != null ?
                            <div id="cancellationConfirmation">
                                <div className="cancellation-headline">{Strings.CANCELLATION_CONFIRMED_HEADLINE}</div>
                                <div className="cancellation-info"
                                     dangerouslySetInnerHTML={{
                                         __html: Strings.CANCELLATION_CONFIRMED_INFO
                                             .replace("{dateTime}", ConvertDateToReadableString(this.state.confirmation.submittedAt, true) + " " + Strings.GLOBAL_TIME)
                                             .replace("{url}", GlobalConstants.URL_CANCEL_CONTRACTS)
                                             .replace("{supportUrl}", "https://helpcenter.tiger.media/hc/de/requests/new")
                                     }}/>
                                <div className="cancellation-info">{Strings.CANCELLATION_CONFIRMED_INFO_2}</div>
                                <Table className="confirmation-table" striped responsive>
                                    <tbody>
                                    <tr>
                                        <td style={{fontWeight: "bold"}}>{Strings.CONTRACT_CANCELLATION_FORM_TYPE}</td>
                                        <td>{this.state.confirmation.cancellationType === this.cancellationTypeOnTime ? Strings.CONTRACT_CANCELLATION_FORM_TYPE_OPTION_ON_TIME : Strings.CONTRACT_CANCELLATION_FORM_TYPE_OPTION_REASON}</td>
                                    </tr>
                                    {this.state.confirmation.extraordinaryReason != null &&
                                    <tr>
                                        <td style={{fontWeight: "bold"}}>{Strings.CONTRACT_CANCELLATION_FORM_EXTRAORDINARY_REASON}</td>
                                        <td>{this.state.confirmation.extraordinaryReason}</td>
                                    </tr>
                                    }
                                    <tr>
                                        <td style={{fontWeight: "bold"}}>{Strings.CONTRACT_CANCELLATION_FORM_PRODUCT_TYPE}</td>
                                        <td>{this.state.confirmation.productType.toLowerCase()}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontWeight: "bold"}}>{Strings.CONTRACT_CANCELLATION_FORM_CANCELLATION_DATE}</td>
                                        <td>{this.state.cancellationDateOption === this.cancellationDateSelected ? Strings.CANCELLATION_CONFIRMED_CANCELLATION_DATE_TYPE + ConvertDateToReadableString(this.state.confirmation.cancellationDate, false) : Strings.CONTRACT_CANCELLATION_FORM_DATE_OPTION_NEXT}</td>
                                    </tr>
                                    {this.state.confirmation.contractValidUntil != null &&
                                    <tr>
                                        <td style={{fontWeight: "bold"}}>{Strings.CONTRACT_CANCELLATION_END_OF_CONTRACT}</td>
                                        <td>{ConvertDateToReadableString(this.state.confirmation.contractValidUntil, true) + " " + Strings.GLOBAL_TIME}</td>
                                    </tr>
                                    }
                                    <tr>
                                        <td style={{fontWeight: "bold"}}>{Strings.CONTRACT_CANCELLATION_FORM_EMAIL}</td>
                                        <td>{this.state.confirmation.email}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontWeight: "bold"}}>{Strings.CONTRACT_CANCELLATION_FORM_CREATED_DATE}</td>
                                        <td>{ConvertDateToReadableString(this.state.confirmation.submittedAt, true) + " " + Strings.GLOBAL_TIME}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                                <div className="cancellation-info">
                                    <div style={{width: "50%", margin: "auto"}}>
                                        <PDFDownloadLink style={{textDecoration: 'none'}}
                                                         fileName={'kuendingsbestaetigung_' + this.state.confirmation.productType.toLowerCase()}
                                                         document={<CancellationConfirmationDocument
                                                             confirmation={this.state.confirmation}
                                                             cancellationDateOption={this.state.cancellationDateOption}/>}>
                                            <TigerButton className="w-100" variant="red"
                                                         type="button">{Strings.CANCELLATION_CONFIRMED_DOWNLOAD_PDF}</TigerButton>
                                        </PDFDownloadLink>
                                    </div>
                                </div>
                                <br />
                                <div className="cancellation-headline" style={{fontSize: "16pt"}}>{Strings.CANCELLATION_CONFIRMED_SURVEY_HEADLINE}</div>
                                <div className="cancellation-survey">
                                    <Widget id="F0W4xZpQ" className="cancellation-survey" hideHeaders={false} hideFooter={false}/>
                                </div>

                            </div>
                            :
                            <></>
                        }
                    </>
                }

                <InfoModal
                    title={Strings.CONTRACT_CANCELLATION_MODAL_HEADER}
                    text={this.state.processingError}
                    show={this.state.processingError != null}
                    onHide={() => this.setState(prevState => ({...prevState, processingError: null}))}
                />

            </div>
        )
    }
}

export default withToast(ContractCancellation);

export function GetCancellationReasonName(internalName) {
    const reasonOptionTooExpensive = "TOO_EXPENSIVE";
    const reasonOptionNotEnoughtContent = "NOT_ENOUGH_CONTENT_FOR_OLDER_KIDS";
    const reasonOptionContent = "UNSATISFIED_CONTENT";
    const reasonOptionTickets = "PREFER_TICKETS";
    const reasonOptionTechnicalProblems = "TECHNICAL_PROBLEMS";
    const reasonOptionTigertones = "TIGERTONES";
    const reasonOptionAvailability = "AVAILABILITY";

    if (internalName == null || internalName === "") {
        return "";
    } else if (internalName === reasonOptionTooExpensive) {
        return Strings.CONTRACT_CANCELLATION_REASON_OPTION_TOO_EXPENSIVE;
    } else if (internalName === reasonOptionNotEnoughtContent) {
        return Strings.CONTRACT_CANCELLATION_REASON_OPTION_NOT_ENOUGH_CONTENT;
    } else if (internalName === reasonOptionContent) {
        return Strings.CONTRACT_CANCELLATION_REASON_OPTION_CONTENT;
    } else if (internalName === reasonOptionTickets) {
        return Strings.CONTRACT_CANCELLATION_REASON_OPTION_TICKETS;
    } else if (internalName === reasonOptionTechnicalProblems) {
        return Strings.CONTRACT_CANCELLATION_REASON_OPTION_TECHNICAL_PROBLEMS;
    } else if (internalName === reasonOptionTigertones) {
        return Strings.CONTRACT_CANCELLATION_REASON_OPTION_TIGERTONES;
    } else if (internalName === reasonOptionAvailability) {
        return Strings.CONTRACT_CANCELLATION_REASON_OPTION_AVAILABILITY;
    } else {
        return internalName;
    }
}
import "./Authentication.css";
import {useState} from "react";
import {sendPasswordReset} from "./Oauth2Service";
import Strings from "../config/Strings";
import {Form, Spinner} from "react-bootstrap";
import { useHistory } from "react-router";
import GlobalConstants from "../config/GlobalConstants";
import { authCheck } from "../util/authCheck";
import { useEffect } from "react";
import {Link} from "react-router-dom";
import { withToast } from "../util/ToastService";
import { TigerButton } from "../global/tiger-button/TigerButton";

function ForgotPassword(props) {
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [resetError, setResetError] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(false);

    const {addToast} = props;

    const history = useHistory();

    window.scrollTo(0, 0);

    useEffect(() => {
        authCheck() && history.push("/" + GlobalConstants.APP_PATH + "dashboard");
    }, [history]);

    const setField = (field, value, isValid) => {
        setForm({
          ...form,
          [field]: value
        });
        
        if (!isValid) {
            setErrors({
                ...errors,
                [field]: true
            });
        }
        else {
            setErrors({
                ...errors,
                [field]: false
            });
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setSubmitDisable(true);
        setResetError(false);

        let hasErrors = !Object.values(errors).every(x => x === false);

        if (!hasErrors) {
            setSubmitted(true);

            const {email} = form;
            let reset = await sendPasswordReset({email});

            if (reset) {
                setResetError(false);
                history.push("/" + GlobalConstants.APP_PATH + "login");
                addToast(Strings.TOAST_PASSWORD_RECOVERY, {
                    autoDismiss: true,
                    appearance: 'success',
                    placement: 'bottom-center'
                });
            }
            else {
                setResetError(true);
                setSubmitDisable(false);
            }
        }
    }

    return(
        <>
            <div className="register-form">
                <p style={{fontSize: "50px", fontWeight: "bold"}}>{Strings.FORGOT_PASSWORD_TITLE}</p>
                <p style={{fontSize: "20px"}}>{Strings.FORGOT_PASSWORD_INFO}</p>
                <br />
                <Form className="text-left" onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="loginFormEmail">
                        <Form.Label>{Strings.FORGOT_PASSWORD_EMAIL}</Form.Label>
                        <Form.Control 
                            type="email" 
                            required 
                            isInvalid={ errors.email }
                            onChange={ e => setField('email', e.target.value, e.target.checkValidity()) }
                        />
                        
                        <Form.Control.Feedback type="invalid">
                            {Strings.FORGOT_PASSWORD_ERROR_INVALID_EMAIL}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <p className="text-danger" style={{fontSize: "20px"}} hidden={!(resetError && submitted)}>
                        {Strings.FORGOT_PASSWORD_ERROR}
                    </p>

                    <TigerButton className="w-100" disabled={submitDisable} variant="red">
                        {(submitDisable && submitted) ?
                            <Spinner animation="border" />
                            : Strings.FORGOT_PASSWORD_SUBMIT}
                    </TigerButton>
                </Form>
                <p style={{marginTop: "30px", fontSize: "20px"}}>
                    <Link to={"/" + GlobalConstants.APP_PATH + "login"} style={{fontWeight: "bold", color: "#333", textDecoration: "underline"}}>
                        {Strings.FORGOT_PASSWORD_LOGIN_LINK}
                    </Link>
                </p>
            </div>
        </>
    )
}

export default withToast(ForgotPassword);
import React, {useEffect, useState} from "react";
import './../Purchase.scss';
import {useHistory} from "react-router";
import PurchaseStatus from "../PurchaseStatus";
import Strings from "../../../../config/Strings";
import GlobalConstants from "../../../../config/GlobalConstants";
import {authCheck} from "../../../../util/authCheck";
import debit from "../../../../../assets/images/maestro.png";
import {TigerButton} from "../../../../global/tiger-button/TigerButton";
import {Col, Form} from "react-bootstrap";
import {ConfirmationModal} from "../../../../global/ConfirmationModal";
import cardValidator from "card-validator";
import IBAN from "iban";

export default function TarifPayment() {

    const [purchaseData, setPurchaseData] = useState(JSON.parse(sessionStorage.getItem('ls.purchase')));
    const [paymentMethod, setPaymentMethod] = useState(purchaseData != null ? purchaseData.paymentMethod : null);
    const [showLoginHint, setShowLoginHint] = useState(false);
    const [debitForm, setDebitForm] = useState((purchaseData != null && purchaseData.paymentData != null) ?
        purchaseData.paymentData : {accountHolder: null, iban: null, bic: null, sepaAccepted: false});
    const [creditcardForm, setCreditcardForm] = useState((purchaseData != null && purchaseData.paymentData != null) ?
        purchaseData.paymentData : {cardHolder: null, cardNumber: null, expiryMonth: null, expiryYear: null, verificationNumber: null});
    const [formIsValid, setFormIsValid] = useState(false);
    const [debitFormError, setDebitFormError] = useState({accountHolder: null, iban: null, bic: null, sepaAccepted: null});
    const [ccFormError, setCcFormError] = useState({cardHolder: null, cardNumber: null, expiryMonth: null, expiryYear: null, verificationNumber: null});

    const history = useHistory();

    document.title = Strings.PURCHASE_SUBSCRIPTION_BROWSER_TAB;

    useEffect(() => {
        //Routing
        if (purchaseData == null) {
            //No purchase data available -> Return to step 1
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif");
        } else if (!authCheck()) {
            //Not authenticated -> Return to step 1 (but with existing purchase data)
            purchaseData.maxStep = 1;
            sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            if (purchaseData.tarif != null) {
                history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif?tarif=" + purchaseData.tarif);
            } else {
                history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif");
            }
        } else if (purchaseData.tarif == null) {
            //No tarif selected -> Return to step 2
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            history.push("/" + GlobalConstants.APP_PATH + "purchase/select");
        }
    }, [history, purchaseData]);

    const submitDebitChannel = (e) => {
        e.preventDefault();

        let secrets = {
            pseudocardpan: null,
            iban: null,
            bic: null,
            owner: null
        };

        purchaseData.payment = {
            channel: paymentMethod,
            channelSecrets: secrets
        };
        purchaseData.paymentData = debitForm;
        purchaseData.paymentMethod = paymentMethod;

        setPurchaseData(purchaseData);
        sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/address");
    }

    const submitCreditCardChannel = (e) => {
        e.preventDefault();

        let secrets = {
            pseudocardpan: null,
            iban: null,
            bic: null,
            owner: null
        };

        purchaseData.payment = {
            channel: paymentMethod,
            channelSecrets: secrets
        };
        purchaseData.paymentData = creditcardForm;
        purchaseData.paymentMethod = paymentMethod;

        setPurchaseData(purchaseData);
        sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/address");
    }

    const updateDebitForm = (field, value) => {
        setDebitForm({
            ...debitForm,
            [field]: value
        });
        let formIsValid = false;
        if (field === 'accountHolder') {
            formIsValid = debitForm.bic != null && debitForm.iban != null && value != null && debitForm.sepaAccepted;
        } else if (field === 'iban'){
            formIsValid = debitForm.bic != null && debitForm.accountHolder != null && value != null && debitForm.sepaAccepted;
        } else if (field === 'bic') {
            formIsValid = debitForm.accountHolder != null && debitForm.iban != null && value != null && debitForm.sepaAccepted;
        } else if (field === 'sepaAccepted') {
            formIsValid = debitForm.iban != null && debitForm.accountHolder != null && debitForm.bic != null && value;
        }
        setFormIsValid(formIsValid);
    }

    const validateDebitForm = (field, value) => {
        if (field === 'accountHolder') {
            if (value == null || value.trim().length === 0) {
                setDebitFormError({...debitFormError, accountHolder: Strings.PURCHASE_FORM_FIELD_REQUIRED});
                setFormIsValid(false);
                return;
            }
        } else if (field === 'iban') {
            if (value == null || value.trim().length === 0) {
                setDebitFormError({...debitFormError, iban: Strings.PURCHASE_FORM_FIELD_REQUIRED});
                setFormIsValid(false);
                return;
            } else if (!IBAN.isValid(value)) {
                setDebitFormError({...debitFormError, iban: Strings.PURCHASE_FORM_DEBIT_IBAN_INVALID});
                setFormIsValid(false);
                return;
            }
        } else if (field === 'sepaAccepted') {
            if (value === false) {
                setDebitFormError({...debitFormError, sepaAccepted: Strings.PURCHASE_FORM_FIELD_REQUIRED});
                setFormIsValid(false)   ;
                return;
            }
        }
        setFormIsValid(true);
    }

    const updateCCForm = (field, value) => {
        setCreditcardForm({
            ...creditcardForm,
            [field]: value
        });
        setCcFormError({
            ...ccFormError,
            [field]: null,
            expiryYear: (field === 'expiryYear' || field === 'expiryMonth') ? null : ccFormError.expiryYear,
            expiryMonth: (field === 'expiryYear' || field === 'expiryMonth') ? null : ccFormError.expiryMonth,
        });
        let formIsValid = false;
        if (field === 'cardHolder') {
            formIsValid = value != null && creditcardForm.cardNumber != null && creditcardForm.expiryMonth != null && creditcardForm.expiryYear != null && creditcardForm.verificationNumber != null;
        } else if (field === 'cardNumber'){
            formIsValid = value != null && creditcardForm.cardHolder != null && creditcardForm.expiryMonth != null && creditcardForm.expiryYear != null && creditcardForm.verificationNumber != null;
        } else if (field === 'expiryMonth') {
            formIsValid = value != null && creditcardForm.cardHolder != null && creditcardForm.cardNumber != null && creditcardForm.expiryMonth != null && creditcardForm.verificationNumber != null;
        } else if (field === 'expiryYear') {
            formIsValid = value != null && creditcardForm.cardHolder != null && creditcardForm.cardNumber != null && creditcardForm.expiryMonth != null && creditcardForm.verificationNumber != null;
        } else if (field === 'verificationNumber') {
            formIsValid = value != null && creditcardForm.cardHolder != null && creditcardForm.cardNumber != null && creditcardForm.expiryMonth != null && creditcardForm.expiryYear != null;
        }
        setFormIsValid(formIsValid);
    }

    const validateCCForm = (field, value) => {
        if (field === 'cardHolder') {
            if (value == null || value.trim().length === 0) {
                setCcFormError({...ccFormError, cardHolder: Strings.PURCHASE_FORM_FIELD_REQUIRED});
                setFormIsValid(false);
            }
        } else if (field === 'cardNumber') {
            if (value == null || value.trim().length === 0) {
                setCcFormError({...ccFormError, cardNumber: Strings.PURCHASE_FORM_FIELD_REQUIRED});
                setFormIsValid(false);
            } else if (!cardValidator.number(value).isValid) {
                setCcFormError({...ccFormError, cardNumber: Strings.PURCHASE_FORM_CC_NUMBER_INVALID});
                setFormIsValid(false);
            }
        } else if (field === 'expiryMonth') {
            if (value == null || value.trim().length === 0) {
                setCcFormError({...ccFormError, expiryMonth: Strings.PURCHASE_FORM_FIELD_REQUIRED});
                setFormIsValid(false);
            } else if (!cardValidator.expirationMonth(value).isValid) {
                setCcFormError({...ccFormError, expiryMonth: Strings.PURCHASE_FORM_CC_EXPIRRY_MONTH_INVALID});
                setFormIsValid(false);
            } else if (creditcardForm.expiryYear != null && creditcardForm.expiryYear.trim().length > 0) {
                const currentMonth = new Date().getMonth() + 1;
                const currentYear = new Date().getFullYear();
                if (Number(creditcardForm.expiryYear) <= currentYear && Number(value) < currentMonth) {
                    setCcFormError({...ccFormError, expiryMonth: Strings.PURCHASE_FORM_CC_CARD_EXPIRED});
                    setFormIsValid(false);
                }
            }
        } else if (field === 'expiryYear') {
            if (value == null || value.trim().length === 0) {
                setCcFormError({...ccFormError, expiryYear: Strings.PURCHASE_FORM_FIELD_REQUIRED});
                setFormIsValid(false);
            } else if (!cardValidator.expirationYear(value).isValid) {
                setCcFormError({...ccFormError, expiryYear: Strings.PURCHASE_FORM_CC_EXPIRY_YEAR_INVALID});
                setFormIsValid(false);
            } else if (creditcardForm.expiryMonth != null && creditcardForm.expiryMonth.trim().length > 0) {
                const currentMonth = new Date().getMonth() + 1;
                const currentYear = new Date().getFullYear();
                if (Number(creditcardForm.expiryMonth) <= currentMonth && Number(value) < currentYear) {
                    setCcFormError({...ccFormError, expiryYear: Strings.PURCHASE_FORM_CC_CARD_EXPIRED});
                    setFormIsValid(false);
                }
            }
        } else if (field === 'verificationNumber') {
            if (value == null || value.trim().length === 0) {
                setCcFormError({...ccFormError, verificationNumber: Strings.PURCHASE_FORM_FIELD_REQUIRED});
                setFormIsValid(false);
            } else if (!cardValidator.cvv(value).isValid) {
                setCcFormError({...ccFormError, verificationNumber: Strings.PURCHASE_FORM_CC_VERIFICATION_NO_INVALID});
                setFormIsValid(false);
            }
        }
    }

    const onChangeStep = (step) => {
        switch (step) {
            case 1:
                setShowLoginHint(true);
                return;
            case 2:
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/select");
                return;
            case 3:
                if (purchaseData.maxStep > 2) {
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/confirmation");
                }
                return;
            case 4:
                if (purchaseData.maxStep > 3) {
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/confirmation");
                }
                return;
            default:
                return;
        }
    }

    return (
        <div className="purchase-container">
            <PurchaseStatus step={2} maxStep={purchaseData != null ? purchaseData.maxStep : 1}
                            onChangeStep={(step) => onChangeStep(step)}
            />
            <div className="purchase-info" dangerouslySetInnerHTML={{__html: Strings.PURCHASE_PAYMENT_METHOD_INFO}}/>
            <div className="purchase-form-container">
                <div className="purchase-payment-buttons">
                    <div className="purchase-payment-provider">
                        {Strings.PURCHASE_SUBSCRIPTION_PAYMENT_METHOD_DEBIT}
                        <div className={paymentMethod === GlobalConstants.PAYMENT_METHOD_DEBIT ? "purchase-payment-provider-img purchase-payment-provider-img-active" : "purchase-payment-provider-img"}
                             onClick={() => setPaymentMethod(GlobalConstants.PAYMENT_METHOD_DEBIT)}>
                            <img src={debit} alt={"payment debit"} />
                        </div>
                        <div className={"round"} style={{margin: "0 auto", width: "20px"}}>
                            <input name="debit" id="debit" type="checkbox" value={paymentMethod === GlobalConstants.PAYMENT_METHOD_DEBIT}
                                   checked={paymentMethod === GlobalConstants.PAYMENT_METHOD_DEBIT}
                                   onChange={() => setPaymentMethod(GlobalConstants.PAYMENT_METHOD_DEBIT)}
                                   />
                            <label htmlFor="debit" />
                        </div>
                    </div>
                    {/**
                     //TODO Activate tihs again as soon as cc works
                    <div className="purchase-payment-provider">
                        {Strings.PURCHASE_PAYMENT_METHOD_CC}
                        <div className={paymentMethod === GlobalConstants.PAYMENT_METHOD_CREDIT_CARD ? "purchase-payment-provider-img purchase-payment-provider-img-active" : "purchase-payment-provider-img"}
                             onClick={() => setPaymentMethod(GlobalConstants.PAYMENT_METHOD_CREDIT_CARD)}>
                            <img src={creditCard} alt={"payment creditcard"} />
                        </div>
                        <div className={"round"} style={{margin: "0 auto", width: "20px"}}>
                            <input name="creditcard" id="creditcard" type="checkbox" value={paymentMethod === GlobalConstants.PAYMENT_METHOD_CREDIT_CARD}
                                   checked={paymentMethod === GlobalConstants.PAYMENT_METHOD_CREDIT_CARD}
                                   onChange={() => setPaymentMethod(GlobalConstants.PAYMENT_METHOD_CREDIT_CARD)}
                            />
                            <label htmlFor="creditcard" />
                        </div>
                    </div>
                     **/}
                </div>
                <div className="purchase-form">
                    <Form className="text-left">
                        {/* The form depends on the selected payment channel */}
                        {paymentMethod === GlobalConstants.PAYMENT_METHOD_CREDIT_CARD ?
                            <>
                                {/* Card Holder*/}
                                <Form.Group className="mb-3" controlId="ticketRedemptionTicketPin">
                                    <Form.Label className="auth-label">{Strings.PURCHASE_SUBSCRIPTION_CC_NAME}</Form.Label>
                                    <Form.Control
                                        type={"text"}
                                        required
                                        value={creditcardForm.cardHolder}
                                        isInvalid={ccFormError.cardHolder != null}
                                        onBlur={(e) => validateCCForm('cardHolder', e.target.value)}
                                        onChange={e => updateCCForm('cardHolder', e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {ccFormError.cardHolder}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {/* Card Number */}
                                <Form.Group className="mb-3" controlId="ticketRedemptionTicketPin">
                                    <Form.Label className="auth-label">{Strings.PURCHASE_SUBSCRIPTION_CC_NUMBER}</Form.Label>
                                    <Form.Control
                                        type={"text"}
                                        required
                                        value={creditcardForm.cardNumber}
                                        isInvalid={ccFormError.cardNumber != null}
                                        onBlur={(e) => validateCCForm('cardNumber', e.target.value)}
                                        onChange={e => updateCCForm('cardNumber', e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {ccFormError.cardNumber}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {/* Expiry date */}
                                <Form.Group as={Col} className="mb-3" controlId="ticketRedemptionTicketPin"
                                            style={{display: "inline-block", width: "50%", marginLeft: "-2%"}}>
                                    <Form.Label className="auth-label">{Strings.PURCHASE_SUBSCRIPTION_CC_MONTH}</Form.Label>
                                    <Form.Control
                                        type={"text"}
                                        required
                                        value={creditcardForm.expiryMonth}
                                        isInvalid={ccFormError.expiryMonth != null}
                                        onBlur={(e) => validateCCForm('expiryMonth', e.target.value)}
                                        placeholder={"MM"}
                                        onChange={e => updateCCForm('expiryMonth', e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {ccFormError.expiryMonth}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} className="mb-3" controlId="ticketRedemptionTicketPin"
                                            style={{width: "50%", float: "right", marginRight: "-2%"}}>
                                    <Form.Label className="auth-label">{Strings.PURCHASE_SUBSCRIPTION_CC_YEAR}</Form.Label>
                                    <Form.Control
                                        type={"text"}
                                        required
                                        value={creditcardForm.expiryYear}
                                        isInvalid={ccFormError.expiryYear}
                                        onBlur={(e) => validateCCForm('expiryYear', e.target.value)}
                                        placeholder={"JJJJ"}
                                        onChange={e => updateCCForm('expiryYear', e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {ccFormError.expiryYear}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                {/* Verification Number */}
                                <Form.Group className="mb-3" controlId="ticketRedemptionTicketPin">
                                    <Form.Label className="auth-label">{Strings.PURCHASE_SUBSCRIPTION_CC_VERIFICATION}</Form.Label>
                                    <Form.Control
                                        type={"text"}
                                        required
                                        value={creditcardForm.verificationNumber}
                                        isInvalid={ccFormError.verificationNumber}
                                        onBlur={(e) => validateCCForm('verificationNumber', e.target.value)}
                                        onChange={e => updateCCForm('verificationNumber', e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {ccFormError.verificationNumber}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <br />
                                <TigerButton className="w-100" variant="red" disabled={paymentMethod == null || !formIsValid}
                                    type="button" onClick={(e) => submitCreditCardChannel(e)}>
                                    {Strings.PURCHASE_PROCEED_BUTTON}
                                </TigerButton>
                            </>
                        :
                            <>
                                {paymentMethod === GlobalConstants.PAYMENT_METHOD_DEBIT &&
                                    <>
                                        {/* ACCOUNT OWNER*/}
                                        <Form.Group className="mb-3" controlId="ticketRedemptionTicketPin">
                                            <Form.Label className="auth-label">{Strings.PURCHASE_SUBSCRIPTION_DEBIT_OWNER}</Form.Label>
                                            <Form.Control
                                                type={"text"}
                                                required
                                                value={debitForm.accountHolder}
                                                isInvalid={debitFormError.accountHolder != null}
                                                onBlur={(e) => validateDebitForm('accountHolder', e.target.value)}
                                                onChange={e => updateDebitForm('accountHolder', e.target.value)}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                {debitFormError.accountHolder}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {/* IBAN */}
                                        <Form.Group className="mb-3" controlId="ticketRedemptionTicketPin">
                                            <Form.Label className="auth-label">{Strings.PURCHASE_SUBSCRIPTION_DEBIT_IBAN}</Form.Label>
                                            <Form.Control
                                            type={"text"}
                                            required
                                            value={debitForm.iban}
                                            isInvalid={debitFormError.iban != null}
                                            onBlur={(e) => validateDebitForm('iban', e.target.value)}
                                            onChange={e => updateDebitForm('iban', e.target.value)}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                {debitFormError.iban}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {/* BIC */}
                                        <Form.Group className="mb-3" controlId="ticketRedemptionTicketPin">
                                            <Form.Label className="auth-label">{Strings.PURCHASE_SUBSCRIPTION_DEBIT_BIC}</Form.Label>
                                            <Form.Control
                                            type={"text"}
                                            required
                                            value={debitForm.bic}
                                            isInvalid={debitFormError.bic != null}
                                            onBlur={(e) => validateDebitForm('bic', e.target.value)}
                                            onChange={e => updateDebitForm('bic', e.target.value)}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                {debitFormError.bic}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <div>
                                            <div className={"round"}>
                                            <input name="privacy" id="privacy" onBlur={(e) => validateDebitForm('sepaAcceped', e.target.checked)}
                                                   type="checkbox" value={debitForm.sepaAccepted} onChange={(e) => updateDebitForm('sepaAccepted', e.target.checked)
                                        }/>
                                            <label htmlFor="privacy"></label>
                                            </div>
                                            <label className="roundlabel purchase-cc-check-label" htmlFor="privacy">{Strings.PURCHASE_SUBSCRIPTION_DEBIT_AGB_TITLE}</label><br />
                                            <div className="purchase-form-check-info">{Strings.PURCHASE_SUBSCRIPTION_DEBIT_AGB_INFO}</div>
                                        </div>
                                        <br />
                                        <TigerButton className="w-100" variant="red" disabled={paymentMethod == null || !formIsValid}
                                            type="button" onClick={(e) => submitDebitChannel(e)}>
                                            {Strings.PURCHASE_PROCEED_BUTTON}
                                        </TigerButton>
                                    </>
                                }
                            </>
                        }

                    </Form>
                </div>
                <ConfirmationModal
                    title={Strings.PURCHASE_REAUTHENTICATE_TITLE}
                    text={Strings.PURCHASE_REAUTHENTICATE_HINT}
                    actionButtonLabel={Strings.PURCHASE_REAUTHENTICATE_BUTTON}
                    actionButtonVariant="danger"
                    onActionPress={() => {
                        localStorage.removeItem("ls.token");
                        sessionStorage.removeItem("ls.purchase");
                        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                        history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif")
                    }}
                    show={showLoginHint}
                    onHide={() => setShowLoginHint(false)}
                />
            </div>

        </div>
    )
}
import {GetFilteredRestResource, GetRestResource} from "../../api/RestResourceClient";

//API METHODS
export async function GetSeries(seriesId) {
    return await GetFilteredRestResource('api/series/' + seriesId, null, null, false, false, false, true);
}

export async function GetRecentlyPublishedEpisodes(seriesId) {
    return await GetRestResource('api/episodes/search/recently-published-by-series-id?seriesId=' + seriesId, false, true);
}

export async function GetSeriesByClusterId(clusterId) {
    let filter = {
        contentClusterId : clusterId
    }
    return await GetFilteredRestResource('api/series', filter, null, true, false, false, true);
}

export async function GetAllSeries(filter) {
    return await GetFilteredRestResource('api/series', filter, null, true, false, false, true);
}

export function getEpisodeLength(episode) {
    if (episode.length && episode.length > -1) {
        const seconds = episode.length
        const minutes = Math.floor(seconds / 60);

        return '<span>' + minutes + "</span> Min";
    } else {
        return null;
    }
}


//API METHODS
export async function GetFilterQuery(id) {
    //return await GetShopResource('api/filterQueries/' + id);
    const result = {id: id, name: "Das Sams"};
    return await result;
}
export async function GetProductsOfPlaylist(queryId) {
    //return await GetShopResource('api/filterQueries/' + queryId + '/products);
    const result = [
        {
            id: 1015840261,
            title: "Ein Sams für Martin Taschenbier Ein Sams für Martin Taschenbier ür Martin Taschenbier",
            ageMin: 6,
            cover: {
                contentLocation: {
                    location: "https://s3.eu-central-1.amazonaws.com/tigerbooks.production.public/98a59e27-bead-468a-9ec6-7aff9d2b78da%2Fcover_small.jpeg"
                }
            }
        },
        {
            id: 1015840262,
            title: "Eine Woche voller Samstage",
            ageMin: 6
        },
        {
            id: 1015840265,
            title: "Onkel Alwin und das Sams",
            ageMin: 6
        },
        {
            id: 1015840266,
            title: "Sams im Glück",
        },
        {
            id: 1015840266,
            title: "Sams im Glück",
        },
        {
            id: 1015840266,
            title: "Sams im Glück",
        },
        {
            id: 1015840266,
            title: "Sams im Glück",
        },
        {
            id: 1015840266,
            title: "Sams im Glück",
        },
        {
            id: 1015840266,
            title: "Sams im Glück",
        }
    ]
    return await result;
}
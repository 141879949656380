import React, {Component} from "react";
import './PremiumType.css';
import Strings from "../../../config/Strings";
import {ConvertDateToReadableString} from "../../../util/ConverterUtil";
import {GetAccountTickets} from "../PremiumService";
import {TigerSpinner} from "../../../global/tiger-spinner/TigerSpinner";
import {TigerButton} from "../../../global/tiger-button/TigerButton";
import ticketIcon from "../../../../assets/images/ticket-item.png";
import ticketIconSwiss from "../../../../assets/images/ticket-item-swiss.png";
import couponIconDdf from "../../../../assets/images/coupon-item-ddf.png";
import couponIconHuibuh from "../../../../assets/images/coupon-item-huibuh.png";
import {IoCloseCircle} from "react-icons/all";
import GlobalConstants from "../../../config/GlobalConstants";
import logo from "../../../../assets/images/loader_bg.png";

class TicketAccess extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accountId: props.accountId,
            subscription: props.subscription,
            tickets: [],
            unusedTickets: [],
            usedTickets: [],
            unusedCoupons: [],
            usedCoupons: [],
            loading: true,
            expandedTickets: []
        };
    }

    async componentDidMount() {
        await this.loadTickets();
    }

    async loadTickets() {
        if (this.props.accountId != null) {
            let ticketRes = await GetAccountTickets(this.props.accountId);
            if (!ticketRes.error) {
                this.sortTickets(ticketRes.result);
            } else {
                this.setState(prevState => ({...prevState, loading: false}));
            }
        } else {
            this.setState(prevState => ({...prevState, loading: false}));
        }
    }

    ticketSortFunction() {
        return function (x, y) {
            if (new Date(x.redemptionDate) > new Date(y.redemptionDate)) {
                return -1;
            }
            if (new Date(x.redemptionDate) < new Date(y.redemptionDate)) {
                return 1;
            }
            return 0;
        }
    }

    sortTickets(tickets) {
        if (tickets != null && Array.isArray(tickets)) {
            const unused = tickets.filter(t => t.used === false);
            const used = tickets.filter(t => t.used === true).sort(this.ticketSortFunction())
            const unusedTickets = unused.filter(t => (t.type === 'DIGITICKET' || t.type === 'TIGERTICKET'));
            const usedTickets = used.filter(t => (t.type === 'DIGITICKET' || t.type === 'TIGERTICKET'));
            const unusedCoupons = unused.filter(t => t.type === 'COUPON');
            const usedCoupons = used.filter(t => t.type === 'COUPON' && (t.couponType != null && t.couponType !== "PRESENT"));
            this.setState(prevState => ({
                ...prevState,
                unusedTickets: unusedTickets,
                usedTickets: usedTickets,
                unusedCoupons: unusedCoupons,
                usedCoupons: usedCoupons,
                loading: false
            }));
        }
    }

    getPremiumAccessTicketType() {
        let articleId = this.state.subscription.articleId;
        if (articleId == null) {
            return "";
        }
        if (articleId.indexOf('swiss') > -1) {
            return Strings.DASHBOARD_PREMIUM_TYPE_VALUE_SWISS_TICKET;
        } else {
            return Strings.DASHBOARD_PREMIUM_TYPE_VALUE_TICKET;
        }
    }

    getTicketDuration(ticket) {
        if (ticket == null || ticket.duration == null) {
            return "";
        }
        const unit = ticket.duration.unit;
        const quantity = ticket.duration.quantity;
        const isPlural = quantity > 1;
        if (unit === 'day') {
            return isPlural ? quantity + " Tage" : quantity + " Tag";
        } else if (unit === 'week') {
            return isPlural ? quantity + " Wochen" : quantity + " Woche";
        } else if (unit === 'year') {
            return isPlural ? quantity + " Jahre" : quantity + " Jahr";
        } else {
            return isPlural ? quantity + " Monate" : quantity + " Monat";
        }
    }

    getTicketIcon(ticket) {
        if (ticket.edition === 'SWISS') {
            return ticketIconSwiss;
        } else {
            return ticketIcon;
        }
    }

    getCouponIcon(coupon) {
        if (coupon.couponType === 'DDF') {
            return couponIconDdf;
        } else if (coupon.couponType === 'SWISS_UPGRADE') {
            return ticketIconSwiss;
        } else if (coupon.couponType === 'HUIBUH' || coupon.couponType === 'HUIBUH_LESUNG') {
            return  couponIconHuibuh;
        } else {
            return ticketIcon;
        }
    }

    getCouponName(coupon) {
        if (coupon.couponType === 'DDF') {
            return Strings.PREMIUM_TICKET_COUPON_TYPE_DDF;
        } else if (coupon.couponType === 'SWISS_UPGRADE') {
            return Strings.PREMIUM_TICKET_COUPON_TYPE_SWISS_UPGRADE;
        } else if (coupon.couponType === 'HUIBUH' || coupon.couponType === 'HUIBUH_LESUNG') {
            return  Strings.PREMIUM_TICKET_COUPON_TYPE_HUI_BUH;
        } else {
            return ticketIconSwiss;
        }
    }

    getCouponLandingPage(coupon) {
        if (coupon.couponType === 'DDF') {
            return 'ddflive'
        } else if (coupon.couponType === 'HUIBUH' || coupon.couponType === 'HUIBUH_LESUNG') {
            return 'huibuh'
        } else {
            return null;
        }
    }

    render() {
        return (
            <div className="premium-type-container">
                {this.state.loading &&
                <TigerSpinner/>
                }
                {!this.state.loading &&
                <div>
                    {this.state.usedTickets.length === 0 && this.state.usedCoupons.length === 0 && this.state.unusedTickets.length === 0 &&
                    <div style={{textAlign: "center"}}>
                        <img src={logo} alt="logo"/>
                        <div className="premium-type-header" style={{color: "#616060", marginTop: "20px"}}>
                            {Strings.PREMIUM_TICKET_NO_TICKETS}
                        </div>
                    </div>
                    }

                    {this.state.unusedTickets.length > 0 &&
                    <>
                        <div className="content-header premium-type-header"
                             style={{color: '#ce023e'}}>{Strings.PREMIUM_TICKET_HEADER_LABEL_UNUSED}
                        </div>
                        {this.state.unusedTickets.map(t => (
                            this.unusedItem(t)
                        ))}
                    </>
                    }
                    {this.state.usedTickets.length > 0 &&
                    <>
                        <div className="content-header premium-type-header"
                             style={{color: '#6c757d'}}>{Strings.PREMIUM_TICKET_HEADER_LABEL_TICKETS}
                        </div>
                        {this.state.usedTickets.map(t => (
                            this.ticketItem(t)
                        ))}
                    </>
                    }

                    {this.state.usedCoupons.length > 0 &&
                    <>
                        <div className="content-header premium-type-header"
                             style={{color: '#6c757d'}}>{Strings.PREMIUM_TICKET_HEADER_LABEL_COUPONS}</div>
                        {this.state.usedCoupons.map(c => (
                            this.couponItem(c)
                        ))}
                    </>
                    }
                </div>
                }
            </div>
        );
    }

    toggleTicket(ticket) {
        let expandedTickets = this.state.expandedTickets;
        const index = expandedTickets.indexOf(ticket.code);
        if (index > -1) {
            expandedTickets.splice(index, 1);
        } else {
            expandedTickets.push(ticket.code);
        }
        this.setState(prevState => ({...prevState, expandedTickets: expandedTickets}));
    }

    unusedItem(ticket) {
        if (this.state.expandedTickets.indexOf(ticket.code) > -1) {
            return (
                <div className="my-4">
                    <div style={{float: "right"}} onClick={() => this.toggleTicket(ticket)}><IoCloseCircle className="icon clickable"/></div>
                    <div className="premium-type-wrapper">
                        <div className="ticket-item-icon">
                            <img src={this.getTicketIcon(ticket)} alt={"Pass " + ticket.code} />
                            <div>
                                {ticket.code}
                            </div>
                        </div>
                        <div className="ticket-item-details w-100">
                            <div className="premium-type-wrapper py-3">
                                <div className="details-label">
                                    <strong>{Strings.PREMIUM_TICKET_TABLE_DURATION}</strong>
                                </div>
                                <div className="details-value">
                                    {this.getTicketDuration(ticket)}
                                </div>
                            </div>
                            <div className="premium-type-wrapper">
                                <div className="details-label" />
                                <div className="details-value">
                                    <TigerButton
                                        className="btn-ci-sm btn-ci-hover"
                                        variant={"red"}
                                        noOutline="true"
                                        onClick={() => this.props.history.push("/" + GlobalConstants.APP_PATH + "ticket?code=" + ticket.code)}
                                    >
                                        {Strings.PREMIUM_TICKET_REDEEM_TICKET_BUTTON}&#187;
                                    </TigerButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />

                </div>
            );
        } else {
            return (
                <>
                    <div className="my-3 ticket-item-minimal">
                        <div className="ticket-item-icon-minimal" style={{cursor: "pointer"}} onClick={() => this.toggleTicket(ticket)}>
                            <img src={this.getTicketIcon(ticket)} alt={"Ticket " + ticket.code} />
                        </div>
                        <div className="ticket-item-details-minimal">
                            <strong>{Strings.PREMIUM_TICKET_TABLE_DURATION}</strong> {this.getTicketDuration(ticket)}
                        </div>
                        <div className="ticket-item-details-minimal clickable" onClick={() => this.props.history.push("/" + GlobalConstants.APP_PATH + "ticket?code=" + ticket.code)}>
                            <span style={{color: '#ce023e', textDecoration: "underline", fontWeight: "bold"}}>
                                {Strings.PREMIUM_TICKET_REDEEM_TICKET_BUTTON}&#187;
                            </span>
                        </div>
                        <div className="ticket-item-details-minimal clickable" onClick={() => this.toggleTicket(ticket)}>
                            <span style={{color: '#ce023e', textDecoration: "underline"}}>{Strings.PREMIUM_TICKET_TABLE_MORE_DETAILS}&#187;</span>
                        </div>

                    </div>
                    <hr />
                </>
            );
        }

    }

    ticketItem(ticket) {
        if (this.state.expandedTickets.indexOf(ticket.code) > -1) {
            return (
                <div>
                    <div style={{float: "right"}} onClick={() => this.toggleTicket(ticket)}><IoCloseCircle className="icon clickable"/></div>
                    <div className="premium-type-wrapper">
                        <div className="ticket-item-icon">
                            <img src={this.getTicketIcon(ticket)} alt={"ticket " + ticket.code} />
                            <div>
                                {ticket.code}
                            </div>
                        </div>
                        <div className="ticket-item-details w-100">
                            <div className="premium-type-wrapper py-3">
                                <div className="details-label">
                                    <strong>{Strings.PREMIUM_TICKET_TABLE_DURATION}</strong>
                                </div>
                                <div className="details-value">
                                    {this.getTicketDuration(ticket)}
                                </div>
                            </div>
                            <div className="premium-type-wrapper">
                                <div className="details-label">
                                    <strong>{Strings.PREMIUM_TICKET_TABLE_REDEMPTION_DATE}</strong>
                                </div>
                                <div className="details-value">
                                    {ConvertDateToReadableString(ticket.redemptionDate)}
                                </div>
                            </div>
                            <div className="premium-type-wrapper py-3">
                                <div className="details-label">
                                    <strong>{Strings.PREMIUM_TICKET_TABLE_TYPE}</strong>
                                </div>
                                <div className="details-value">
                                    {ticket.edition === 'SWISS' ? Strings.DASHBOARD_PREMIUM_TYPE_VALUE_SWISS_TICKET : Strings.DASHBOARD_PREMIUM_TYPE_VALUE_TICKET}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />

                </div>
            );
        } else {
            return (
                <>
                    <div className="ticket-item-minimal">
                        <div className="ticket-item-icon-minimal" style={{cursor: "pointer"}} onClick={() => this.toggleTicket(ticket)}>
                            <img src={this.getTicketIcon(ticket)} alt={"Ticket " + ticket.code} />
                        </div>
                        <div className="ticket-item-details-minimal">
                            <strong>{Strings.PREMIUM_TICKET_TABLE_DURATION}</strong> {this.getTicketDuration(ticket)}
                        </div>
                        <div className="ticket-item-details-minimal">
                            <strong>{Strings.PREMIUM_TICKET_TABLE_REDEMPTION_DATE}</strong> {ConvertDateToReadableString(ticket.redemptionDate)}
                        </div>
                        <div className="ticket-item-details-minimal clickable" onClick={() => this.toggleTicket(ticket)}>
                            <span style={{color: '#ce023e', textDecoration: "underline"}}>{Strings.PREMIUM_TICKET_TABLE_MORE_DETAILS}&#187;</span>
                        </div>
                    </div>
                    <hr />
                </>
            );
        }

    }

    couponItem(coupon) {
        if (this.state.expandedTickets.indexOf(coupon.code) > -1) {
            const landingPage = this.getCouponLandingPage(coupon);
            return (
                <div className="my-4">
                    <div style={{float: "right"}} onClick={() => this.toggleTicket(coupon)}><IoCloseCircle className="icon clickable"/></div>
                    <div className="premium-type-wrapper">
                        <div className="ticket-item-icon">
                            <img src={this.getCouponIcon(coupon)} alt={"Pass " + coupon.code} />
                            <div>
                                {coupon.code}
                            </div>
                        </div>
                        <div className="ticket-item-details w-100">
                            {coupon.duration != null && coupon.duration.unit != null && coupon.duration.unit > 0 &&
                                <div className="premium-type-wrapper py-3">
                                    <div className="details-label">
                                        <strong>{Strings.PREMIUM_TICKET_TABLE_DURATION}</strong>
                                    </div>
                                    <div className="details-value">
                                        {this.getTicketDuration(coupon)}
                                    </div>
                                </div>
                            }
                            <div className="premium-type-wrapper">
                                <div className="details-label">
                                    <strong>{Strings.PREMIUM_TICKET_TABLE_REDEMPTION_DATE}</strong>
                                </div>
                                <div className="details-value">
                                    {ConvertDateToReadableString(coupon.redemptionDate)}
                                </div>
                            </div>
                            <div className="premium-type-wrapper py-3">
                                <div className="details-label">
                                    <strong>{Strings.PREMIUM_TICKET_TABLE_COUPON_NAME}</strong>
                                </div>
                                <div className="details-value">
                                    {this.getCouponName(coupon)}
                                </div>
                            </div>
                            {landingPage != null &&
                                <div className="premium-type-wrapper">
                                    <div className="details-label" />
                                    <div className="details-value">
                                        <TigerButton
                                            className="btn-ci-sm btn-ci-hover"
                                            variant={"red"}
                                            noOutline="true"
                                            onClick={() => this.props.history.push("/" + GlobalConstants.APP_PATH + landingPage)}
                                        >
                                            {Strings.PREMIUM_TICKET_TABLE_OPEN_LANDING_PAGE}&#187;
                                        </TigerButton>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <hr />

                </div>
            );
        } else {
            return (
                <>
                    <div className="my-3 ticket-item-minimal">
                        <div className="ticket-item-icon-minimal" style={{cursor: "pointer"}} onClick={() => this.toggleTicket(coupon)}>
                            <img src={this.getCouponIcon(coupon)} alt={"Ticket " + coupon.code} />
                        </div>
                        <div className="ticket-item-details-minimal">
                            <strong>{Strings.PREMIUM_TICKET_TABLE_PASS_CODE}</strong> {coupon.code}
                        </div>
                        <div className="ticket-item-details-minimal">
                            <strong>{Strings.PREMIUM_TICKET_TABLE_COUPON_NAME}</strong> {this.getCouponName(coupon)}
                        </div>
                        <div className="ticket-item-details-minimal clickable" onClick={() => this.toggleTicket(coupon)}>
                            <span style={{color: '#ce023e', textDecoration: "underline"}}>{Strings.PREMIUM_TICKET_TABLE_MORE_DETAILS}&#187;</span>
                        </div>
                    </div>
                    <hr />
                </>
            );
        }

    }
}

export default TicketAccess;
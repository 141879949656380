import React, {Component} from "react";
import {BackButton} from "../../global/tiger-button/BackButton";
import {withToast} from "../../util/ToastService";
import {DeleteRecentlyUsedProduct} from "./AudiobooksService";
import {TigerMessage} from "../../global/tiger-message/TigerMessage";
import {TigerSpinner} from "../../global/tiger-spinner/TigerSpinner";
import Strings from "../../config/Strings";
import AudiobookDetails from "./AudiobookDetails";
import {ConfirmationModal} from "../../global/ConfirmationModal";
import GlobalConstants from "../../config/GlobalConstants";
import ErrorCodes from "../../config/ErrorCodes";
import {GetRecentlyUsedProductsOfProfile} from "../profiles/ProfileService";

class Audiobooks extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            showRemoveModal: false,
            account: null,
            profileId: null,
            productIds: [],
            productForRemoval: null
        };

        //Window title
        document.title = "Zuletzt gehört :: tiger.media";
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.accountId !== this.props.accountId) {
            await this.loadRecentlyUsedProducts();
        }
    }

    async componentDidMount() {
        if (this.props.location.state && this.props.location.state.account && this.props.location.state.profileLink && this.props.location.state.profileId) {
            this.setState(prevState => ({
                ...prevState,
                account: this.props.location.state.account,
                profileId: this.props.location.state.profileId,
                profileLink: this.props.location.state.profileLink
            }), () => this.loadRecentlyUsedProducts());
        } else {
            this.props.history.push("/" + GlobalConstants.APP_PATH + "profiles")
        }
        window.scrollTo(0, 0);
    }

    async loadRecentlyUsedProducts() {
        if (this.state.account != null && this.state.account.id != null && this.state.profileId != null) {
            let productsRes = await GetRecentlyUsedProductsOfProfile(this.state.account.id, this.state.profileId);
            if (productsRes.error === false) {
                let products = (productsRes.result != null && productsRes.result._embedded != null && productsRes.result._embedded.products != null) ? productsRes.result._embedded.products.filter(p => (p.state !== 'INACTIVE' && p.state !== 'TAKEDOWN')) : [];
                const productIds = products.map(p => Number(p.id));
                this.setState({
                    loading: false,
                    productIds: productIds
                });
            } else {
                this.props.addToast(Strings.TOAST_ERROR_FETCHING_DATA + ErrorCodes.CODE_ACCOUNT + " - " + productsRes.message, {
                    autoDismiss: true,
                    appearance: 'error',
                    placement: 'bottom-center'
                });
                this.setState({
                    loading: false,
                    account: null,
                    productIds: []
                });
            }
        }
    }

    showModal = (productId, index) => {
        this.setState({
            showRemoveModal: true,
            productForRemoval: {
                id: productId,
                index: index
            }
        });
    }

    removeAudiobook = () => {
        DeleteRecentlyUsedProduct(this.state.account.id, this.state.productForRemoval.id).then(res => {
            if (res.error === false) {
                const deleted = res.result;

                if (deleted === 1) {
                    // remove from state
                    let productIdArray = this.state.productIds;
                    productIdArray.splice(this.state.productForRemoval.index, 1);

                    this.setState({
                        productIds: productIdArray
                    }, () => {
                        this.props.addToast(Strings.AUDIOBOOKS_REMOVE_SUCCESS, {
                            autoDismiss: true,
                            appearance: 'success',
                            placement: 'bottom-center'
                        });
                    });
                }
                else {
                    this.props.addToast(Strings.AUDIOBOOKS_REMOVE_ERROR_TRY_AGAIN, {
                        autoDismiss: true,
                        appearance: 'error',
                        placement: 'bottom-center'
                    });
                }
            }
            else {
                this.props.addToast(Strings.AUDIOBOOKS_REMOVE_ERROR + res.message, {
                    autoDismiss: true,
                    appearance: 'error',
                    placement: 'bottom-center'
                });
            }
            this.setState({
                showRemoveModal: false,
                productForRemoval: null
            });
        });
    }

    closeView = () => {
        this.props.history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "profile-details",
            state: this.state.profileLink
        });
    }

    render() {
        return (
            <div className="content-box last-used-container">
                <BackButton
                        onClick={() => this.closeView()}
                />
                <div className="content-header" style={{color: '#a51382'}}>{Strings.TITLE_AUDIOBOOKS}</div>
                <div className="mb-4 last-used-items-container">

                    {!this.state.loading && this.state.productIds.length !== 0 &&
                    <>
                        {this.state.productIds.map((pid, index) => {
                            return (
                                <AudiobookDetails key={pid}
                                                  productId={pid}
                                                  removeAudiobook={() => this.showModal(pid, index)}
                                />
                            )
                        })}
                    </>
                    }

                    {!this.state.loading && this.state.productIds.length === 0 && (
                        <TigerMessage text={Strings.AUDIOBOOKS_NOTHING_FOUND}/>
                    )}

                    {this.state.loading && (
                        <TigerSpinner/>
                    )}

                    <ConfirmationModal
                        title={Strings.AUDIOBOOKS_MODAL_REMOVE_TITLE}
                        text={Strings.AUDIOBOOKS_MODAL_REMOVE_TEXT}
                        actionButtonLabel={Strings.AUDIOBOOKS_MODAL_REMOVE_BUTTON_LABEL}
                        actionButtonVariant="danger"
                        onActionPress={() => this.removeAudiobook()}
                        show={this.state.showRemoveModal}
                        onHide={() => this.setState({showRemoveModal: false})}
                    />

                </div>
            </div>
        )
    }
}

export default withToast(Audiobooks);
import "./Authentication.css";
import React, {useState} from "react";
import {changeEmail} from "./Oauth2Service";
import Strings from "../config/Strings";
import {Form, Spinner} from "react-bootstrap";
import {useHistory} from "react-router";
import GlobalConstants from "../config/GlobalConstants";
import {Link} from "react-router-dom";
import {withToast} from "../util/ToastService";
import {TigerButton} from "../global/tiger-button/TigerButton";

function ChangeEmail(props) {
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(false);

    const {addToast} = props;

    const history = useHistory();

    window.scrollTo(0, 0);

    const setField = (field, value, isValid) => {
        setForm({
          ...form,
          [field]: value
        });
        
        if (!isValid) {
            setErrors({
                ...errors,
                [field]: true
            });
        }
        else {
            setErrors({
                ...errors,
                [field]: false
            });
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setSubmitDisable(true);

        let hasErrors = !Object.values(errors).every(x => x === false);

        if (!hasErrors) {
            setSubmitted(true);
            let response = await changeEmail(props.accountId, {email: form.newEmail});

            if (!response.error) {
                history.push("/" + GlobalConstants.APP_PATH + "dashboard");
                addToast(Strings.TOAST_EMAIL_CHANGE, {
                    autoDismiss: true,
                    appearance: 'success',
                    placement: 'bottom-center'
                });
            } else {
                addToast("Bei der Anfrage ist ein Fehler aufgetreten. Bitte versuche es später erneut oder kontaktiere den Support.", {
                    autoDismiss: true,
                    appearance: "error"
                });
                setSubmitDisable(false);
            }
        } else {
            addToast("Bitte überprüfe deine Angaben.", {
                autoDismiss: true,
                appearance: "error"
            });
            setSubmitDisable(false);
        }
    }

    return(
        <>
            <div className="register-form">
                <p style={{fontSize: "50px", fontWeight: "bold"}}>{Strings.CHANGE_EMAIL_TITLE}</p>
                <p style={{fontSize: "20px"}}>{Strings.CHANGE_EMAIL_INFO}</p>
                <br />
                <Form className="text-left" onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="changePasswordNew">
                        <Form.Label className="auth-label" style={{width: "100%"}}>
                            {Strings.CHANGE_EMAIL_NEW}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            required
                            isInvalid={ errors.newEmail }
                            onChange={ e => setField('newEmail', e.target.value, (e.target.value.length >= 8 && e.target.value.length <= 50)) }
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="changePasswordConfirmation">
                        <Form.Label className="auth-label">{Strings.CHANGE_EMAIL_CONFIRMATION}</Form.Label>
                        <Form.Control
                            type="text"
                            required
                            isInvalid={ errors.confirmationEmail }
                            onChange={ e => setField('confirmationEmail', e.target.value, (e.target.value === form.newEmail)) }
                        />

                        {(form.confirmationEmail != null && form.confirmationEmail !== "" && form.confirmationEmail !== form.newEmail) &&
                        <Form.Control.Feedback type="invalid">
                            <span style={{fontSize: "16px"}}>{Strings.CHANGE_EMAIL_ERROR_NOT_MATCH}</span>
                        </Form.Control.Feedback>
                        }
                    </Form.Group>

                    <TigerButton className="w-100" disabled={submitDisable} variant="red">
                        {(submitDisable && submitted) ?
                            <Spinner animation="border" />
                            : Strings.CHANGE_EMAIL_SUBMIT}
                    </TigerButton>
                </Form>
                <p style={{marginTop: "30px", fontSize: "20px"}}>
                    <Link to={"/" + GlobalConstants.APP_PATH + "dashboard"} style={{fontWeight: "bold", color: "#333", textDecoration: "underline"}}>
                        {Strings.CHANGE_PASSWORD_DASHBOARD_LINK}
                    </Link>
                </p>
            </div>
        </>
    )
}

export default withToast(ChangeEmail);
import React from 'react';
import {Page, Image, Text, View, Document, StyleSheet, Link} from '@react-pdf/renderer';
import logo from '../../../../assets/images/tiger_logo_yellow.png';
import tigerMediaLogo from '../../../../assets/images/tiger_media_logo_yellow.png';
import tigerMediaLogoColored from '../../../../assets/images/tiger_media_logo_colored.png';
import facebook from '../../../../assets/images/facebook.png';
import instagram from '../../../../assets/images/instagram.png';
import Strings from "../../../config/Strings";
import {ConvertDateToReadableString} from "../../../util/ConverterUtil";
import GlobalConstants from "../../../config/GlobalConstants";

const cancellationTypeOnTime = "ON_TIME";
const cancellationDateSelected = "SELECT";

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#ffffff'
    },
    header: {
        height: '10%',
        backgroundColor: '#f9b900',
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 18,
        flexDirection: 'row'
    },
    tigerLogo: {
        width: '80%'
    },
    tigerMediaLogo: {
        width: '20%',
    },
    title: {
        height: '5%',
        textAlign: 'center',
        fontSize: '26px',
        color: '#f9b900',
        marginTop: '5%'
    },
    info: {
        width: '80%',
        marginTop: '5%',
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: '10px',
        lineHeight: '1.3',
        color: '#5e6977',
    },
    table: {
        width: '80%',
        marginTop: '3%',
        marginLeft: 'auto',
        marginRight: 'auto',
        color: '#5e6977',
        fontSize: '10px'
    },
    tableRow: {
        flexDirection: 'row',
        padding: 6
    },
    tableColumnParam: {
        /*flexGrow: 2,*/
        width: '50%',
        color: '#343941',
    },
    tableColumnValue: {
        /*flexGrow: 1,*/
        width: '50%',
        color: '#5e6977',
        textAlign: 'left'
    },
    footer: {
        width: '80%',
        marginTop: 60,
        marginLeft: 'auto',
        marginRight: 'auto',
        color: '#5e6977',
        fontSize: '8px'
    },
    footerRow: {
        flexDirection: 'row',
        padding: 2
    },
    footerColumn: {
        width: '50%',
        textAlign: 'left'
    },
    centeredFooterRow: {
        width: '100%',
        marginTop: 10,
        textAlign: 'center'
    }
});

export const CancellationConfirmationDocument = (props) => (
    <Document>
        <Page size="A4" style={styles.page}>
            {/* HEADER */}
            <View style={styles.header}>
                <View style={styles.tigerLogo}>
                    <Image src={logo} style={{width: '12%'}} />
                </View>
                <View style={{...styles.tigerMediaLogo, paddingTop: 8}}>
                    <Image src={tigerMediaLogo}/>
                </View>
            </View>
            {/* TITLE */}
            <Text style={styles.title}>{Strings.CANCELLATION_PDF_TITLE}</Text>
            {/* INFO 1 - 4*/}
            <View style={styles.info}>
                <Text>{Strings.CANCELLATION_PDF_INFO_1
                    .replace("{dateTime}", ConvertDateToReadableString(props.confirmation.submittedAt, true) + " " + Strings.GLOBAL_TIME)
                    .replace("{url}", GlobalConstants.URL_CANCEL_CONTRACTS)}</Text>
                <Text> </Text>
                <Text>{Strings.CANCELLATION_PDF_INFO_2}</Text>
                <Text> </Text>
                <Text>{Strings.CANCELLATION_PDF_INFO_3
                    .replace("{supportUrl}", "https://helpcenter.tiger.media/hc/de/requests/new")}</Text>
                <Text> </Text>
                <Text>{Strings.CANCELLATION_PDF_INFO_4}</Text>
            </View>

            {/* TABLE */}
            <View style={styles.table}>

                <View style={{...styles.tableRow, backgroundColor: '#e5e5e5'}}>
                    <Text style={styles.tableColumnParam}>{Strings.CONTRACT_CANCELLATION_FORM_TYPE}</Text>
                    <Text style={styles.tableColumnValue}>{props.confirmation.cancellationType === cancellationTypeOnTime ? Strings.CONTRACT_CANCELLATION_FORM_TYPE_OPTION_ON_TIME : Strings.CONTRACT_CANCELLATION_FORM_TYPE_OPTION_REASON}</Text>
                </View>

                {props.confirmation.extraordinaryReason != null &&
                    <>
                        <View style={{...styles.tableRow}}>
                            <Text style={styles.tableColumnParam}>{Strings.CONTRACT_CANCELLATION_FORM_EXTRAORDINARY_REASON}</Text>
                            <Text style={styles.tableColumnValue}>{props.confirmation.extraordinaryReason}</Text>
                        </View>
                    </>
                }

                <View style={{...styles.tableRow, backgroundColor: props.confirmation.extraordinaryReason != null ? '#e5e5e5' : '#ffffff'}}>
                    <Text style={styles.tableColumnParam}>{Strings.CANCELLATION_PDF_CANCELLED_PRODUCT}</Text>
                    <Text style={styles.tableColumnValue}>{props.confirmation.productType.toLowerCase()}</Text>
                </View>


                <View style={{...styles.tableRow, backgroundColor: props.confirmation.extraordinaryReason != null ? '#ffffff' : '#e5e5e5'}}>
                    <Text style={styles.tableColumnParam}>{Strings.CONTRACT_CANCELLATION_FORM_CANCELLATION_DATE}</Text>
                    <Text style={styles.tableColumnValue}>{props.cancellationDateOption === cancellationDateSelected ? Strings.CANCELLATION_CONFIRMED_CANCELLATION_DATE_TYPE + ConvertDateToReadableString(props.confirmation.cancellationDate, false) : Strings.CONTRACT_CANCELLATION_FORM_DATE_OPTION_NEXT}</Text>
                </View>


                {props.confirmation.contractValidUntil != null &&
                    <View style={{
                        ...styles.tableRow,
                        backgroundColor: props.confirmation.extraordinaryReason != null ? '#e5e5e5' : '#ffffff'
                    }}>
                        <Text style={styles.tableColumnParam}>{Strings.CONTRACT_CANCELLATION_END_OF_CONTRACT}</Text>
                        <Text
                            style={styles.tableColumnValue}>{ConvertDateToReadableString(props.confirmation.contractValidUntil, true) + " " + Strings.GLOBAL_TIME}</Text>
                    </View>
                }

                <View style={{...styles.tableRow, backgroundColor: props.confirmation.extraordinaryReason != null ? '#ffffff' : '#e5e5e5'}}>
                    <Text style={styles.tableColumnParam}>{Strings.CONTRACT_CANCELLATION_FORM_EMAIL}</Text>
                    <Text style={styles.tableColumnValue}>{props.confirmation.email}</Text>
                </View>

                <View style={{...styles.tableRow, backgroundColor: props.confirmation.extraordinaryReason != null ? '#e5e5e5' : '#ffffff'}}>
                    <Text style={styles.tableColumnParam}>{Strings.CONTRACT_CANCELLATION_FORM_CREATED_DATE}</Text>
                    <Text style={styles.tableColumnValue}>{ConvertDateToReadableString(props.confirmation.submittedAt, true) + " " + Strings.GLOBAL_TIME}</Text>
                </View>

            </View>
            {/* INFO 2 */}
            <View style={styles.info}>
                <Text>{Strings.CANCELLATION_PDF_INFO_5}</Text>
                <Text>{Strings.CANCELLATION_PDF_INFO_6}</Text>
            </View>

            {/* FOOTER */}
            <View style={styles.footer}>
                <View style={styles.footerRow}>
                    <Image src={tigerMediaLogoColored} style={{width: '35%'}}/>
                </View>
                <View style={styles.footerRow}>
                    <Text style={styles.footerColumn}>{Strings.CANCELLATION_PDF_ADDRESS_1}</Text>
                    <Text style={styles.footerColumn}>{Strings.CANCELLATION_PDF_OWNER_1}</Text>
                </View>
                <View style={styles.footerRow}>
                    <Text style={styles.footerColumn}>{Strings.CANCELLATION_PDF_ADDRESS_2}</Text>
                    <Text style={styles.footerColumn}>{Strings.CANCELLATION_PDF_OWNER_2}</Text>
                </View>
                <View style={styles.footerRow}>
                    <Text style={styles.footerColumn}>{Strings.CANCELLATION_PDF_ADDRESS_3}</Text>
                    <Text style={styles.footerColumn}>{Strings.CANCELLATION_PDF_OWNER_3}</Text>
                </View>
                <View style={styles.footerRow}>
                    <Text style={{...styles.footerColumn, marginTop: 5}}>{Strings.CANCELLATION_PDF_ADDRESS_4} <Link src={"https://tiger.media/policies/contact-information"}>{Strings.CANCELLATION_PDF_EMAIL}</Link></Text>
                    <Text style={styles.footerColumn}>{Strings.CANCELLATION_PDF_OWNER_4}</Text>
                </View>
                <View style={styles.footerRow}>
                    <Text style={styles.footerColumn}>{Strings.CANCELLATION_PDF_ADDRESS_5} <Link src={"https://tiger.media"}>{Strings.CANCELLATION_PDF_WEB}</Link></Text>
                    <Text style={{...styles.footerColumn, marginTop: -5}}>{Strings.CANCELLATION_PDF_OWNER_5}</Text>
                </View>
            </View>
            <Text style={styles.centeredFooterRow}>
                <Link src={"https://www.facebook.com/tiger.media.deutschland"} style={{textDecoration: 'none'}}><Image src={facebook}/></Link>
                <Text>  </Text>
                <Link src={"https://www.instagram.com/tiger.media/?hl=de"}  style={{textDecoration: 'none'}}><Image src={instagram}/></Link>
            </Text>
            <Text style={{...styles.centeredFooterRow, fontSize: '12px'}}>
                <Link src={"https://tiger.media/policies/terms-of-service"}>{Strings.CANCELLATION_PDF_AGB}</Link>
                <Text> - </Text>
                <Link src={"https://tiger.media/policies/privacy-policy"}>{Strings.CANCELLATION_PDF_DATENSCHUTZ}</Link>
                <Text> - </Text>
                <Link src={"https://helpcenter.tiger.media/hc/de"}>{Strings.CANCELLATION_PDF_HELP}</Link>
            </Text>
        </Page>
    </Document>
);

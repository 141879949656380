import React from "react";
import Strings from "../../../config/Strings";
import GlobalConstants from "../../../config/GlobalConstants";
import logo from "../../../../assets/images/tigerbox_touch_logo.png";
import './DashboardCard.scss';
import {TigerSpinner} from "../../../global/tiger-spinner/TigerSpinner";
import {SingleCardItem} from "./single-item/SingleCardItem";
import {Link} from "react-router-dom";
import {GetDeviceIcon} from "../../tigerboxes/TigerboxSevice";

export const TigerboxesCard = (props) => {
    const {devices, loading, history} = props;

    if (loading) {
        return (
            <TigerSpinner/>
        )
    }

    if(devices.length === 0) {
        return (
            <>
                <h4 className="dashboard-card-header">{Strings.DASHBOARD_DEVICES_CARD_LABEL}</h4>
                <div className="static-content-container">
                    <img src={logo} width={180} alt={"tigerbox Touch Logo"}/>
                    <hr style={{width: "270px"}}/>
                    <span style={{fontWeight: "bold"}}>{Strings.DASHBOARD_NO_ACTIVE_TIGERBOX}</span>
                    <div className="small-text"
                         dangerouslySetInnerHTML={{__html: Strings.DASHBOARD_TIGERBOX_TOOLTIP}} />
                </div>
                <div className="dashboard-card-footer centered">
                    <a href={"https://helpcenter.tiger.media/hc/de/articles/7609900088593-Wie-richte-ich-eine-tigerbox-ein"}>
                        <button
                            className={"dashboard-card-button"}
                            type="button"
                        >tigerbox TOUCH einrichten</button>
                    </a>
                </div>
            </>
        );
    }

    const openDevice = (device) => {
        history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "tigerbox-details",
            state: {device: device}
        });
    }

    return (
        <>
            <h4 className="dashboard-card-header">{Strings.DASHBOARD_DEVICES_CARD_LABEL}</h4>
            <div className="dashboard-card-content">
                {devices.map(device => (
                    <SingleCardItem icon={GetDeviceIcon(device)}
                                    label={device.displayName}
                                    key={device.id}
                                    centered
                                    rounded
                                    onClick={() => openDevice(device)}
                    />
                ))}
            </div>
            <div className="dashboard-card-footer centered">
                <Link to={"/" + GlobalConstants.APP_PATH + 'tigerboxes'}>
                    <button
                        className={"dashboard-card-button"}
                        type="button"
                    >Alle anzeigen
                    </button>
                </Link>
            </div>
        </>
    );
};
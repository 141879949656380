import React, {Component} from "react";
import {GetBillwerkAccountByOwner, GetCustomerInvoices, UpdateBillwerkCustomerAddress} from "../PremiumService";
import './PremiumType.css';
import {withToast} from "../../../util/ToastService";
import Strings from "../../../config/Strings";
import {ConvertDateToReadableString} from "../../../util/ConverterUtil";
import {TigerButton} from "../../../global/tiger-button/TigerButton";
import GlobalConstants from "../../../config/GlobalConstants";
import {Col, Form, Row, Table} from "react-bootstrap";
import {TigerSpinner} from "../../../global/tiger-spinner/TigerSpinner";
import logo from "../../../../assets/images/loader_bg.png";
import {FunctionalModal} from "../../../global/ConfirmationModal";
import {BiCheck} from "react-icons/all";

class ShopSubscriptionAccess extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accountId: props.accountId,
            contract: props.contract,
            cancellationAllowed: props.contract != null && props.contract.EndDate == null &&
                props.subscription != null && props.subscription.articleId != null && props.subscription.articleId.indexOf("tigerticket") < 0,
            customer: {Address: {}},
            updatedCustomer: {Address: {}},
            paymentData: (props.contract != null && props.contract.PaymentBearer != null) ? props.contract.PaymentBearer : null,
            invoices: [],
            loading: true,
            editCustomer: false
        }
    }

    async componentDidMount() {
        await this.loadInvoices();
    }

    async loadInvoices() {
        let invoicesRes = await GetCustomerInvoices(null);
        if (!invoicesRes.error) {
            let customer = {Address: {}};
            let customerRes = await GetBillwerkAccountByOwner(this.state.accountId);
            if (!customerRes.error) {
                customer = customerRes.result;
                if (customer.Address == null) {
                    customer.Address = {};
                }
            }
            this.setState(prevState => ({
                ...prevState,
                invoices: invoicesRes.result.filter(i => (i.details != null && i.details.downloadUrl != null)),
                customer: customer,
                updatedCustomer: customer,
                loading: false
            }))
        } else {
            this.setState(prevState => ({...prevState, loading: false}));
        }
    }

    async updateBillwerkCustomer() {
        let updatedCustomer = this.state.customer;
        if (updatedCustomer.Address != null && (updatedCustomer.Address.Country == null || updatedCustomer.Address.Country === "")) {
            updatedCustomer.Address.Country = "DE";
        }

        let response = await UpdateBillwerkCustomerAddress(this.state.accountId, this.state.updatedCustomer);
        if (!response.error) {
            this.setState(prevState => ({
                ...prevState,
                customer: response.result,
                updatedCustomer: response.result,
                editCustomer: false
            }));
        } else {
            this.props.addToast("Beim Ändern der Daten ist leider ein Fehler aufgetreten", {autoDismiss: true, appearance: "error"});
        }
    }

    isCreditCard() {
      return (this.state.paymentData && this.state.paymentData.Type != null && this.state.paymentData.Type === "CreditCard");
    }

    isSepa() {
        return (this.state.paymentData && this.state.paymentData.Type != null && this.state.paymentData.Type === "BankAccount");
    }

    isPaypal() {
        return (this.state?.paymentData?.Type === "PayPal");
    }

    customerAdressExists() {
        return (this.state.customer != null && this.state.customer.Address != null);
    }

    getCcValidity() {
        const month = this.state.paymentData && this.state.paymentData.ExpiryMonth;
        const year = this.state.paymentData && this.state.paymentData.ExpiryYear;
        if (month && year) {
            return month + "/" + year;
        } else {
            return null;
        }
    }

    getValueOrPlaceholder(originalString, value, placeholder = "-") {
        if (value != null && value.trim() !== "") {
            return (<><strong>{originalString}</strong>{value}</>);
        } else {
            return (<><strong>{originalString}</strong>{placeholder}</>);
        }
    }

    getCountryName() {
        if (this.state.customer == null || this.state.customer.Address == null || this.state.customer.Address.Country == null) {
            return null;
        }

        if (this.state.customer.Address.Country === "DE") {
            return Strings.PURCHASE_COUNTRY_DE;
        } else if (this.state.customer.Address.Country === "AT") {
            return Strings.PURCHASE_COUNTRY_AT;
        } else if (this.state.customer.Address.Country === "CH") {
            return Strings.PURCHASE_COUNTRY_CH;
        } else {
            return null;
        }
    }

    openSelfService() {
        this.props.history.push("/" + GlobalConstants.APP_PATH + "premium/settings")
    }


    customerIsValid() {
        if (this.state.customer == null || this.state.updatedCustomer == null) {
            return true;
        }

        //If the previous customer had an adress, the updated one should have one to
        if (this.state.customer.Address != null) {
            const address = this.state.customer.Address;
            const updatedAddress = this.state.updatedCustomer.Address;
            if (address.Street != null && (updatedAddress.Street == null || updatedAddress.Street === "")) {
                return false;
            } else if (address.HouseNumber != null && (updatedAddress.HouseNumber == null || updatedAddress.HouseNumber === "")) {
                return false;
            } else if (address.PostalCode != null && (updatedAddress.PostalCode == null || updatedAddress.PostalCode === "")) {
                return false;
            } else if (address.City != null && (updatedAddress.City == null || updatedAddress.City === "")) {
                return false;
            }
        }
        if (this.state.customer.FirstName != null && (this.state.updatedCustomer.FirstName == null || this.state.updatedCustomer.FirstName === "")) {
            return false;
        } else if (this.state.customer.LastName != null && (this.state.updatedCustomer.LastName == null || this.state.updatedCustomer.LastName === "")) {
            return false;
        } else if (this.state.customer.EmailAddress != null && (this.state.updatedCustomer.EmailAddress == null || this.state.updatedCustomer.EmailAddress === "")) {
            return false;
        }
        return true;
    }

    render() {
        return (
            <>
                <div className="premium-type-container">
                    {this.state.loading &&
                    <TigerSpinner/>
                    }
                    {!this.state.loading && (this.state.customer == null || this.state.customer.Address == null) && !this.isCreditCard() && !this.isSepa() && this.state.invoices.length === 0 &&
                    <div style={{textAlign: "center"}}>
                        <img src={logo} alt="logo"/>
                        <div className="premium-type-header" style={{color: "#616060", marginTop: "20px"}}>
                            {Strings.PREMIUM_NO_INVOICES}
                        </div>
                    </div>
                    }
                    {!this.state.loading &&
                    <div>
                        <div className="premium-type-wrapper">
                            {this.state.customer != null &&
                            <div className="w-100">
                                <div className="content-header premium-type-header"
                                     style={{color: '#6c757d'}}>{Strings.PREMIUM_SETTINGS_ADRESS}</div>
                                {(this.state.customer.FirstName != null || this.state.customer.LastName != null) &&
                                <div>{this.state.customer.FirstName} {this.state.customer.LastName}</div>
                                }
                                {this.state.customer.Address &&
                                <>
                                    <div>{this.state.customer.Address.Street} {this.state.customer.Address.HouseNumber}</div>
                                    <div>{this.state.customer.Address.PostalCode} {this.state.customer.Address.City}</div>
                                    <div>{this.getCountryName()}</div>
                                </>
                                }
                                {this.state.customer.EmailAddress != null &&
                                <div>{this.state.customer.EmailAddress}</div>
                                }
                                <br/>
                                <div style={{color: "#ce023e", cursor: "pointer"}}
                                     onClick={() => this.setState(prevState => ({...prevState, editCustomer: true}))}>
                                    {Strings.PREMIUM_SETTINGS_EDIT}&#187;
                                </div>
                            </div>
                            }

                            <div className="w-100">
                                <div className="content-header premium-type-header"
                                     style={{color: '#6c757d'}}>{Strings.PREMIUM_SETTINGS_PAYMENT}</div>
                                {this.isCreditCard() &&
                                <div>
                                    <div>{this.getValueOrPlaceholder(Strings.PREMIUM_PAYMENT_CC_TYPE, this.state.paymentData.CardType)}</div>
                                    <div>{this.getValueOrPlaceholder(Strings.PREMIUM_PAYMENT_CC_NUMBER, this.state.paymentData.MaskedCardPan)}</div>
                                    <div>{this.getValueOrPlaceholder(Strings.PREMIUM_PAYMENT_CC_VALIDITY, this.getCcValidity())}</div>
                                    <br/>
                                    <div style={{color: "#ce023e", cursor: "pointer"}}
                                         onClick={() => this.openSelfService()}>{Strings.PREMIUM_SETTINGS_EDIT}&#187;</div>
                                </div>
                                }

                                {this.isSepa() &&
                                <div>
                                    <div>{this.getValueOrPlaceholder(Strings.PREMIUM_PAYMENT_CC_TYPE, Strings.PREMIUM_PAYMENT_SEPA)}</div>
                                    <div>{this.getValueOrPlaceholder(Strings.PREMIUM_PAYMENT_SEPA_HOLDER, this.state.paymentData.Holder)}</div>
                                    <div>{this.getValueOrPlaceholder(Strings.PREMIUM_PAYMENT_SEPA_IBAN, this.state.paymentData.IBAN)}</div>
                                    <div>{this.getValueOrPlaceholder(Strings.PREMIUM_PAYMENT_SEPA_BIC, this.state.paymentData.BIC)}</div>
                                    <br/>
                                    <div style={{color: "#ce023e", cursor: "pointer"}}
                                         onClick={() => this.openSelfService()}>{Strings.PREMIUM_SETTINGS_EDIT}&#187;</div>
                                </div>
                                }

                                {this.isPaypal() &&
                                        <div>
                                            <div>{this.getValueOrPlaceholder(Strings.PREMIUM_PAYMENT_CC_TYPE, Strings.PREMIUM_PAYMENT_PP)}</div>
                                            <div>{this.getValueOrPlaceholder(Strings.PREMIUM_PAYMENT_PAYPAL_EMAIL, this.state.paymentData.EmailAddress)}</div>
                                            <div>{this.getValueOrPlaceholder(Strings.PREMIUM_PAYMENT_PAYPAL_FIRSTNAME, this.state.paymentData.FirstName)}</div>
                                            <div>{this.getValueOrPlaceholder(Strings.PREMIUM_PAYMENT_PAYPAL_LASTNAME, this.state.paymentData.LastName)}</div>
                                            <br/>
                                            <div style={{color: "#ce023e", cursor: "pointer"}}
                                                 onClick={() => this.openSelfService()}>{Strings.PREMIUM_SETTINGS_EDIT}&#187;</div>
                                        </div>
                                }

                                {!this.isSepa() && !this.isCreditCard() && !this.isPaypal() &&
                                <div>
                                    <div dangerouslySetInnerHTML={{__html: Strings.PREMIUM_PAYMENT_NO_DATA}}/>
                                    <br/>
                                    <div style={{color: "#ce023e", cursor: "pointer"}}
                                         onClick={() => this.openSelfService()}>{Strings.PREMIUM_SETTINGS_EDIT}&#187;</div>
                                </div>
                                }
                            </div>
                        </div>
                        {this.state.invoices.length > 0 &&
                        <>
                            <div className="content-header premium-type-header"
                                 style={{color: '#6c757d'}}>{Strings.PREMIUM_INVOICES_LABEL}</div>
                            <div className="premium-type-table">
                                <Table striped>
                                    <thead>
                                    <tr>
                                        <th>Nummer:</th>
                                        <th>Vom:</th>
                                        <th/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.invoices.map(item => (
                                        <tr role="row" key={item.InvoiceNumber}>
                                            <td>{item.InvoiceNumber}</td>
                                            <td>{ConvertDateToReadableString(item.Created)}</td>
                                            <td>
                                                <a href={item.details.downloadUrl} className="button-link">
                                                    <TigerButton className="btn-ci-sm btn-ci-hover"
                                                                 variant="red"
                                                                 noOutline="true">
                                                        {Strings.PREMIUM_INVOICES_DOWNLOAD_BUTTON}
                                                    </TigerButton>
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </div>
                        </>
                        }
                        {this.state.cancellationAllowed &&
                        <div>
                            <a href={GlobalConstants.URL_CANCEL_CONTRACTS}
                               style={{color: "#ce023e"}}>{Strings.PREMIUM_SHOW_CANCEL_CONTRACT}&#187;</a>
                        </div>
                        }
                    </div>
                    }
                </div>
                <FunctionalModal
                    title={Strings.PURCHASE_EDIT_CUSTOMER_TITLE}
                    show={this.state.editCustomer}
                    actionButtonLabel={<><BiCheck/>{Strings.PROFILES_DETAILS_SAVE_BUTTON}</>}
                    actionButtonVariant="red"
                    onActionPress={() => this.updateBillwerkCustomer()}
                    actionDisabled={!this.customerIsValid()}
                    onHide={() => this.setState(prevState => ({
                        ...prevState,
                        editCustomer: false,
                        updatedCustomer: this.state.customer
                    }))}
                >
                    {this.editCustomerDialog()}
                </FunctionalModal>
            </>
        );
    }


    editCustomerDialog() {
        if (this.state.updatedCustomer == null) {
            return <></>
        }

        return (
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>{Strings.PURCHASE_CONTACT_FIRSTNAME}</Form.Label>
                    <Form.Control onChange={(e) => this.setState(prevState => ({
                                      ...prevState,
                                      updatedCustomer: {
                                          ...prevState.updatedCustomer,
                                          FirstName: e.target.value
                                      }}))}
                                  value={this.state.updatedCustomer.FirstName}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>{Strings.PURCHASE_CONTACT_LASTNAME}</Form.Label>
                    <Form.Control onChange={(e) => this.setState(prevState => ({
                        ...prevState,
                        updatedCustomer: {
                            ...prevState.updatedCustomer,
                            LastName: e.target.value
                        }}))}
                                  value={this.state.updatedCustomer.LastName}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>{Strings.PURCHASE_CONTACT_EMAIL}</Form.Label>
                    <Form.Control onChange={(e) => this.setState(prevState => ({
                        ...prevState,
                        updatedCustomer: {
                            ...prevState.updatedCustomer,
                            EmailAddress: e.target.value
                        }}))}
                                  value={this.state.updatedCustomer.EmailAddress}/>
                </Form.Group>
                <Row>
                    <Form.Group as={Col} className="mb-3">
                        <Form.Label>{Strings.PURCHASE_CONTACT_STREET}</Form.Label>
                        <Form.Control onChange={(e) => this.setState(prevState => ({
                            ...prevState,
                            updatedCustomer: {
                                ...prevState.updatedCustomer,
                                Address: {
                                    ...prevState.updatedCustomer.Address,
                                    Street: e.target.value
                                }
                            }}))}
                                      value={this.state.updatedCustomer.Address.Street}/>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                        <Form.Label>{Strings.PURCHASE_CONTACT_HOUSE_NO}</Form.Label>
                        <Form.Control onChange={(e) => this.setState(prevState => ({
                            ...prevState,
                            updatedCustomer: {
                                ...prevState.updatedCustomer,
                                Address: {
                                    ...prevState.updatedCustomer.Address,
                                    HouseNumber: e.target.value
                                }
                            }}))}
                                      value={this.state.updatedCustomer.Address.HouseNumber}/>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} className="mb-3">
                        <Form.Label>{Strings.PURCHASE_CONTACT_POSTAL_CODE}</Form.Label>
                        <Form.Control onChange={(e) => this.setState(prevState => ({
                            ...prevState,
                            updatedCustomer: {
                                ...prevState.updatedCustomer,
                                Address: {
                                    ...prevState.updatedCustomer.Address,
                                    PostalCode: e.target.value
                                }
                            }}))}
                                      value={this.state.updatedCustomer.Address.PostalCode}/>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                        <Form.Label>{Strings.PURCHASE_CONTACT_CITY}</Form.Label>
                        <Form.Control onChange={(e) => this.setState(prevState => ({
                            ...prevState,
                            updatedCustomer: {
                                ...prevState.updatedCustomer,
                                Address: {
                                    ...prevState.updatedCustomer.Address,
                                    City: e.target.value
                                }
                            }}))}
                                      value={this.state.updatedCustomer.Address.City}/>
                    </Form.Group>
                </Row>
                <Form.Group className="mb-3">
                    <Form.Label>{Strings.PURCHASE_CONTACT_COUNTRY}</Form.Label>
                    <Form.Control
                        as="select"
                        required
                        value={this.state.updatedCustomer.Address.Country}
                        onChange={(e) => this.setState(prevState => ({
                            ...prevState,
                            updatedCustomer: {
                                ...prevState.updatedCustomer,
                                Address: {
                                    ...prevState.updatedCustomer.Address,
                                    Country: e.target.value
                                }
                            }}))}
                    >
                        <option value="DE">{Strings.PURCHASE_COUNTRY_DE}</option>
                        <option value="CH">{Strings.PURCHASE_COUNTRY_CH}</option>
                        <option value="AT">{Strings.PURCHASE_COUNTRY_AT}</option>
                    </Form.Control>
                </Form.Group>
            </Form>
        )
    }

    invoiceItem(item) {
        return (
            <>
                <div className="my-0 ticket-item-minimal py-0">
                    <div className="ticket-item-details-minimal my-0">
                        <strong>{Strings.PREMIUM_TICKET_TABLE_PASS_CODE}</strong> {item.InvoiceNumber}
                    </div>
                    <div className="ticket-item-details-minimal">
                        <strong>{Strings.PREMIUM_TICKET_TABLE_COUPON_NAME}</strong> {ConvertDateToReadableString(item.Created)}
                    </div>
                    <div className="ticket-item-details-minimal">
                        <span style={{color: '#ce023e', textDecoration: "underline"}}>{Strings.PREMIUM_TICKET_TABLE_MORE_DETAILS}&#187;</span>
                    </div>
                </div>
            </>
        );
    }
}

export default withToast(ShopSubscriptionAccess);
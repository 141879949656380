import React, {useState} from "react";
import './HuibuhRedemption.css';
import Strings from "../../../config/Strings";
import {Form, Spinner} from "react-bootstrap";
import {TigerButton} from "../../../global/tiger-button/TigerButton";
import {GetFilteredResources, GetUnauthorizedResources} from "../../../api/SpineClient";
import GlobalConstants from "../../../config/GlobalConstants";
import {InfoModal} from "../../../global/ConfirmationModal";
import {useHistory, useLocation} from "react-router";
import huibuhlogo from "../../../../assets/images/huibuh-logo-schloss.png";
import huibuh_graphic_1 from "../../../../assets/images/huibuh-lp-grafik-01.jpg";
import huibuh_graphic_3 from "../../../../assets/images/huibuh-lp-grafik-02.jpg";
import tigerbox_touch_logo from "../../../../assets/images/tigerbox_touch_logo.png";
import tigertones_app_icon from "../../../../assets/images/ddf-tigertones-app-icon.png";
import huibuh_graphic_4 from "../../../../assets/images/huibuh-lp-grafik-03.png";
import tigertones_logo from "../../../../assets/images/ddf-tigertones-logo-k.png";

export default function HuibuhRedemptionTicketValidation() {

    const queryParams = new URLSearchParams(window.location.search);

    const [form, setForm] = useState({code: queryParams.get("code"), pin: null});
    const [formError, setFormError] = useState({code: null, pin: null});
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorText, setErrorText] = useState(null);
    const history = useHistory();
    const location = useLocation();

    document.title = Strings.HUIBUH_REDEMPTION_BROWSER_TAB;

    if (location !== undefined && location.hash !== "") {
        const element = document.getElementById(location.hash);
        if (element) {
            element.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }


    //Submitting
    const validateTicket = async e => {
        setLoading(true);
        e.preventDefault();

        //First check if it's a huibuh
        let minimalCoupon = await GetUnauthorizedResources('api/purchases/coupons/search/minimal-by-code', {code: form.code}, 'application/json', GlobalConstants.BILL_HOST);
        if (minimalCoupon.error) {
            if (minimalCoupon.result === 404) {
                setFormError({
                    ...formError,
                    code:  Strings.HUIBUH_REDEMPTION_CODE_ERROR_1
                });
            } else {
                setErrorText(Strings.HUIBUH_REDEMPTION_UNKNOWN_ERROR);
                setShowError(true);
            }
            setLoading(false);
            return;

        } else {
            var today = new Date().toJSON().slice(0, 10);
            if (minimalCoupon.result.couponType === "HUIBUH_LESUNG" && today < '2022-11-04') {
                setFormError({
                    ...formError,
                    code:  Strings.HUIBUH_REDEMPTION_CODE_ERROR_LESUNG
                });
                return;
            } else if (minimalCoupon.result.couponType !== "HUIBUH_LESUNG" && minimalCoupon.result.couponType !== "HUIBUH" ) {
                setFormError({
                    ...formError,
                    code:  Strings.HUIBUH_REDEMPTION_CODE_ERROR_1
                });
                return;
            }
        }

        let validation = await GetFilteredResources('api/purchases/coupons/validate', form, 'application/json', GlobalConstants.BILL_HOST);

        setLoading(false);

        if (validation.error) {
            setErrorText(Strings.HUIBUH_REDEMPTION_UNKNOWN_ERROR)
            setShowError(true);
        } else {
            await handleValidationResult(validation.result);
        }
    }

    const handleValidationResult = async (result) => {
        let valid = result.valid;
        const code = result.code;
        let alreadyUsed = false;

        //Validate the response from the purchase service
        switch (code) {
            case 1:
                //Coupon does not exist
                setFormError({
                    ...formError,
                    code:  Strings.HUIBUH_REDEMPTION_CODE_ERROR_1
                });
                break;
            case 2:
                //Coupon temporary disabled for X Minutes
                setFormError({
                    ...formError,
                    code: Strings.HUIBUH_REDEMPTION_CODE_ERROR_2.replace("{0}", result.value)
                });
                break;
            case 3:
                //Wrong PIN provided for last time -> Coupon is now disabled
                setFormError({
                    ...formError,
                    code: Strings.HUIBUH_REDEMPTION_CODE_ERROR_3
                });
                break;
            case 4:
                //Wrong PIN provided -> X retries left
                setFormError({
                    ...formError,
                    pin: Strings.HUIBUH_REDEMPTION_PIN_ERROR_1.replace("{0}", result.value)
                });
                break;
            case 5:
                //Coupon has been already used
                alreadyUsed = true;
                break;
            default:
                //Coupopn has not been used yet
                valid = true;
                break;
        }

        //In case of an already used ticket, redirect to huibuh product (depending on client)
        if (alreadyUsed) {
            setFormError({
                ...formError,
                code:  Strings.HUIBUH_REDEMPTION_CODE_ERROR_ALREADY_USED
            });
        } else if (valid) {
            //It's not used -> It can be redeemed (proceed to next step)
            sessionStorage.setItem('ls.huibuh', JSON.stringify(form));
            history.push("/" + GlobalConstants.APP_PATH + "huibuh/authenticate#huibuh-authentication-register")
        }
    }

    const updateForm = (field, value) => {
        setForm({
            ...form,
            [field]: value.toUpperCase()
        });
        setFormError({
            ...formError,
            [field]: null
        });
    }

    //Rendering
    return (
        <div className="huibuh-container" style={{backgroundColor: "black"}}>
            <div className="huibuh-logo"><img alt="logo" src={huibuhlogo} style={{width: "100%", maxWidth:"600px"}}  id="#huibuh-validation"/></div>
            <h1 className="huibuh-title" dangerouslySetInnerHTML={{__html: Strings.HUIBUH_REDEMPTION_TITLE}} />
            <div className="huibuh-info" dangerouslySetInnerHTML={{__html: Strings.HUIBUH_REDEMPTION_INFO}} />

            <div className="huibuh-section-black">
                <div className="huibuh-form">
                    <Form className="text-left" onSubmit={(e) => validateTicket(e)}>
                        {/* CODE */}
                        <Form.Group className="mb-3" controlId="ticketRedemptionTicketNo">
                            <Form.Label className="auth-label">Code:</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                isInvalid={ formError.code != null}
                                value={form.code}
                                onChange={e => updateForm('code', e.target.value)}
                                plaintext={"Code"}
                                placeholder={Strings.HUIBUH_REDEMPTION_CODE_PLACEHOLDER}
                            />

                            <Form.Control.Feedback type="invalid">
                                {formError.code}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* PIN */}
                        <Form.Group className="mb-3" controlId="ticketRedemptionTicketPin">
                            <Form.Label className="auth-label">{Strings.TICKET_REDEMPTION_TICKET_PIN}</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                isInvalid={ formError.pin != null}
                                value={form.pin}
                                onChange={e => updateForm('pin', e.target.value)}
                                plaintext={"PIN"}
                                placeholder={Strings.HUIBUH_REDEMPTION_PIN_PLACEHOLDER}
                            />

                            <Form.Control.Feedback type="invalid">
                                {formError.pin}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <TigerButton className="w-100" variant="red" disabled={loading}>
                            {loading ?
                                <Spinner animation="border" />
                                :
                                Strings.TICKET_REDEMPTION_SUBMIT_CREDENTIALS}
                        </TigerButton>

                    </Form>
                </div>
            </div>

            <div className="huibuh-image-section-black">
                <div className="huibuh-section-image-content-shifted">
                    <img src={huibuh_graphic_1} alt={"Hui Buh VIP-Pass"} />
                </div>
                <br/>
            </div>
            <div className="huibuh-section-white">
                <div className="huibuh-section-text-content">
                    <div className="huibuh-section-headline" dangerouslySetInnerHTML={{__html: Strings.HUIBUH_REDEMPTION_HEADLINE_2}} />
                    <br />
                    <div dangerouslySetInnerHTML={{__html: Strings.HUIBUH_REDEMPTION_INFO_2}} />
                </div>
            </div>
            <div className="huibuh-section-black">
                <div className="huibuh-section-text-content">
                    <div className="huibuh-section-headline" dangerouslySetInnerHTML={{__html: Strings.HUIBUH_REDEMPTION_HEADLINE_4}} />
                </div>
            </div>
            <div className="huibuh-image-section-black">
                <div className="huibuh-section-image-content-shifted">
                    <img src={huibuh_graphic_3} alt={"Hui Buh  VIP-Pass"} />
                </div>
            </div>
            <div className="huibuh-section-black">
                <div className="huibuh-section-text-content" dangerouslySetInnerHTML={{__html: Strings.HUIBUH_REDEMPTION_INFO_4}} />
                <br />
                <div className="huibuh-section-image-content">
                    <img src={tigerbox_touch_logo} alt={"tigerbox TOUCH logo"} />
                </div>
            </div>
            <div className="huibuh-section-white">
                <div className="huibuh-section-text-content">
                    <div className="huibuh-section-headline" dangerouslySetInnerHTML={{__html: Strings.HUIBUH_REDEMPTION_HEADLINE_5}} />
                    <br />
                    <img src={tigertones_app_icon} className="huibuh-icon" alt={"tigertones icon"} />
                    <br />
                    <br />
                    <div dangerouslySetInnerHTML={{__html: Strings.HUIBUH_REDEMPTION_INFO_5}} />
                </div>
                <div className="huibuh-section-image-content">
                    <img src={huibuh_graphic_4} alt={"Hui Buh VIP-Pass"}/>
                    <br />
                    <img src={tigertones_logo} className="huibuh-icon" alt={"tigertones logo"} />
                </div>
            </div>

            <InfoModal
                title={Strings.REGISTER_UNKNOWN_ERROR_TITLE}
                text={errorText}
                show={showError}
                onHide={() => setShowError(false)} />
        </div>
    )

}
import React from "react";

const months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli","August","September","Oktober","November","Dezember"];
const years = [...Array(100).keys()].map(i => i + 1970);

export function ConvertDateToReadableString(dateIn, withTime = false, monthAsName = true) {
    if (dateIn == null) {
        return "";
    }
    const date = new Date(dateIn);
    if (withTime) {
        const hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        const minutes = date.getMinutes() < 10? "0" + date.getMinutes() : date.getMinutes();
        const month = monthAsName ? " " + months[date.getMonth()] + " " :
            (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) + "." : (date.getMonth() + 1) + ".");
        return date.getDate() + "." + month + date.getFullYear() + " um " + hours + ":" + minutes;
    } else {
        const month = monthAsName ? " " + months[date.getMonth()] + " " :
            (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) + "." : (date.getMonth() + 1) + ".");
        return date.getDate() + "." + month + date.getFullYear();
    }

}

export function ConvertPeriodToDurationString(period) {
    if (period == null || period.quantity == null || period.unit == null) {
        return "";
    }

    let plural = false;
    const quantity = period.quantity;
    const unit = period.unit.toLowerCase();

    if (quantity > 1) {
        plural = true;
    }

    let unitString;
    switch (unit) {
        case "day":
            unitString = plural ? "Tage" : "Tag";
            break;
        case "week":
            unitString = plural ? "Wochen" : "Woche";
            break;
        case "year":
            unitString = plural ? "Jahre" : "Jahr";
            break;
        default:
            unitString = plural ? "Monate" : "Monat";
            break;
    }

    return quantity + " " + unitString;
}

export function TranslatePurchaseServiceError(message) {
    if (message == null || message === "") {
        return "";
    }

    const messageArray = message.split["/"];
    switch (messageArray.length) {
        case 1:
            return {httpCode: null, code: null, message: messageArray[0]};
        case 2:
            return {httpCode: messageArray[0], code: null, message: messageArray[1]}
        case 3:
            return {httpCode: messageArray[0], code: messageArray[1], message: messageArray[2]}
        default:
            return message;
    }

}

export function transformToDatepickerFormat(dateFromResource) {

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    if(dateFromResource != null) {
        //Get the year, month and day as number
        const year = dateFromResource.substring(0, 4);
        let month = dateFromResource.substring(5, 7);
        let day = dateFromResource.substring(8, 10);

        //Remove the leading "0" in month and day (is there is any)
        if (month[0] === "0") {
            month = month.substring(1);
        }
        if (day[0] === "0") {
            day = day.substring(1);
        }

        //Now return the date in the format "XXX Jul 27 2021 00:00:00 GMT+0200 (CEST)"
        return Date.parse(months[month - 1] + " " + day + " " + year + " 00:00:00 GMT");
    } else {
        return "";
    }
}

export function transformToLocalDate(dateFromPicker) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    //Remove the name of the weekday (first 4 chars)
    dateFromPicker = dateFromPicker.substring(4);

    //Get the day, month and year
    let day = dateFromPicker.substring(4,6);
    let month = months.indexOf(dateFromPicker.substring(0,3)) + 1;
    let year = dateFromPicker.substring(7,11);

    //Make sure that the month has to chars, i.e. "2" -> "02"
    if(month < 10) {
        month = "0" + month;
    }
    return year + "-" + month + "-" + day;
}

export function transformToLocalDateTime(dateFromPicker) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    //Remove the name of the weekday (first 4 chars)
    dateFromPicker = dateFromPicker.substring(4);

    //Get the day, month and year
    let day = dateFromPicker.substring(4,6);
    let month = months.indexOf(dateFromPicker.substring(0,3)) + 1;
    let year = dateFromPicker.substring(7,11);
    let hours = dateFromPicker.substring(12, 14);
    let minutes = dateFromPicker.substring(15, 17);
    let seconds = dateFromPicker.substring(18, 20);

    //Make sure that the month has to chars, i.e. "2" -> "02"
    if(month < 10) {
        month = "0" + month;
    }
    return year + "-" + month + "-" + day + "T" + hours + ":" + minutes + ":" + seconds;
}

export function transformStringToReadableDate(dateFromResource, withTime = false) {

    if(dateFromResource != null && dateFromResource !== "") {
        //Get the year, month and day as number
        const year = dateFromResource.substring(0, 4);
        let month = dateFromResource.substring(5, 7);
        let day = dateFromResource.substring(8, 10);

        let hour = "";
        let minute = "";
        let second = "";
        if(withTime) {
            hour = dateFromResource.substring(11, 13);
            minute = dateFromResource.substring(14,16);
            second = dateFromResource.substring(17,19);
        }

        //Now return the date in the format "XXX Jul 27 2021 00:00:00 GMT+0200 (CEST)"
        if(withTime) {
            return day + "." + month + "." + year + " " + hour + ":" + minute + ":" + second;
        } else {
            return day + "." + month + "." + year;
        }
    } else {
        return "";
    }
}

export function customDatePickerHeader(date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled) {
    return (
        <div
            style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
            }}
        >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} type="button">
                {"<"}
            </button>
            <select
                style={{fontSize: "18px", marginTop: "4px"}}
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(value)}
            >
                {years.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>

            <select
                style={{fontSize: "18px", marginTop: "4px"}}
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                }
            >
                {months.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} type="button">
                {">"}
            </button>
        </div>
    )
}

export function ProfileBirthdayDatepickerHeader(date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled, deleteBirthday, disableBirthdayDeletion) {
    return (
        <>
            {customDatePickerHeader(date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled)}
        </>
    )
}

export function SortByAlphabet(a, b, attribute) {
    if (a[attribute] < b[attribute]) {
        return -1;
    }
    if (a[attribute] > b[attribute]) {
        return 1;
    }
    return 0;
}
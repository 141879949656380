import React, {Component} from "react";
import './PremiumType.css';
import Strings from "../../../config/Strings";
import {withToast} from "../../../util/ToastService";

class IAPAccess extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accountId: props.accountId,
            subscription: props.subscription
        }
    }

    async componentDidMount() {

    }

    getIapType() {
        let source = this.state.subscription.source;
        if (source == null) {
            return Strings.PREMIUM_IAP_TYPE_VARIOUS;
        }
        if (source.indexOf('_GOOGLE') > -1) {
            return Strings.PREMIUM_IAP_TYPE_GOOGLE;
        } else if (source.indexOf('_APPLE') > -1) {
            return Strings.PREMIUM_IAP_TYPE_APPLE;
        } else {
            return Strings.PREMIUM_IAP_TYPE_VARIOUS;
        }
    }

    getManagementInfo() {
        const type = this.getIapType();
        if (type === Strings.PREMIUM_IAP_TYPE_GOOGLE) {
            return (
                <div dangerouslySetInnerHTML={{__html: Strings.PREMIUM_IAP_INFO_MANAGE
                        .replace("{0}", Strings.PREMIUM_IAP_TYPE_GOOGLE)
                        .replace("{1}", "http://support.google.com/googleplay?p=cancelsubsawf")}} />
            );
        } else if (type === Strings.PREMIUM_IAP_TYPE_APPLE) {
            return (
                <div dangerouslySetInnerHTML={{__html: Strings.PREMIUM_IAP_INFO_MANAGE
                        .replace("{0}", Strings.PREMIUM_IAP_TYPE_APPLE)
                        .replace("{1}", "https://apps.apple.com/account/subscriptions")}} />
            );
        } else {
            return null;
        }
    }

    render() {
        return(
            <>
                <div className="premium-type-container">
                    <div className="premium-type-header" style={{color: "#333333", fontWeight: "normal"}}>
                        {this.getManagementInfo()}
                    </div>
                </div>
            </>
        );
    }
}

export default withToast(IAPAccess);
import Strings from "../../../config/Strings";
import React from "react";
import './PremiumType.css';
import {TigerButton} from "../../../global/tiger-button/TigerButton";
import GlobalConstants from "../../../config/GlobalConstants";

export default function InactiveAccess(props) {
    return(
        <>
            <div className="premium-type-container">
                <div className="content-header premium-type-header"
                     style={{color: '#f07319'}}>{Strings.PREMIUM_INACTIVE_SUBSCRIPTION_HEADER}</div>
                <div className="ticket-item-details py-0" dangerouslySetInnerHTML={{__html: Strings.PREMIUM_INACTIVE_SUBSCRIPTION_INFO}} />
                <br />
                <div style={{width: "max-content", margin: "auto"}}>
                    <a href="https://tiger.media/pages/tigertones##shopify-section-template--22721234207068__portfolio_rcnBec" className="button-link">
                        <TigerButton variant="red" type="button">{Strings.PREMIUM_INACTIVE_SHOP_BUTTON}</TigerButton>
                    </a>
                </div>
                <hr />
                <div className="content-header premium-type-header"
                     style={{color: '#00b4eb'}}>{Strings.PREMIUM_INACTIVE_TICKET_HEADER}</div>
                <div className="ticket-item-details py-0" dangerouslySetInnerHTML={{__html: Strings.PREMIUM_INACTIVE_TICKET_INFO}} />
                <br />
                <div className="premium-type-wrapper">
                    <div style={{width: "max-content", margin: "auto"}}>
                        <a href="https://tiger.media/pages/tigertones##shopify-section-template--22721234207068__portfolio_rcnBec" className="button-link">
                            <TigerButton variant="red" type="button">{Strings.PREMIUM_INACTIVE_SHOP_BUTTON}</TigerButton>
                        </a>
                    </div>
                    <div style={{width: "max-content", margin: "auto"}}>
                        <a href={GlobalConstants.UI_HOST + "ticket"} className="button-link">
                            <TigerButton variant="red" type="button">{Strings.PREMIUM_INACTIVE_REDEEM_TICKET_BUTTON}</TigerButton>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}
import React, { Component } from "react";
import { Card, Col } from "react-bootstrap";
import { FiTrash } from "react-icons/fi";
import logo from "../../../assets/images/loader_bg.png";
import Strings from "../../config/Strings";
import { TigerButton } from "../../global/tiger-button/TigerButton";
import { GetProduct, GetProductTitle } from "../products/ProductService";

class AudiobookDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            product: null
        };
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.product !== this.props.product) {
            //await this.loadProduct();
        }
    }

    async componentDidMount() {
        await this.loadProduct();
    }

    async loadProduct() {
        if (this.props.product == null && this.props.productId != null) {
            GetProduct(this.props.productId).then((response) => {
                if (!response.error) {
                    //We only want to show products with state = 'ACTIVE'
                    const product = response.result._embedded.simpleHalRepresentationModels[0];
                    if (product && product.state === 'ACTIVE') {
                        this.setState({
                            product: product,
                        });
                    } else {
                        this.setState({
                            product: null
                        });
                    }
                }
            });
        } else {
            this.setState({
                product: this.props.product,
            });
        }
    }

    render() {
        const p = this.state.product;
        let title = GetProductTitle(p);

        return (
            p &&
            <Card className="rounded-card last-used-item justify-content-between">
                    <Col className="last-used-item-cover-container">
                        {p.cover ? (
                            <img
                                src={p.cover.contentLocation.location}
                                className="w-100"
                                alt=""
                            />
                        ) : (
                            <img
                                src={logo}
                                className="w-100"
                                style={{ opacity: "0.6" }}
                                alt=""
                            />
                        )}
                    </Col>
                    <Col className="pl-0">
                        <Card.Body className="pl-0 h-100">
                            <Card.Title className={"last-used-item-title"}><a href={"/product/" + p.id}>{title}</a></Card.Title>
                            <Card.Text dangerouslySetInnerHTML={{__html:p.description}}></Card.Text>
                        </Card.Body>
                    </Col>
                    <TigerButton
                            className="btn-ci-sm btn-ci-hover delete-last-used-item-button"
                            style={{alignSelf: "end"}}
                            variant="red"
                            noOutline="true"
                            onClick={this.props.removeAudiobook}
                    >
                        <FiTrash /> {Strings.AUDIOBOOKS_REMOVE_BUTTON_LABEL}
                    </TigerButton>
            </Card>
        );
    }
}

export default AudiobookDetails;

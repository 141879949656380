import React from "react";
import './Purchase.scss';
import Strings from "../../../config/Strings";

export default function PurchaseStatus(props) {

    const purchaseStatusStepClasses = "purchase-status-step" + (props.bundled ? " bundled" : "")
    //Rendering
    return (
        <div className="purchase-status">
            { props.bundled &&
                <div className={purchaseStatusStepClasses} onClick={() => props.onChangeStep(0)}>
                    <div className={props.step === 0 ? "purchase-status-step-number purchase-status-step-number-active" :
                            "purchase-status-step-number purchase-status-step-number-inactive"}>
                        1
                    </div>
                    <div className={props.step === 0 ? "purchase-status-step-text-active" : "purchase-status-step-text-inactive"}>
                        {Strings.PURCHASE_STATUS_STEP_0}
                    </div>
                </div>
            }
            <div className={purchaseStatusStepClasses} onClick={() => props.onChangeStep(1)}>
                <div className={props.step === 1 ? "purchase-status-step-number purchase-status-step-number-active" :
                        (props.maxStep >= 1 ? "purchase-status-step-number purchase-status-step-number-inactive" :
                                "purchase-status-step-number purchase-status-step-number-blocked")}>
                    {props.bundled ? "2" : "1"}
                </div>
                <div className={props.step === 1 ? "purchase-status-step-text-active" : (props.maxStep >= 1 ? "purchase-status-step-text-inactive" : "purchase-status-step-text-blocked")}
                     dangerouslySetInnerHTML={{__html: Strings.PURCHASE_STATUS_STEP_1}}>
                </div>
            </div>
            <div className={purchaseStatusStepClasses} onClick={() => props.onChangeStep(2)}>
                <div className={props.step === 2 ? "purchase-status-step-number purchase-status-step-number-active" :
                    (props.maxStep >= 2 ? "purchase-status-step-number purchase-status-step-number-inactive" :
                    "purchase-status-step-number purchase-status-step-number-blocked")}>
                    {props.bundled ? "3" : "2"}
                </div>
                <div className={props.step === 2 ? "purchase-status-step-text-active" : (props.maxStep >= 2 ? "purchase-status-step-text-inactive" : "purchase-status-step-text-blocked")}>
                    {Strings.PURCHASE_STATUS_STEP_2}
                </div>
            </div>
            <div className={purchaseStatusStepClasses} onClick={() => props.onChangeStep(3)}>
                <div className={props.step === 3 ? "purchase-status-step-number purchase-status-step-number-active" :
                    (props.maxStep >= 3 ? "purchase-status-step-number purchase-status-step-number-inactive" :
                    "purchase-status-step-number purchase-status-step-number-blocked")}>
                    {props.bundled ? "4" : "3"}
                </div>
                <div className={props.step === 3 ? "purchase-status-step-text-active" : (props.maxStep >= 3 ? "purchase-status-step-text-inactive" : "purchase-status-step-text-blocked")}>
                    {Strings.PURCHASE_STATUS_STEP_3}
                </div>
            </div>
            <div className={purchaseStatusStepClasses} onClick={() => props.onChangeStep(4)}>
                <div className={props.step === 4 ? "purchase-status-step-number purchase-status-step-number-active" :
                    (props.maxStep >= 4 ? "purchase-status-step-number purchase-status-step-number-inactive" :
                    "purchase-status-step-number purchase-status-step-number-blocked")}>
                    {props.bundled ? "5" : "4"}
                </div>
                <div className={props.step === 4 ? "purchase-status-step-text-active" : (props.maxStep >= 4 ? "purchase-status-step-text-inactive" : "purchase-status-step-text-blocked")}>
                    {Strings.PURCHASE_STATUS_STEP_4}
                </div>
            </div>
            <hr />
        </div>
    );
}
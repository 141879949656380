import React, { Component } from "react";
import "./PlaylistDetails.css";
import logo from "../../../assets/images/loader_bg.png";
import Strings from "../../config/Strings";
import {FaChild} from "react-icons/all";

class PlaylistItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            playlist: this.props.playlist,
            product: this.props.product
        };
    }

    async componentDidMount() {
    }


    render() {
        const product = this.state.product;
        const coverUrl = this.state.product.cover != null ? (
            this.state.product.cover.contentLocation != null ? (
                this.state.product.cover.contentLocation.location != null ? this.state.product.cover.contentLocation.location : logo
            ) : logo
        ) : (
            this.state.product._links != null ? (this.state.product._links.cover != null ? (this.state.product._links.cover.href != null ? this.state.product._links.cover.href : logo) :logo)
            :
                logo);


        return (
            <a href={"https://tgrmd.de/product/play/" + product.id + "?skipChromePage=true"} style={{textDecoration: "none"}}>
                <div className="playlist-item-cover">
                    <img src={coverUrl} style={{borderRadius: "15px", width: "100%"}}
                         alt={"Titelbild von " + product.title}/>
                </div>
                <div className="playlist-item-name">
                    {product.title.length > 64 ? product.title.substring(0, 60) + "..." : product.title}
                </div>
                {product.ageMin != null &&
                <div className="playlist-item-age">
                    <FaChild /> {Strings.PLAYLIST_ITEM_AGE.replace("{0}", product.ageMin)}
                </div>
                }
            </a>
        );
    }
}

export default PlaylistItem;

import React, {Component} from "react";
import Strings from "../../../config/Strings";
import GlobalConstants from "../../../config/GlobalConstants";
import Header from "../../../global/header/Header";
import './DeeplinkRedirect.css';
import logo from './../../../../assets/images/tiger-redirect.webp';
import {TigerButton} from "../../../global/tiger-button/TigerButton";

class DeeplinkRedirect extends Component {
    constructor(props) {
        super(props);

        const queryParams = new URLSearchParams(window.location.search);
        this.state = {
            loading: false,
            productId: queryParams.get("productId"),
            url: queryParams.get("url"),
            imageUrl: queryParams.get("imageUrl"),
            platform: queryParams.get("platform"),
            showCanvas: true,
            appStoreUrl: ""
        };

        if (this.state.platform === "apple") {
            this.state.appStoreUrl = "https://apps.apple.com/de/app/tigertones/id1269083572";
            this.state.appStoreImageUrl = "https://tiger.media/cdn/shop/files/Badge-AppStore_2x_32122b1b-b16c-4cb0-a620-82a3d978c2cc.webp?height=72&v=1715960540";
        } else if (this.state.platform === "google") {
            this.state.appStoreUrl = this.state.productId != null ? "market://launch?id=com.tigermedia.TigerAudio&url=" + GlobalConstants.TONES_DEEPLINK_TO_PRODUCT + this.state.productId
                        : (this.state.url != null ? "market://launch?id=com.tigermedia.TigerAudio&url=" + this.state.url : GlobalConstants.REDIRECT_WEBSITE)
            this.state.appStoreImageUrl = "https://tiger.media/cdn/shop/files/Badge-GooglePlay_2x_05a7c719-fb02-4af6-a2d4-4812be05a747.webp?height=72&v=1716469293";
        }
    }

    async componentDidMount() {
        document.title = Strings.DEEPLINK_REDIRECT_BROWSER_TAB;
        window.scrollTo(0, 0);
    }

    render() {

        return(
            <div className="redirect-container">
                <Header />
                <h1 className="dashboard-card-title" style={{textTransform: "uppercase", transform: "rotate(-4.2deg)", color: "rgb(165, 20, 130)", textAlign: "left", marginTop: "2em", marginRight: "5%", marginBottom: "1em", marginLeft: "5%"}}>
                    Tigertones öffnen</h1>
                <div className="redirect-button" style={{marginTop: "0"}}>
                    <a href={this.state.productId != null ? GlobalConstants.TONES_DEEPLINK_TO_PRODUCT + this.state.productId +
                            "&referrer=/product/play/" + this.state.productId :
                                (this.state.url != null ? this.state.url : GlobalConstants.REDIRECT_WEBSITE)}
                       onClick={() => this.setState({showRemovingModal: true})}>
                        <img style={{width: "50%", maxWidth: "420px", marginBottom: "1em"}} src={this.state.imageUrl ? this.state.imageUrl : logo} alt={"tiger button"}/>
                        <TigerButton
                                className="btn-ci-sm btn-ci-hover mx-auto"
                                variant="red"
                        >
                            {Strings.DEEPLINK_REDIRECT_TO_APP}
                        </TigerButton>
                    </a>


                </div>
                <h1 className="dashboard-card-title" style={{textTransform: "uppercase", transform: "rotate(-4.2deg)", color: "rgb(165, 20, 130)", textAlign: "left", marginTop: "2em", marginRight: "5%", marginBottom: "1em", marginLeft: "5%"}}>
                    Tigertones-App<br/>
                    <span style={{marginLeft: "10%"}}>herunterladen!</span></h1>
                <div className="redirect-button" style={{marginTop: "0"}}>
                    <a href={this.state.appStoreUrl}>
                        <img style={{width: "50%"}} src={this.state.appStoreImageUrl} alt={"link to store"}/>
                    </a>
                </div>
            </div>
        );
    }
}

export default DeeplinkRedirect;
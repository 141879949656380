import React from "react";
import "./StatusIndicator.css";
import Strings from "../../config/Strings";

export const StatusIndicator = (props) => {
    const { status } = props;
    return (
        <div className="indicatorWrapper mx-1">
            <div className={`indicator ${status === true ? "indicator-green" : "indicator-red"} mx-1`} />
            {status === true ? Strings.STATUS_ACTIVE : Strings.STATUS_INACTIVE}
        </div>
    );
}

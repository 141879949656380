import React, { Component } from "react";
import "./Search.scss";
import logo from "../../../assets/images/loader_bg.png";
import radioplay from "../../../assets/images/search/radioplay.png";
import audiobook from "../../../assets/images/search/audiobook.png";
import music from "../../../assets/images/search/music.png";
import Strings from "../../config/Strings";
import {GetPrevNextProductTitle, GetProductTitle} from "../products/ProductService";
import {getProductLength} from "./SearchService";


class SearchResultItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            product: this.props.product,
            prevNextItem: this.props.prevNextItem,
        };
    }

    async componentDidMount() {
    }

    render() {
        const product = this.state.product;
        const coverUrl = this.state.product.cover != null ? (
            this.state.product.cover.contentLocation != null ? (
                this.state.product.cover.contentLocation.location != null ? this.state.product.cover.contentLocation.location : logo
            ) : logo
        ) : (
            this.state.product._links != null ? (this.state.product._links.cover != null ? (this.state.product._links.cover.href != null ? this.state.product._links.cover.href : logo) :logo)
            :
                logo);

        let title;
        if (this.state.prevNextItem) {
            title = GetPrevNextProductTitle(product);
        } else {
            title = GetProductTitle(product);
        }


        return (
            <a href={"/product/" + product._embedded.slugs[0].defaultValue + "/" + product.id} style={{textDecoration: "none"}}>
                <div className="search-item-cover">
                    <div className="item-cover-background-container">
                        {product.ageMin != null &&
                                <div className="cover-age-display" dangerouslySetInnerHTML={{__html:Strings.SEARCH_ITEM_AGE.replace("{0}", product.ageMin)}}/>
                        }
                        {product.productClassification === 'RADIOPLAY' &&
                                <img src={radioplay} className="cover-product-classification-display" alt={"radioplay"} />
                        }
                        {product.productClassification === 'AUDIOBOOK' &&
                                <img src={audiobook} className="cover-product-classification-display" alt={"audiobook"} />
                        }
                        {product.productClassification === 'MUSIC' &&
                                <img src={music} className="cover-product-classification-display" alt={"music"} />
                        }
                        <div className="cover-product-length-display" dangerouslySetInnerHTML={{__html: getProductLength(product)}}/>
                    </div>
                    <img src={coverUrl} style={{borderRadius: "15px", width: "100%"}}
                         alt={"Titelbild von " + product.title}/>
                </div>
                <div className="search-item-text-container">
                    <div className="search-item-name">
                        {title}
                    </div>
                </div>
            </a>
        );
    }

    getEpisodeNumberText(episode) {
        return isNaN(episode.episodeNumber) ? episode.episodeNumber + ": " : Strings.SEARCH_EPISODE_STANDARD_NAME + " " + episode.episodeNumber + ": ";
    }

}

export default SearchResultItem;

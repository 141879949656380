import React, {useEffect, useState} from "react";
import './../Purchase.scss';
import {useHistory} from "react-router";
import PurchaseStatus from "../PurchaseStatus";
import Strings from "../../../../config/Strings";
import GlobalConstants from "../../../../config/GlobalConstants";
import {authCheck} from "../../../../util/authCheck";
import {TigerButton} from "../../../../global/tiger-button/TigerButton";
import {Form} from "react-bootstrap";
import {ConfirmationModal} from "../../../../global/ConfirmationModal";
import {GetResource} from "../../../../api/SpineClient";

export default function SubscriptionPurchaseAddress() {

    const [purchaseData, setPurchaseData] = useState(JSON.parse(sessionStorage.getItem('ls.purchase')));
    const [contact, setContact] = useState((purchaseData != null && purchaseData.contact != null ) ? purchaseData.contact : {country: "DE"});
    const [email] = useState(JSON.parse(sessionStorage.getItem('email')));
    const [showLoginHint, setShowLoginHint] = useState(false);
    const [formError, setFormError] = useState({
        firstName: null,
        lastName: null,
        street: null,
        houseNumber: null,
        zip: null,
        city: null,
        country: null
    })

    const history = useHistory();

    document.title = Strings.PURCHASE_SUBSCRIPTION_BROWSER_TAB;

    useEffect(() => {
        if (purchaseData == null) {
            history.push("/" + GlobalConstants.APP_PATH + "purchase");
        }
        if (!authCheck()) {
            purchaseData.maxStep = 1;
            sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));
            history.push("/" + GlobalConstants.APP_PATH + "purchase/subscription/authenticate");
        }

        document.title = Strings.PURCHASE_SUBSCRIPTION_BROWSER_TAB;

        GetResource('api/purchases//customer/search/shopify-customer-by-current-account')
            .then(response => {
                if (!response.error) {
                    let shopifyCustomer = response.result;
                    let address = shopifyCustomer.default_address.address1;
                    let houseNumberPos = address.search(/\d/);
                    let houseNumber = address.substring(houseNumberPos);
                    let street = address.substring(0, houseNumberPos);
                    setContact({
                        firstName: shopifyCustomer.first_name,
                        lastName: shopifyCustomer.last_name,
                        street: street,
                        houseNumber: houseNumber,
                        zip: shopifyCustomer.default_address.zip,
                        city: shopifyCustomer.default_address.city,
                        country: shopifyCustomer.default_address.country_code,
                    })
                }
            });
    }, [history, purchaseData]);

    const submitContactData = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }
        contact.email = email != null ? email.email : null;
        purchaseData.contact = contact;
        purchaseData.maxStep = 3;

        setPurchaseData(purchaseData);
        sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));

        history.push("/" + GlobalConstants.APP_PATH + "purchase/subscription/confirmation");

    }

    const validateForm = () => {
        let formIsValid = true;
        if (contact.firstName == null || contact.firstName === "") {
            formIsValid = false;
            setFormError({
                ...formError,
                firstName: Strings.PURCHASE_CONTACT_ERROR
            });
        }
        if (contact.lastName == null || contact.lastName === "") {
            formIsValid = false;
            setFormError({
                ...formError,
                lastName: Strings.PURCHASE_CONTACT_ERROR
            });
        }
        if (contact.street == null || contact.street === "") {
            formIsValid = false;
            setFormError({
                ...formError,
                street: Strings.PURCHASE_CONTACT_ERROR
            });
        }
        if (contact.zip == null || contact.zip === "") {
            formIsValid = false;
            setFormError({
                ...formError,
                zip: Strings.PURCHASE_CONTACT_ERROR
            });
        }
        if (contact.country == null || contact.country === "") {
            formIsValid = false;
            setFormError({
                ...formError,
                country: Strings.PURCHASE_CONTACT_ERROR
            });
        }
        return formIsValid;
    }

    const updateContact = (field, value) => {
        setContact({
            ...contact,
            [field]: value
        });
        setFormError({
            ...formError,
            [field]: null
        });
    }

    const onChangeStep = (step) => {
        switch (step) {
            case 0:
                history.push("/" + GlobalConstants.APP_PATH + "bundles/colors?planVariantId=" + purchaseData.planVariantId + "&componentId=" +  + purchaseData.componentId);
                return;
            case 1:
                setShowLoginHint(true);
                return;
            case 2:
                history.push("/" + GlobalConstants.APP_PATH + "purchase/subscription/payment");
                return;
            case 3:
                if (purchaseData.maxStep > 2) {
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/subscription/confirmation");
                }
                return;
            case 4:
                if (purchaseData.maxStep > 3) {
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/subscription/confirmation");
                }
                return;
            default:
                return;
        }
    }

    return (
        <div className="purchase-container">
            <PurchaseStatus step={2} maxStep={purchaseData ? purchaseData.maxStep : 1} bundled={!!purchaseData?.selectedColor}
                            onChangeStep={(step) => onChangeStep(step)}
            />
            <div className="purchase-info" dangerouslySetInnerHTML={{__html: (purchaseData?.selectedColor ? Strings.PURCHASE_BUNDLE_CONTACT_INFO : Strings.PURCHASE_CONTACT_INFO)}}/>
            <div className="purchase-form-container">
                <div className="purchase-form">
                    <Form className="text-left" onSubmit={(e) => submitContactData(e)}>
                        {/* FIRST NAME */}
                        <Form.Group className="mb-3" controlId="firstNameText">
                            <Form.Label className="auth-label">{Strings.PURCHASE_CONTACT_FIRSTNAME}</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                isInvalid={ formError.firstName != null}
                                value={contact.firstName ? contact.firstName : ''}
                                onChange={e => updateContact('firstName', e.target.value)}
                                plaintext={Strings.PURCHASE_CONTACT_FIRSTNAME}
                            />

                            <Form.Control.Feedback type="invalid">
                                {formError.firstName}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* LAST NAME */}
                        <Form.Group className="mb-3" controlId="lastNameText">
                            <Form.Label className="auth-label">{Strings.PURCHASE_CONTACT_LASTNAME}</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                isInvalid={ formError.lastName != null}
                                value={contact.lastName ? contact.lastName : ''}
                                onChange={e => updateContact('lastName', e.target.value)}
                                plaintext={Strings.PURCHASE_CONTACT_LASTNAME}
                            />

                            <Form.Control.Feedback type="invalid">
                                {formError.lastName}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* STREET */}
                        <div>
                            <Form.Group className="mb-3" style={{display: "inline-block", width: "70%", marginRight: "2%"}} controlId="streetText">
                                <Form.Label className="auth-label">{Strings.PURCHASE_CONTACT_STREET}</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    isInvalid={ formError.street != null}
                                    value={contact.street ? contact.street : ''}
                                    onChange={e => updateContact('street', e.target.value)}
                                    plaintext={Strings.PURCHASE_CONTACT_STREET}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {formError.street}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" style={{display: "inline-block", width: "28%"}} controlId="houseNumberText">
                                <Form.Label className="auth-label">{Strings.PURCHASE_CONTACT_HOUSE_NO}</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    isInvalid={ formError.houseNumber != null}
                                    value={contact.houseNumber ? contact.houseNumber : ''}
                                    onChange={e => updateContact('houseNumber', e.target.value)}
                                    plaintext={Strings.PURCHASE_CONTACT_HOUSE_NO}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {formError.houseNumber}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>

                        {/* ZIP */}
                        <Form.Group className="mb-3" controlId="zipText">
                            <Form.Label className="auth-label">{Strings.PURCHASE_CONTACT_POSTAL_CODE}</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                isInvalid={ formError.zip != null}
                                value={contact.zip ? contact.zip : ''}
                                onChange={e => updateContact('zip', e.target.value)}
                                plaintext={Strings.PURCHASE_CONTACT_POSTAL_CODE}
                            />

                            <Form.Control.Feedback type="invalid">
                                {formError.zip}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* CITY */}
                        <Form.Group className="mb-3" controlId="cityText">
                            <Form.Label className="auth-label">{Strings.PURCHASE_CONTACT_CITY}</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                isInvalid={ formError.city != null}
                                value={contact.city ? contact.city : ''}
                                onChange={e => updateContact('city', e.target.value)}
                                plaintext={Strings.PURCHASE_CONTACT_CITY}
                            />

                            <Form.Control.Feedback type="invalid">
                                {formError.city}
                            </Form.Control.Feedback>
                        </Form.Group>


                        {/* COUNTRY */}
                        <Form.Group className="mb-3" controlId="countrySelectBox">
                            <Form.Label className="auth-label">{Strings.PURCHASE_CONTACT_COUNTRY}</Form.Label>
                            {!purchaseData?.selectedColor &&
                                <Form.Control
                                    style={{height: "40px", fontSize: "16pt"}}
                                    as="select"
                                    required
                                    isInvalid={ formError.country != null}
                                    value={contact.country}
                                    onChange={e => updateContact('country', e.target.value)}
                                >
                                    <option value="DE">{Strings.PURCHASE_COUNTRY_DE}</option>
                                    <option value="AT">{Strings.PURCHASE_COUNTRY_AT}</option>
                                    <option value="CH">{Strings.PURCHASE_COUNTRY_CH}</option>
                                </Form.Control>
                            }
                            {purchaseData?.selectedColor &&
                                <Form.Control
                                    style={{height: "40px", fontSize: "16pt"}}
                                    as="select"
                                    required
                                    isInvalid={ formError.country != null}
                                    value={contact.country}
                                    onChange={e => updateContact('country', e.target.value)}
                                    disabled={true}
                                >
                                    <option value="DE">{Strings.PURCHASE_COUNTRY_DE}</option>
                                </Form.Control>
                            }
                            <Form.Control.Feedback type="invalid">
                                {formError.country}
                            </Form.Control.Feedback>
                        </Form.Group>


                        <br />

                        <TigerButton className="w-100" variant="red">
                            {Strings.PURCHASE_PROCEED_BUTTON}
                        </TigerButton>
                    </Form>
                </div>
                <ConfirmationModal
                    title={Strings.PURCHASE_REAUTHENTICATE_TITLE}
                    text={Strings.PURCHASE_REAUTHENTICATE_HINT}
                    actionButtonLabel={Strings.PURCHASE_REAUTHENTICATE_BUTTON}
                    actionButtonVariant="danger"
                    onActionPress={() => {
                        localStorage.removeItem("ls.token");
                        sessionStorage.removeItem("ls.purchase");
                        history.push("/" + GlobalConstants.APP_PATH + "purchase/" + purchaseData.planVariantId + '?selectedColor=' + (purchaseData.selectedColor ? purchaseData.selectedColor : '') + "&componentId=" + purchaseData.componentId + (purchaseData.discountId ? "&discountId=" + purchaseData.discountId : '') + (purchaseData.selectedGtin ? "&selectedGtin=" + purchaseData.selectedGtin : ''))
                    }}
                    show={showLoginHint}
                    onHide={() => setShowLoginHint(false)}
                />
            </div>

        </div>
    )
}
import "./Authentication.css";
import React, {useCallback, useState} from "react";
import PropTypes from 'prop-types';
import {loginUser} from "./Oauth2Service";
import Strings from "../config/Strings";
import {Form, Spinner} from "react-bootstrap";
import { useHistory } from "react-router";
import GlobalConstants from "../config/GlobalConstants";
import { authCheck } from "../util/authCheck";
import { useEffect } from "react";
import {Link} from "react-router-dom";
import { TigerButton } from "../global/tiger-button/TigerButton";
import {Helmet} from "react-helmet";

export default function Authentication({ setToken }) {
    const queryParams = new URLSearchParams(window.location.search);
    const newUser = queryParams.get("new") != null && queryParams.get("new") === "true";
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(false);
    const [redirectTarget, setRedirectTarget] = useState(null);

    const history = useHistory();
    const goto = useCallback(() => {
        history.push(redirectTarget);
    }, [redirectTarget, history]);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        let redirectTarget = queryParams.get("goto") ? queryParams.get("goto") : "/" + GlobalConstants.APP_PATH + "dashboard";
        setRedirectTarget(redirectTarget);
        authCheck() && goto(redirectTarget);
    }, [goto]);


    const setField = (field, value, isValid) => {
        setForm({
          ...form,
          [field]: value
        });
        
        if (!isValid) {
            setErrors({
                ...errors,
                [field]: true
            });
        }
        else {
            setErrors({
                ...errors,
                [field]: false
            });
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setSubmitDisable(true);
        setLoginError(false);

        let hasErrors = !Object.values(errors).every(x => x === false);

        if (!hasErrors) {
            setSubmitted(true);

            const {email, password} = form;

            let token = await loginUser({
                email,
                password
            });

            if (token) {
                //Token is available -> Set the expiration date/time
                let expiredAt = new Date();
                expiredAt.setSeconds(expiredAt.getSeconds() + token.expires_in);
                token.expires_at = expiredAt.getTime();

                //Now save the token
                setToken(token);

                goto(redirectTarget);
            } else {
                setLoginError(true);
                setSubmitDisable(false);
            }
        }
    }

    return(
        <>
            <div className="authentication-form">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{"Login :: tiger.media"}</title>
                    <meta name="description" content={'Melde dich hier mit deinem tigermedia-Account an, um deine tigertones-Zugänge, Profile, tigerboxen und Helden und Welten zu verwalten'} />
                    <meta name="keywords" content={'tigertones,login,anmeldung,tigerbox,account,konto,tigermedia'} />
                    <link rel="canonical" href={"https://my.tiger.media/login"}/>
                </Helmet>

                <p style={{fontSize: "50px", fontWeight: "bold"}}>{Strings.AUTHENTICATION_TITLE}</p>
                <p style={{fontSize: "20px"}} dangerouslySetInnerHTML={{__html: newUser ? Strings.AUTHENTICATION_INFO_NEW_ACCOUNT : Strings.AUTHENTICATION_INFO}} />

                <Form className="text-left" onSubmit={handleSubmit}>
                    {/* Email */}
                    <Form.Group className="mb-3" controlId="loginFormEmail">
                        <Form.Label className="auth-label">{Strings.AUTHENTICATION_EMAIL}</Form.Label>
                        <Form.Control 
                            type="email" 
                            required 
                            isInvalid={ errors.email }
                            onChange={ e => setField('email', e.target.value, e.target.checkValidity()) }
                        />
                        
                        <Form.Control.Feedback type="invalid">
                            {Strings.AUTHENTICATION_ERROR_INVALID_EMAIL}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {/* Password */}
                    <Form.Group className="mb-3" controlId="loginFormPassword">
                        <Form.Label className="auth-label">{Strings.AUTHENTICATION_PASSWORD}</Form.Label>
                        <Link className="password-forgot" to={"/" + GlobalConstants.APP_PATH + "forgot-password"}>
                            {Strings.FORGOT_PASSWORD_TITLE}
                        </Link>
                        <Form.Control 
                            type="password" 
                            required 
                            isInvalid={ errors.password }
                            onChange={ e => setField('password', e.target.value, e.target.checkValidity()) }
                        />

                        <Form.Control.Feedback type="invalid">
                            {Strings.AUTHENTICATION_ERROR_BLANK_PASSWORD}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <p className="text-danger" style={{fontSize: "20px"}} hidden={!(loginError && submitted)}>
                        {Strings.AUTHENTICATION_ERROR_BAD_CREDENTIALS}
                    </p>

                    <TigerButton className="w-100" disabled={submitDisable} variant="red">
                        {(submitDisable && submitted) ?
                            <Spinner animation="border" />
                            : Strings.AUTHENTICATION_SUBMIT}
                    </TigerButton>
                </Form>

                <p style={{marginTop: "30px", fontSize: "20px"}}>
                    {Strings.AUTHENTICATION_REGISTER_QUESTION}<br />
                    <Link style={{fontWeight: "bold", color: "#333", textDecoration: "underline"}} to={"/" + GlobalConstants.APP_PATH + "register"}>
                        {Strings.AUTHENTICATION_REGISTER_LINK}
                    </Link>
                </p>
            </div>
        </>
    )
}

Authentication.propTypes = {
    setToken: PropTypes.func.isRequired
}
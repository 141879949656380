import React, { Component } from "react";
import "../../Wildcards.scss";
import "./WildcardContent.css";
import {withToast} from "../../../../util/ToastService";
import {
    TOTAL_DURATION_IN_SECONDS,
    MAXIMUM_FILE_SIZE_MB,
    BYTE_TO_MEGABYTE,
    checkFileExtension,
} from "../../WildcardHelper";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {BiPencil, FiTrash, ImCross} from "react-icons/all";
import {Button, FormControl} from "react-bootstrap";
import {
    AddWildcardContent, DeleteAllWildcardContent,
    DeleteWildcardContent,
    GetWildcardContent,
    UpdateContentOrder,
    UpdateMetadata,
} from "../../WildcardService";
import Strings from "../../../../config/Strings";
import {TigerButton} from "../../../../global/tiger-button/TigerButton";
import {ConfirmationModal} from "../../../../global/ConfirmationModal";
import {AudioItem} from "./audio-item/AudioItem";
import {UploadInformation} from "./upload-information/UploadInformation";

class WildcardContent extends Component {
    constructor(props) {
        super(props);

        this.audioFileInput = React.createRef();

        this.state = {
            isEditing: false,

            showRemoveItemModal: false,
            itemToRemove: null,
            removedItemsSortNumber: [],

            content: null,
            playlistName: null,
            audioFiles: [],
            newPlaylistName: null,
            showTermsModal: false,
            showUploadInformation: false,

            uploadStep: 1,
            showRemoveAllModal: false,
            playlistNameEditable: this.props.wildcardProps.product.id == null,

            addItemsMode: false
        };

        this.onDragEnd = this.onDragEnd.bind(this);
    }

    async componentDidMount() {
        await this.loadContent();
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.audioFiles.length !== this.state.audioFiles.length) {
            const total = this.state.audioFiles.map(af => af.duration).reduce((a, b) => a + b, 0);
            this.props.setPlayingTime(total);
        }
    }

    async loadContent() {
        if (this.props.wildcardProps.accountGeneratedContents) {
            GetWildcardContent(this.props.wildcard).then(res => {
                if (!res.error) {
                    const content = res.result;
                    this.setState({
                        content: content,
                        playlistName: content.name,
                        audioFiles: content.accountGeneratedContentItems,
                        removedItemsSortNumber: []
                    });
                }
            });
        } else {
            this.setState({
                content: null,
                playlistName: this.props.wildcardProps.product.title,
                audioFiles: []
            });
        }
    }

    openAudioFileDialog = () => {
        this.audioFileInput.current.click();
    }

    onAudioFilesAdded = (event) => {
        if (event.target.files && event.target.files?.length > 0) {
            let filesUploadedList = event.target.files;
            //sort the new added files by filename
            let filesUploaded = [];
            for (const element of filesUploadedList) {
                filesUploaded.push(element);
            }
            filesUploaded.sort((file1, file2) => {
                if (file1.name < file2.name) {
                    return -1
                } else {
                    return 1
                }
            }
            );

            for (let i = 0; i < filesUploaded.length; i++) {
                const file = filesUploaded[i];

                if (checkFileExtension(file.name, "mp3") || checkFileExtension(file.name, "aac")) {
                    const url = URL.createObjectURL(file);
                    const audio = new Audio(url);

                    audio.onerror = () => {
                        this.props.addToast(Strings.WILDCARDS_UPLOAD_ERROR_NOT_MP3, {
                            autoDismiss: true,
                            appearance: 'error',
                            placement: 'bottom-center'
                        });
                    };
                    audio.onloadedmetadata = () => {
                        const durationInSecondsRounded = Math.floor(audio.duration);
                        const fileSmallerThanMaximumFileSize = file.size / BYTE_TO_MEGABYTE <= MAXIMUM_FILE_SIZE_MB;
                        const newDurationSmallerThanTotalDuration = this.props.totalDuration + durationInSecondsRounded <= TOTAL_DURATION_IN_SECONDS;

                        const newFileFits = fileSmallerThanMaximumFileSize && newDurationSmallerThanTotalDuration;

                        if (newFileFits) {
                            const audioFileData = {
                                name: file.name,
                                isEditable: true,
                                audio: audio,
                                duration: audio.duration,
                                file: file,
                                sortNumber: null
                            };

                            this.setState(prevState => ({
                                audioFiles: [
                                    ...prevState.audioFiles,
                                    audioFileData
                                ],
                                addItemsMode: true,
                            }))
                        }
                        else {
                            let errorMessage;
                            if (!newDurationSmallerThanTotalDuration) {
                                errorMessage = Strings.WILDCARDS_UPLOAD_ERROR_MAX_LENGTH_EXCEEDED;
                            } else if (file.size / BYTE_TO_MEGABYTE > MAXIMUM_FILE_SIZE_MB) {
                                errorMessage = Strings.WILDCARDS_UPLOAD_ERROR_FILE_SIZE;
                            } else {
                                errorMessage = Strings.WILDCARDS_UPLOAD_ERROR_PLAYLIST_SIZE;
                            }
                            this.props.addToast(errorMessage, {
                                autoDismiss: true,
                                appearance: 'error',
                                placement: 'bottom-center'
                            });
                        }
                    };
                } else {
                    this.props.addToast(Strings.WILDCARDS_UPLOAD_ERROR_NOT_MP3 + " : " + file.name, {
                        autoDismiss: true,
                        appearance: 'error',
                        placement: 'bottom-center'
                    });
                }
            }
        }
        if (this.audioFileInput.current) {
            this.audioFileInput.current.value = '';
        }
    }

    removeItemClick = (item, index) => {
        this.setState({
            showRemoveItemModal: true,
            itemToRemove: {
                item: item,
                index: index
            }
        })
    }

    removeAudioItem = async () => {
        const audio = this.state.itemToRemove.item.audio;
        if (audio) {
            audio.pause();
        }

        let removeItemsEndpoint;

        if (this.props.wildcardProps.accountGeneratedContents) {
            removeItemsEndpoint = this.props.wildcardProps.accountGeneratedContents._links['add-content'].href;
        }

        // removing
        if (removeItemsEndpoint) {
            const removed = [Number(this.state.itemToRemove.item.sortNumber)];
            await DeleteWildcardContent(removeItemsEndpoint, removed);
        }

        let fileArray = [...this.state.audioFiles];
        fileArray.splice(this.state.itemToRemove.index, 1);
        this.setState(prevState => ({
            audioFiles: fileArray,
            removedItemsSortNumber: [
                ...prevState.removedItemsSortNumber,
                this.state.itemToRemove.item.sortNumber
            ],
            itemToRemove: null,
            showRemoveItemModal: false
        }));

    }

    renameItem = (item, newName) => {
        let fileArray = [...this.state.audioFiles];
        let index = fileArray.indexOf(item);
        fileArray[index].name = newName;
        this.setState({
            audioFiles: fileArray
        })
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        if (sourceIndex === destinationIndex) {
            return;
        }

        const items = this.reorder(
            this.state.audioFiles,
            sourceIndex,
            destinationIndex
        );

        this.setState({
            audioFiles: items
        });
    }

    savePlaylist = async () => {
        this.setState({
            isEditing: false,
            showTermsModal: false,
            uploadStep: 1
        });

        let removeItemsEndpoint;
        let orderItemsEndpoint;
        let uploadContentEndpoint;

        if (this.props.wildcardProps.accountGeneratedContents) {
            removeItemsEndpoint = this.props.wildcardProps.accountGeneratedContents._links['add-content'].href;
            orderItemsEndpoint = this.props.wildcardProps.accountGeneratedContents._links['update-item-order'].href;
            uploadContentEndpoint = this.props.wildcardProps.accountGeneratedContents._links['add-content'].href;
        }
        else {
            uploadContentEndpoint = this.props.wildcard._links['upload'].href;
        }

        // removing
        if (removeItemsEndpoint) {
            this.setState({
                showUploadInformation: false
            }, () =>  this.setState({
                uploadInformationText: "Gelöschte Dateien werden entfernt",
                showUploadInformation: true
            }));

            const removed = this.state.removedItemsSortNumber.filter(Number);
            if (removed.length > 0) {
                await DeleteWildcardContent(removeItemsEndpoint, removed);
            }
        }

        // ordering
        if (orderItemsEndpoint && !this.state.addItemsMode) {
            this.setState({
                uploadInformationText: "Neue Sortierung der Dateien wird gespeichert",
                showUploadInformation: true
            });

            const order = this.state.audioFiles.map(af => af.sortNumber)
                .filter(Number)
                .filter(n => n > 0);
            this.normalize(order);
            await UpdateContentOrder(orderItemsEndpoint, order).then(() => {
                // update sort numbers
                let fileArray = [...this.state.audioFiles];
                for(let i = 0; i < fileArray.length; i++) {
                    if (!fileArray.isEditable) {
                        fileArray[i].sortNumber = i + 1;
                    }
                }
                this.setState({
                    audioFiles: fileArray
                })
            });
        }

        // uploading
        if (uploadContentEndpoint && this.state.addItemsMode) {
            this.setState({
                uploadInformationText: "Neue Dateien werden hochgeladen",
                showUploadInformation: true
            })

            let uploadArray = [];
            let fileArray = [...this.state.audioFiles];
            for (let i = 1; i <= fileArray.length; i++) {
                let item = fileArray[i - 1];

                if (!item.sortNumber) {
                    item.sortNumber = i;
                }

                if (item.isEditable) {
                    const fileToUpload = {
                        wildcarditem: item.file,
                        wildcarditemname: item.name,
                        wildcarditemposition: item.sortNumber
                    };
                    uploadArray.push(fileToUpload);
                }
            }

            if (uploadArray.length !== 0) {
                // add playlist name
                uploadArray.push({contentName: this.state.newPlaylistName || this.state.playlistName});
                if (this.props.coverToUpload) {
                    uploadArray.push({cover: this.props.coverToUpload});
                }
                await AddWildcardContent(uploadContentEndpoint, uploadArray);
            }
        }

        this.checkTranscodingStatus();
    }

    // normalizes the array of numbers keeping the original order
    // eg. [5,7,3] -> [2,3,1]
    //     [3,2,4] -> [3,2,1]
    //     [4,1,2,8] -> [3,1,2,4]
    normalize = (numberArray) => {
        let helpArray = [...numberArray].sort((a, b) => b-a);
        let map = new Map();
        for(let i = 0; i < helpArray.length; i++) {
            const num = helpArray[i];
            const index = helpArray.length - i;
            map.set(num, index);
        }
        for(let i = 0; i < numberArray.length; i++) {
            numberArray[i] = map.get(numberArray[i]);
        }
    }

    updatePlaylistName() {
        if (this.state.newPlaylistName) {
            if (this.props.wildcardProps.accountGeneratedContents) {
                UpdateMetadata(this.props.wildcardProps.accountGeneratedContents, {name: this.state.newPlaylistName}).then(res => {
                    if (!res.error) {
                        this.setState({
                            playlistName: this.state.newPlaylistName
                        })
                    }
                });
            }
        }
    }

    checkTranscodingStatus = () => {
        this.setState({
            uploadInformationText: "Die hochgeladenen Daten werden für die Nutzung mit deiner wildcard verarbeitet",
        });
        this.props.loadWildcard();
        const interval = setInterval(() => {
            if (this.props.wildcardProps.product.transcodingStatus !== "FINISHED") {
                this.props.loadWildcard();
                this.loadContent();
            } else {
                this.setState({
                    uploadStep: 2
                });
                clearInterval(interval);
            }
        }, 5000);
    }

    deleteAllContent = () => {
        DeleteAllWildcardContent(this.props.wildcard).then((res) => {
            if(!res.error) {
                this.props.addToast(Strings.WILDCARDS_MODAL_EMPTY_SUCCESS, {
                    autoDismiss: true,
                    appearance: 'success',
                    placement: 'bottom-center'
                });
                this.setState({
                    content: null,
                    audioFiles: []
                }, () => this.props.loadWildcard());
            }
            else {
                this.props.addToast(Strings.WILDCARDS_MODAL_EMPTY_ERROR + " : " + res.message, {
                    autoDismiss: true,
                    appearance: 'error',
                    placement: 'bottom-center'
                });
            }
            this.setState({
                showRemoveAllModal: false
            });
        });
    }

    render() {
        return (
                <>
                    <div className="d-flex align-items-center p-2">
                        <div className="playlist-name">
                            {this.state.isEditing && this.state.playlistNameEditable && (
                                    <FormControl
                                            size="lg"
                                            defaultValue={this.state.playlistName}
                                            onChange={e => this.setState({newPlaylistName: e.target.value})}
                                    />
                            )}
                            {this.state.isEditing && !this.state.playlistNameEditable &&
                                    <h2>{this.state.playlistName}</h2>}
                            {!this.state.isEditing && <h2>{this.state.playlistName}</h2>}
                        </div>
                    </div>
                    <div className="d-flex justify-content-end p-2">
                        {this.state.content && !this.state.isEditing && (
                                <Button
                                        variant="secondary"
                                        size="lg"
                                        className="d-flex align-items-center rounded-circle p-2 mx-1"
                                        onClick={() => {
                                            this.setState({showRemoveAllModal: true})
                                        }}
                                >
                                    <FiTrash/>
                                </Button>
                        )}
                        <Button
                                variant={this.state.isEditing ? 'danger' : 'secondary'}
                                size="lg"
                                className="d-flex align-items-center rounded-circle p-2 mx-1"
                                onClick={() => {
                                    this.setState(prevState => ({isEditing: !prevState.isEditing}))
                                }}
                        >
                            {this.state.isEditing ? <ImCross/> : <BiPencil/>}
                        </Button>
                    </div>
                    {!this.state.content && !this.state.isEditing && (
                            <div className="p-2">
                                {this.props.wildcardProps.description.length !== 0 && this.state.audioFiles.length === 0 && (
                                        this.props.wildcardProps.description.map((paragraph, index) => (
                                                <p key={index}>{paragraph}</p>
                                        ))
                                )}
                            </div>
                    )}
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                    <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className="content-list select-none p-2"
                                    >
                                        {this.state.isEditing && (!this.state.audioFiles || this.state.audioFiles.length === 0) ? (
                                                <h3 className="text-center my-5">{Strings.WILDCARDS_NO_CONTENT}</h3>
                                        ) : (
                                                this.state.audioFiles.map((item, index) => (
                                                        <AudioItem
                                                                key={index}
                                                                item={item}
                                                                index={index}
                                                                isEditing={this.state.isEditing}
                                                                onItemRename={this.renameItem}
                                                                onItemDelete={() => this.removeItemClick(item, index)}
                                                        />
                                                ))
                                        )}
                                        {provided.placeholder}
                                    </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    {this.state.isEditing && (
                            <>
                                <input
                                        type="file"
                                        multiple
                                        className="d-none"
                                        onChange={this.onAudioFilesAdded}
                                        ref={this.audioFileInput}
                                        accept=".mp3,.aac"
                                />
                                <div className="d-flex justify-content-around">
                                    <TigerButton
                                            className="btn-ci btn-ci-hover"
                                            variant="green"
                                            onClick={() => this.setState({showTermsModal: true})}
                                            disabled={!this.state.audioFiles || this.state.audioFiles.length === 0}
                                    >
                                        {Strings.WILDCARDS_SAVE_BUTTON_LABEL}
                                    </TigerButton>
                                    <TigerButton
                                            className="btn-ci btn-ci-hover"
                                            variant="green"
                                            onClick={() => this.openAudioFileDialog()}
                                    >
                                        {Strings.WILDCARDS_UPLOAD_BUTTON_LABEL}
                                    </TigerButton>
                                </div>
                            </>
                    )}

                    <hr/>

                    <ConfirmationModal
                            title={Strings.WILDCARDS_MODAL_REMOVE_ITEM_TITLE}
                            text={Strings.WILDCARDS_MODAL_REMOVE_ITEM_TEXT}
                            actionButtonLabel={Strings.WILDCARDS_MODAL_REMOVE_ITEM_BUTTON_LABEL}
                            actionButtonVariant="danger"
                            onActionPress={() => this.removeAudioItem()}
                            show={this.state.showRemoveItemModal}
                            onHide={() => this.setState({showRemoveItemModal: false})}
                    />

                    <ConfirmationModal
                            title={Strings.WILDCARDS_MODAL_EMPTY_TITLE}
                            text={Strings.WILDCARDS_MODAL_EMPTY_TEXT}
                            actionButtonLabel={Strings.WILDCARDS_MODAL_EMPTY_YES}
                            actionButtonVariant="danger"
                            onActionPress={() => this.deleteAllContent()}
                            show={this.state.showRemoveAllModal}
                            onHide={() => this.setState({showRemoveAllModal: false})}
                    />

                    <ConfirmationModal
                            title={Strings.WILDCARDS_SAVE_TERMS_MODAL_TITLE}
                            actionButtonLabel={Strings.WILDCARDS_SAVE_TERMS_MODAL_ACCEPT}
                            actionButtonVariant="green"
                            closeButtonLabel={Strings.WILDCARDS_SAVE_TERMS_MODAL_DECLINE}
                            closeButtonVariant="danger"
                            onActionPress={() => this.savePlaylist()}
                            show={this.state.showTermsModal}
                            onHide={() => this.setState({showTermsModal: false})}
                            text={Strings.WILDCARDS_AGB}
                    />

                    <UploadInformation
                            step={this.state.uploadStep}
                            uploadInformationText={this.state.uploadInformationText}
                            show={this.state.showUploadInformation}
                            onHide={() => this.setState({showUploadInformation: false})}
                    />
                </>
        )
    }
}

export default withToast(WildcardContent);
import * as fileType from 'file-type';
import Strings from "../../config/Strings";

export const TOTAL_DURATION_IN_SECONDS = 7200;
export const MAXIMUM_FILE_SIZE_MB = 500;
export const MAXIMUM_PLAYLIST_FILE_SIZE_MB = 500;
export const BYTE_TO_MEGABYTE = 1000000;

export var calculateDuration = (duration) => {
    const durationMinutes = Math.floor(duration / 60);
    const durationSeconds = Math.floor(duration % 60);

    if (durationSeconds < 10) {
        return `${durationMinutes}:0${durationSeconds} Minuten`;
    }

    return `${durationMinutes}:${durationSeconds} Minuten`;
};

export var isValidWildcardCode = (code) => {
    return code.match(/([a-z]|[A-Z]|[0-9]){4}-([a-z]|[A-Z]|[0-9]){10}/);
};

export const checkFileMimeType = (file, typesToCheck, errorCallback, successCallback) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onloadend = (e) => {
        if (e.target && e.target.result) {
            const bytes = new Uint8Array(e.target.result);
            fileType.fromBuffer(bytes).then((res) => {
                if (res && typesToCheck.includes(res.mime)) {
                    successCallback(file);
                } else {
                    errorCallback();
                }
            });
        }
    };
};

export const checkFileExtension = (fileName, extension) => {
     return fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2) === extension;
}

export const getTranscodingStatus = (status) => {
    switch (status) {
        case "FINISHED_AND_ADDITIONAL_CONTENT_UPLOADED":
        case "UPLOADED_BY_USER":
        case "FINISHED_AND_COVER_UPLOADED_BY_USER":
            return Strings.WILDCARDS_TRANSCODE_UPLOAD_SUCCESS;
        case "FINISHED_AND_CHECK_ADDITIONAL_CONTENT_FOR_DURATION":
        case "CHECK_FOR_DURATION":
            return Strings.WILDCARDS_TRANSCODE_CHECKING;
        case "FINISHED_AND_ADDITIONAL_CONTENT_ENQUEUED":
        case "ENQUEUED":
        case "COVER_ENQEUED":
        case "COVER_UPLOADED_BY_USER":
            return Strings.WILDCARDS_TRANSCODE_PROCESSING;
        case "FAILED":
            return Strings.WILDCARDS_TRANSCODE_FAILED;
        case "FINISHED":
            return Strings.WILDCARDS_TRANSCODE_FINISHED;
        case "EDITED_BY_USER":
            return Strings.WILDCARDS_TRANSCODE_PROCESSED;
        default:
            return Strings.WILDCARDS_TRANSCODE_UPLOADED;
    }
}
import React, {useEffect, useState} from "react";
import './../Purchase.scss';
import Strings from "../../../../config/Strings";
import {GetFilteredResources, GetUnauthorizedResources} from "../../../../api/SpineClient";
import {useHistory} from "react-router";
import {authCheck} from "../../../../util/authCheck";
import GlobalConstants from "../../../../config/GlobalConstants";
import {ConfirmationModal} from "../../../../global/ConfirmationModal";
import PurchaseStatus from "../PurchaseStatus";
import {TigerSpinner} from "../../../../global/tiger-spinner/TigerSpinner";
import {SingleTarif} from "./single-tarif/SingleTarif";
import './Tarif.css';
import {Row} from "react-bootstrap";

export default function TarifProductSelection({ setToken, setEmail }) {


    const [loading, setLoading] = useState(true);
    const [tarifList, setTarifList] = useState([]);
    let [tarif, setTarif] = useState(null);
    const [showLoginHint, setShowLoginHint] = useState(false);
    let [purchaseData] = useState(JSON.parse(sessionStorage.getItem('ls.purchase')));

    const history = useHistory();

    document.title = Strings.PURCHASE_SUBSCRIPTION_BROWSER_TAB;

    //Get the premium access type id if it's provided
    const searchParams = new URLSearchParams(window.location.search);
    let tarifId = (searchParams != null && searchParams.get("tarif") != null) ? searchParams.get("tarif") : null;

    useEffect(() => {
        if (purchaseData == null || !authCheck()) {
            //Set the max step to 1 and proceed
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            if (tarifId != null) {
                history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif?tarif=" + tarifId);
            } else {
                history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif");
            }
        } else {
            //Set the mex step
            if (purchaseData.maxStep < 2) {
                purchaseData.maxStep = 2;
            }
        }

        if (tarifId != null && (tarif == null || (tarif.id !== tarifId))) {
            setLoading(true);
            GetUnauthorizedResources('api/purchases/premium-access-types/' + tarifId, {},
                'application/json', GlobalConstants.BILL_HOST)
                .then(r => {
                    if (!r.error && r.result != null && r.result.name != null) {
                        setTarif(r.result);
                    }
                    setLoading(false);
                });
        }

        if (tarifList.length === 0) {
            setLoading(true);
            GetFilteredResources('api/purchases/premium-access-types', {}, 'application/json', GlobalConstants.BILL_HOST)
                .then(r => {
                    if (!r.error && r.result != null) {
                        if (Array.isArray(r.result) && r.result.length > 0) {
                            setTarifList(r.result);
                            if (tarif != null && tarifList.map(t => t.id).indexOf(tarif.id) > -1) {
                                purchaseData.tarif = tarif.id;
                                purchaseData.paymentRequired = tarif.paymentRequired;
                                setTarif(tarif);
                                sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));
                                window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                                if (tarif.paymentRequired) {
                                    history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/payment");
                                } else {
                                    history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/address");
                                }
                            }
                        } else {
                            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                            history.push("/" + GlobalConstants.APP_PATH + "dashboard?info=no-tarif");
                        }
                    } else {
                        if (r.message != null && r.message.indexOf("24") > -1) {
                            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                            history.push("/" + GlobalConstants.APP_PATH + "dashboard?info=active-iap");
                        } else {
                            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                            history.push("/" + GlobalConstants.APP_PATH + "dashboard?info=tarif-error");
                        }
                    }
                    setLoading(false);
                });
        } else if (tarif != null && tarifList.map(t => t.id).indexOf(tarif.id) > -1) {
            purchaseData.tarif = tarif.id;
            purchaseData.paymentRequired = tarif.paymentRequired;
            setTarif(tarif);
            sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            if (tarif.paymentRequired) {
                history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/payment");
            } else {
                history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/address");
            }
        } else {
            setLoading(false);
        }

        sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));

    }, [history, purchaseData, tarifId, tarif, tarifList, tarifList.length]);

    const onChangeStep = (step) => {
        switch (step) {
            case 1:
                setShowLoginHint(true);
                return;
            case 2:
                //Active step -> Do nothing
                return;
            case 3:
                if (purchaseData.maxStep > 2) {
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/confirmation");
                }
                return;
            case 4:
                if (purchaseData.maxStep > 3) {
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/success");
                }
                return;
            default:
                return;
        }
    }

    const onSelectTarif = (tarif) => {
        if (tarif != null && tarif.id != null) {
            purchaseData.tarif = tarif.id;
            purchaseData.paymentRequired = tarif.paymentRequired;
            setTarif(tarif);
            sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            if (tarif.paymentRequired) {
                history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/payment");
            } else {
                history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif/address");
            }
        }
    }

    if (loading) {
        return <TigerSpinner />
    }

    return (
        <div className="purchase-container">
            <PurchaseStatus id="purchase-step-2" step={2} maxStep={purchaseData != null ? purchaseData.maxStep : 1}
                            onChangeStep={(step) => onChangeStep(step)}
            />
            <div className="purchase-info" dangerouslySetInnerHTML={{__html: Strings.PURCHASE_TARIF_SELECT_TARIF}} />

            <div className="mx-5">
                <Row className="tarif-list">
                    {tarifList.length > 0 &&
                        tarifList.map(t => <SingleTarif tarif={t} onSelectTarif={() => onSelectTarif(t)} />)
                    }
                </Row>
            </div>

            <ConfirmationModal
                title={Strings.PURCHASE_REAUTHENTICATE_TITLE}
                text={Strings.PURCHASE_REAUTHENTICATE_HINT}
                actionButtonLabel={Strings.PURCHASE_REAUTHENTICATE_BUTTON}
                actionButtonVariant="danger"
                onActionPress={() => {
                    localStorage.removeItem("ls.token");
                    sessionStorage.removeItem("ls.purchase");
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                    if (tarifId != null) {
                        history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif?tarif=" + tarifId);
                    } else {
                        history.push("/" + GlobalConstants.APP_PATH + "purchase/tarif");
                    }
                }}
                show={showLoginHint}
                onHide={() => setShowLoginHint(false)}
            />
        </div>
    )
}
import {GetResource, DeleteResource, GetFilteredResources} from "../../api/SpineClient";

//API METHODS

export async function GetAccount(accountId) {
    return await GetResource('api/accounts/' + accountId);
}

export async function DeleteRecentlyUsedProduct(accountId, productId) {
    return await DeleteResource('api/accounts/' + accountId + '/recentlyUsedProducts/' + productId, true);
}

export async function GetRecentlyUsedProducts(accountId) {
    return await GetFilteredResources('api/accounts/' + accountId + '/recentlyUsedProducts', {});
}